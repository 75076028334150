import React, { memo } from "react";
import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Stack, useTheme } from "@mui/system";
import { css } from "@emotion/css";

import { IChips } from "src/interfaces/IChips/IChips";
import Chips from "../core/Chips/Chips";
import { SearchField } from "../SearchField/SearchField";

export const useStylesForSubHeader = (theme: Theme, marginBottom: boolean = true) => ({
    filtersWrapper: css({
        marginBottom: marginBottom ? "20px !important" : 0,
        [theme.breakpoints.down("md")]: {
            height: "auto !important",
            flexWrap: "wrap",
        },
    }),
    searchInput: css({
        width: "300px",
        "& .MuiInputBase-root": {
            fontSize: "15px",
            padding: "10px",
            "& input": {
                padding: 0,
            },
        },
    }),
});

interface ISubHeaderProps {
    title: string;
    itemsLength?: number;
    multiple?: boolean;
    chosenItemsLength?: number;
    chipsValues?: IChips[];
    isEventPerform?: boolean;
    filterComponent?: JSX.Element;
    isSearch?: boolean;
    marginBottom?: boolean;
    showCount?: boolean;
    chipUpdated?: boolean;
    extraComponent?: JSX.Element | null;
    className?: string;
    onSearchChange(item: string): void;

    onSearchChange(item: string): void;

    onChipsChange?(items: number[] | string[]): void;

    onChipsChangeSingle?(item: number): void;
}

export const SubHeader: React.FC<ISubHeaderProps> = memo(
    ({
        onSearchChange,
        itemsLength,
        title,
        chosenItemsLength,
        chipsValues,
        multiple = true,
        onChipsChange,
        onChipsChangeSingle,
        isEventPerform = true,
        filterComponent,
        isSearch = true,
        marginBottom,
        extraComponent,
        showCount = true,
        chipUpdated = false,
        className = "",
    }) => {
        const classes = useStylesForSubHeader(useTheme(), marginBottom);

        const handleChipsChange = (item: number[] | string[] | number) => {
            if (multiple && onChipsChange && typeof item !== "number") {
                onChipsChange(item);
            } else if (onChipsChangeSingle && typeof item === "number") {
                onChipsChangeSingle(item);
            }
        };

        return (
            <Stack
                className={`${className} ${classes.filtersWrapper}`}
                direction="row"
                gap="16px"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="row" gap="16px" alignItems="center" flexWrap="wrap">
                    {isSearch && (
                        <SearchField
                            placeholder={`Search ${title.toLowerCase()}`}
                            onChange={(e: any) => onSearchChange(e.target.value)}
                        />
                    )}
                    {filterComponent && filterComponent}
                    {chipsValues && chosenItemsLength !== undefined && (
                        <Chips
                            items={chipsValues}
                            callback={handleChipsChange}
                            disabled={chosenItemsLength > 0}
                            multiple={multiple}
                            isEventPerform={isEventPerform}
                            isMargin={false}
                            chipUpdated={chipUpdated}
                        />
                    )}
                </Stack>
                {showCount && (
                    <Typography fontSize="15px" variant="body2">
                        {itemsLength} {title}
                    </Typography>
                )}
                {extraComponent && extraComponent}
            </Stack>
        );
    },
);
