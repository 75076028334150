import React, { FC } from "react";
import { Avatar, Divider, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Box } from "@mui/system";

import { demoModeSelector } from "../../../reduxState/slices/demoModeSlice";
import companyLogoMini from "../../../assets/logo/companyLogoMini.png";
import { userStateSelector } from "../../../reduxState/slices/userSlice";
import useAvatar from "../../MemberCard/useAvatar";
import { useLogoutMutation } from "../../../reduxState/apis/authApi";
import { cleanUpUserInfo } from "../../../services/auth/logout";
import { LightMenu } from "../../LightMenu/LightMenu";
import { LOGIN_PATH, USER_SETTINGS_PATH } from "../../../consts/path/path";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

interface IAccountSettings {
    classes: any;
}

export const AccountSettings: FC<IAccountSettings> = ({ classes }) => {
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();

    const currentUser = useSelector(userStateSelector);
    const isDemoMode = useSelector(demoModeSelector);
    const avatarUrl = useAvatar(currentUser.avatarMediumUrl || "");

    const handleLogout = async () => {
        await logout("");
        cleanUpUserInfo(true);
        navigate(LOGIN_PATH);
    };

    const handleAccount = () => {
        navigate(USER_SETTINGS_PATH);
    };

    const getProfileAvatar = (avatarClass: any, isAppBarProfile?: boolean) => {
        const styleObj = { marginRight: isAppBarProfile ? 0 : "16px", backgroundColor: "transparent" };
        return (
            <Box p={0}>
                {isDemoMode ? (
                    <Avatar style={styleObj} src={companyLogoMini} className={avatarClass}></Avatar>
                ) : avatarUrl ? (
                    <Avatar style={styleObj} src={avatarUrl} variant="circular" alt="image" className={avatarClass} />
                ) : (
                    <Avatar
                        variant="circular"
                        alt="image"
                        className={avatarClass}
                        style={{ marginRight: isAppBarProfile ? 0 : "16px" }}
                        color="info"
                    >
                        {currentUser.firstName.charAt(0).toUpperCase()}
                        {currentUser.lastName.charAt(0).toUpperCase()}
                    </Avatar>
                )}
            </Box>
        );
    };

    const getAccountCardInfo = () => {
        return (
            <>
                {getProfileAvatar(classes.avatar)}
                <Box>
                    <Typography variant="body1" color="text.primary">
                        {isDemoMode ? "Demo Mode" : `${currentUser.firstName} ${currentUser.lastName}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {isDemoMode ? "demo.mode@gmail.com" : currentUser.email}
                    </Typography>
                </Box>
            </>
        );
    };

    return (
        <LightMenu
            buttonTitle="Account"
            title={getProfileAvatar(classes.avatar, true)}
            isList={false}
            isIconButton
            placement="bottom-end"
            transformOrigin="top right"
            dataCy="accountBtn"
        >
            <Box p={0} py={1}>
                <Typography component="div" className={`${classes.displayRow}`} sx={{ padding: "12px 15px" }}>
                    {getAccountCardInfo()}
                </Typography>
                <Divider />
                <MenuItem className={classes.menuItemClass} data-cy="manageAccount" onClick={handleAccount}>
                    <ListItemIcon className="listItemIcon">
                        <SVGIconRenderer icon="settingsIcon" />
                    </ListItemIcon>
                    <Typography color="text.primary" variant="body2">
                        Manage account
                    </Typography>
                </MenuItem>

                <MenuItem className={classes.menuItemClass} onClick={handleLogout}>
                    <ListItemIcon className="listItemIcon">
                        <SVGIconRenderer icon="logoutIcon" />
                    </ListItemIcon>
                    <Typography color="text.primary" variant="body2">
                        Log out
                    </Typography>
                </MenuItem>
            </Box>
        </LightMenu>
    );
};
