import get from "lodash/get";
import baseCreateApi from "./baseCreateApi";
import { setPerformanceDetails } from "../slices/performanceDetailsSlice";
import { appendModelEligibilityData } from "../slices/modelEligibilitySlice";
import { IPerformancePage, IPerformancePageResponse } from "../../interfaces/IPerformancePage";
import {
    IPerformanceCampaignMetricsResponse,
    IPerformanceCampaignMetrics,
} from "../../interfaces/IPerformanceCampaignMetrics";
import { IModelEligibilityResponse } from "../../interfaces/api/IModelEligibilityResponse";
import { keysToCamelCase } from "../../utils/format";
import {
    filterGoogleAnalytics,
    formatArrayWithCamelCase,
    generateForecastBody,
    getPreparedObjectForArray,
} from "../../services/performancePage/performancePage";
import { IModelEligibility } from "../../interfaces/entities/IModelEligibility";
import { ISecondOrderEffectsMetrics } from "../../interfaces/entities/ISecondOrderEffects";
import { ISecondOrderEffectsApiResponse } from "../../interfaces/api/ISecondOrderEffectsApiResponse";
import { IForecastApiResponse } from "src/interfaces/api/IForecastApiResponse";
import { IForecast } from "src/interfaces/entities/IForecast";
import { IErrors } from "src/interfaces/entities/IErrors";
import { IForecastEligibilityResponse } from "src/interfaces/api/IForecastEligibilityReponse";
import { IForecastEligibility } from "src/interfaces/entities/IForecastEligibility";
import { getDefaultSource } from "../slices/connectorsSlice";
import { IForecastBySpendApiResponse } from "src/interfaces/api/IForecastBySpendApiResponse";
import { IForecastBySpend } from "src/interfaces/entities/IForecastBySpend";
import { FORECAST_BY_SPEND_METRIC } from "src/consts/optimizationPage/optimizationPage";

export interface IPerformancePageBody {
    orgId: string;
    data: {
        end_date: string;
        start_date: string;
        connectors: string;
        campaign_id?: string[];
        send_eligibility?: boolean;
    };
}

interface IPerformanceMetricBody {
    orgId: string;
    data: {
        campaign_id: string;
        end_date: string;
        start_date: string;
        metrics: string;
    };
    pastDate: {
        endDate: string;
        startDate: string;
    } | null;
}

interface IModelEligibilityBody {
    orgId: string;
    campaignIds: string[];
}

interface IAttributionBody {
    orgId: string;
    campaignId: string;
    startDate: string;
    endDate: string;
    pastDate: {
        endDate: string;
        startDate: string;
    } | null;
    metrics: string;
}

interface IForecastBody {
    orgId: string;
    campaignId: string | undefined;
    connectorName: string | undefined;
    modelType: FORECAST_BY_SPEND_METRIC;
}

interface IForecastEligibilityBody {
    orgId: string;
    campaignId: string;
}

export interface IForecastCampaignBody {
    orgId: string;
    campaignId: string;
    connectorName: string;
    spend: number;
    modelType: FORECAST_BY_SPEND_METRIC;
}

const setPerformancePageAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const res = await queryFulfilled;
        if (res) {
            dispatch(setPerformanceDetails(res.data));
        }
    } catch (error) {
        console.error(error);
    }
};

const setModelEligibilityAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const res = await queryFulfilled;
        if (res) {
            dispatch(appendModelEligibilityData(res.data));
        }
    } catch (error) {
        console.error(error);
    }
};

export const performancePage = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getPerformancePage: builder.query({
            query: (body: IPerformancePageBody) => {
                let campaignUrl = `organizations/${body.orgId}/performance/metric_attribution?start_date=${body.data.start_date}&end_date=${body.data.end_date}&connectors=${body.data.connectors}`;
                if (body.data.campaign_id) {
                    body.data.campaign_id.forEach((c) => {
                        campaignUrl = `${campaignUrl}&campaign_id[]=${c}`;
                    });
                }
                if (body.data.send_eligibility) {
                    campaignUrl = `${campaignUrl}&send_eligibility=true`;
                }
                return {
                    url: campaignUrl,
                    method: "GET",
                };
            },
            onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
                setPerformancePageAsyncHandler({ dispatch, queryFulfilled });
            },
            keepUnusedDataFor: 0,
            transformResponse: (
                response: IPerformancePageResponse,
                meta,
                args: IPerformancePageBody,
            ): IPerformancePage => {
                const defaultRevenueConnectorSource =
                    getDefaultSource(response.meta?.revenue_data?.map((r) => r.connector_name)) || "";
                const revenueData = response.meta?.revenue_data ? response.meta.revenue_data : [];
                return {
                    meta: {
                        campaignsDate: response.meta?.campaigns_date,
                        revenueData,
                        defaultRevenueConnectorSource,
                    },
                    data: getPreparedObjectForArray(response.data),
                };
            },
        }),
        getPerformanceMetric: builder.query({
            query: (body: IPerformanceMetricBody) => {
                let apiURL = `organizations/${body.orgId}/performance/${body.data.campaign_id}?start_date=${body.data.start_date}&end_date=${body.data.end_date}&${body.data.metrics}`;
                if (body.pastDate && body.pastDate.startDate && body.pastDate.endDate) {
                    apiURL = `${apiURL}&comparison_start_date=${body.pastDate.startDate}&comparison_end_date=${body.pastDate.endDate}`;
                }

                return {
                    url: apiURL,
                    method: "GET",
                };
            },
            keepUnusedDataFor: 0,
            transformResponse: (response: IPerformanceCampaignMetricsResponse): IPerformanceCampaignMetrics => {
                const filteredResponse = filterGoogleAnalytics(response?.data || []);
                const filteredComparisionResponse = response?.meta?.comparison_data
                    ? filterGoogleAnalytics(response?.meta?.comparison_data || [])
                    : [];

                return {
                    data: formatArrayWithCamelCase(
                        // filter out google analytics from paid performance metrics.
                        filteredResponse,
                    ),
                    meta: {
                        comparisonData: formatArrayWithCamelCase(filteredComparisionResponse),
                        comparisonStartDate: get(response, "meta.comparison_start_date", ""),
                        comparisonEndDate: get(response, "meta.comparison_end_date", ""),
                    },
                    percentageData: keysToCamelCase(get(response, "meta.percentage_differences", {})),
                };
            },
        }),
        getModelEligibility: builder.query({
            query: (body: IModelEligibilityBody) => {
                const p = body.campaignIds
                    .reduce((acc: string[], curr: string) => acc.concat(`campaign_ids[]=${curr}`), [])
                    .join("&");
                return {
                    url: `organizations/${body.orgId}/model_eligibility?${p}`,
                    method: "GET",
                };
            },
            onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
                setModelEligibilityAsyncHandler({ dispatch, queryFulfilled });
            },
            transformResponse: (response: IModelEligibilityResponse): IModelEligibility[] => {
                return keysToCamelCase(response.data);
            },
        }),
        getAttributionData: builder.query({
            query: (body: IAttributionBody) => {
                let apiURL = `organizations/${body.orgId}/performance/${body.campaignId}/second_order_effects?start_date=${body.startDate}&end_date=${body.endDate}&${body.metrics}`;
                if (body.pastDate && body.pastDate.startDate && body.pastDate.endDate) {
                    apiURL = `${apiURL}&comparison_start_date=${body.pastDate.startDate}&comparison_end_date=${body.pastDate.endDate}`;
                }
                return {
                    url: apiURL,
                    method: "GET",
                };
            },
            transformResponse: (response: ISecondOrderEffectsApiResponse): ISecondOrderEffectsMetrics => {
                return {
                    data: formatArrayWithCamelCase(response?.data),
                    meta: {
                        comparisonData: formatArrayWithCamelCase(response.meta?.comparison_data),
                        comparisonStartDate: get(response, "meta.comparison_start_date", ""),
                        comparisonEndDate: get(response, "meta.comparison_end_date", ""),
                    },
                };
            },
        }),
        getForecast: builder.mutation({
            query: (body: IForecastBody) => {
                return {
                    url: `organizations/${body.orgId}/campaigns/${body.campaignId}/forecast`,
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            connector_name: body.connectorName,
                            model_type: body.modelType,
                        },
                        type: "campaign_forecast",
                    }),
                };
            },
            transformResponse: (response: IForecastApiResponse): IForecast | IErrors => {
                return keysToCamelCase(response.data);
            },
        }),
        getForecastEligibility: builder.query({
            query: (body: IForecastEligibilityBody) => {
                return {
                    url: `organizations/${body.orgId}/campaigns/${body.campaignId}/forecast/eligible`,
                    method: "GET",
                };
            },
            transformResponse: (response: IForecastEligibilityResponse): IForecastEligibility => {
                return keysToCamelCase(response.data);
            },
        }),
        getCampaignForecastBySpend: builder.mutation({
            query: (body: IForecastCampaignBody) => {
                return {
                    url: `organizations/${body.orgId}/campaigns/${body.campaignId}/forecast_by_spend`,
                    method: "POST",
                    body: JSON.stringify({
                        data: generateForecastBody(body),
                        type: "campaign_forecast",
                    }),
                };
            },
            transformResponse: (response: IForecastBySpendApiResponse): IForecastBySpend => {
                return keysToCamelCase(response.data);
            },
        }),
    }),
});

export const {
    useLazyGetPerformancePageQuery,
    useLazyGetPerformanceMetricQuery,
    useLazyGetModelEligibilityQuery,
    useLazyGetAttributionDataQuery,
    useGetForecastMutation,
    useLazyGetForecastEligibilityQuery,
    useGetCampaignForecastBySpendMutation,
} = performancePage;
