import baseCreateApi from "./baseCreateApi";
import {
    formatCompanyForecastData,
    formatScenarioCampaign,
    formatForecastResponse,
    formatScenarioResponse,
    generateScenaioBody,
    generateScenaioEditBody,
    transformOptimization,
} from "../../services/optimizationPage/optimization";
import { IMetricAttributionTableValuesTransformed } from "src/interfaces/performanceDetails/IMetricAttributionTableResponse";
import {
    IScenarioApiResponse,
    IScenarioByIdApiResponse,
    IScenarioCampaign,
} from "../../interfaces/api/IScenarioResponse";
import {
    ICampaignData,
    IOptimizationModelResponse,
    IScenario,
    IScenarioFormatted,
} from "../../interfaces/entities/IScenario";
import { ICreateScenarioDTO } from "../../interfaces/Iscenario/ICreateScenarioDTO";
import { IPerformanceDataResponseData } from "../../interfaces/IPerformancePage";
import { setCurrentlyViewingScenario, setScenarios } from "../slices/scenarioSlice";
import { keysToCamelCase } from "../../utils/format";
import { getPreparedObjectForArray } from "../../services/performancePage/performancePage";
import { ICompanyForecast } from "src/interfaces/ICompanyForecast";
import { ICompanyForecastResponse } from "src/interfaces/ICompanyForecastResponse";
import { ICompanyForecastResponseV1 } from "src/interfaces/api/ICompanyForecastResponseV1";
import { ICompanyBacktestResponse } from "src/interfaces/api/ICompanyBacktestResponse";
import { ICompanyForecastFormattedResponse } from "src/interfaces/companyForecast/ICompanyForecastFormattedResponse";
import { ICompanyBacktestFormattedResponse } from "src/interfaces/companyForecast/ICompanyBacktestFormattedResponse";
import { transformMembers } from "src/services/members/members";
import { IMembersResponse } from "src/interfaces/IMembersResponse";
import { IScenarioCampaignsRespnse } from "src/interfaces/api/IScenarioCampaignsResponse";
import { OPTIMIZED_MODELS_BY_METRIC, SCENARIO_TYPE } from "src/consts/optimizationPage/optimizationPage";

interface IScenarioBody {
    name: string;
    description: string;
    scenarioType: SCENARIO_TYPE;
    forecastTimeframe: number;
    budget: number;
    revenue: number;
    roas: number;
    id?: string;
    campaignData: ICampaignData;
    optimizationConfidence: number;
    isPrivate: boolean;
    isArchived?: boolean;
    isHidden: boolean;
}

interface IScenarioEditBody extends IScenarioBody {
    runForecastOnly?: boolean;
}

export interface IScenarioCampaignBody {
    channel: string;
    campaign_id: string;
    budget_allocated: number;
    id: number;
    forecasted_revenue?: number;
    is_locked: boolean;
    is_accepted?: boolean | null;
    implementation_date?: string | null;
    decline_reason?: string | null;
}

export interface IAvailableOptimizationModelsBody {
    orgId: string;
    data: {
        end_date: string;
        start_date: string;
        scenarioType: OPTIMIZED_MODELS_BY_METRIC;
    };
}

export interface IAvailableOptimizationModelResponse {
    data: IPerformanceDataResponseData[];
    jsonapi?: {
        version: string;
    };
    meta: {
        spend_date: string;
    };
}

export interface IAvailableOptimizationModelsPage {
    data: IMetricAttributionTableValuesTransformed[];
    meta: {
        spendDate: string;
    };
}

const setScenariosAsyncHandler = async ({ dispatch, queryFulfilled, getState }: any) => {
    try {
        const res = await queryFulfilled;
        dispatch(setScenarios({ data: res.data.data, lastModelRunDate: res.data.meta.lastModelRun }));
    } catch (error) {
        console.error(error);
    }
};

const setScenarioByIdAsyncHandler = async ({ dispatch, queryFulfilled, getState }: any) => {
    try {
        const res = await queryFulfilled;
        dispatch(setCurrentlyViewingScenario(res.data.scenario));
    } catch (error) {
        console.error(error);
    }
};

export const apiController = new AbortController();

export const optimizationAPi = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getScenario: builder.query({
            query: (params: { orgId: string; page?: number; pageSize?: number; filter?: string }) => {
                let apiUrl = `organizations/${params.orgId}/scenarios?include=creator,jobs`;
                if (params.page && params.pageSize) {
                    apiUrl = apiUrl + `&page[number]=${params.page}&page[size]=${params.pageSize}`;
                }
                if (params.filter) {
                    apiUrl = apiUrl + `&${params.filter}`;
                }
                return {
                    url: apiUrl,
                    method: "GET",
                    signal: apiController.signal,
                };
            },
            onQueryStarted(queryArgs, { dispatch, queryFulfilled, getState }) {
                setScenariosAsyncHandler({ dispatch, queryFulfilled, getState });
            },
            keepUnusedDataFor: 0,
            transformResponse: (
                response: IScenarioApiResponse,
                meta,
            ): {
                data: IScenario[];
                meta: { lastModelRun: string; totalCount: number; totalPages: number; currentPage: number };
            } => {
                return {
                    data: formatScenarioResponse(response),
                    meta: keysToCamelCase(response?.meta),
                };
            },
        }),
        getScenarioById: builder.query({
            query: (params: { orgId: string; scenarioId: string }) => ({
                url: `organizations/${params.orgId}/scenarios/${params.scenarioId}?include=jobs,creator`,
                method: "GET",
            }),
            onQueryStarted(queryArgs, { dispatch, queryFulfilled, getState }) {
                setScenarioByIdAsyncHandler({ dispatch, queryFulfilled, getState });
            },
            keepUnusedDataFor: 0,
            transformResponse: (response: IScenarioByIdApiResponse): IScenarioFormatted => {
                return transformOptimization(response);
            },
        }),
        createScenario: builder.mutation({
            query: (params: { orgId: string; body: IScenarioBody }) => {
                return {
                    url: `organizations/${params.orgId}/scenarios`,
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            attributes: {
                                ...generateScenaioBody({
                                    ...params.body,
                                    orgId: params.orgId,
                                }),
                            },
                            type: "scenarios",
                        },
                    }),
                };
            },
            transformResponse: (response: ICreateScenarioDTO) => {
                return keysToCamelCase(response.data);
            },
        }),
        getAvailableOptimizationModels: builder.query({
            query: (body: IAvailableOptimizationModelsBody) => {
                const campaignUrl = `organizations/${body.orgId}/available_optimize_models?start_date=${body.data.start_date}&end_date=${body.data.end_date}&model_type=${body.data.scenarioType}`;
                return {
                    url: campaignUrl,
                    method: "GET",
                };
            },
            keepUnusedDataFor: 0,
            transformResponse: (response: IAvailableOptimizationModelResponse): IAvailableOptimizationModelsPage => {
                return {
                    data: getPreparedObjectForArray(response.data),
                    meta: keysToCamelCase(response.meta),
                };
            },
        }),
        optimizeScenarioAllocation: builder.mutation({
            query: (params: { orgId: string; scenarioId: string }) => {
                return {
                    url: `organizations/${params.orgId}/scenarios/${params.scenarioId}/optimize_allocation`,
                    method: "POST",
                };
            },
        }),
        scenarioForecast: builder.mutation({
            query: (params: { orgId: string; scenarioId: string }) => {
                return {
                    url: `organizations/${params.orgId}/scenarios/${params.scenarioId}/forecast`,
                    method: "POST",
                };
            },
        }),
        pollingScenarioJob: builder.mutation({
            query: (params: { orgId: string; jobId: string }) => {
                return {
                    url: `organizations/${params.orgId}/jobs/${params.jobId}`,
                    method: "GET",
                };
            },
        }),
        editScenario: builder.mutation({
            query: (params: { orgId: string; body: IScenarioEditBody; scenarioId: string }) => {
                return {
                    url: `organizations/${params.orgId}/scenarios/${params.scenarioId}`,
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            attributes: generateScenaioEditBody({
                                ...params.body,
                                orgId: params.orgId,
                            }),
                            type: "scenarios",
                        },
                    }),
                };
            },
            transformResponse: (response: ICreateScenarioDTO) => {
                return keysToCamelCase(response.data);
            },
        }),
        deleteScenario: builder.mutation({
            query: (params: { orgId: string; scenarioId: string }) => ({
                url: `organizations/${params.orgId}/scenarios/${params.scenarioId}`,
                method: "DELETE",
            }),
        }),
        editScenarioCampaigns: builder.mutation({
            query: (params: { orgId: string; body: IScenarioCampaignBody[]; scenarioId: string }) => {
                return {
                    url: `organizations/${params.orgId}/scenarios/${params.scenarioId}/bulk_update_scenario_campaigns_budgets`,
                    method: "PATCH",
                    body: JSON.stringify({
                        data: params.body.map((c) => ({
                            attributes: {
                                is_locked: c.is_locked,
                                budget_allocated: c.budget_allocated,
                                forecasted_revenue: null,
                            },
                            id: c.id,
                            type: "scenario_campaign",
                        })),
                    }),
                };
            },
        }),
        revertPastJob: builder.mutation({
            query: (params: { orgId: string; scenarioId: string; jobId: string }) => {
                return {
                    url: `organizations/${params.orgId}/scenarios/${params.scenarioId}/revert/${params.jobId}`,
                    method: "PUT",
                };
            },
        }),
        companyForecast: builder.query({
            query: (body: { orgId: string; timeframe: number; startDate: string }) => {
                return {
                    url: `organizations/${body.orgId}/company_forecast?forecast_len=${body.timeframe}&start_date=${body.startDate}`,
                    method: "GET",
                };
            },
            keepUnusedDataFor: 60,
            transformResponse: (response: ICompanyForecastResponse): ICompanyForecast => {
                return formatCompanyForecastData(response);
            },
        }),
        companyWideForecast: builder.query({
            query: (body: { orgId: string }) => {
                return {
                    url: `organizations/${body.orgId}/company_wide_forecast`,
                    method: "GET",
                };
            },
            keepUnusedDataFor: 60,
            transformResponse: (response: ICompanyForecastResponseV1): ICompanyForecastFormattedResponse => {
                return formatForecastResponse(response.data);
            },
        }),
        companyWideBacktest: builder.query({
            query: (body: { orgId: string }) => {
                return {
                    url: `organizations/${body.orgId}/company_wide_backtest`,
                    method: "GET",
                };
            },
            keepUnusedDataFor: 60,
            transformResponse: (response: ICompanyBacktestResponse): ICompanyBacktestFormattedResponse => {
                return keysToCamelCase(response.data);
            },
        }),
        getScenarioCreators: builder.query({
            query: (params: { orgId: string }) => {
                return {
                    url: `organizations/${params.orgId}/scenarios/creators`,
                    method: "GET",
                };
            },
            transformResponse: (response: { data: IMembersResponse[]; included: [] }) => {
                return transformMembers(response.data, []);
            },
        }),
        editScenarioCampaign: builder.mutation({
            query: (params: { orgId: string; body: Partial<IScenarioCampaignBody>; scenarioId: string }) => {
                return {
                    url: `organizations/${params.orgId}/scenario_campaigns/${params.body.id}`,
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            attributes: {
                                ...params.body,
                                scenario_id: params.scenarioId,
                            },
                            type: "scenario_campaigns",
                        },
                    }),
                };
            },
            transformResponse: (response: IScenarioCampaignsRespnse): IOptimizationModelResponse => {
                return keysToCamelCase(formatScenarioCampaign({ ...response.data.attributes, id: +response.data.id }));
            },
        }),
    }),
});

export const {
    useLazyGetScenarioByIdQuery,
    useLazyGetScenarioQuery,
    useCreateScenarioMutation,
    useEditScenarioMutation,
    useDeleteScenarioMutation,
    useLazyGetAvailableOptimizationModelsQuery,
    useOptimizeScenarioAllocationMutation,
    useEditScenarioCampaignsMutation,
    useEditScenarioCampaignMutation,
    usePollingScenarioJobMutation,
    useScenarioForecastMutation,
    useRevertPastJobMutation,
    useLazyCompanyForecastQuery,
    useLazyCompanyWideForecastQuery,
    useLazyCompanyWideBacktestQuery,
    useLazyGetScenarioCreatorsQuery,
} = optimizationAPi;
