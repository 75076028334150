import { Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { ISummary } from "src/interfaces/entities/IScenario";
import { formatValue } from "src/services/utils";

interface IAggregateScenarioCardProps {
    isTableLoading: boolean;
    summaryArray: ISummary[];
    classes: any;
    isCacScenario: boolean;
}

export const AggregateScenarioCard: FC<IAggregateScenarioCardProps> = ({
    isTableLoading,
    summaryArray,
    classes,
    isCacScenario,
}) => {
    return (
        <>
            {summaryArray.map((summary) => (
                <TableRow key={summary.label}>
                    <TableCell className={classes.outcomeTableCell} component="th" scope="row">
                        <Typography>{summary.label}</Typography>
                    </TableCell>
                    <TableCell className={classes.outcomeTableCell} align="right">
                        <Typography>
                            {isTableLoading ? <Skeleton /> : formatValue(summary.last, summary.unit, summary.fixed)}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.outcomeTableCell} align="right">
                        <Typography>
                            {isTableLoading ? (
                                <Skeleton />
                            ) : (
                                <>
                                    {`${formatValue(summary.forecasted, summary.unit, summary.fixed)}`}{" "}
                                    <span
                                        className={
                                            (
                                                isCacScenario && summary.label === "Total MMM CAC"
                                                    ? summary.change < 0
                                                    : summary.change > 0
                                            )
                                                ? classes.increaseText
                                                : classes.decreaseText
                                        }
                                    >
                                        {`(${summary.change > 0 ? "+" : ""}${formatValue(
                                            summary.change,
                                            summary.unit,
                                            summary.fixed,
                                        )})`}
                                    </span>
                                </>
                            )}
                        </Typography>
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};
