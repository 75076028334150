import { css } from "@emotion/css";

import { FORMATS } from "src/enums/Formats";
import { formatValue } from "src/services/utils";

export const getValueSign = (value: number) => {
    return `${value === 0 ? "" : value > 0 ? "+" : ""}`;
};

export const FormattedValueClassName = (value: number, isOpposite: boolean) => {
    const classes = () => ({
        increaseText: css({ color: "#2F970B" }),
        decreaseText: css({ color: "#FF0000" }),
    });

    if (value === 0) { return ""; }
    const textClass = (isOpposite ? value < 0 : value > 0) ? classes().increaseText : classes().decreaseText;
    return textClass;
};

export const FormattedValue = ({
    value,
    format,
    precision,
    isOpposite = false,
}: {
    value: number;
    format: FORMATS;
    precision: number;
    isOpposite?: boolean;
}) => {
    return (
        <span className={FormattedValueClassName(+value.toFixed(precision), isOpposite)}>
            ({getValueSign(+value.toFixed(precision))}
            {formatValue(value, format, precision)})
        </span>
    );
};
