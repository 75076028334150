import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import groupBy from "lodash/groupBy";
import React, { memo, useEffect, useMemo, useState } from "react";

import { SearchField } from "src/components/SearchField/SearchField";
import { getSearchedCampaigns } from "src/services/optimizationPage/optimization";
import { calculateCAC, changeDate, recalculateSortDirection } from "src/services/utils";
import { XIncreaseByYPer } from "../../../../components/CampaignComponents/InsightsModal/ForecastTab/forecastUtils";
import { Choice, SCENARIO_TYPE } from "../../../../consts/optimizationPage/optimizationPage";
import { ICampaignData } from "../../../../interfaces/entities/IScenario";
import { calculateROAS } from "../../../../services/utils";
import { IScenarioFromProps } from "../../formModel/addScenarioFormModel";
import { CampaignTable } from "./CampaignTable";

interface ICampaignSelectionExtraProps {
    filterChangeEvent: (timeframe: string | number, campaigns: ICampaignData) => void;
}

const CampaignSelection = (props: IScenarioFromProps & ICampaignSelectionExtraProps) => {
    const {
        classes,
        performanceData,
        setFieldValue,
        errors,
        values,
        isPerformanceLoading,
        touched,
        filterChangeEvent,
        lastDaysTooltip,
    } = props;

    const [selectedCampaignList, setSelectedCampaigns] = useState<ICampaignData>(values.campaignData);
    const [searchCampaigns, setSearchCampaigns] = useState("");

    useEffect(() => {
        setSelectedCampaigns(values.campaignData);
    }, [values.campaignData]);

    useEffect(() => {
        const selectedCampaignsId = selectedCampaignList.map((s: any) => s.campaignId);
        const selectedPerformances: any = performanceData.data.filter((p) =>
            selectedCampaignsId.includes(p.campaignId || ""),
        );

        const selectedTotalSpend = +selectedPerformances
            .reduce((a: number, p: { currentEstimatedSpend: any }) => a + +(p.currentEstimatedSpend || 0).toFixed(0), 0)
            .toFixed(0);

        // Limit budget movement to +10% for shift type
        const increasedTotalSpend = XIncreaseByYPer(10, selectedTotalSpend);
        if (
            values.scenarioType === SCENARIO_TYPE.SHIFT &&
            increasedTotalSpend < values.budget &&
            increasedTotalSpend > 0
        ) {
            setFieldValue("scenarioType", SCENARIO_TYPE.SCALE);
        }
    }, [values.budget, values.campaignData, performanceData.data]);

    const [openChannelList, setOpenChannelList] = useState("");

    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("trueRevenue");
    const channelGrp = useMemo(() => groupBy(performanceData.data, "connectorName"), [performanceData.data]);
    const eligibleCampaigns = performanceData.data;

    const searchedCampaignData = useMemo(() => {
        return getSearchedCampaigns(performanceData.data, searchCampaigns);
    }, [performanceData.data, searchCampaigns]);

    const searchedchannelGrp = useMemo(() => groupBy(searchedCampaignData, "connectorName"), [searchedCampaignData]);

    const channelGroupData = useMemo(() => {
        // make channel group data with particular campaign belonging
        return Object.entries(searchedchannelGrp).map(([channel, campaigns]) => {
            const spend = campaigns.reduce((prev, curr) => prev + (curr.spend ? curr.spend : 0), 0);
            const trueRevenue = campaigns.reduce((prev, curr) => prev + (curr.trueRevenue ? curr.trueRevenue : 0), 0);
            const trueRoas = calculateROAS(spend, trueRevenue);
            const currentEstimatedDailySpend = campaigns.reduce(
                (prev, curr) => prev + +(curr.currentEstimatedDailySpend || 0).toFixed(0),
                0,
            );
            const currentEstimatedSpend = campaigns.reduce(
                (prev, curr) => prev + +(curr.currentEstimatedSpend || 0).toFixed(0),
                0,
            );

            const findLockedCampaign = values.campaignData.map((campaign) => campaign.campaignId);
            const campaignsWithLock = campaigns.map((c) => ({
                ...c,
                isLocked: findLockedCampaign.includes(c.campaignId || "")
                    ? values.campaignData.find((vc) => vc.campaignId === c.campaignId)?.isLocked
                    : false,
            }));
            const newCustomers = campaigns.reduce((prev, curr) => prev + Math.max(curr.newCustomers || 0, 0), 0);
            const cac = calculateCAC(spend, newCustomers);

            return {
                channelName: channel,
                connectorName: campaigns[0].connectorName || "",
                spend,
                trueRevenue,
                trueRoas,
                campaigns: campaignsWithLock,
                cleanChannelName: campaigns[0].channelName || "",
                currentEstimatedDailySpend,
                currentEstimatedSpend,
                isLocked: campaignsWithLock.every((c) => c.isLocked),
                newCustomers,
                cac,
            };
        });
    }, [searchedchannelGrp]);

    // set form budget, revenue data as per selected campaigns
    const updateScenarioInputs = (list: ICampaignData) => {
        const selectedCampaignsId = list.map((s: any) => s.campaignId);
        const selectedPerformances: any = performanceData.data.filter((p) =>
            selectedCampaignsId.includes(p.campaignId || ""),
        );

        setFieldValue(
            "revenue",
            +selectedPerformances
                .reduce(
                    (prev: any, curr: { trueRevenue: any }) =>
                        prev + (curr.trueRevenue < 0 ? 0 : curr.trueRevenue || 0),
                    0,
                )
                .toFixed(0),
        );
        setFieldValue(
            "budget",
            +selectedPerformances
                .reduce(
                    (prev: any, curr: { currentEstimatedSpend: any }) => prev + +(curr.currentEstimatedSpend || 0),
                    0,
                )
                .toFixed(0),
        );
    };

    useEffect(() => {
        setSelectedCampaigns(selectedCampaignList);
        setFieldValue("campaignData", selectedCampaignList);
    }, [selectedCampaignList]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        setOrder(recalculateSortDirection(orderBy, order, property));
        setOrderBy(property);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = e.target;
        setSearchCampaigns(value);
    };

    const searchedChannelGroupData = useMemo(() => {
        return channelGroupData
            .map((channel) => ({
                ...channel,
                campaigns: getSearchedCampaigns(channel.campaigns, searchCampaigns),
            }))
            .filter((channel) => channel.campaigns.length > 0);
    }, [channelGroupData, searchCampaigns]);

    return (
        <Stack gap="20px">
            <Typography variant="h2" fontWeight="700">
                Campaign Selection
            </Typography>
            <SearchField placeholder={`Search campaign(s)`} onChange={handleChange} value={searchCampaigns} />
            <CampaignTable
                channelTableData={searchedChannelGroupData}
                channelGrp={channelGrp}
                openChannelList={openChannelList}
                setOpenChannelList={setOpenChannelList}
                classes={classes}
                errors={errors}
                touched={touched}
                handleRequestSort={handleRequestSort}
                orderBy={orderBy}
                order={order}
                selectedCampaings={selectedCampaignList}
                setSelectedCampaigns={setSelectedCampaigns}
                values={values}
                isLoading={isPerformanceLoading}
                updateScenarioInputs={updateScenarioInputs}
                eligibleCampaigns={eligibleCampaigns}
                filterChangeEvent={filterChangeEvent}
                lastDaysTooltip={lastDaysTooltip}
            />
            {performanceData.meta.spendDate ? (
                <Typography variant="subtitle1" color="text.secondary">
                    No change spend is based on your reported spend on {changeDate(performanceData.meta.spendDate)} for
                    each campaign
                </Typography>
            ) : null}
        </Stack>
    );
};

export default memo(CampaignSelection);
