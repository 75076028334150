import React, { useMemo } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { addDays, format, isPast } from "date-fns";
import { toDate } from "date-fns-tz";

import { useStylesForRowInTable } from "../../../consts/StylesForTables";
import { TableActionCell } from "../../../components/core/TableActionCell/TableActionCell";
import { formatValue, handleClickWithTextSelectionCheck } from "../../../services/utils";
import { FORMATS } from "../../../enums/Formats";
import { IScenario } from "../../../interfaces/entities/IScenario";
import {
    OptimizationTab,
    SCENARIO_TYPE,
    TIMEFRAME_RADIO_OPTIONS,
} from "../../../consts/optimizationPage/optimizationPage";
import { cursor } from "../../../assets/styles/commonStyle";
import { getTrackingDates } from "src/services/optimizationPage/optimization";

interface IOptimizationRow {
    optimization: IScenario;
    currentTab?: OptimizationTab;
    disableTracking: boolean;

    onDeleteOptimization: (e: React.MouseEvent<HTMLDivElement>, scenario: IScenario) => void;
    onEditOptimization: (e: React.MouseEvent<HTMLDivElement>, scenario: IScenario) => void;
    handleTracking: (scenario: IScenario) => void;
    onArchiveScenario: (e: React.MouseEvent<HTMLDivElement>, scenario: IScenario) => void;
}

export const OptimizationRow: React.FC<IOptimizationRow> = ({
    optimization,
    currentTab,
    onDeleteOptimization,
    onEditOptimization,
    onArchiveScenario,
    handleTracking,
    disableTracking = false,
}) => {
    const isForecastedScenario = optimization.scenarioType === SCENARIO_TYPE.FORECAST;
    const classes = useStylesForRowInTable();

    const acceptedCampaigns = optimization.scenarioCampaigns?.filter((c) => c.isAccepted) || [];
    const acceptedCampaignsCount = acceptedCampaigns.length;

    const { latestEndDate } = getTrackingDates(
        (optimization.scenarioCampaigns || []).map((s) => ({ ...s, ...s.tracking })) || [],
    );
    const isScenarioExpired = useMemo(() => isPast(latestEndDate || new Date()), [optimization]);

    const getTimeframeValue = () =>
        TIMEFRAME_RADIO_OPTIONS.find((o) => o.value === optimization.forecastTimeframe)?.label ||
        `${optimization.forecastTimeframe} Days`;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        handleTracking(optimization);
    };

    let trackingEnabled = true;
    const anyNullForecasts = optimization.scenarioCampaigns?.filter((a) => a.forecastedRevenue === null) || [];
    const optimizationTrackingDisabledDueToError =
        optimization.jobStatus === "completed" && anyNullForecasts.length > 0;

    let trackingTooltip = "";

    if (optimizationTrackingDisabledDueToError) {
        trackingTooltip = "Tracking is not available as there was an error running this optimization.";
        trackingEnabled = false;
    }

    let actionArray = [
        {
            title: optimization.isPrivate ? "Only viewable by you" : "Viewable by anyone in your organization",
            onClick: () => {},
            data: optimization,
            icon: optimization.isPrivate ? "eyeOffIcon" : "eyeIcon",
        },
        { title: "Delete", onClick: onDeleteOptimization, data: optimization, icon: "trashIcon" },
    ];

    if (currentTab === OptimizationTab.Scenarios) {
        actionArray = [
            ...actionArray,
            { title: "Archive", onClick: onArchiveScenario, data: optimization, icon: "archiveIcon" },
        ];
    }

    if (currentTab === OptimizationTab.Archived) {
        actionArray = [
            ...actionArray,
            {
                title: "Archive Restore",
                onClick: onArchiveScenario,
                data: optimization,
                icon: "archiveRestoreIcon",
            },
        ];
    }

    return (
        <TableRow
            className={`${classes.bodyRow} ${cursor("pointer")}`}
            hover
            tabIndex={-1}
            onClick={(e) => handleClickWithTextSelectionCheck(onEditOptimization)(e, optimization)}
        >
            <TableCell align="left" className={classes.tableCell}>
                <Typography variant="body2" fontSize="15px">
                    {optimization.name}
                </Typography>
                <Typography variant="body2" fontSize="15px" sx={{ opacity: 0.5 }}>
                    {optimization.description}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2" fontSize="15px">
                    {optimization.creatorName}
                </Typography>
                <Typography variant="body2" fontSize="15px" sx={{ opacity: 0.5 }}>
                    {optimization.createdAt && format(toDate(optimization.createdAt), "M/d/yyyy 'at' hh:mm a")}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                {isForecastedScenario
                    ? `Forecast (Revenue)`
                    : `Fixed Budget (${optimization.scenarioType === SCENARIO_TYPE.CAC ? "CAC" : "Revenue"})`}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {formatValue(Number(optimization.budget), FORMATS.DOLLAR, 0)}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {optimization.scenarioLevel} campaign{(optimization.scenarioLevel || 0) > 1 ? "s" : ""}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {getTimeframeValue()}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                <Tooltip arrow title={trackingTooltip}>
                    <Typography component="span" sx={{ cursor: "not-allowed" }}>
                        {!!acceptedCampaignsCount &&
                            (isScenarioExpired ? (
                                <Button
                                    disabled={!trackingEnabled}
                                    sx={{ textTransform: "initial", color: "black" }}
                                    variant={"outlined"}
                                    onClick={(e) => handleClick(e)}
                                    data-cy={optimization.id + "-tracking-complete"}
                                >
                                    Tracking Complete
                                </Button>
                            ) : (
                                <Button
                                    disabled={!trackingEnabled}
                                    sx={{ textTransform: "initial" }}
                                    variant={"contained"}
                                    onClick={(e) => handleClick(e)}
                                    data-cy={optimization.id + "-see-tracking"}
                                >
                                    See Tracking
                                </Button>
                            ))}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableActionCell actionIcons={actionArray} />
        </TableRow>
    );
};
