import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import queryString from "query-string";
import { format } from "date-fns";
import { Range } from "react-date-range";
import { css } from "@emotion/css";
import { Stack } from "@mui/system";
import { TabContext, TabPanel } from "@mui/lab";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

import {
    getPercentageGroupDataBasedOnActiveTab,
    getPercentageFilteredData,
    getDefaultRange,
    getFilteredPeformance,
    getInitialColumnFilter,
    handleDeleteParamKey,
    setChannelsRedirectFilterForCampaign,
    setTacticsRedirectFilterForCampaign,
    getGroupDataBasedOnActiveTab,
} from "../../services/performancePage/performancePage";
import { ApiError } from "../Errors/ApiError";
import { useLazyGetPerformancePageQuery } from "../../reduxState/apis/performancePageApi";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import {
    capitalizeFirstLetter,
    convertObjectToQueryParam,
    customSortData,
    getCurrentPerformanceTab,
    getPathForAnalytics,
    getTotalAmountForPerformanceTable,
    handleFilteredData,
    handleSingleFilter,
} from "../../services/utils";
import {
    getPaidDataSources,
    getPerformanceWithCAC,
    IHeadCell,
    PerformanceTab,
    PerformanceTabKeyMapping,
} from "../../consts/performancePaidPage/performancePaidPage";
import { selectCurentFilterView, selectIsSavedViewOn } from "../../reduxState/slices/filterViewSlice";
import { InsightsModal } from "../../components/CampaignComponents/InsightsModal/InsightsModal";
import { insightsModalSelector, setInsightsModal } from "../../reduxState/slices/insightsModalSlice";
import { isCampaignAvailableSelector, isModelingAvailableSelector } from "../../reduxState/slices/settingsSlice";
import { ActivePerformanceTabs } from "../../enums/ActivePerfomanceTabs";
import { Choice } from "../../consts/featuresPage/featuresPage";
import { IConnector } from "../../interfaces/IConnector";
import { IPerformancePercentageTableData } from "../../interfaces/performanceDetails/IPerformancePercentage";
import {
    formattedCompareDateSelector,
    formattedDateSelector,
    isCompareSelector,
    minDateSelector,
    setCompare,
    setCompareDateFilter,
    setCurrentDateFilter,
} from "../../reduxState/slices/dateFilterSlice";
import { getPastPeriod } from "../../components/DateRangeFilter/reactDateRangeUtils";
import {
    dataQaReady,
    selectCurrentlyViewingCode,
    selectCurrentlyViewingId,
} from "../../reduxState/slices/organizationSlice";
import ReactDateRangeSelect from "../../components/DateRangeFilter/ReactDateRange";
import { CampaignTable } from "../../components/CampaignComponents/CampaignTable/CampaignTable";
import { CampaignSubHeader } from "../../components/CampaignComponents/CampaignSubHeader/CampaignSubHeader";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import Loader from "../../components/core/Loader/Loader";
import { CampaignAggregate } from "../../components/CampaignComponents/CampaignAggregate";
import { CampaignPercentageTable } from "../../components/CampaignComponents/CampaignPercentageTable/CampaignPercentageTable";
import { performanceColumnOrdersSelector } from "../../reduxState/slices/settingsSlice";
import {
    PERFORMANCE_CAMPAIGNS_PATH,
    PERFORMANCE_CHANNELS_PATH,
    PERFORMANCE_PATH,
    PERFORMANCE_TACTICS_PATH,
} from "src/consts/path/path";
import { IMetricAttributionTableValuesTransformed } from "src/interfaces/performanceDetails/IMetricAttributionTableResponse";
import { PageHeaderTabContext } from "src/components/CustomTabContext/PageHeaderTabContext";
import { AMAZON_ADS, SHOPIFY } from "src/consts/connectors";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import { getAllTactics } from "src/reduxState/slices/tacticsSlice";

export const useStylesForCampaingPage = () => ({
    pageContainer: css({
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    }),
    saveViewBtn: css({
        boxShadow: "none",
        height: "38px",
        whiteSpace: "nowrap",
        textTransform: "inherit",
        padding: "0 15px",
        "& button": {
            textTransform: "inherit",
        },
        "&.closePastBtn": {
            padding: "4px 10px",
            border: 0,
            borderRight: "1px",
            color: "white",
            backgroundColor: "#275469",
            fontSize: "15px",
            height: "50px",

            "&.groupIconBtn": {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
            },

            "&:hover": {
                backgroundColor: "#316B87!important",
            },
        },
    }),
});

interface IPerformanceContext {
    defaultRevenueConnectorSource: string;
    isAmazonCampaignAvailable: boolean;
    activeTab?: PerformanceTab;
}
export const PerformanceContext = React.createContext<IPerformanceContext | null>(null);

interface ILocationState {
    state: {
        showInsightsTab?: boolean;
    };
    search: string;
}

export const handleSelectedColsHidden = (performanceCols: IHeadCell[]) => {
    const latestQueryParamsObj = queryString.parse(window.location.search);
    const selectedCols: IHeadCell[] = performanceCols.map((h) => {
        if (latestQueryParamsObj?.excluded_columns?.includes(h.id)) {
            return { ...h, hidden: true };
        }
        if (
            latestQueryParamsObj.campaign_id ||
            (h.id === "campaignId" && latestQueryParamsObj?.excluded_columns) ||
            (h.id === "amazonSellingPartnerRevenue" && latestQueryParamsObj?.excluded_columns) ||
            (h.id === "storeModeledRevenue" && latestQueryParamsObj?.excluded_columns) ||
            (h.id === "baseRevenue" && latestQueryParamsObj?.excluded_columns) ||
            (h.id === "haloEffectRevenue" && latestQueryParamsObj?.excluded_columns) ||
            (h.id === "cpc" && latestQueryParamsObj?.excluded_columns) ||
            (h.id === "status" && latestQueryParamsObj?.excluded_columns) ||
            (h.id === "tacticName" && latestQueryParamsObj?.excluded_columns)
        ) {
            return { ...h, hidden: false };
        }
        return h;
    });
    return selectedCols;
};

export const CampaignPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStylesForCampaingPage();
    const { state, search: locationSearch } = useLocation() as ILocationState;
    const supportedDataSources = useSelector(supportedDataSourcesSelector);
    const analyticsService = useAnalyticsService();
    const selectFormattedDates = useSelector(formattedDateSelector);
    const performanceColumnOrders = useSelector(performanceColumnOrdersSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const connectoChannelButtonRef = useRef<any>(null);
    const connectorsRef = useRef<IConnector[]>([]);
    connectorsRef.current = useSelector(connectorsSelector);
    const [tabValue, setTabValue] = useState<PerformanceTab>(getCurrentPerformanceTab());
    const [partialPageViewed, setPartialPageViewed] = useState(false);
    const tabs = [
        {
            label: "Channels",
            value: PerformanceTab.Channels,
            icon: "channelIcon",
            to: `/org/${orgCode}${PERFORMANCE_CHANNELS_PATH}${location.search}`,
        },
        {
            label: "Tactics",
            value: PerformanceTab.Tactics,
            icon: <CategoryOutlinedIcon sx={{ width: "16px", height: "16px" }} />,
            to: `/org/${orgCode}${PERFORMANCE_TACTICS_PATH}${location.search}`,
        },
        {
            label: "Campaigns",
            value: PerformanceTab.Campaigns,
            icon: "campaignIcon",
            to: `/org/${orgCode}${PERFORMANCE_CAMPAIGNS_PATH}${location.search}`,
        },
    ];
    const isChanneltabActive = useMemo(() => tabValue === PerformanceTab.Channels, [tabValue]);
    const isTacticTabActive = useMemo(() => tabValue === PerformanceTab.Tactics, [tabValue]);
    const isGroupTabActive = isChanneltabActive || isTacticTabActive;

    const getPerformanceColumns = (defaultRevenueConnectorSource: string = "", connectors: IConnector[]) => {
        let performanceCols = handleSelectedColsHidden(
            getPerformanceWithCAC(defaultRevenueConnectorSource, connectors, orgCode),
        );

        if (tabValue === PerformanceTab.Channels || tabValue === PerformanceTab.Tactics) {
            performanceCols = performanceCols.filter((c) => c.id !== "tacticName");
        }

        if (performanceColumnOrders?.length) {
            const sortMemberByCustomStatus = customSortData(
                performanceCols.filter((p) => p.id !== "campaignName"),
                "id",
                performanceColumnOrders,
            );
            return [performanceCols[0], ...sortMemberByCustomStatus];
        } else {
            return [...performanceCols];
        }
    };
    const filterQueryParamsObj = queryString.parse(window.location.search);

    const [selectedTableColumns, setselectedTableColumns] = useState<IHeadCell[]>(
        getPerformanceColumns(SHOPIFY, connectorsRef.current),
    );

    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("spend");
    const [defaultRevenueConnectorSource, setdefaultRevenueConnectorSource] = useState(SHOPIFY);
    const [isAmazonCampaignAvailable, setIsAmazonCampaignAvailable] = useState(false);

    const companyId = useSelector(selectCurrentlyViewingId);
    const curentFilterView = useSelector(selectCurentFilterView);
    const insightsModalList = useSelector(insightsModalSelector);
    const minDate = useSelector(minDateSelector);

    const isCampaignAvailable = useSelector(isCampaignAvailableSelector);
    const isModelingAvailable = useSelector(isModelingAvailableSelector);
    const isDataQaReady = useSelector(dataQaReady);

    const isSavedViewOn = useSelector(selectIsSavedViewOn);
    const pastDateFilter = useSelector(formattedCompareDateSelector);

    const allTactics = useSelector(getAllTactics);
    const allTacticsStringArr = allTactics.map((t) => t.tactic);

    const searchText = (filterQueryParamsObj.search_text as string) || "";
    const [search, setSearch] = useState<string>(searchText);

    const [
        getPerformancePage,
        {
            data: allCurrentData = {
                data: [],
                meta: { campaignsDate: "", revenueData: [] },
            },
            isFetching,
            isError,
            error,
        },
    ] = useLazyGetPerformancePageQuery();

    const [
        getPastPerformancePage,
        {
            data: allPastData = {
                data: [],
                meta: { campaignsDate: "", revenueData: [] },
            },
            isFetching: isPastDataFetching,
        },
    ] = useLazyGetPerformancePageQuery();

    const {
        data: currentData,
        meta: { campaignsDate: currentCampaignsDate },
    } = allCurrentData;
    const { data: pastData } = allPastData;

    const compareToPast = useSelector(isCompareSelector);
    const performanceData: IMetricAttributionTableValuesTransformed[] = getFilteredPeformance(
        getGroupDataBasedOnActiveTab(currentData, tabValue),
        search,
        filterQueryParamsObj,
    );

    const totalAmountForSelectedRange = getTotalAmountForPerformanceTable(currentData || [], true);
    const totalAmountWithFilter = getTotalAmountForPerformanceTable(
        isGroupTabActive ? performanceData.map((p) => p.campaigns || []).flat(1) : performanceData || [],
        true,
    );

    useEffect(() => {
        analyticsService.logEvent(`Performance ${capitalizeFirstLetter(getCurrentPerformanceTab())} Page Viewed`, {
            Page: getPathForAnalytics(location.pathname),
        });
        setTabValue(getCurrentPerformanceTab());
        setPage(0);
    }, [location.pathname]);

    useEffect(() => {
        if (!isCampaignAvailable) {
            analyticsService.logEvent("Performance Empty Page Viewed", {});
        }
    }, [isCampaignAvailable]);

    useEffect(() => {
        if (!isCampaignAvailable) {
            return;
        }
        if (!isModelingAvailable && !partialPageViewed) {
            setPartialPageViewed(true);
            analyticsService.logEvent("Performance Partial Page Viewed", {});
        } else if (isModelingAvailable && isDataQaReady) {
            analyticsService.logEvent("Performance Data QA Page Viewed", {});
        }
    }, [isModelingAvailable, isCampaignAvailable, isDataQaReady]);

    useEffect(() => {
        setselectedTableColumns(getPerformanceColumns(defaultRevenueConnectorSource, connectorsRef.current));
    }, [connectorsRef.current, tabValue]);

    const getPastPerformanceData = (pastDateRange: Range[]) => {
        const { startDate, endDate } = pastDateRange[0];
        if (startDate && endDate) {
            const formattedStartDate = format(startDate, "yyyy-MM-dd");
            const formattedEndDate = format(endDate, "yyyy-MM-dd");
            getPastPerformancePage({
                orgId: companyId,
                data: {
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    connectors: getPaidDataSources(supportedDataSources),
                },
            })
                .unwrap()
                .then(() => {
                    const filterQueryParamsObj = queryString.parse(window.location.search);
                    const updateDateFilter = {
                        ...filterQueryParamsObj,
                        comparison_start_date: formattedStartDate,
                        comparison_end_date: formattedEndDate,
                    };
                    navigate({
                        search: convertObjectToQueryParam(updateDateFilter),
                    });
                });
        }
    };

    const getCurrentPerformanceData = (dateRange: Range[]) => {
        const { startDate, endDate } = dateRange[0];
        if (startDate && endDate && supportedDataSources) {
            const formattedStartDate = format(startDate, "yyyy-MM-dd");
            const formattedEndDate = format(endDate, "yyyy-MM-dd");
            getPerformancePage({
                orgId: companyId,
                data: {
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    connectors: getPaidDataSources(supportedDataSources),
                },
            })
                .unwrap()
                .then((response) => {
                    if (response) {
                        // if user switched to campaign tab but there are no campaigns, switch back to channels tab
                        if (response?.data?.length === 0) {
                            navigate(`/org/${orgCode}${PERFORMANCE_CHANNELS_PATH}${location.search}`);
                            return;
                        }

                        const amazonCampaigns = response?.data.filter(
                            (d: { connectorName: string }) => d.connectorName === AMAZON_ADS,
                        );
                        setIsAmazonCampaignAvailable(!!amazonCampaigns?.length);
                        setdefaultRevenueConnectorSource(response?.meta?.defaultRevenueConnectorSource);
                        setselectedTableColumns(
                            getPerformanceColumns(response?.meta?.defaultRevenueConnectorSource, connectorsRef.current),
                        );
                        const filterQueryParamsObj = queryString.parse(window.location.search);
                        let updateDateFilter: any = {
                            ...filterQueryParamsObj,
                            start_date: formattedStartDate,
                            end_date: formattedEndDate,
                        };

                        if (!isSavedViewOn && !filterQueryParamsObj.campaign_id) {
                            updateDateFilter = {
                                ...updateDateFilter,
                                excluded_columns: getInitialColumnFilter(filterQueryParamsObj, connectorsRef.current),
                            };
                        }

                        // when redirect from email to performance page and have viewing params
                        if (locationSearch.includes("viewing") && response?.data?.length) {
                            const campaignIds = filterQueryParamsObj[`viewing[]`];
                            const findCampaigns = response.data.filter((r: any) => campaignIds?.includes(r.campaignId));

                            findCampaigns.forEach((campaign: any) => {
                                dispatch(
                                    setInsightsModal({
                                        data: campaign,
                                        isMinimize: false,
                                        activeTab: ActivePerformanceTabs.Performance,
                                        activeMetric: "revenue",
                                    }),
                                );
                            });
                        }

                        if (location.pathname.includes(PERFORMANCE_PATH)) {
                            navigate({
                                search: convertObjectToQueryParam(updateDateFilter),
                                hash: location.hash,
                                pathname: location.pathname,
                            });
                        }
                    }
                });
        }
    };

    useEffect(() => {
        const currentDate = getDefaultRange(selectFormattedDates);
        const startDate = currentDate[0].startDate;
        const endDate = currentDate[0].endDate;
        if (startDate && !isNaN(startDate.getTime()) && endDate && !isNaN(endDate.getTime())) {
            dispatch(
                setCurrentDateFilter({
                    date: JSON.stringify(currentDate),
                }),
            );
        }
    }, []);

    useEffect(() => {
        const defaultRange = getDefaultRange(selectFormattedDates);
        getCurrentPerformanceData(defaultRange);
        if (locationSearch.includes("comparison_start_date")) {
            const defaultPastDate = getPastPeriod(defaultRange, true);
            dispatch(setCompare({ isCompareOn: true }));
            dispatch(setCompareDateFilter({ compareDate: JSON.stringify(defaultPastDate) }));
        }
        setPage(0);
        setSearch("");
        setOrder("desc");
        setOrderBy("spend");
    }, []);

    useEffect(() => {
        if (compareToPast && supportedDataSources.length) {
            getPastPerformanceData(pastDateFilter);
        }
    }, [pastDateFilter, compareToPast, supportedDataSources]);

    useEffect(() => {
        getCurrentPerformanceData(getDefaultRange(selectFormattedDates));
    }, [JSON.stringify(selectFormattedDates), curentFilterView, companyId, supportedDataSources]);

    useEffect(() => {
        const selectedCols = handleSelectedColsHidden(
            getPerformanceColumns(defaultRevenueConnectorSource, connectorsRef.current),
        );
        setselectedTableColumns(selectedCols);
        setSearch(searchText);
    }, [curentFilterView]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search) {
                analyticsService.logEvent("Performance Table Searched", {
                    Page: getPathForAnalytics(location.pathname, locationSearch),
                    "Search term": search,
                    "Search result count": performanceData.length,
                });
            }
            handleSingleFilter("search_text", search, navigate);
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    if (isError && error && "status" in error) {
        const errMsg = "error" in error ? error.error : JSON.stringify(error.data);
        return <ApiError errMsg={errMsg} />;
    }

    const onSearchChange = (e: any) => {
        setPage(0);
        setSearch(e.target.value);
    };

    const handleTableColSelection = (column: IHeadCell) => {
        const columns = selectedTableColumns.map((c) => {
            if (c.id === column.id) {
                if (!c.hidden && c.id === "campaignId") {
                    const queryParamsObj = { ...filterQueryParamsObj };
                    delete queryParamsObj.campaign_id;
                    navigate({
                        search: convertObjectToQueryParam(queryParamsObj),
                    });
                }
                handleFilteredData("excluded_columns", { id: c.id }, navigate);
                analyticsService.logEvent("Performance Table Column Toggled", {
                    Page: getPathForAnalytics(location.pathname, locationSearch),
                    Column: c.label,
                    "Toggled state": !c.hidden ? "Removed" : "Added",
                });
                return { ...c, hidden: !c.hidden };
            } else {
                return c;
            }
        });
        setselectedTableColumns(columns);
    };

    const finalPercentagePerformanceData: IPerformancePercentageTableData = getPercentageGroupDataBasedOnActiveTab(
        tabValue,
        currentData,
        pastData,
        search,
        filterQueryParamsObj,
    );

    const percentagePerformanceDataForSelectedRange: IPerformancePercentageTableData = getPercentageFilteredData(
        currentData,
        pastData,
        "",
        "",
        true,
        PerformanceTabKeyMapping[PerformanceTab.Campaigns],
    );

    const commonPropsForTables = {
        selectedTableColumns: (isGroupTabActive
            ? selectedTableColumns.map((c) =>
                  c.id === "campaignName"
                      ? {
                            id: "campaignName",
                            numeric: false,
                            disablePadding: false,
                            label: isChanneltabActive ? "Channels" : "Tactics",
                            hidden: false,
                            width: "350px",
                        }
                      : c,
              )
            : selectedTableColumns) as IHeadCell[],
        page,
        setPage,
        setOrder,
        order,
        orderBy,
        setOrderBy,
        currentCampaignsDate,
        activePerformanceTab: tabValue,
    };

    const handleTabChange = (event: React.SyntheticEvent | null, newValue: PerformanceTab) => {
        setTabValue(newValue);
        if (newValue === PerformanceTab.Channels) {
            handleDeleteParamKey("contains", navigate);
        }
    };

    const handleChannelClick = (row: IMetricAttributionTableValuesTransformed) => {
        if (tabValue === PerformanceTab.Channels) {
            analyticsService.logEvent("Performance Channel Table Link Clicked", {
                "Connector name": row.channelName,
            });
            setChannelsRedirectFilterForCampaign(performanceData, row, navigate, orgCode);
        } else if (tabValue === PerformanceTab.Tactics) {
            setTacticsRedirectFilterForCampaign(
                performanceData,
                row,
                connectorsRef.current,
                navigate,
                allTacticsStringArr,
                selectFormattedDates,
                orgCode,
                setselectedTableColumns,
            );
        }
    };

    return (
        <div>
            <PageHeader
                pageHeading="Performance"
                dateFilterComponent={<ReactDateRangeSelect minDate={minDate} compareView={true} />}
                customStyle={{ padding: "30px 30px 0" }}
                showBreadcrumbs={false}
                stats={
                    <CampaignAggregate
                        totalAmount={totalAmountForSelectedRange}
                        finalPercentagePerformanceData={percentagePerformanceDataForSelectedRange}
                        isLoading={isFetching}
                        revenueData={allCurrentData.meta.revenueData}
                        pastRevenueData={allPastData.meta.revenueData}
                        defaultRevenueConnectorSource={defaultRevenueConnectorSource}
                        connectors={connectorsRef.current}
                    />
                }
                tabs={<PageHeaderTabContext value={tabValue} handleChange={handleTabChange} tabs={tabs} />}
            />
            <PageContainer className={classes.pageContainer}>
                <PerformanceContext.Provider
                    value={{ defaultRevenueConnectorSource, isAmazonCampaignAvailable, activeTab: tabValue }}
                >
                    <CampaignSubHeader
                        currentDateFilter={selectFormattedDates}
                        performanceData={performanceData}
                        selectedTableColumns={selectedTableColumns}
                        onSearchChange={onSearchChange}
                        handleTableCol={handleTableColSelection}
                        originalPerformanceData={
                            compareToPast && pastData && currentData
                                ? [...new Set([...pastData, ...currentData])]
                                : currentData || []
                        }
                        compareToPast={compareToPast}
                        finalPercentagePerformanceData={finalPercentagePerformanceData}
                        order={order}
                        orderBy={orderBy}
                        searchCampaign={search}
                        setselectedTableColumns={setselectedTableColumns}
                        activeTab={tabValue}
                    />
                </PerformanceContext.Provider>
                {isPastDataFetching || isFetching ? (
                    <Loader />
                ) : (
                    currentData && (
                        <div className="performanceTable">
                            {compareToPast && pastData ? (
                                <CampaignPercentageTable
                                    currentDateFilter={selectFormattedDates}
                                    finalPercentagePerformanceData={finalPercentagePerformanceData}
                                    handleChannelClick={handleChannelClick}
                                    isImagesInTable={tabValue !== PerformanceTab.Tactics}
                                    {...commonPropsForTables}
                                />
                            ) : (
                                <TabContext value={tabValue}>
                                    <TabPanel sx={{ padding: 0 }} value={PerformanceTab.Channels}>
                                        <CampaignTable
                                            isImagesInTable={true}
                                            tableData={performanceData}
                                            totalAmount={totalAmountWithFilter}
                                            isChannelRow={isChanneltabActive}
                                            handleChannelClick={handleChannelClick}
                                            orgCode={orgCode}
                                            {...commonPropsForTables}
                                        />
                                    </TabPanel>
                                    <TabPanel sx={{ padding: 0 }} value={PerformanceTab.Tactics}>
                                        <CampaignTable
                                            isImagesInTable={false}
                                            tableData={performanceData}
                                            totalAmount={totalAmountWithFilter}
                                            handleChannelClick={handleChannelClick}
                                            orgCode={orgCode}
                                            {...commonPropsForTables}
                                        />
                                    </TabPanel>
                                    <TabPanel sx={{ padding: 0 }} value={PerformanceTab.Campaigns}>
                                        <CampaignTable
                                            isImagesInTable={true}
                                            tableData={performanceData}
                                            totalAmount={totalAmountWithFilter}
                                            isChannelRow={isChanneltabActive}
                                            handleChannelClick={handleChannelClick}
                                            orgCode={orgCode}
                                            {...commonPropsForTables}
                                        />
                                    </TabPanel>
                                </TabContext>
                            )}
                        </div>
                    )
                )}
            </PageContainer>
            {insightsModalList?.length ? (
                <Stack
                    flexDirection="row-reverse"
                    gap="10px"
                    sx={({ zIndex }: any) => ({
                        position: "fixed",
                        bottom: 0,
                        right: "30px",
                        zIndex: zIndex?.appBar + 1,
                    })}
                >
                    {insightsModalList.map((modalData) => (
                        <React.Fragment key={modalData.data.campaignId}>
                            <PerformanceContext.Provider
                                value={{ defaultRevenueConnectorSource, isAmazonCampaignAvailable }}
                            >
                                <InsightsModal modalInfo={modalData} />
                            </PerformanceContext.Provider>
                        </React.Fragment>
                    ))}
                </Stack>
            ) : null}
        </div>
    );
};
