import { IChips } from "src/interfaces/IChips/IChips";
import { ICompanyCampaign } from "src/interfaces/entities/ICompanyCampaign";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { IAddCampaignTactic } from "src/reduxState/apis/campaignTacticsApi";

export enum TacticView {
    LABELED = "labeled",
    UNLABELED = "unlabeled",
    ALL = "all",
}

export const generateCampaignTacticBody = (campaignTacticData: IAddCampaignTactic[]) => {
    return {
        data: {
            attributes: {
                campaign_tactics: campaignTacticData.map((tactic) => ({
                    campaign_id: tactic.campaignId,
                    campaign_tactic_type_id: tactic.tacticId,
                })),
            },
            type: "campaign_tactic_types",
        },
    };
};

export const filterTacticsCampaigns = (dimCampaigns: IDimCampaign[], companyCampaigns: ICompanyCampaign[]) => {
    let filteredCampaigns: IDimCampaign[] = [];

    // Check if there are any company campaigns
    if (companyCampaigns && companyCampaigns.length > 0) {
        const companyCampaignIds = companyCampaigns.map((campaign) => campaign.campaignId);
        // Filter dimCampaigns to include only those in companyCampaigns
        filteredCampaigns = dimCampaigns.filter((campaign) => companyCampaignIds.includes(campaign.campaignId));
    } else {
        // Include all dimCampaigns if companyCampaigns is empty
        filteredCampaigns = dimCampaigns;
    }

    // Exclude campaigns with isIncluded=true unless in companyCampaigns
    filteredCampaigns = filteredCampaigns.filter(
        (campaign) =>
            !campaign.isIncluded ||
            companyCampaigns.some((companyCampaign) => companyCampaign.campaignId === campaign.campaignId),
    );

    return filteredCampaigns;
};

export const getFilteredCampaignTacticsData = (
    campaigns: IDimCampaign[],
    search: string,
    filter: string[],
    filterChipsValue: IChips[],
) => {
    if (campaigns && campaigns.length) {
        let updatedCampaigns = campaigns;
        if (filter && !filter.includes("all")) {
            const filterKeys = filterChipsValue
                .filter((chip) => filter.includes(chip.key.toString()))
                ?.map((chip) => chip.key);
            updatedCampaigns = updatedCampaigns.filter((campaign) => filterKeys.includes(campaign.channel));
        }

        if (search) {
            updatedCampaigns = search
                ? updatedCampaigns.filter((campaign) =>
                      `${campaign.campaignId} ${campaign.channel} ${campaign.campaignName}`
                          .toLowerCase()
                          .includes(search.toLowerCase()),
                  )
                : updatedCampaigns;
        }
        return updatedCampaigns;
    }
    return [];
};
