import React from "react";
import { Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { IAnnouncement } from "../../../interfaces/entities/IAnnouncement";
import { useStylesForRowInTable } from "../../../consts/StylesForTables";
import { PRIMARY_MAIN_COLOR } from "../../../consts/colors";
import { TableActionCell } from "../../../components/core/TableActionCell/TableActionCell";
import { format } from "date-fns";
import { toDate } from "date-fns-tz";

interface IAnnouncementsPageRow {
    announcement: IAnnouncement;
    onDeleteAnnouncement: (e: any, announcement: IAnnouncement) => void;
    onEditAnnouncement: (e: any, announcement: IAnnouncement) => void;
}

export const AnnouncementTableRow: React.FC<IAnnouncementsPageRow> = ({
    onDeleteAnnouncement,
    onEditAnnouncement,
    announcement,
}) => {
    const classes = useStylesForRowInTable();

    return (
        <TableRow key={announcement.id} className={classes.bodyRow}>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">{announcement.title}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">{announcement.description}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2">{format(toDate(announcement.releaseDate), "MM/dd/yyyy")}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography color={PRIMARY_MAIN_COLOR}>{announcement.link}</Typography>
            </TableCell>
            <TableActionCell
                actionIcons={[
                    { title: "Edit", onClick: onEditAnnouncement, data: announcement, icon: "editIcon2" },
                    { title: "Delete", onClick: onDeleteAnnouncement, data: announcement, icon: "trashIcon" },
                ]}
            />
        </TableRow>
    );
};
