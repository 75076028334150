import { css } from "@emotion/css";
import { Theme } from "@mui/material";

export const optimizationStyle = (theme: Theme) => ({
    leftSection: css({
        padding: "30px",
        backgroundColor: "white",
        minHeight: "calc(100vh - 64px)",
        width: "360px",
        [theme.breakpoints.down("md")]: {
            width: "98%!important",
            gap: "20px",
        },
    }),
    rightSection: css({
        width: "calc(100% - 400px)",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    }),
    formContainer: css({
        padding: "30px 30px 150px 30px",
        [theme.breakpoints.down("md")]: {
            padding: "10px",
        },
    }),
    formContentStyle: css({
        backgroundColor: "white",
        padding: "30px 30px 50px",
        border: "1px solid #D9D9D9",
        borderRadius: "5px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: "10px",
        },
    }),
    formSimpleLabel: css({ color: "#000000", fontSize: "15px" }),
    formError: css({ color: theme.palette.error.main }),

    // campaign selection
    avatarImage: css({ width: "32px!important", height: "32px!important" }),
    campaignNameOverflow: css({
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "400px",
        textAlign: "left",
        fontSize: "15px",
    }),
    campaignCell: css({
        width: "300px",
        paddingLeft: "8px",
    }),
    tableCellFont: css({
        fontSize: "15px",
    }),
    disableCampaignRow: css({
        opacity: 0.5,
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        cursor: "not-allowed",
    }),

    // footer style
    footerContainer: css({
        position: "fixed",
        bottom: 0,
        left: 0,
        height: "84px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        width: "100%",
        boxShadow: "inset 0px 1px 0px #D9D9D9",
        zIndex: theme.zIndex.modal,
    }),
    footerContent: css({
        padding: "30px",
        width: "100%",
    }),
    exitDialogBox: css({
        "& .MuiPaper-root": {
            display: "flex",
            flexDirection: "column",
            padding: "30px",
            gap: "30px",
        },
        "& .title": {
            "& p": { fontWeight: "bold", fontSize: "18px" },
        },
        "& .MuiTypography-h6": {
            fontSize: "18px",
            padding: 0,
        },
    }),
    outlinedBtn: css({
        textTransform: "inherit",
        height: "54px",
        padding: "0px 30px",
        fontSize: "15px",
    }),
    containedBtn: css({ textTransform: "inherit", height: "54px", padding: "0px 30px", fontSize: "15px" }),
    outcomeTableCell: css({
        fontSize: "15px",
        width: "320px",
        border: "solid rgba(224, 224, 224, 1)",
        borderWidth: "0 0 1px 0",
    }),
    totalRow: css({
        "& td, & p": css({ fontWeight: "700", fontSize: "15px" }),
    }),
    increaseText: css({ color: "#2F970B" }),
    decreaseText: css({ color: "#FF0000" }),
    boldText: css({ fontWeight: "700" }),
    projectionsTableHeader: css({
        color: "black",
        fontSize: "16px",
        fontWeight: "700",
        borderBottom: "2px solid black",
    }),
});
