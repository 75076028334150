import React from "react";
import { TableBody } from "@mui/material";
import { useSelector } from "react-redux";

import { IPageProps } from "../../../interfaces/IPageProps";
import { IOrganization } from "../../../interfaces/entities/IOrganization";
import { stableSort, getComparator } from "../../../utils/sort";
import { Choice } from "../../../consts/connectorsPage/connectorsPage";
import { IOrganizationCell } from "../../../consts/OrganizaionTable/OrganizationTable";
import { OrganizationsTableHeader } from "../OrganizationHeader/OrganizationTableHeader";
import { OrganizationTableRow } from "../OrganizationRow/OrganizationRow";
import { CustomTableStructure } from "../../../components/core/CustomTableStructure/CustomTableStructure";
import { useStylesTable } from "../../../consts/StylesForTables";
import { paginationCountSelector } from "../../../reduxState/slices/settingsSlice";
import { recalculateSortDirection } from "src/services/utils";

interface IOrganizationsTableViewProps extends IPageProps {
    organizations: any[];
    editClick(organization: IOrganization): void;

    deleteClick(organization: IOrganization): void;

    viewOrganization(organization: IOrganization): void;
    subscriptionClick(organization: IOrganization): void;
}

export const OrganizationsTable: React.FC<IOrganizationsTableViewProps> = ({
    organizations,
    editClick,
    deleteClick,
    viewOrganization,
    subscriptionClick,
}) => {
    const classes = useStylesTable();
    const rowsPerPage = useSelector(paginationCountSelector);

    const [page, setPage] = React.useState(0);

    const [order, setOrder] = React.useState<Choice>("asc");

    const [orderBy, setOrderBy] = React.useState("name");

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IOrganizationCell) => {
        setOrder(recalculateSortDirection(orderBy, order, property));
        setOrderBy(property);
    };

    const viewOrg = (organization: IOrganization) => {
        viewOrganization(organization);
    };

    const handleEdit = (e: React.MouseEvent<any>, organization: IOrganization) => {
        e.stopPropagation();
        if (organization) {
            editClick(organization);
        }
    };

    const handleDelete = (e: React.MouseEvent<any>, organization: IOrganization) => {
        e.stopPropagation();
        if (organization) {
            deleteClick(organization);
        }
    };

    const handleSubsription = (e: React.MouseEvent<any>, organization: IOrganization) => {
        e.stopPropagation();
        if (organization) {
            subscriptionClick(organization);
        }
    };

    return (
        <CustomTableStructure
            paginationProps={{
                count: organizations.length,
                page,
                handleChangePage,
                handleChangeRowsPerPage,
            }}
        >
            <OrganizationsTableHeader choiceBy={orderBy} choice={order} handleRequestSort={handleRequestSort} />
            <TableBody className={classes.tableBody}>
                {stableSort(organizations, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <OrganizationTableRow
                                labelId={labelId}
                                key={row.id}
                                row={row}
                                viewOrg={viewOrg}
                                editClick={handleEdit}
                                deleteClick={handleDelete}
                                subscriptionClick={handleSubsription}
                            />
                        );
                    })}
            </TableBody>
        </CustomTableStructure>
    );
};
