import React, { useEffect, useState } from "react";
import { Card, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useSelector } from "react-redux";

import { IConnector } from "../../../interfaces/IConnector";
import { IPageProps } from "../../../interfaces/IPageProps";
import { ConnectorTableHeader } from "./ConnectorTableHeader";
import { ConnectorTableRow } from "./ConnectorTableRow";
import { ConnectorView } from "src/containers/Connectors/ConnectorsV2";
import { IDataSourceWithConnected } from "src/interfaces/IData";
import { Choice, IConnectorsCells } from "src/consts/connectorsPage/connectorsPage";
import { getComparator, stableSort } from "src/utils/sort";
import { CustomTablePagination } from "src/components/core/CustomTablePagination/CustomTablePagination";
import { paginationCountSelector } from "src/reduxState/slices/settingsSlice";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { recalculateSortDirection } from "src/services/utils";

interface IConnectorTableViewProps extends IPageProps {
    connectors: IConnector[];
    selectedView?: ConnectorView;
    isListingPage?: boolean;
    isConnectionInProgress?: boolean;
    editClick: (connector: IConnector) => void;
    openRespectiveConfirmModal: (connector: IConnector, action: string) => void;
    handleCreateConnectorWithFivetran?: (service: IDataSourceWithConnected) => Promise<void>;
}

export const ConnectorTable: React.FC<IConnectorTableViewProps> = ({
    connectors,
    selectedView,
    openRespectiveConfirmModal,
    editClick,
    isListingPage = false,
    handleCreateConnectorWithFivetran,
    isConnectionInProgress = false,
}) => {
    const [order, setOrder] = useState<Choice>("asc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState<number>(0);
    const rowsPerPage = useSelector(paginationCountSelector);
    const analyticsService = useAnalyticsService();

    useEffect(() => {
        setPage(0);
        setOrder("asc");
        setOrderBy("");
    }, [selectedView]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IConnectorsCells) => {
        setOrder(recalculateSortDirection(orderBy, order, property));
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleActionBasedOnModal = (
        e: React.MouseEvent<HTMLButtonElement>,
        connector: IConnector,
        action: string,
    ) => {
        e.stopPropagation();
        if (connector) {
            openRespectiveConfirmModal(connector, action);
        }
    };

    const handleEdit = (e: React.MouseEvent<HTMLButtonElement>, connector: IConnector) => {
        e.stopPropagation();
        editClick(connector);
    };

    const handleShareConnector = (e: React.MouseEvent<HTMLButtonElement>, connector: IConnector, action: string) => {
        e.stopPropagation();
        analyticsService.logEvent("Share Connector Clicked", {});
        if (connector) {
            openRespectiveConfirmModal(connector, action);
        }
    };

    const handleConnectForListing = (e: React.MouseEvent<HTMLButtonElement>, connector: any) => {
        e.stopPropagation();
        if (connector && handleCreateConnectorWithFivetran) {
            handleCreateConnectorWithFivetran(connector);
        }
    };

    const sortedConnectors = stableSort(connectors, getComparator(order, orderBy));
    const paginatedAndSortedConnectors = isListingPage
        ? sortedConnectors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : sortedConnectors;

    return (
        <TableContainer component={Card} sx={{ boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.5)" }}>
            <Table>
                <ConnectorTableHeader
                    selectedView={selectedView}
                    onRequestSort={handleRequestSort}
                    choice={order}
                    choiceBy={orderBy}
                />
                <TableBody>
                    {connectors.length === 0 ? (
                        <TableRow>
                            <TableCell align="center" colSpan={5}>
                                You don’t have data sources connected for this step
                            </TableCell>
                        </TableRow>
                    ) : (
                        paginatedAndSortedConnectors.map((connector: any) => (
                            <ConnectorTableRow
                                key={connector.id}
                                connector={connector}
                                onDeleteConnector={handleActionBasedOnModal}
                                onPurgeConnector={handleActionBasedOnModal}
                                onResyncClick={handleActionBasedOnModal}
                                handleConnect={handleActionBasedOnModal}
                                onEditConnector={handleEdit}
                                brokenStatusClick={handleConnectForListing}
                                onShareConnector={handleShareConnector}
                                isListingPage={isListingPage}
                                handleCreateConnectorWithFivetran={isListingPage ? handleConnectForListing : undefined}
                                isConnectionInProgress={isConnectionInProgress}
                            />
                        ))
                    )}
                </TableBody>
            </Table>
            {isListingPage && (
                <CustomTablePagination
                    count={connectors.length}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </TableContainer>
    );
};
