import { FormikErrors, FormikTouched } from "formik";
import { IAvailableOptimizationModelsPage } from "src/reduxState/apis/optimizationApi";
import { OPTIMIZED_MODELS_BY_METRIC, TIMEFRAME_OPTIONS } from "../../../consts/optimizationPage/optimizationPage";
import { IScenario } from "../../../interfaces/entities/IScenario";

export interface IScenarioField {
    [key: string]: {
        name: string;
        label: string;
        requiredErrorMsg?: string;
        placeholder?: string;
    };
}

export interface IScenarioFormData {
    formId: string;
    formField: IScenarioField;
}

export default {
    formId: "addScenarioForm",
    formField: {
        name: {
            name: "name",
            label: "Scenario name",
            requiredErrorMsg: "Scenario name is required",
            placeholder: "Enter name...",
        },
        description: {
            name: "description",
            label: "Scenario Description",
            requiredErrorMsg: "Scenario Description is required",
            placeholder: "Enter description...",
        },
        scenarioType: {
            name: "scenarioType",
            label: "Scenario Target",
            requiredErrorMsg: "Scenario target is required",
        },
        forecastTimeframe: {
            name: "forecastTimeframe",
            label: "Forecasting Timeframe",
            requiredErrorMsg: "Forecast timeframe is required",
        },
        budget: {
            name: "budget",
            label: "Estimated Budget",
            placeholder: "Enter budget...",
        },
        optimizationConfidence: {
            name: "optimizationConfidence",
            label: "Optimization Confidence",
            placeholder: "Enter optimization confidence...",
        },
        revenue: {
            name: "revenue",
            label: "Minimum Revenue (optional)",
            placeholder: "Enter minimum revenue...",
        },
        roas: {
            name: "roas",
            label: "Minimum ROAS (optional)",
            placeholder: "1.00",
        },
        campaignData: {
            name: "campaignData",
            label: "Campaign",
            requiredErrorMsg: "Please select at least one campaign",
        },
    },
} as IScenarioFormData;

export interface IScenarioFromProps {
    formField: IScenarioField;
    errors: FormikErrors<IScenario>;
    touched: FormikTouched<IScenario>;
    values: IScenario;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    classes: any;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    performanceData: IAvailableOptimizationModelsPage;
    isPerformanceLoading: boolean;
    getTimeframeValue: (value: TIMEFRAME_OPTIONS) => void;
    selectedScenario: IScenario;
    lastDaysTooltip: string;
    getSelectedForecastTarget: (value: OPTIMIZED_MODELS_BY_METRIC) => void;
}
