import { createSelector, createSlice } from "@reduxjs/toolkit";
import union from "lodash/union";
import {
    ATTRIBUTION_METRIC_DROPDOWN,
    CAMPAIGN_INCLUSIONS_PAGE,
    COMPANY_WIDE_FORECASTING_CARDS,
    EXCLUDE_OPTIMIZER,
    KLAVIYO_EMAIL_PAGE,
    OPTIMIZATION_PREDICTED_LIFT,
    OPTIMIZER_WITH_CONFIDENCE,
    CAC_OPTIMIZER,
} from "../../consts/featuresPage/featuresPage";
import { IFeature } from "../../interfaces/entities/IFeature";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";
import { selectFeaturesOfCurrentlyViewingCompany } from "./organizationSlice";

export interface IFeatureState {
    order: string[];
    byId: { [key: string]: IFeature };
}

const initialState: IFeatureState = {
    order: [],
    byId: {},
};

export const addFeaturesToState = (
    state: IFeatureState,
    action: {
        payload: IFeature[];
        type: string;
    },
) => {
    const { payload } = action;
    const { byId, order } = state;
    const newState = {
        byId: payload.reduce((acc, item) => Object.assign(acc, { [item.id]: item }), {}),
        order: payload.reduce((acc: string[], item) => {
            acc.push(item.id);
            return acc;
        }, []),
    };
    const newById = Object.assign({}, byId);
    payload.forEach((item) => {
        newById[item.id] = item;
    });

    return {
        byId: newById,
        order: union(order, newState.order),
    };
};

export const removeFeatureFromStateById = (
    state: IFeatureState,
    action: {
        payload: string;
        type: string;
    },
) => {
    const id = action.payload;
    state.byId[id] && delete state.byId[id];
    state.order = state.order.filter((i) => i !== id);
};

const featuresSlice = createSlice({
    name: "features",
    initialState,
    reducers: {
        setFeatures: addFeaturesToState,
        removeFeatureById: removeFeatureFromStateById,
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return initialState;
        });
    },
});

export const { setFeatures, removeFeatureById } = featuresSlice.actions;

export default featuresSlice.reducer;

// Selectors
export const featuresSelector = (state: RootState) => state.features;

export const listFeaturesSelector = createSelector(featuresSelector, ({ byId }) => Object.values(byId));

export const klaviyoEmailPageSelector = createSelector(
    [featuresSelector, selectFeaturesOfCurrentlyViewingCompany],
    ({ byId }, features = []) => {
        return (
            features.filter((f) => {
                const feature = byId[f];
                return feature && feature.cleanName === KLAVIYO_EMAIL_PAGE;
            }).length === 1
        );
    },
);

export const attributionMetricDropdownSelector = createSelector(
    [featuresSelector, selectFeaturesOfCurrentlyViewingCompany],
    ({ byId }, features = []) => {
        return (
            features.filter((f) => {
                const feature = byId[f];
                return feature && feature.cleanName === ATTRIBUTION_METRIC_DROPDOWN;
            }).length === 1
        );
    },
);

export const cacOptimizerSelector = createSelector(
    [featuresSelector, selectFeaturesOfCurrentlyViewingCompany],
    ({ byId }, features = []) => {
        return (
            features.filter((f) => {
                const feature = byId[f];
                return feature && feature.cleanName === CAC_OPTIMIZER;
            }).length === 1
        );
    },
);

export const optimizerWithConfidenceSelector = createSelector(
    [featuresSelector, selectFeaturesOfCurrentlyViewingCompany],
    ({ byId }, features = []) => {
        return (
            features.filter((f) => {
                const feature = byId[f];
                return feature && feature.cleanName === OPTIMIZER_WITH_CONFIDENCE;
            }).length === 1
        );
    },
);

export const companyWideForecastingCardsSelector = createSelector(
    [featuresSelector, selectFeaturesOfCurrentlyViewingCompany],
    ({ byId }, features = []) => {
        return (
            features.filter((f) => {
                const feature = byId[f];
                return feature && feature.cleanName === COMPANY_WIDE_FORECASTING_CARDS;
            }).length === 1
        );
    },
);

export const optimizationPredictedLiftSelector = createSelector(
    [featuresSelector, selectFeaturesOfCurrentlyViewingCompany],
    ({ byId }, features = []) => {
        return (
            features.filter((f) => {
                const feature = byId[f];
                return feature && feature.cleanName === OPTIMIZATION_PREDICTED_LIFT;
            }).length === 1
        );
    },
);

export const campaignInclusionsSelector = createSelector(
    [featuresSelector, selectFeaturesOfCurrentlyViewingCompany],
    ({ byId }, features = []) => {
        return (
            features.filter((f) => {
                const feature = byId[f];
                return feature && feature.cleanName === CAMPAIGN_INCLUSIONS_PAGE;
            }).length === 1
        );
    },
);

export const optimizationPageSelector = createSelector(
    [featuresSelector, selectFeaturesOfCurrentlyViewingCompany],
    ({ byId }, features = []) => {
        return (
            features.filter((f) => {
                const feature = byId[f];
                return feature && feature.cleanName === EXCLUDE_OPTIMIZER;
            }).length === 0
        );
    },
);
