import React, { Fragment } from "react";
import { Box, Stack } from "@mui/system";
import { Badge, MenuItem, Theme, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import { toDate } from "date-fns-tz";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/css";

import { LightMenu } from "../../../components/LightMenu/LightMenu";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { announcementCountSelector, announcementsSelector } from "../../../reduxState/slices/announcementSlice";
import { setUserLastSeenAnnouncement } from "../../../reduxState/slices/userSlice";
import { IAnnouncement } from "../../../interfaces/entities/IAnnouncement";
import { useAnnouncementSeenMutation } from "../../../reduxState/apis/announcementApi";
import { getComparator, stableSort } from "../../../utils/sort";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "src/services/utils";

const useStyles = (theme: Theme) => ({
    notificationBox: css({ width: "420px" }),
    bellIcon: css({ height: "24px", width: "24px", borderRadius: "50%" }),
    heading: css({ padding: "12px 15px", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }),
    notificationItem: css({
        display: "inherit",
        whiteSpace: "inherit",
        paddingBottom: "10px",
        "& .title": {
            textDecoration: "underline",
            margin: "5px 0",
        },
        "& .description": { opacity: 0.5, fontSize: "14px" },
        "& .link": { fontSize: "14px", color: theme.palette.primary.main },
    }),
});

const dummyNotification = (announcement: IAnnouncement, classes: any, isNew: boolean) => {
    const handleLinkCLick = () => {
        if (announcement.link) {
            window.open(announcement.link, "_blank");
        }
    };
    return (
        <MenuItem
            className={`${classes.notificationItem} ${classes.menuItemClass}`}
            data-cy="manageAccount"
            sx={
                isNew
                    ? { backgroundColor: `#EEE!important` }
                    : {
                          "&.MuiMenuItem-root:hover": {
                              cursor: announcement.link ? "pointer" : "text",
                          },
                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }
            }
            onClick={handleLinkCLick}
            disableRipple
        >
            <Typography className="title" color="text.primary" variant="body1">
                {format(toDate(announcement.releaseDate), "MMM d, yyyy")}: {announcement.title}
            </Typography>
            <Typography className="description">{announcement.description}</Typography>
            {announcement.link && (
                <Stack mt={1} direction="row" alignItems="center">
                    <Typography className="link">Click here to learn more</Typography>
                    <SVGIconRenderer strokeColor="primary" icon="chevronRightIcon" height="14px" width="14px" />
                </Stack>
            )}
        </MenuItem>
    );
};

export default function NotificationsList({ classes }: { classes: any }) {
    const extraClasses = { ...classes, ...useStyles(useTheme()) };
    const dispatch = useDispatch();
    const analyticsService = useAnalyticsService();
    const announcements = useSelector(announcementsSelector);
    const announcementIds = useSelector(announcementCountSelector);
    const announcementCount = announcementIds.length;

    const [announcementSeen] = useAnnouncementSeenMutation();

    const onClickHandleOpen = () => {
        analyticsService.logEvent("Notifications Window Viewed", {
            Page: getPathForAnalytics(location.pathname, location.search),
            "Notification count": announcementCount,
        });
        if (announcementCount > 0) {
            announcementSeen({});
        }
    };

    const menuCloseHandle = () => {
        if (announcementCount > 0) {
            dispatch(setUserLastSeenAnnouncement({}));
        }
    };

    return (
        <LightMenu
            buttonTitle="Notifications"
            title={
                <Badge
                    sx={{ "& .MuiBadge-badge": { fontWeight: "bold" } }}
                    badgeContent={announcementCount}
                    color="primary"
                    max={9}
                >
                    <Typography className={extraClasses.bellIcon} component="div" color="inherit">
                        <SVGIconRenderer icon="bellIcon" />
                    </Typography>
                </Badge>
            }
            isList={false}
            isIconButton
            placement="bottom-end"
            transformOrigin="top right"
            dataCy="notificationBtn"
            menuCloseHandle={menuCloseHandle}
            onClickHandleOpen={onClickHandleOpen}
        >
            <Box p={0} py={1} className={extraClasses.notificationBox}>
                <Typography variant="h3" className={`${extraClasses.displayRow} ${extraClasses.heading}`}>
                    Notifications
                </Typography>
                {announcements.length ? (
                    stableSort(announcements, getComparator("desc", "releaseDate")).map((a: any) => {
                        const isNew = announcementIds.includes(a.id);
                        return <Fragment key={a.id}>{dummyNotification(a, extraClasses, isNew)}</Fragment>;
                    })
                ) : (
                    <Typography
                        className="title"
                        color="text.primary"
                        variant="body1"
                        padding="10px"
                        textAlign="center"
                    >
                        No notifications yet
                    </Typography>
                )}
            </Box>
        </LightMenu>
    );
}
