import { FC, memo, useState } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { SubHeader } from "src/components/SubHeader/SubHeader.v2";
import { VISIBILITY_CHIPS_VALUE } from "src/services/optimizationPage/optimization";
import { IMember } from "src/interfaces/entities/IMember";

interface IOptimizationSubHeader {
    totalCount: number;
    handleFilterChange: (filter: number) => void;
    members: IMember[];
    handleCreatorChange: (filter: IMember[]) => void;
}

export const OptimizationSubHeader: FC<IOptimizationSubHeader> = memo(
    ({ totalCount, handleFilterChange, members, handleCreatorChange }) => {
        const [selectedCreators, setSelectedCreators] = useState<IMember[]>([]);

        const applyFilter = () => {
            handleCreatorChange(selectedCreators);
        };

        const handleChange = (event: React.SyntheticEvent, value: IMember[], action: any) => {
            setSelectedCreators(value);
            if (action !== "selectOption") {
                handleCreatorChange(value);
            }
        };

        return (
            <Stack
                direction="row"
                gap={2}
                alignItems="center"
                sx={{
                    ".filter div div": {
                        flexWrap: "nowrap",
                    },
                }}
            >
                <SubHeader
                    chosenItemsLength={0}
                    itemsLength={totalCount}
                    chipsValues={VISIBILITY_CHIPS_VALUE}
                    title="Optimization(s)"
                    onSearchChange={() => {}}
                    onChipsChangeSingle={(item) => {
                        handleFilterChange(item);
                    }}
                    isSearch={false}
                    multiple={false}
                    isEventPerform={false}
                    marginBottom={false}
                    showCount={false}
                    className="filter"
                />
                <Autocomplete
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    size="small"
                    sx={{ minWidth: "250px", maxWidth: "100%" }}
                    multiple
                    options={members}
                    value={selectedCreators}
                    limitTags={8}
                    onChange={handleChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={
                                selectedCreators.length > 0
                                    ? `Filtering by ${selectedCreators.length} creator(s)`
                                    : "Created By"
                            }
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option: IMember, index: number) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                                <Chip
                                    size="small"
                                    variant="outlined"
                                    label={`${option.firstName} ${option.lastName}`}
                                    key={key}
                                    {...tagProps}
                                />
                            );
                        })
                    }
                    disableCloseOnSelect
                    onClose={applyFilter} // Apply the filter when the dropdown closes
                    disablePortal
                    filterSelectedOptions
                />
            </Stack>
        );
    },
);
