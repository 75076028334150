export type Choice = "asc" | "desc";

export interface IConnectorsCells {
    connectorName: string;
    schema: string;
    categories: string;
    createdAt: Date;
    status: string;
}

interface IHeadCell {
    id: keyof IConnectorsCells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    align?: "right" | "left" | "center" | "inherit" | "justify" | undefined;
    width?: string;
}

export const CONNECTORS_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "connectorName",
        numeric: false,
        disablePadding: false,
        label: "Connector",
        align: "left",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        align: "center",
    },
    // {
    //     id: "schema",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Connector Name",
    // },
    {
        id: "categories",
        numeric: false,
        disablePadding: false,
        label: "Type",
        align: "left",
    },
    {
        id: "createdAt",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        align: "right",
    },
];

export const CONNECTORS_CHIPS_VALUE = [
    {
        key: 0,
        label: "All",
        isAll: true,
    },
    {
        key: 1,
        label: "Ecommerce",
        isAll: false,
    },
    {
        key: 2,
        label: "Advertising",
        isAll: false,
    },
    {
        key: 3,
        label: "Outreach",
        isAll: false,
    },
    {
        key: 4,
        label: "Analytics",
        isAll: false,
    },
];

export const CONNECTORS_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "connectorName",
        numeric: false,
        disablePadding: false,
        label: "Connector",
        align: "left",
        width: "240px",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        align: "center",
        width: "248px",
    },
    {
        id: "categories",
        numeric: false,
        disablePadding: false,
        label: "Type",
        align: "center",
        width: "248px",
    },
    {
        id: "createdAt",
        numeric: false,
        disablePadding: false,
        label: "Connection Date",
        align: "center",
        width: "248px",
    },
];

export const CONNECTOR_CREATION_STEPS = [
    { label: "Add Data Channel", description: "Select all that apply", id: "dataChannel" },
    { label: "Connect E-commerce", description: "Create source of truth", id: "ecommerce" },
    { label: "Connect Advertising", description: "Unlock modeled results", id: "advertising" },
    { label: "Connect Analytics", description: "Increase Accuracy", id: "analytics" },
];

export const CONNECTOR_COMPLETION_STEPS = [
    { label: "E-commerce", description: "Link Shopify, Amazon, etc., for modeling", id: "ecommerce" },
    { label: "Advertising", description: "Connect all ad platforms for full view", id: "advertising" },
    { label: "Analytics", description: "Your Analytics engine adds context beyond ads", id: "analytics" },
];

export const LEGACY_CUSTOM_CONNECTORS = ["grin", "kargo_ctv", "kargo_display"];
export const CUSTOM_CONNECTORS = LEGACY_CUSTOM_CONNECTORS.concat([
    "applovin",
    "mountain",
    "neonpixel",
    "partnerize_affiliate",
    "tatari_linear",
    "tatari_streaming",
]);
