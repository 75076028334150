export const AVATAR_TEXT_COLOR = "#0B9786";

// new theme colors
export const WHITE_COLOR = "#FFFFFF";
export const BLACK_COLOR = "#000000";

export const PRIMARY_MAIN_COLOR = "#262626";
export const PRIMARY_LIGHT_COLOR = "#676767";
export const PRIMARY_DARK_COLOR = BLACK_COLOR;
export const PRIMARY_FOCUS_VISIBLE = "#2626264D";

export const SECONDARY_MAIN_COLOR = "#1FD25A";
export const SECONDARY_LIGHT_COLOR = "#52E683";
export const SECONDARY_DARK_COLOR = "#18A647";
export const SECONDARY_CONTRAST_TEXT_COLOR = PRIMARY_DARK_COLOR;

export const TERTAIRY_MAIN_COLOR = "#E7FF59";
export const TERTAIRY_LIGHT_COLOR = "#F3FFAC";
export const TERTAIRY_DARK_COLOR = "#DEFF1C";
export const TERTAIRY_CONTRAST_TEXT_COLOR = BLACK_COLOR;

export const ERROR_MAIN_COLOR = "#FF140F";
export const ERROR_LIGHT_COLOR = "#FF504C";
export const ERROR_DARK_COLOR = "#D10500";

export const WARNING_MAIN_COLOR = "#FF8E25";
export const WARNING_LIGHT_COLOR = "#FFB066";
export const WARNING_DARK_COLOR = "#FF5F0D";

export const SUCCESS_MAIN_COLOR = "#18A647";
export const SUCCESS_LIGHT_COLOR = "#1FD25A";
export const SUCCESS_DARK_COLOR = "#107030";
export const SUCCESS_CONTRAST_TEXT_COLOR = WHITE_COLOR;

export const INFO_MAIN_COLOR = "#0DC4E0";
export const INFO_DARK_COLOR = "#0093AE";

export const HOVER_ITEM_COLOR = "rgba(31, 210, 90, 0.04)";
export const SELECTED_ITEM_COLOR = "rgba(32, 219, 94, 0.08)";

export const CHART_COLORS: { [key: string]: string } = {
    total: "#0194FF",
    ["shopify-transactional"]: "#008060",
    ["shopify-wholesale"]: "#1877F2",
    ["shopify-subscription"]: "#ED750A",
    ["halo - amazon selling partner"]: "#FF6348",
    ["base - shopify"]: "#4164E0",
    ["halo - shopify"]: "#33CD32",
};
