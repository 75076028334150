import React from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { cursor } from "../../assets/styles/commonStyle";

interface IMessageProps {
    icon?: any;
    title: string;
    subtitle: string | JSX.Element;
    filters?: string[];

    onIconClick?(): void;
}

const Headline6 = styled.h2`
    font-style: normal;
    font-weight: normal;
    text-align: center;
    margin: 0;
`;

const Body1 = styled.h2`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    margin: 8px 0;
`;

export const Message: React.FC<IMessageProps> = ({ icon, title, subtitle, onIconClick }) => {
    const handleIconClick = () => {
        if (onIconClick) {
            onIconClick();
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box
                    display="flex"
                    flexDirection="row-reverse"
                    onClick={handleIconClick}
                    className={`${cursor("pointer")}`}
                >
                    {icon && <img src={icon} alt="wallet" />}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Headline6>{title}</Headline6>
                    <Body1>{subtitle}</Body1>
                </Box>
            </Box>
        </>
    );
};
