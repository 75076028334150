import { FC, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { CustomModal } from "src/components/CustomModal/CustomModal";
import { BLACK_COLOR } from "src/consts/colors";
import { IConnector } from "src/interfaces/IConnector";

interface IDeleteConnectorModal {
    selectedConnector: IConnector;
    deleteConnector: (connector: IConnector) => void;
    closeModal: () => void;
    disableDeleteBtn: boolean;
}

export const DeleteConnectorModal: FC<IDeleteConnectorModal> = ({
    selectedConnector,
    deleteConnector,
    closeModal,
    disableDeleteBtn,
}) => {
    const [confirmationText, setConfirmationText] = useState("");
    const { connectorName } = selectedConnector;
    const expectedPhrase = `I confirm I want to remove the ${connectorName} connector`;
    const isDeleteDisabled = confirmationText !== expectedPhrase;

    return (
        <CustomModal
            title="Delete Connector?"
            subTitle={`Deleting your ${connectorName} connector will:`}
            details={
                <>
                    <Typography component="li" variant="body2">
                        - Remove it from your dashboards
                    </Typography>
                    <Typography component="li" variant="body2">
                        - Prevent its data from being used in models
                    </Typography>
                    <Typography component="li" variant="body2">
                        - Require you to reconnect and re-sync if you want to access its data in Prescient again
                    </Typography>
                    <Stack gap={2} mt={2}>
                        <Stack gap={1}>
                            <Typography
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    display: "flex",
                                    color: `${BLACK_COLOR}`,
                                }}
                            >
                                Type the following phrase to enable deleting:
                            </Typography>
                            <Typography variant="subtitle2" color="text.secondary">
                                {expectedPhrase}
                            </Typography>
                        </Stack>
                        <TextField
                            variant="outlined"
                            placeholder="Enter the phrase here"
                            value={confirmationText}
                            onChange={(e) => setConfirmationText(e.target.value)}
                            fullWidth
                            size="small"
                            data-cy="connector-confirmation-input"
                            onPaste={(e) => e.preventDefault()}
                        />
                    </Stack>
                </>
            }
            buttonName="Delete"
            handleAction={deleteConnector}
            selectedConnector={selectedConnector}
            closeModal={closeModal}
            disableBtn={disableDeleteBtn}
            constraintMatch={isDeleteDisabled}
            type="error"
        />
    );
};
