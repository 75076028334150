import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";

import { CustomTableStructure } from "src/components/core/CustomTableStructure/CustomTableStructure";
import { useStylesForHeaderInTable, useStylesForRowInTable, useStylesTable } from "src/consts/StylesForTables";
import { Choice, DATA_PROVIDER_PAGE_TABLE_HEADERS, ICells } from "src/consts/dataProviderPage/dataProviderPage";
import { IDataProvider } from "src/interfaces/IDataProvider";
import { paginationCountSelector } from "src/reduxState/slices/settingsSlice";
import { capitalizeFirstLetter, recalculateSortDirection } from "src/services/utils";
import { getComparator, stableSort } from "src/utils/sort";

export const DataProviders = ({ data }: { data: IDataProvider[] }) => {
    const headerClasses = useStylesForHeaderInTable();
    const classes = useStylesTable();
    const rowClasses = useStylesForRowInTable();
    const rowsPerPage = useSelector(paginationCountSelector);

    const [order, setOrder] = useState<Choice>("asc");
    const [orderBy, setOrderBy] = useState("id");
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (_: unknown, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => setPage(0);
    const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof ICells) => {
        setOrder(recalculateSortDirection(orderBy, order, property));
        setOrderBy(property);
        setPage(0);
    };

    return (
        <CustomTableStructure
            paginationProps={{
                count: data.length,
                page,
                handleChangePage,
                handleChangeRowsPerPage,
            }}
        >
            <TableHead className={headerClasses.tableHeader}>
                <TableRow className={headerClasses.headerRow}>
                    {DATA_PROVIDER_PAGE_TABLE_HEADERS.map((column) => (
                        <TableCell
                            className={headerClasses.tableCell}
                            key={column.id}
                            sortDirection={orderBy === column.id ? order : false}
                            align={column.align || "left"}
                        >
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : "desc"}
                                onClick={(e) => handleRequestSort(e, column.id)}
                            >
                                {column.label}
                                {orderBy === column.id ? (
                                    <Box component="span" style={{ display: "none" }}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((dataProvider: any) => (
                        <TableRow key={dataProvider.id} className={rowClasses.bodyRow}>
                            <TableCell>{dataProvider.id}</TableCell>
                            <TableCell>{capitalizeFirstLetter(dataProvider.name)}</TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </CustomTableStructure>
    );
};
