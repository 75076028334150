// target
export const SALESFORCE_COMMERCE_CLOUD_REVENUE = "salesforce_commerce_cloud_revenue";
export const SHOPIFY_REVENUE = "shopify_revenue";
export const GOOGLE_ANALYTICS_4_REVENUE = "google_analytics_4_revenue";
export const AMAZON_SELLING_PARTNER_REVENUE = "amazon_selling_partner_revenue";
export const BIGQUERY_ECOMMERCE_REVENUE = "bigquery_ecommerce_revenue";

// targetChannelName
export const DIRECT_SPEND = "direct_spend";
export const ORGANIC = "organic";
export const DIRECT = "direct";
export const AMAZON_SPEND = "amazon_spend";

// priority wise target revenue
export const DEFAULT_TARGET_REVENUE = BIGQUERY_ECOMMERCE_REVENUE;
export const SECONDARY_TARGET_REVENUE = SALESFORCE_COMMERCE_CLOUD_REVENUE;
export const TERTIARY_TARGET_REVENUE = SHOPIFY_REVENUE;
export const QUATERNARY_TARGET_REVENUE = GOOGLE_ANALYTICS_4_REVENUE;
