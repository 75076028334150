import { css } from "@emotion/css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { TabPanel } from "@mui/lab";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { format } from "date-fns";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";

import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NEGATIVE_TREND_GOOD } from "src/consts/performancePaidPage/performancePaidPage";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { SVGIconRenderer } from "../../SVGIconRenderer/SVGIconRenderer";

import { capitalize } from "lodash";
import { PRIMARY_MAIN_COLOR } from "src/consts/colors";
import {
    MetricNameTypeForPayload,
    defaultDateRangeForHomePage,
    getMetricTooltipText,
    metricParamsForTrendCard,
} from "src/consts/homeDashboard/homeDashboard";
import { PERFORMANCE_CHANNELS_PATH } from "src/consts/path/path";
import { FORMATS } from "src/enums/Formats";
import { UserRole } from "src/enums/UserRole";
import { IDateSelectionRange, IHeadCell, ITrendTableRows } from "src/interfaces/dashboard/trend";
import { useLazyGetPerformanceTrendQuery } from "src/reduxState/apis/historicalApi";
import { defaultRevenueSourceSelector, isAmazonConnectedSelector } from "src/reduxState/slices/connectorsSlice";
import { dataQaReady } from "src/reduxState/slices/organizationSlice";
import {
    dataSourcesByProgrammaticNameSelector,
    supportedDataSourcesSelector,
} from "src/reduxState/slices/supportedDataSourcesSlice";
import { currentUserRoleByCompanyIdSelector, isSuperAdminSelector } from "src/reduxState/slices/userSlice";
import { getDynamicTrendTableHeader } from "src/services/homeDashboard/homePageTab";
import {
    calculateAllMetrics,
    defaultMetricSortBy,
    findMetric,
    getTrendTableRows,
} from "src/services/homeDashboard/trendCardUtils";
import { isTrendPostive } from "src/services/performancePage/performancePage";
import { customSortData, formatValue } from "src/services/utils";
import { SparklineChart } from "../../Charts/SparkLine/SparkLine";
import { CustomTabContext } from "../../CustomTabContext/CustomTabContext";
import SimpleReactDateRange from "../../DateRangeFilter/SimpleReactDateRange";
import { DefaultDateRangeInText, getRangeFormattedTitle } from "../../DateRangeFilter/reactDateRangeUtils";
import { DashboardCardHeader } from "../CardComponents/CardHeader/CardHeader";
import { CardLoader } from "../CardComponents/CardLoader";
import { HomepageCardContainer } from "../CardComponents/HomepageCardContainer";
import { VerifyDataQa } from "../CardComponents/VerifyDataQa";
import logoAttribution from "src/assets/Prescient-Logo-Bug.png";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import {
    AMAZON_SELLING_PARTNER,
    BIGQUERY_ECOMMERCE,
    GOOGLE_ANALYTICS_4,
    SALESFORCE_COMMERCE_CLOUD,
    SHOPIFY,
} from "src/consts/connectors";
import { ExportTableData } from "src/components/ExportTableData/ExportTableData";
import { exportCSV, prepareDataForCSV } from "src/services/exportCSV";
import { HomePagePartialCard } from "../CardComponents/HomePagePartialCard";
import { partialStateSelectorForHome } from "src/reduxState/slices/settingsSlice";

const useStyle = () => ({
    tableHeadCell: css({
        fontWeight: "700",
        fontSize: "15px",
        whiteSpace: "nowrap",
        background: "white",
        top: "2px",
    }),
});

interface ITrendCard {
    tooltipRef: React.RefObject<HTMLDivElement>;
}

export const TrendCard: FC<ITrendCard> = ({ tooltipRef }) => {
    const theme = useTheme();
    const classes = useStyle();
    const { enqueueSnackbar } = useSnackbar();
    const analyticsService = useAnalyticsService();
    const chartTitle = useMemo(() => "Trends", []);

    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const dataSources = useSelector(dataSourcesByProgrammaticNameSelector);
    const defaultRevenueSource = useSelector(defaultRevenueSourceSelector);
    const isAmazonConnected = useSelector(isAmazonConnectedSelector);
    const partialStateForHome = useSelector(partialStateSelectorForHome);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const supportedDataSources = useSelector(supportedDataSourcesSelector);

    const [dateRange, setDateRange] = useState<IDateSelectionRange>(defaultDateRangeForHomePage);
    const [compareMode, setCompareMode] = useState(true);
    const [selectedPreset, setSelectedPreset] = useState<DefaultDateRangeInText>(DefaultDateRangeInText.LAST_30_DAYS);
    const [tabs, setTabs] = useState([{ label: "Total", value: "total" }]);
    const [tabValue, setTabValue] = useState<string>("total");
    const [trendTableRows, setTrendTableRows] = useState<ITrendTableRows[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { startDate, endDate } = dateRange.selection;
    const { startDate: pastStartDate, endDate: pastEndDate } = dateRange.compare;

    const updatedTableHeader = getDynamicTrendTableHeader(dateRange, selectedPreset);

    const [getCurrentPerformanceTrend, { data: currentData }] = useLazyGetPerformanceTrendQuery();
    const [getPastPerformanceTrend, { data: pastData }] = useLazyGetPerformanceTrendQuery();

    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);

    const isAdmin = userRole === UserRole.ADMIN || isSuperAdmin;

    const isDataQaReady = useSelector(dataQaReady);

    useEffect(() => {
        setDateRange(defaultDateRangeForHomePage);
        setTabValue("total");
        setTabs([{ label: "Total", value: "total" }]);
    }, [currentOrgId]);

    useEffect(() => {
        if (!supportedDataSources.length) {
            return;
        }
        const fetchTrendData = async () => {
            setIsLoading(true);
            const allConnectors = supportedDataSources.map((s) => s.programmaticName);
            const specialMetric = [
                AMAZON_SELLING_PARTNER,
                GOOGLE_ANALYTICS_4,
                SHOPIFY,
                SALESFORCE_COMMERCE_CLOUD,
                BIGQUERY_ECOMMERCE,
            ];

            const dataObject = {
                start_date: format(startDate, "yyyy-MM-dd"),
                end_date: format(endDate, "yyyy-MM-dd"),
                metric_names: specialMetric.includes(tabValue)
                    ? metricParamsForTrendCard.filter((t) => t !== MetricNameTypeForPayload.ChannelReportedRoas)
                    : metricParamsForTrendCard,
                connectors: tabValue === "total" ? allConnectors : [tabValue],
                target: "",
            };

            try {
                await Promise.all([
                    getCurrentPerformanceTrend({ orgId: currentOrgId, data: dataObject }),
                    getPastPerformanceTrend({
                        orgId: currentOrgId,
                        data: {
                            ...dataObject,
                            start_date: format(pastStartDate, "yyyy-MM-dd"),
                            end_date: format(pastEndDate, "yyyy-MM-dd"),
                        },
                    }),
                ]);
            } catch (error) {
                enqueueSnackbar("Something went wrong with trends!", { id: uniqueId(), variant: "error" });
            } finally {
                setIsLoading(false);
            }
        };

        fetchTrendData();
    }, [dateRange, tabValue, currentOrgId, supportedDataSources]);

    useEffect(() => {
        if (!currentData || !pastData) {
            return;
        }
        const { metrics = {}, connectorList = [], metricSparkValues = {} } = currentData;

        // Create channelTabs
        const channelTabs = connectorList.map((conn: string) => ({
            label: dataSources[conn]?.name || capitalize(conn),
            value: conn,
        }));
        setTabs((tabs) => uniqBy([...tabs, ...orderBy(uniqBy(channelTabs, "value"), ["label"])], "value"));

        const currentRowData = calculateAllMetrics(metrics);
        const pastRowData = calculateAllMetrics(pastData.metrics);
        const rows = getTrendTableRows(
            currentData.metrics,
            pastData.metrics,
            currentRowData,
            pastRowData,
            metricSparkValues,
        );

        const removeAllZeroData = rows.filter((row) => {
            const checkObj = { current: row.current, prior: row.prior, change: row.change, difference: row.difference };
            const isAllZero = Object.values(checkObj).every((c) => c === 0);
            return !isAllZero;
        });
        setTrendTableRows(customSortData(removeAllZeroData, "metric", defaultMetricSortBy));
    }, [currentData, pastData]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        const getLabel = tabs.find((op) => op.value === newValue)?.label || "";
        analyticsService.logEvent("Dashboard Chart Tab Clicked", {
            Chart: chartTitle,
            "Chart tab": getLabel,
        });
        setTabValue(newValue);
    };

    const dateRangeChangeHandler = (dateRange: any, selectedPreset: DefaultDateRangeInText) => {
        setSelectedPreset(selectedPreset);
        setDateRange(dateRange);
    };

    const generateCellContent = (row: ITrendTableRows, header: IHeadCell, changeColor: string) => {
        const { id } = header;
        const metricInfo = findMetric(row.metric);
        const isChangeCol = id === "change";
        const isNegativePerChange = +row.change < 0;
        const isPreviousHaveValue = !!row.prior;

        if (id === "metric") {
            return metricInfo?.label;
        } else if (id === "sparkLine") {
            return <SparklineChart data={row[id]} color={isPreviousHaveValue ? changeColor : "#000"} />;
        } else {
            return (
                <>
                    {isChangeCol && isPreviousHaveValue && (
                        <SVGIconRenderer
                            icon={isNegativePerChange ? "redDownArrowIcon" : "greenUpArrowIcon"}
                            strokeColor={changeColor}
                        />
                    )}
                    {formatValue(
                        row[id],
                        isChangeCol ? FORMATS.PERCENT : metricInfo?.sign,
                        isChangeCol ? (isPreviousHaveValue ? 2 : 0) : metricInfo?.fixed,
                    )}
                </>
            );
        }
    };

    let modeledMetricRows: ITrendTableRows[] = [];
    let nonModeledMetricRows: ITrendTableRows[] = [];

    trendTableRows.forEach((r) => {
        if (r.metric.includes("modeled")) {
            modeledMetricRows = [...modeledMetricRows, r];
        } else {
            nonModeledMetricRows = [...nonModeledMetricRows, r];
        }
    });

    const shouldShowAlternateBorder = !partialStateForHome && modeledMetricRows.length === 0;

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setOpenExportMenu(false);
    };

    const anchorRef = useRef<HTMLDivElement>(null);

    const exportVisibleColumns = () => {
        const allMetricRows: Array<{ metric: string; isPercentage?: boolean; isDollars?: boolean }> =
            modeledMetricRows.concat(nonModeledMetricRows);
        for (const row of allMetricRows) {
            const foundMetric = findMetric(row.metric);
            if (foundMetric !== undefined) {
                row.metric = foundMetric.label;
                row.isPercentage = foundMetric.sign === FORMATS.PERCENT;
                row.isDollars = foundMetric.sign === FORMATS.DOLLAR;
            }
        }
        const columns = updatedTableHeader.map((key) => {
            return {
                label: getExportLabel(key) || "",
                numeric: true,
                disablePadding: false,
                hidden: key.id === "sparkLine",
                width: "150px",
                id: key.id,
                overridePercentage: key.id === "change",
            };
        });

        exportCSV(
            "Trends " +
                getRangeFormattedTitle([dateRange.selection]) +
                " vs. " +
                getRangeFormattedTitle([dateRange.compare]),
            prepareDataForCSV(allMetricRows, columns),
        );
    };

    const getExportLabel = (key: IHeadCell) => {
        const currTabLabel = tabs.find((tab) => tab.value === tabValue)?.label || "";
        const currentLabel = getRangeFormattedTitle([dateRange.selection]);
        const priorLabel = getRangeFormattedTitle([dateRange.compare]);

        switch (key.id) {
            case "current":
                return tabValue !== "total" ? `"{${capitalize(currTabLabel)}} ${currentLabel}"` : `"${currentLabel}"`;
            case "prior":
                return tabValue !== "total" ? `"{${capitalize(currTabLabel)}} ${priorLabel}"` : `"${priorLabel}"`;
            case "difference":
                return "Absolute change";
            case "change":
                return "% change";
            default:
                return capitalize(key.label.toString());
        }
    };

    const [openExportMenu, setOpenExportMenu] = useState(false);

    const handleExportToggle = () => {
        setOpenExportMenu((prevOpen) => !prevOpen);
    };

    const handleExportMenuClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenExportMenu(false);
    };

    const renderTableCells = (rows: ITrendTableRows[], isModeledMetric?: boolean) =>
        rows.map((row) => {
            const tooltipText = getMetricTooltipText(dataSources[defaultRevenueSource]?.name, isAmazonConnected)[
                row.metric
            ];
            return (
                <TableRow key={row.metric}>
                    {updatedTableHeader.map((h) => {
                        const isChangeCol = h.id === "change";
                        const isSparkLineCol = h.id === "sparkLine";
                        const showNegativeTrend = !isTrendPostive(
                            +row.change,
                            NEGATIVE_TREND_GOOD.indexOf(row.id.toString().toUpperCase()) === -1,
                        );
                        const changeColor = (isChangeCol || isSparkLineCol) && showNegativeTrend ? "red" : "green";
                        const style = isModeledMetric
                            ? {
                                  borderColor: theme.palette.primary.main,
                                  borderWidth: "thin",
                                  borderLeft: 0,
                                  borderRight: 0,
                                  whiteSpace: "nowrap",
                              }
                            : {};
                        return (
                            <TableCell
                                key={h.id}
                                sx={{
                                    color: isChangeCol && !!row.prior ? changeColor : "inherit",
                                    height: "65px",
                                    minHeight: "52px",
                                    ...style,
                                }}
                            >
                                <Stack direction="row" alignItems="center" component="div" justifyContent={h.align}>
                                    {h.id === "metric" && tooltipText ? (
                                        <Tooltip arrow title={tooltipText} placement="right">
                                            <span>{generateCellContent(row, h, changeColor)}</span>
                                        </Tooltip>
                                    ) : (
                                        generateCellContent(row, h, changeColor)
                                    )}
                                </Stack>
                            </TableCell>
                        );
                    })}
                </TableRow>
            );
        });

    const renderNoData = () => (
        <TableRow>
            <TableCell
                colSpan={updatedTableHeader.length}
                align="center"
                sx={{ color: "rgba(0, 0, 0, 0.6)!important" }}
            >
                No data found
            </TableCell>
        </TableRow>
    );

    const renderPartialStateCard = () => (
        <TableRow>
            <TableCell
                sx={(theme) => ({
                    padding: "0 !important",
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                })}
                colSpan={6}
            >
                {isDataQaReady && isAdmin ? (
                    <VerifyDataQa
                        tabName="Key Metric Trends"
                        style={{
                            padding: "15px 150px",
                            height: `${4 * 65}px`,
                        }}
                    />
                ) : (
                    <HomePagePartialCard
                        icon={
                            <TrendingUpOutlinedIcon
                                color="primary"
                                sx={(theme) => ({
                                    width: "56px",
                                    height: "56px",
                                    opacity: "100%",
                                    color: theme.palette.secondary.main,
                                })}
                            />
                        }
                        heading="Compiling Key Metric Trends"
                        description={
                            <>
                                Results typically begins to flow 1 week after data verification is complete. Please
                                allow more time for Amazon. <br /> Check back later!
                            </>
                        }
                        actionButton={
                            <Button
                                variant="contained"
                                color="inherit"
                                size="small"
                                endIcon={<ChevronRightIcon />}
                                href="https://help.prescient-ai.io/en/articles/9293715-new-feature-tour-homepage#h_5a450f5747"
                                target="_blank"
                            >
                                Learn More About Trends
                            </Button>
                        }
                        style={{
                            padding: "15px 150px",
                            height: `${4 * 65}px`,
                        }}
                    />
                )}
            </TableCell>
        </TableRow>
    );

    return (
        <HomepageCardContainer>
            <Stack gap={3}>
                <DashboardCardHeader
                    title={chartTitle}
                    tooltipRef={tooltipRef}
                    tooltip="Comparison reports split by channel"
                    instances={[
                        <SimpleReactDateRange
                            compareView={true}
                            startRange={dateRange}
                            applyRangeHandler={(selectedRange, selectedPreset) =>
                                dateRangeChangeHandler(selectedRange, selectedPreset)
                            }
                            compareMode={compareMode}
                            setCompareMode={setCompareMode}
                            chartTitle={chartTitle}
                            isAlwaysOnCompareMode={true}
                            isUpgraded={true}
                            isTitleInOneLine={true}
                        />,
                        <ExportTableData
                            handleMenuItemClick={handleMenuItemClick}
                            anchorRef={anchorRef}
                            exportVisibleColumns={exportVisibleColumns}
                            openExportMenu={openExportMenu}
                            handleExportToggle={handleExportToggle}
                            handleExportMenuClose={handleExportMenuClose}
                            noPopup
                        />,
                    ]}
                />
                <Stack>
                    <CustomTabContext tabValue={tabValue} handleChange={handleChange} tabs={tabs} scrollButtons={true}>
                        {isLoading ? (
                            <CardLoader height="350px" />
                        ) : (
                            <TabPanel sx={{ padding: "0" }} value={tabValue}>
                                <TableContainer sx={{ paddingLeft: "30px" }}>
                                    <Table sx={{ td: { fontSize: "15px", padding: "8px 16px" } }}>
                                        <TableHead>
                                            <TableRow>
                                                {updatedTableHeader.map((campaignHeader) => {
                                                    return (
                                                        <TableCell
                                                            key={campaignHeader.id}
                                                            align={campaignHeader.align}
                                                            className={classes.tableHeadCell}
                                                            sx={(theme) => ({
                                                                borderBottom: `1px solid ${theme.palette.primary.main}`,
                                                            })}
                                                        >
                                                            {campaignHeader.label}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody sx={{ position: "relative" }}>
                                            <TableRow>
                                                <TableCell
                                                    sx={(theme) => ({
                                                        height: partialStateForHome
                                                            ? "263.5px"
                                                            : `${modeledMetricRows.length * 65.5 + 1.5}px`,
                                                        position: "absolute",
                                                        top: "-1px",
                                                        left: "-25px",
                                                        fontSize: "15px",
                                                        background: theme.palette.text.primary,
                                                        width: `25px`,
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        display: shouldShowAlternateBorder ? "none" : "flex",
                                                    })}
                                                >
                                                    <img
                                                        src={logoAttribution}
                                                        style={{
                                                            width: "25px",
                                                            height: "28px",
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            {trendTableRows?.length ? (
                                                <>
                                                    {partialStateForHome || isDataQaReady
                                                        ? renderPartialStateCard()
                                                        : renderTableCells(modeledMetricRows, true)}
                                                    {renderTableCells(nonModeledMetricRows)}
                                                </>
                                            ) : partialStateForHome ? (
                                                renderPartialStateCard()
                                            ) : (
                                                renderNoData()
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        )}
                    </CustomTabContext>
                </Stack>
                {!partialStateForHome && (
                    <Button
                        variant="contained"
                        sx={{ width: "fit-content", marginLeft: "auto" }}
                        href={`/org/${orgCode}${PERFORMANCE_CHANNELS_PATH}`}
                    >
                        See Performance Details
                    </Button>
                )}
            </Stack>
        </HomepageCardContainer>
    );
};
