import * as amplitude from "@amplitude/analytics-browser";
import { BaseEvent } from "@amplitude/analytics-types";
import { IDictionary } from "../../../interfaces/IDictionary";
import { getDateInYYYYMMDDFormat } from "../../utils";
import { IAnalytics, IOrganizationWithRole } from "../IAnalytics";

export class AmplitudeAnalytics implements IAnalytics {
    private static ORG_GROUP_TYPE = "Organization";
    private static ORG_GROUP_TYPE_PROPERTY_NAME = "Organization Name";

    private amplitudeOrgRef: string | null = null;

    constructor(token?: string) {
        token && this.init(token);
    }

    public init(token: string) {
        amplitude.init(token, {
            minIdLength: 1,
            autocapture: false,
        });
    }

    public login(user: any) {
        const currentDate = getDateInYYYYMMDDFormat(new Date());
        const createdAt = getDateInYYYYMMDDFormat(new Date(user.createdAt));

        const identify = new amplitude.Identify();

        identify
            .set("User ID", +user.id)
            .set("Email", user.email)
            .set("Last login date", currentDate)
            .set("Account creation date", createdAt)
            .setOnce("First login date", currentDate);

        amplitude.setUserId(user.id.toString());
        amplitude.identify(identify);
        amplitude.logEvent("Logged In");
    }

    public logout() {
        amplitude.reset();
    }

    public logEvent(eventType: string, eventProperties?: any) {
        const baseEvent: BaseEvent = {
            event_type: eventType,
            event_properties: eventProperties,
        };

        if (this.amplitudeOrgRef) {
            baseEvent.groups = {
                [AmplitudeAnalytics.ORG_GROUP_TYPE]: this.amplitudeOrgRef,
            };
        }

        amplitude.track(baseEvent);
    }

    public setCurrentOrganization(organization: IOrganizationWithRole): void {
        this.setUserProperties({
            "Current organization role": organization.role,
            "Current organization": organization.name,
            "Current organization id": organization.id,
            "Current organization subscription status": organization.subscriptionStatus,
        });
        this.setOrgProperties(organization);
    }

    public setUserProperties(properties: IDictionary<any>): void {
        const identify = new amplitude.Identify();
        Object.keys(properties).forEach((key) => {
            identify.set(key, properties[key]);
        });
        amplitude.identify(identify);
    }

    public setUser(user: any): void {
        amplitude.setUserId(user.id);
    }

    private setOrgProperties(organization: IOrganizationWithRole) {
        const orgIdentify = new amplitude.Identify();
        const orgRef = this.get_org_group_ref(organization);
        orgIdentify.set(AmplitudeAnalytics.ORG_GROUP_TYPE_PROPERTY_NAME, organization.displayName);
        amplitude.groupIdentify(AmplitudeAnalytics.ORG_GROUP_TYPE, orgRef, orgIdentify);
        this.setOrgId(orgRef);
    }

    private get_org_group_ref(organization: IOrganizationWithRole) {
        return organization.id;
    }

    private setOrgId(orgRef: string) {
        this.amplitudeOrgRef = orgRef;
    }
}
