import React from "react";
import { InputAdornment, TextField, Theme, Stack } from "@mui/material";
import { useTheme } from "@mui/system";
import { css } from "@emotion/css";
import { useNavigate, useLocation } from "react-router";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { Range } from "react-date-range";
import SearchIcon from "@mui/icons-material/Search";

import { Choice, IHeadCell, PerformanceTab } from "../../../consts/performancePaidPage/performancePaidPage";
import { IPerformancePercentageTableData } from "../../../interfaces/performanceDetails/IPerformancePercentage";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { selectFilterViewList, setFilterView } from "../../../reduxState/slices/filterViewSlice";
import { selectCurrentlyViewingCode } from "../../../reduxState/slices/organizationSlice";
import { getPathForAnalytics, getSumOfConnectors, handleFilteredData } from "../../../services/utils";
import {
    getFilterChipList,
    getFilterList,
    handleDeleteParamKey,
    removeDateFilter,
} from "../../../services/performancePage/performancePage";
import { PERFORMANCE_PATH } from "../../../consts/path/path";
import { crudPerfomanceView } from "../../../reduxState/actions/performanceView";
import { CampaignFunctionalityTools } from "./CampaignFunctionalityTools";
import { DraggablePerformanceColumnSelect } from "src/components/CampaignComponents/CampaignSubHeader/DraggablePerformanceColumnSelect";
import { PerformanceFilterComponent } from "./FilterComponents/PerformanceFilterComponent";
import { SavedFilterList } from "./FilterComponents/SavedFilterList";
import FilterChips from "../../../components/FilterChips/FilterChips";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { getAllTactics } from "src/reduxState/slices/tacticsSlice";

export const useStylesForCampaignSubHeader = (theme: Theme) => ({
    subHeader: css({
        gap: "20px",
        [theme.breakpoints.down("md")]: {
            padding: "10px !important",
            gap: "15px",
        },
    }),
    searchField: css({
        height: "40px",
        width: "220px",
        background: "white",
        borderRadius: "4px",
        [theme.breakpoints.down("md")]: {
            width: "calc(100vw - 285px)",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    }),
});

interface ICampaignSubHeader {
    originalPerformanceData: any[];
    performanceData: any[];
    selectedTableColumns: IHeadCell[];
    currentDateFilter: Range[];
    order?: Choice;
    orderBy?: string;
    compareToPast: boolean;
    searchCampaign: string;
    setselectedTableColumns: React.Dispatch<React.SetStateAction<IHeadCell[]>>;
    activeTab: PerformanceTab;

    onSearchChange: (e: any) => void;
    handleTableCol: (column: IHeadCell) => void;
    finalPercentagePerformanceData: IPerformancePercentageTableData;
}

export const CampaignSubHeader: React.FC<ICampaignSubHeader> = ({
    originalPerformanceData,
    performanceData,
    selectedTableColumns,
    onSearchChange,
    handleTableCol,
    currentDateFilter,
    compareToPast,
    finalPercentagePerformanceData,
    order,
    orderBy,
    searchCampaign,
    setselectedTableColumns,
    activeTab,
}) => {
    const classes = useStylesForCampaignSubHeader(useTheme());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const analyticsService = useAnalyticsService();
    const filterViewList = useSelector(selectFilterViewList);
    const orgCode = useSelector(selectCurrentlyViewingCode);

    const queryParamsObj = queryString.parse(search);

    const formattedData = getSumOfConnectors(originalPerformanceData || []);
    const connectorCollection = formattedData.map((d) => d.connectorName);
    const tacticsCollection = useSelector(getAllTactics);
    const generalFilterList = getFilterList(
        connectorCollection,
        tacticsCollection,
        queryParamsObj,
        originalPerformanceData,
    );
    const filterList = [PerformanceTab.Channels, PerformanceTab.Tactics].includes(activeTab)
        ? generalFilterList.filter((f) => !["campaign_name", "campaigns"].includes(f.id))
        : generalFilterList;
    const connectors = useSelector(connectorsSelector);
    const filterChipsList = getFilterChipList(filterList, connectors);

    const addFilterEvent = (filter: string) => {
        analyticsService.logEvent("Performance View Filter Applied", {
            Page: getPathForAnalytics(window.location.pathname, window.location.search),
            Filter: filter,
        });
    };

    const removeFilterEvent = (filter: string) => {
        analyticsService.logEvent("Performance View Filter Removed", {
            Page: getPathForAnalytics(window.location.pathname, window.location.search),
            Filter: filter,
        });
    };

    const handleFilter = (paramKey: string, item: { id: string }) => {
        const isFilterDelete = handleFilteredData(paramKey, item, navigate);
        if (isFilterDelete) {
            removeFilterEvent(`&${paramKey}=${item.id}`);
        } else {
            addFilterEvent(`&${paramKey}=${item.id}`);
        }
    };

    const handleSavedViewChange = (name: string, filter: string, id: string) => {
        const params = new URLSearchParams(window.location.search);
        dispatch(setFilterView({ isSavedViewOn: true, data: { name, filter, id } }));
        const filterParams = removeDateFilter(filter);
        const currentUrl = location.pathname.split("/");
        const currentTab = currentUrl[currentUrl.length - 1];

        navigate({
            pathname: `/org/${orgCode}${PERFORMANCE_PATH}/${currentTab}`,
            search: `start_date=${params.get("start_date")}&end_date=${params.get("end_date")}${
                filterParams ? `&${filterParams}` : ""
            }`,
        });
        dispatch(
            crudPerfomanceView({
                eventName: "Performance View Opened",
                page: getPathForAnalytics(window.location.pathname, window.location.search),
                viewName: name,
            }),
        );
    };

    const removeFilter = (key: string) => {
        handleDeleteParamKey(key, navigate, removeFilterEvent);
    };

    return (
        <Stack className={classes.subHeader}>
            <Stack direction="row" flexWrap="wrap" justifyContent="space-between" sx={{ gap: "8px" }}>
                <Stack direction="row" flexWrap="wrap" sx={{ gap: "8px" }}>
                    <TextField
                        className={classes.searchField}
                        size="small"
                        id="campaign-search"
                        label=""
                        variant="outlined"
                        placeholder={`Search ${activeTab.charAt(0).toUpperCase()}${activeTab.slice(1)}`}
                        color="primary"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={searchCampaign}
                        onChange={onSearchChange}
                    />
                    <PerformanceFilterComponent
                        filterList={filterList}
                        handleFilter={handleFilter}
                        selectedTableColumns={selectedTableColumns}
                        filterChipsCount={filterChipsList.length}
                        campaignIdOptions={originalPerformanceData}
                    />
                    <DraggablePerformanceColumnSelect
                        handleTableCol={handleTableCol}
                        selectedTableColumns={selectedTableColumns}
                        setselectedTableColumns={setselectedTableColumns}
                    />
                </Stack>

                <Stack direction="row" flexWrap="wrap" sx={{ gap: "8px" }}>
                    <SavedFilterList
                        filterChipsCount={filterChipsList.length}
                        selectedTableColumns={selectedTableColumns}
                        compareToPast={compareToPast}
                        searchCampaign={searchCampaign}
                        handleSavedViewChange={handleSavedViewChange}
                        filterList={filterViewList}
                    />
                    <CampaignFunctionalityTools
                        selectedTableColumns={selectedTableColumns}
                        tableData={performanceData}
                        order={order}
                        orderBy={orderBy}
                        compareToPast={compareToPast}
                        finalPercentagePerformanceData={finalPercentagePerformanceData}
                        currentDateFilter={currentDateFilter}
                        activeTab={activeTab}
                    />
                </Stack>
            </Stack>
            <FilterChips filterChipsList={filterChipsList} removeFilter={removeFilter} />
        </Stack>
    );
};
