import React from "react";
import { Theme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/system";
import { createGlobalStyle } from "styled-components";

import { IPageProps } from "../interfaces/IPageProps";
import logo from "../assets/logo/Presicent-Logo-White.png";

const logoClass = css`
    height: 60px;
    margin-bottom: 32px;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #009985; 
  }
`; // this is the brand dark green, convert to theme when possible.

const useStyles = (theme: Theme) => ({
    root: css({
        padding: theme.spacing(4),
        paddingTop: theme.spacing(4),
    }),
    paper: css({
        padding: theme.spacing(4),
    }),
});

interface ISimplTextProps extends IPageProps {
    children: React.ReactNode;
}

export const SimpleText: React.FC<ISimplTextProps> = ({ children }) => {
    const classes = useStyles(useTheme());
    return (
        <div className={classes.root}>
            <Link to="/">
                <img alt="logo" src={logo} className={logoClass} />
            </Link>
            <Paper className={classes.paper}>{children}</Paper>
        </div>
    );
};
