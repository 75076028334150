import { Skeleton, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
    isCampaignAvailableSelector,
    isModelingAvailableSelector,
    performanceHeroMetricsSelector,
} from "../../reduxState/slices/settingsSlice";
import { IHeadCell as IEmailHeadCell } from "../../consts/emailPage/emailPage";
import {
    IExtraPercentageRevenue,
    IHeadCell,
    NEGATIVE_TREND_GOOD,
} from "../../consts/performancePaidPage/performancePaidPage";
import { FORMATS } from "../../enums/Formats";
import { formattedDateSelector, isCompareSelector } from "../../reduxState/slices/dateFilterSlice";
import { formatValue } from "../../services/utils";
import { pastRangeBasedOnCurrentRange } from "../DateRangeFilter/reactDateRangeUtils";
import { TRUE_METRICS } from "../../consts/performancePaidPage/performancePaidPage";
import { PercentageTrendChip } from "../core/Chips/PercentageTrendChip";
import { HeroMetricColumnSelector } from "../CampaignComponents/HeroMetricColumnSelector";
import { selectCurrentlyViewingCode } from "src/reduxState/slices/organizationSlice";

type CellType = Array<IHeadCell | IEmailHeadCell | IExtraPercentageRevenue>;

interface IAggregateMetricHeader {
    tableHeader: CellType;
    metricTotalMapObj: {
        [key: string]: {
            title: string;
            info: string;
        };
    };
    isMetricLoading: boolean;
    totalTableAmount: any;
    totalPercentageTableAmount: any;
}

export const AggregateMetricHeader: FC<IAggregateMetricHeader> = ({
    tableHeader,
    metricTotalMapObj,
    isMetricLoading,
    totalTableAmount,
    totalPercentageTableAmount,
}) => {
    const performanceHeroMetrics: Array<{ id: string; isHidden: boolean }> =
        useSelector(performanceHeroMetricsSelector);
    const compareToPast = useSelector(isCompareSelector);
    const selectFormattedDates = useSelector(formattedDateSelector);
    const isCampaignAvailable = useSelector(isCampaignAvailableSelector);
    const isModelingAvailable = useSelector(isModelingAvailableSelector);
    const pastRangeString = pastRangeBasedOnCurrentRange(selectFormattedDates);

    const [selectedHeroMetrics, setSelectedHeroMetrics] = useState<CellType>([]);

    const supportedHeroMetrics = useMemo(() => {
        return tableHeader.filter((c) => !["campaignId", "campaignName", "tacticName"].includes(c.id));
    }, [tableHeader]);

    const performanceHeroMetricsMeta = useMemo(() => {
        if (!performanceHeroMetrics.length) {
            // return supportedHeroMetrics if performanceHeroMetrics is empty
            return supportedHeroMetrics;
        }

        // Step 1: maintain order of performanceHeroMetrics and match with supportedHeroMetrics
        const metrics: CellType = performanceHeroMetrics.reduce<CellType & Array<{ hidden: boolean }>>((acc, h) => {
            const findHeader = supportedHeroMetrics.find((th) => th.id === h.id);
            if (findHeader) {
                acc.push({
                    ...findHeader,
                    hidden: h.isHidden,
                });
            }
            return acc;
        }, []);

        // Step 2: append any supportedHeroMetrics that were not in performanceHeroMetrics
        const missingMetrics = supportedHeroMetrics.filter(
            (supportedHeroMetric) =>
                !metrics.some((performanceHeroMetric) => performanceHeroMetric.id === supportedHeroMetric.id),
        );
        metrics.push(...missingMetrics);

        return metrics;
    }, [performanceHeroMetrics, supportedHeroMetrics]);

    const { hiddenHeroMetrics } = useMemo(() => {
        const hidden: string[] = [];
        performanceHeroMetricsMeta.forEach((h: any) => {
            if (h.hidden) {
                hidden.push(h.id);
            }
        });
        return { hiddenHeroMetrics: hidden };
    }, [performanceHeroMetricsMeta]);

    useEffect(() => {
        setSelectedHeroMetrics(performanceHeroMetricsMeta);
    }, [performanceHeroMetricsMeta]);

    useEffect(() => {
        setSelectedHeroMetrics((prevSelectedHeroMetrics) =>
            prevSelectedHeroMetrics.map((h) => {
                const shouldBeHidden = hiddenHeroMetrics.includes(h.id);
                if (h.hidden !== shouldBeHidden) {
                    return { ...h, hidden: shouldBeHidden };
                }
                return h;
            }),
        );
    }, [hiddenHeroMetrics, performanceHeroMetricsMeta]);

    const getPercentageValue = (value: number, isPositiveTrendGood: boolean) => {
        return typeof value === "number" ? (
            <PercentageTrendChip
                value={value}
                isPositiveTrendGood={isPositiveTrendGood}
                isSpecialChip={true}
                pastRangeString={pastRangeString}
            />
        ) : (
            value
        );
    };
    const getPercentageChip = (element: any) => {
        const formattedPercentageVal = getPercentageValue(
            totalPercentageTableAmount[element.id],
            NEGATIVE_TREND_GOOD.indexOf(element.id.toUpperCase()) === -1,
        );

        return formattedPercentageVal;
    };
    const isMetricAvailable = (column: any, metricData: any) => {
        if (!isCampaignAvailable || (TRUE_METRICS.includes(column.id) && !isModelingAvailable)) {
            return false;
        }
        return true;
    };
    const columnText = (column: any, metricData: any) => {
        if (!isMetricAvailable(column, metricData)) {
            return "N/A";
        }

        return formatValue(totalTableAmount[column.id], column.sign || FORMATS.NUMERIC, column.fixed || 0);
    };

    const handleTableColSelection = (column: IHeadCell) => {
        setSelectedHeroMetrics((prevColumns) =>
            prevColumns.map((c) => (c.id === column.id ? { ...c, hidden: !c.hidden } : c)),
        );
    };

    const visibleHeroMetrics = useMemo(() => {
        return selectedHeroMetrics.filter((column) => !column.hidden);
    }, [selectedHeroMetrics]);

    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="flex-start" gap={3} flexWrap="wrap">
                {visibleHeroMetrics.map((column: any) => {
                    const metricData = metricTotalMapObj[column.id];
                    return (
                        <Box key={column.id} component="span" sx={{ p: 0 }}>
                            <Typography
                                color="text.primary"
                                variant="h3"
                                sx={{ display: "flex", alignItems: "center" }}
                                data-cy={`hero-metric-value-${column.id}`}
                            >
                                {isMetricLoading ? (
                                    <Skeleton variant="rounded" width={100} height={32} />
                                ) : (
                                    <>
                                        {columnText(column, metricData)}
                                        {compareToPast && getPercentageChip(column)}
                                    </>
                                )}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                {column.label}
                                {metricData?.info && (
                                    <Tooltip
                                        data-cy={`hero-metric-tooltip-${column.id}`}
                                        title={<span style={{ fontSize: "14px" }}>{metricData.info}</span>}
                                        arrow
                                    >
                                        <InfoOutlinedIcon sx={{ height: "14px", width: "14px" }} />
                                    </Tooltip>
                                )}
                            </Typography>
                        </Box>
                    );
                })}
            </Stack>
            <HeroMetricColumnSelector
                handleTableCol={handleTableColSelection}
                selectedTableColumns={selectedHeroMetrics as IHeadCell[]}
                setselectedTableColumns={setSelectedHeroMetrics as React.Dispatch<React.SetStateAction<IHeadCell[]>>}
            />
        </Stack>
    );
};
