import { IPerformanceAttributionTableCells } from "src/consts/performancePaidPage/performancePaidPage";
import baseCreateApi from "./baseCreateApi";

const formatSettings = (
    performanceColumnOrders?: string[],
    paginationCount?: number,
    performanceHeroMetrics?: Array<{ id: keyof IPerformanceAttributionTableCells; isHidden: boolean }>,
) => {
    let finalSetting = {};

    if (performanceColumnOrders) {
        finalSetting = { ...finalSetting, performance_column_orders: performanceColumnOrders };
    }
    if (paginationCount) {
        finalSetting = {
            ...finalSetting,
            counts: [{ pagination_count: paginationCount }],
        };
    }

    if (performanceHeroMetrics) {
        finalSetting = {
            ...finalSetting,
            performance_hero_metrics: performanceHeroMetrics.map((c) => ({ id: c.id, is_hidden: c.isHidden })),
        };
    }

    return finalSetting;
};

export const kpiSettings = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        editUserSettings: builder.mutation({
            query: (data: {
                orgId: string;
                userId: string;
                performanceColumnOrders?: string[];
                paginationCount?: number;
                performanceHeroMetrics?: Array<{ id: keyof IPerformanceAttributionTableCells; isHidden: boolean }>;
            }) => ({
                url: `organizations/${data.orgId}/user_settings/update`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            user_id: data.userId,
                            settings: formatSettings(
                                data.performanceColumnOrders,
                                data.paginationCount,
                                data.performanceHeroMetrics,
                            ),
                        },
                        type: "user_settings",
                    },
                }),
            }),
        }),
    }),
});

export const { useEditUserSettingsMutation } = kpiSettings;
