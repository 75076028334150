import React from "react";
import { Theme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";
import Paper from "@mui/material/Paper";
import { Stack, useTheme } from "@mui/system";
import { createGlobalStyle } from "styled-components";

import { IPageProps } from "../interfaces/IPageProps";
import companyLogo from "../assets/logo/Presicent-Logo-White.png";

const useStyles = (theme: Theme, isUpgraded: boolean) => ({
    formClass: css({
        padding: theme.spacing(4),
        backgroundColor: "rgba(255, 255, 255, 1)",
        width: "444px",
        form: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(3),
        },
        [theme.breakpoints.down("sm")]: {
            padding: "30px!important",
            width: "calc(100vw - 100px)!important",
        },
    }),
    containerClass: css({
        padding: "70px 0",
        [theme.breakpoints.down("sm")]: {
            padding: "32px!important",
        },
    }),
    markLogo: css({
        position: "absolute",
        top: "68px",
        width: "100%",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    }),
});

export const fromStyles = (theme: Theme) => ({
    fieldClass: css({
        backgroundColor: "white",
    }),
});

const GlobalStyle = createGlobalStyle`
  body {
    background-color: var(--primary-main, #262626);
  }
`;

export const fieldClass = css`
    background-color: white;
`;

interface ISimpleFormProps extends IPageProps {
    children: React.ReactNode;
    isUpgraded?: boolean;
}

export const SimpleForm: React.FC<ISimpleFormProps> = ({ children, isUpgraded = false }) => {
    const classes = useStyles(useTheme(), isUpgraded);
    return (
        <Stack>
            <Stack alignItems="center">
                <GlobalStyle />
                <Stack alignSelf="center" sx={{ padding: "56px 0 56px 0", gap: "24px" }}>
                    <Link to="/" data-cy="logolink">
                        <img src={companyLogo} data-cy="logo" />
                    </Link>
                </Stack>
                <Paper elevation={isUpgraded ? 8 : 1} className={classes.formClass}>
                    {children}
                </Paper>
            </Stack>
        </Stack>
    );
};
