import { Stack } from "@mui/system";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Alert, AlertTitle, Backdrop, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import PlaceholderImage from "src/assets/PlaceholderImage.png";
import DialogVisualHeaderImage from "src/assets/dialogVisualHeader.png";

import { BaseVsHaloCard } from "src/components/Dashboard/BaseVsHaloCard/BaseVsHaloCard";
import { CompanyForecastTab } from "src/components/Dashboard/CompanyWideForecast/CompanyForecastTab";
import { Goals } from "src/components/Dashboard/Goals/Goals";
import { KpiPerformanceCard } from "src/components/Dashboard/KpiPerformanceCard/KpiPerformanceCard";
import { TrendCard } from "src/components/Dashboard/TrendCard/TrendCard";
import { DataQaModal } from "src/components/HomePageComponents/DataQaModal";
import { OnboardingAdminHomePage } from "src/components/OnboardingPages/AdminOnboardingPages/OnboardingAdminHomePage";
import { OnboardingViewerHomePage } from "src/components/OnboardingPages/ViewerOnboardingPages/OnboardingViewerHomePage";
import { OnboardingTooltip, Placements } from "src/components/OnboardingTooltip/OnboardingTooltip";
import { CustomDialog } from "src/components/core/CustomDialog/CustomDialog";
import { CONNECTORS_PATH, HOME_PATH } from "src/consts/path/path";
import { homeTourDismissed } from "src/consts/userEvent";
import { UserRole } from "src/enums/UserRole";
import { IConnector } from "src/interfaces/IConnector";
import { IOrganization } from "src/interfaces/entities/IOrganization";
import { useAddUserEventMutation, useGetUserEventsQuery } from "src/reduxState/apis/userEventApi";
import { selectBrokenConnectors } from "src/reduxState/slices/connectorsSlice";
import { companyWideForecastingCardsSelector } from "src/reduxState/slices/featuresSlice";
import {
    dataQaReady,
    selectCurrentlyViewingCode,
    selectCurrentlyViewingCompanyById,
} from "src/reduxState/slices/organizationSlice";
import {
    isCampaignAvailableSelector,
    isModelingAvailableSelector,
    onboardingStateForEventSelector,
    partialStateSelectorForHome,
} from "src/reduxState/slices/settingsSlice";
import {
    currentUserRoleByCompanyIdSelector,
    isSuperAdminSelector,
    userStateSelector,
} from "src/reduxState/slices/userSlice";
import { checkIsIncompletePagesExist } from "src/services/onboardingPage/onboardingPage";
import { formattedArrayInSentence, getPathForAnalytics } from "src/services/utils";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { NoCampaignPage } from "../CampaignPageV2/NoCampaignPage";

export const HomePageV1 = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);
    const [isTourEnabled, setIsTourEnabled] = useState(false);

    const isCompanyWideForecastingCardsEnabled = useSelector(companyWideForecastingCardsSelector);
    const isCampaignAvailable = useSelector(isCampaignAvailableSelector);
    const isModelingAvailable = useSelector(isModelingAvailableSelector);
    const brokenConnectors = useSelector(selectBrokenConnectors);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const currentOrg: IOrganization = useSelector(selectCurrentlyViewingCompanyById);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const user = useSelector(userStateSelector);
    const partialStateForHome = useSelector(partialStateSelectorForHome);
    const onboardingState = useSelector(onboardingStateForEventSelector);

    const isAdmin = userRole === UserRole.ADMIN || isSuperAdmin;
    const showScheduleCallModal = location.search?.includes("data_qa=incompleted");
    const showDataQaCompleteModal = location.search?.includes("data_qa=completed");
    const analyticsService = useAnalyticsService();

    const { data } = useGetUserEventsQuery({});
    const [addUserEvent, { isLoading: isAddUserEventLoading }] = useAddUserEventMutation();

    const isDataQaReady: boolean = useSelector(dataQaReady);

    const isIncompletePagesExist = useMemo(
        () => checkIsIncompletePagesExist(currentOrg?.onboardingState),
        [currentOrg],
    );

    useEffect(() => {
        if (
            isIncompletePagesExist === undefined ||
            isCampaignAvailable === undefined ||
            isModelingAvailable === undefined
        ) {
            return;
        }
        analyticsService.logEvent("Homepage Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
            "Onboarding State": onboardingState,
        });
    }, [isIncompletePagesExist, isCampaignAvailable, isModelingAvailable]);

    useEffect(() => {
        if (!isSuperAdmin && !partialStateForHome && data) {
            const isHomeTourDismissed = data.find((ev: any) => ev.event.includes(homeTourDismissed));
            if (!isHomeTourDismissed) {
                const timeoutId = setTimeout(() => {
                    setShowWelcomeModal(true);
                }, 1200);
                return () => clearTimeout(timeoutId);
            }
        } else {
            setShowWelcomeModal(false);
            setIsTourEnabled(false);
        }
    }, [data, partialStateForHome]);

    const closeDataQaModal = () => {
        navigate(`/org/${orgCode}${HOME_PATH}`);
    };

    const handleAddUserEvent = async (eventName: string, successCallback: () => void) => {
        try {
            await addUserEvent({
                userId: user.id,
                eventName,
            }).unwrap();

            successCallback();
        } catch (err: any) {
            const errorDetail = err.status && err.data?.errors?.[0]?.detail;
            const errMsg = errorDetail
                ? `Add user event error - ${err.status} (${errorDetail})`
                : `Something went wrong! (Error code - ${err.status || ""})`;
            enqueueSnackbar(errMsg, {
                id: uniqueId(),
                variant: "error",
            });
        }
    };

    const dismissHomeTourHandler = () => {
        handleAddUserEvent(homeTourDismissed, () => {
            setIsTourEnabled(false);
            setShowWelcomeModal(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
        });
    };

    const connectorBrokenWarningAction = () => {
        navigate(`/org/${orgCode}${CONNECTORS_PATH}`);
    };

    const brokenConnectorsNames = brokenConnectors?.map((connector: IConnector) => connector.connectorName);

    const getEmptyComponent = () =>
        isSuperAdmin || userRole === UserRole.ADMIN ? <OnboardingAdminHomePage /> : <OnboardingViewerHomePage />;

    const takeTourHandler = () => {
        setShowWelcomeModal(false);
        setIsTourEnabled(true);
    };

    // Define the tooltip references
    const tooltipRefTargets = [
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
    ];

    // Define the card details
    const cards = useMemo(
        () => [
            {
                title: "E-commerce Forecasting",
                description: "Make better decisions by tracking our model's accuracy.",
                isEnabled: isCompanyWideForecastingCardsEnabled,
                targetId: tooltipRefTargets[0],
                component: <CompanyForecastTab tooltipRef={tooltipRefTargets[0]} />,
            },
            {
                title: "KPI Report",
                description: "Quickly assess your overall business performance with high-level metrics.",
                isEnabled: true,
                targetId: tooltipRefTargets[1],
                component: <KpiPerformanceCard tooltipRef={tooltipRefTargets[1]} />,
            },
            {
                title: "Trends",
                description: "See quick comparison reports for your key metrics, split by channels.",
                isEnabled: true,
                targetId: tooltipRefTargets[2],
                component: <TrendCard tooltipRef={tooltipRefTargets[2]} />,
            },
            {
                title: "Base vs Halo Effect Rollup",
                description: "See the overall base-to-halo mix for your business.",
                isEnabled: true,
                targetId: tooltipRefTargets[3],
                component: <BaseVsHaloCard tooltipRef={tooltipRefTargets[3]} />,
            },
            {
                title: "Goals",
                description: "Track how you are doing and the progress you are making.",
                isEnabled: true,
                targetId: tooltipRefTargets[4],
                component: <Goals tooltipRef={tooltipRefTargets[4]} />,
            },
        ],
        [isCompanyWideForecastingCardsEnabled, tooltipRefTargets],
    );

    // Filter out the cards that are disabled
    const enabledCards = cards.filter((card) => card.isEnabled);

    return (
        <>
            {!isCampaignAvailable && !isIncompletePagesExist ? (
                <NoCampaignPage
                    title="We're building your dashboard!"
                    subTitle="We're in the process of syncing your data and using it to build your custom attribution model. This can take some time, so please check back later."
                />
            ) : (
                <div>
                    <PageHeader
                        pageHeading="Home"
                        welcomeMessage={`Welcome back to Prescient, ${user?.firstName}!`}
                        showBreadcrumbs={false}
                    />
                    {isIncompletePagesExist ? (
                        <PageContainer style={{ padding: 0, backgroundColor: "#fff" }}>
                            {getEmptyComponent()}
                        </PageContainer>
                    ) : (
                        <PageContainer style={{ padding: "24px 30px" }}>
                            <Stack gap={3}>
                                {brokenConnectors?.length ? (
                                    <Alert
                                        severity="error"
                                        action={
                                            <Button
                                                color="inherit"
                                                size="small"
                                                variant="outlined"
                                                onClick={connectorBrokenWarningAction}
                                            >
                                                Re-Connect
                                            </Button>
                                        }
                                    >
                                        {`${formattedArrayInSentence(
                                            brokenConnectorsNames,
                                        )} Connection Interrupted - Action Required`}
                                    </Alert>
                                ) : null}

                                {isDataQaReady && isAdmin && (
                                    <Alert severity="info">
                                        <AlertTitle>Verify Your Data Accuracy</AlertTitle>
                                        In order for your MMM data to populate, you need to verify that the input data
                                        is correct.
                                    </Alert>
                                )}

                                {enabledCards.map((card, index) => (
                                    <Fragment key={index}>{card.component}</Fragment>
                                ))}
                            </Stack>

                            {isTourEnabled && (
                                <OnboardingTooltip
                                    targets={enabledCards.map((c) => c.targetId)}
                                    cards={enabledCards}
                                    placement={Placements.RIGHT}
                                    onFinishCloseTooltip={dismissHomeTourHandler}
                                />
                            )}

                            {showWelcomeModal && (
                                <Backdrop
                                    sx={{
                                        zIndex: (theme) => theme.zIndex.drawer + 1,
                                        alignItems: "start",
                                    }}
                                    open={showWelcomeModal}
                                    transitionDuration={300}
                                >
                                    <Stack sx={{ width: "490px", position: "absolute", top: "307px" }}>
                                        <img src={DialogVisualHeaderImage} style={{ borderRadius: "4px 4px 0 0" }} />
                                        <CustomDialog
                                            title={<>Welcome Home!</>}
                                            instanceSlot={
                                                <>
                                                    <Typography color="text.secondary">
                                                        This dashboard aggregates key metrics to help you analyze trends
                                                        across your business. Start here to evaluate performance using
                                                        high-level reports before diving into detailed insights on the
                                                        Performance Page or engaging with the Optimizer.
                                                    </Typography>
                                                </>
                                            }
                                            actions={{
                                                1: {
                                                    label: "Take the Tour",
                                                    onClick: takeTourHandler,
                                                    disabled: isAddUserEventLoading,
                                                },
                                                3: {
                                                    label: "No Thanks",
                                                    onClick: dismissHomeTourHandler,
                                                    disabled: isAddUserEventLoading,
                                                },
                                            }}
                                            closeIcon={false}
                                            sx={{
                                                width: "100%",
                                                borderRadius: "0 0 4px 4px",
                                                " .MuiDialogTitle-root": {
                                                    padding: "24px 16px 0 16px",
                                                },
                                                " .MuiDialogContent-root": {
                                                    padding: "16px",
                                                    paddingTop: "16px!important",
                                                },
                                            }}
                                        />
                                    </Stack>
                                </Backdrop>
                            )}
                        </PageContainer>
                    )}
                </div>
            )}

            {showDataQaCompleteModal && (
                <DataQaModal
                    placeholderImage={PlaceholderImage}
                    title="Glad Everything Checks Out!"
                    instanceSlot={
                        <Typography variant="body1" color="text.secondary">
                            Explore your new home dashboard while your data propagates. Please note, this process can
                            take up to 72 hours, and Amazon data may take up to 2 weeks.
                        </Typography>
                    }
                    actions={{
                        1: {
                            label: "Close",
                            onClick: closeDataQaModal,
                            props: {
                                variant: "outlined",
                            },
                            disabled: false,
                        },
                    }}
                />
            )}

            {showScheduleCallModal && (
                <DataQaModal
                    placeholderImage={PlaceholderImage}
                    title="We Will Be Reaching Out to Schedule a Call With You"
                    instanceSlot={
                        <Typography variant="body1" color="text.secondary">
                            In the meantime, check out your new home dashboard experience!
                        </Typography>
                    }
                    actions={{
                        1: {
                            label: "Close",
                            onClick: closeDataQaModal,
                            props: {
                                variant: "outlined",
                            },
                            disabled: false,
                        },
                    }}
                />
            )}
        </>
    );
};
