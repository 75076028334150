import { FC } from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { dataValueViewOptions } from "src/consts/homeDashboard/homeDashboard";

interface IAbsoluteVsPercentagesSelect {
    value: string;
    handleDataValueViewChange: (grp: string) => void;
    isDisabled?: boolean;
    isUpgraded?: boolean;
}

export const AbsoluteVsPercentagesSelect: FC<IAbsoluteVsPercentagesSelect> = ({
    value,
    handleDataValueViewChange,
    isDisabled = false,
    isUpgraded = false,
}) => {
    const changeHandler = (e: SelectChangeEvent<string>) => {
        handleDataValueViewChange(e.target.value);
    };

    return (
        <FormControl sx={{ minWidth: 120, backgroundColor: "white" }} size="small">
            <Select
                labelId="data-value-select-label"
                id="data-value-select"
                value={value}
                onChange={changeHandler}
                sx={{
                    height: isUpgraded ? "56px" : "50px",
                }}
                variant="outlined"
                disabled={isDisabled}
            >
                {dataValueViewOptions.map((grp) => {
                    return (
                        <MenuItem value={grp.id} key={grp.id} disabled={grp.isDisabled}>
                            {grp.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
