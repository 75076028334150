import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import { useLazyGetEventLogsQuery, useLazyGetFiltersQuery } from "../../reduxState/apis/eventLogsApi";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { EventLogTable } from "../../components/EventLogsComponent/EventLogsTable/EventLogTable";
import { Choice, ICells } from "../../consts/eventLogsPage/eventLogsPage";
import { paginationCountSelector } from "../../reduxState/slices/settingsSlice";
import Loader from "../../components/core/Loader/Loader";
import { SubHeader } from "../../components/SubHeader/SubHeader.v2";
import { EventFilter } from "../../components/EventLogsComponent/EventFIlter/EventFilter";
import { handleFilteredData, recalculateSortDirection } from "../../services/utils";
import { organizationsSelector } from "../../reduxState/slices/organizationSlice";
import { IEventLog } from "../../interfaces/entities/IEventLog";

export const EventLogs: FC = () => {
    const navigate = useNavigate();

    const organizations = useSelector(organizationsSelector).byId;

    const [getEventLogs, { isLoading, data: logData }] = useLazyGetEventLogsQuery();
    const [getFilters, { isLoading: isFiltersLoading, data: filterData }] = useLazyGetFiltersQuery();
    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("createdAt");
    const [page, setPage] = useState<number>(0);
    const [changedBy, setChangedBy] = useState<string[]>([]);
    const [auditableType, setAuditableType] = useState<string[]>([]);

    const rowsPerPage = useSelector(paginationCountSelector);

    const getCompanyName = (eventLog: IEventLog) => {
        if (eventLog.auditableType === "Company") {
            if (organizations[eventLog.auditableId]) {
                return organizations[eventLog.auditableId].displayName;
            } else {
                return `${eventLog.auditableId}`;
            }
        } else if (eventLog.associatedId) {
            if (organizations[eventLog.associatedId]) {
                return organizations[eventLog.associatedId].displayName;
            } else {
                return eventLog.associatedId;
            }
        }
        return "";
    };

    const addCompanyNames = (eventLogs: IEventLog[]) => {
        const newLogs = eventLogs.map((eventLog) => {
            const companyName = getCompanyName(eventLog);
            if (companyName) {
                return { ...eventLog, companyName };
            } else {
                return eventLog;
            }
        });
        return newLogs;
    };

    const filteredEventLogsData = addCompanyNames(logData?.data ? logData.data : []);
    const totalLogsCount = logData?.pagination.count ? logData?.pagination.count : 0;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (logData && rowsPerPage * page < logData.pagination.count) {
            setPage(0);
        }
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICells) => {
        setOrder(recalculateSortDirection(orderBy, order, property));
        setOrderBy(property);
        setPage(0);
    };

    const getData = () => {
        getEventLogs({ params: { page: page + 1, rowsPerPage } }).unwrap();
    };

    const getFilterListData = () => {
        getFilters({})
            .unwrap()
            .then((filters) => {
                setChangedBy(filters.data.changedBy);
                setAuditableType(filters.data.auditableTypes);
            });
    };

    useEffect(() => {
        getData();
        getFilterListData();
    }, []);

    useEffect(() => {
        if (logData && rowsPerPage * page < logData.pagination.count) {
            getData();
        }
    }, [rowsPerPage, page]);

    const handleFilter = (paramKey: string, item: { id: string }) => {
        handleFilteredData(paramKey, item, navigate);
        getData();
        setPage(0);
    };

    return (
        <div>
            <PageHeader pageHeading="Event Logs" />
            <PageContainer>
                {isLoading ? (
                    <Loader />
                ) : (
                    logData?.data && (
                        <>
                            <SubHeader
                                isSearch={false}
                                itemsLength={totalLogsCount}
                                title="Event log(s)"
                                onSearchChange={() => {}}
                                filterComponent={
                                    <EventFilter
                                        handleFilter={handleFilter}
                                        onClear={getData}
                                        changedByList={changedBy}
                                        auditableTypeList={auditableType}
                                    />
                                }
                            />
                            <EventLogTable
                                tableData={filteredEventLogsData}
                                page={page}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                order={order}
                                orderBy={orderBy}
                                handleRequestSort={handleRequestSort}
                                count={totalLogsCount}
                            />
                        </>
                    )
                )}
            </PageContainer>
        </div>
    );
};
