import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chartModeOptions } from "src/consts/homeDashboard/homeDashboard";
import { ChartMode, chartModeSelector, setChartMode } from "src/reduxState/slices/chartSlice";

interface IChartModeSelect {
    isDisabled?: boolean;
    isUpgraded?: boolean;
}

export const ChartModeSelect: FC<IChartModeSelect> = ({ isDisabled = false, isUpgraded = false }) => {
    const dispatch = useDispatch();

    const value = useSelector(chartModeSelector);

    const changeHandler = (e: SelectChangeEvent<ChartMode>) => {
        dispatch(setChartMode(e.target.value as ChartMode));
    };

    return (
        <FormControl sx={{ minWidth: 120, backgroundColor: "white" }} size="small">
            <Select
                labelId="chart-mode-select-label"
                id="chart-mode-select"
                value={value}
                onChange={changeHandler}
                sx={{
                    height: isUpgraded ? "56px" : "50px",
                }}
                variant="outlined"
                disabled={isDisabled}
            >
                {chartModeOptions.map((grp) => {
                    return (
                        <MenuItem value={grp.id} key={grp.id} disabled={grp.isDisabled}>
                            {grp.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
