import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { format, addDays } from "date-fns";
import { IScenario } from "src/interfaces/entities/IScenario";
import { getTrackingDates } from "src/services/optimizationPage/optimization";

interface IStatusDetailsProps {
    selectedScenario: IScenario;
    acceptedCampaignsCount: number;
    classes: any;
    isScenarioExpired: boolean;
}

export const StatusDetails: FC<IStatusDetailsProps> = ({
    selectedScenario,
    acceptedCampaignsCount,
    isScenarioExpired,
}) => {
    const { earliestStartDate, latestEndDate } = getTrackingDates(selectedScenario.scenarioCampaigns || []);

    return (
        <Stack gap={1}>
            <Typography component="span">
                <Typography component="span">Status: </Typography>
                <Typography component="span" fontWeight="700">
                    {isScenarioExpired ? "Completed" : "In progress"}
                </Typography>
            </Typography>
            <Typography component="span">
                <Typography component="span">Dates: </Typography>
                {earliestStartDate && latestEndDate && (
                    <Typography component="span" fontWeight="700">
                        {` ${format(earliestStartDate, "MMM dd, yyyy")} - ${format(
                            latestEndDate,
                            "MMM dd, yyyy",
                        )}`}
                    </Typography>
                )}
            </Typography>
            <Typography component="span">
                <Typography component="span">Accepted Recommendation: </Typography>
                {acceptedCampaignsCount && (
                    <Typography component="span" fontWeight="700">
                        {acceptedCampaignsCount}
                    </Typography>
                )}
            </Typography>
        </Stack>
    );
};
