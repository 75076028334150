import React from "react";
import { format } from "date-fns";
import { Typography, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Container } from "@mui/system";
import capitalize from "lodash/capitalize";
import { css } from "@emotion/css";
import { IEventLog } from "../../../interfaces/entities/IEventLog";
import { cursor } from "../../../assets/styles/commonStyle";

interface IEventLogItem {
    eventLog: IEventLog;
    onClick: (id: string) => void;
    prevData: IEventLog;
}

const useStyles = (theme: Theme) => ({
    dateHeader: css({
        margin: "10px 10px 10px 25px",
        fontSize: "1.5rem",
        fontWeight: "bold",
    }),

    timeStamp: css({
        margin: ".25rem 0",
        width: "15%",
        minWidth: "max-content",
    }),

    logItem: css({
        width: "100%",
        margin: ".25rem 0",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up("sm")]: {
            gap: "2rem",
            alignItems: "center",
        },
    }),

    itemBox: css({
        padding: ".5rem 1rem",
        border: "2px solid #E0E0E0",
        borderRadius: "10px",
        overflow: "auto",
    }),
});

const isSameDay = (date1: string, date2: string) => {
    if (!date1) {
        return false;
    }
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

const getActionType = (eventLog: IEventLog, theme: Theme) => {
    let returnElem = <span></span>;
    switch (eventLog.action) {
        case "create":
            returnElem = <span style={{ color: theme.palette.success.main }}>created</span>;
            break;
        case "update":
            returnElem = <span style={{ color: theme.palette.info.dark }}>updated</span>;
            break;
        case "destroy":
            returnElem = <span style={{ color: theme.palette.error.main }}>deleted</span>;
            break;
        default:
            returnElem = <span>performed an unknown action on</span>;
            break;
    }
    return returnElem;
};

const getConnector = (eventLog: IEventLog) => {
    const newOrNot = eventLog.action === "create" ? " new" : "";
    if (["a", "e", "i", "o"].includes(eventLog.auditableType[0].toLowerCase())) {
        return "an" + newOrNot;
    } else {
        return "a" + newOrNot;
    }
};

const getCompanyName = (eventLog: IEventLog) => {
    if (!eventLog.companyName) {
        return false;
    }
    if (eventLog.companyName && eventLog.auditableType === "Company") {
        return `called "${eventLog.companyName}"`;
    } else {
        return `for ${eventLog.companyName}`;
    }
};

export const EventLogItem: React.FC<IEventLogItem> = ({ eventLog, prevData, onClick }) => {
    const prevDate: string = prevData ? prevData.createdAt : "";

    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <>
            {!isSameDay(prevDate, eventLog.createdAt) && (
                <Typography className={classes.dateHeader}>
                    {format(new Date(eventLog.createdAt), "MMM d, yyyy")}
                </Typography>
            )}
            <Container
                key={eventLog.id}
                className={`${cursor("pointer")} ${classes.logItem}`}
                onClick={() => onClick(eventLog.id)}
            >
                <Typography className={classes.timeStamp}>
                    {format(new Date(eventLog.createdAt), "hh:mm:ss a")}
                </Typography>
                <Container className={classes.itemBox}>
                    {eventLog.username ? (
                        <Typography>
                            {`${eventLog.username} `} {getActionType(eventLog, theme)}{" "}
                            {`${getConnector(eventLog)} ${eventLog.auditableType}`} {getCompanyName(eventLog)}
                        </Typography>
                    ) : (
                        <Typography>
                            {`${capitalize(getConnector(eventLog))} ${eventLog.auditableType} was `}{" "}
                            {getActionType(eventLog, theme)}
                        </Typography>
                    )}
                </Container>
            </Container>
        </>
    );
};
