import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { differenceInMonths } from "date-fns";
import { useSelector } from "react-redux";

import {
    CHART_GRP_BY_OPTIONS,
    EXCLUDE_OPTIONS,
    chartGrpKey,
} from "../../../../consts/performancePaidPage/performancePaidPage";
import { formattedCurrentDateStringSelector } from "../../../../reduxState/slices/dateFilterSlice";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { capitalizeFirstLetter, getPathForAnalytics } from "src/services/utils";
import { IDateRange } from "src/interfaces/dashboard/trend";
import { IDictionary } from "src/interfaces/IDictionary";

interface IGroupBySelection {
    grpBy: string;
    handleGrpByChange: (grp: string) => void;
    selectedDateRanage?: IDateRange[];
    grpByCustomOptions?: Array<{
        id: chartGrpKey;
        label: string;
        isDisabled: boolean;
    }>;
    label?: boolean;
    chartTitle?: string;
    isUpgraded?: boolean;
}

const GroupBySelection: FC<IGroupBySelection> = ({
    grpBy,
    handleGrpByChange,
    selectedDateRanage,
    grpByCustomOptions = CHART_GRP_BY_OPTIONS,
    label = true,
    chartTitle,
    isUpgraded = false,
}) => {
    const analytics = useAnalyticsService();
    const [grpByOptions, setGrpByOptions] = useState(grpByCustomOptions);
    const { EXCLUDE_FOR_MONTH3, EXCLUDE_FOR_MONTH6, EXCLUDE_FOR_MONTH9 } = EXCLUDE_OPTIONS;
    const currentDate = selectedDateRanage ? selectedDateRanage : useSelector(formattedCurrentDateStringSelector);

    const diffInMonths = useMemo(
        () => differenceInMonths(new Date(currentDate[0].endDate), new Date(currentDate[0].startDate)) + 1,
        [currentDate],
    );

    useEffect(() => {
        if (diffInMonths <= 3) {
            setGrpByOptions(grpByOptions.map((o) => ({ ...o, isDisabled: EXCLUDE_FOR_MONTH3.includes(o.id) })));
        } else if (diffInMonths <= 6) {
            setGrpByOptions(grpByOptions.map((o) => ({ ...o, isDisabled: EXCLUDE_FOR_MONTH6.includes(o.id) })));
        } else if (diffInMonths <= 9) {
            setGrpByOptions(grpByOptions.map((o) => ({ ...o, isDisabled: EXCLUDE_FOR_MONTH9.includes(o.id) })));
        } else {
            setGrpByOptions(CHART_GRP_BY_OPTIONS);
        }
        if (!selectedDateRanage) {
            handleGrpByChange(chartGrpKey.DAY);
        }
    }, [currentDate]);

    const grpChange = (e: SelectChangeEvent<string>) => {
        const { value } = e.target;
        let eventProperties: IDictionary = {
            Page: getPathForAnalytics(window.location.pathname),
            "Grouping length": capitalizeFirstLetter(value),
        };
        if (chartTitle) {
            eventProperties = {
                ...eventProperties,
                Chart: chartTitle,
            };
        }
        analytics.logEvent("Data Grouping Length Changed", eventProperties);
        handleGrpByChange(value);
    };

    return (
        <FormControl sx={{ m: isUpgraded ? 0 : 1, minWidth: 120, backgroundColor: "white" }} size="small">
            {label && <InputLabel id="demo-select-small-label">Group By</InputLabel>}
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label={label ? "Group By" : ""}
                value={grpBy}
                onChange={(e) => grpChange(e)}
                sx={{
                    height: isUpgraded ? "56px" : "50px",
                    "& .MuiSvgIcon-root": {
                        color: "#000000",
                    },
                }}
                variant="outlined"
            >
                {grpByOptions.map((grp) => {
                    return (
                        <MenuItem value={grp.id} key={grp.id} disabled={grp.isDisabled}>
                            {grp.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default memo(GroupBySelection);
