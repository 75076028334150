import { NavigateFunction } from "react-router";
import { toDate } from "date-fns-tz";
import queryString from "query-string";
import inRange from "lodash/inRange";
import groupBy from "lodash/groupBy";
import find from "lodash/find";
import { Range } from "react-date-range";
import {
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    format,
    getQuarter,
    getYear,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
} from "date-fns";
import isEqual from "lodash/isEqual";

import { keysToCamelCase } from "../../utils/format";
import {
    getDemoCampaignName,
    isValueNaN,
    calculateCPC,
    calculateCPM,
    capitalizeFirstLetter,
    calculateROAS,
    getTotalAmountForPerformanceTable,
    convertObjectToQueryParam,
    getFilterChipValues,
    getUniqueListBy,
    formatValue,
    getTooltipDateFormat,
    getFormattedMetricQuery,
    compare,
    calculateCAC,
} from "../utils";
import { IPerformanceDataResponseData } from "../../interfaces/IPerformancePage";
import { IDictionary } from "../../interfaces/IDictionary";
import { IConnector } from "../../interfaces/IConnector";
import {
    CAMPARE_CONDITIONS,
    CampaignStatus,
    Choice,
    MAP_VALUE_PARAMS,
    getMetricNames,
    PERFORMANCE_ATTRIBUTION_HEADERS,
    getAmazonRevenueCols,
    PERFORMANCE_FILTER_LIST,
    SAMPLE_IN_METRIC_ATTRIBUTION_TABLE,
    IHeadCell,
    chartGrpKey,
    emptyTableCampaign,
    PerformanceTab,
    PerformanceTabKeyMapping,
} from "../../consts/performancePaidPage/performancePaidPage";
import { store } from "../../reduxState/stores/store";
import { selectDataSourceNameByProgrammaticName } from "../../reduxState/slices/supportedDataSourcesSlice";
import { selectTacticById } from "../../reduxState/slices/tacticsSlice";
import { IMetricAttributionTableValuesTransformed } from "../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import {
    IPerformancePercentageRows,
    IPerformancePercentageTableData,
} from "../../interfaces/performanceDetails/IPerformancePercentage";
import { getComparator, stableSort } from "../../utils/sort";
import { getRangeFormattedTitle } from "../../components/DateRangeFilter/reactDateRangeUtils";
import { FORMATS } from "../../enums/Formats";
import {
    IPerformanceMetric,
    IPerformanceResponseMetric,
    PartialIPerformanceMetric,
} from "../../interfaces/IPerformanceCampaignMetrics";
import { IFieldItem, IFieldItems } from "../../components/DynamicFilter/MetricFilterInputs";
import { IEmailPercentageTableData } from "../../interfaces/emailDetails/IEmailPercentage";
import { AMAZON_ADS, GOOGLE_ANALYTICS, AMAZON_SELLING_PARTNER } from "src/consts/connectors";
import { AMAZON_SELLING_PARTNER_REVENUE } from "src/consts/targetRevenue";
import { IForecastCampaignBody } from "src/reduxState/apis/performancePageApi";
import { PERFORMANCE_CAMPAIGNS_PATH } from "src/consts/path/path";

export const generateForecastBody = (data: IForecastCampaignBody) => {
    return { connector_name: data.connectorName, spend: data.spend, model_type: data.modelType };
};

export const NOT_SET_TACTIC = { tactic: "NOT_SET", displayName: "Not Set" };

export const getPreparedObjectForArray = (data: IPerformanceDataResponseData[]) => {
    const array = formatArrayWithCamelCase(data);
    return array.map((value: IMetricAttributionTableValuesTransformed) => {
        const tactic = value.tacticId ? selectTacticById(store.getState(), value.tacticId) : null;
        let attributionObj = {
            ...value,
            campaignName: value.campaignName || value.campaignId,
            spend: isValueNaN(value.spend),
            impressions: isValueNaN(value.impressions),
            trueRoas: value.trueRoas ? isValueNaN(value.trueRoas) : value.trueRoas === 0 ? 0 : -1,
            clicks: isValueNaN(value.clicks),
            sessions: isValueNaN(value.sessions),
            trueOrders: isValueNaN(value.trueOrders),
            trueRevenue: value.trueRevenue ? isValueNaN(value.trueRevenue) : value.trueRevenue === 0 ? 0 : -1,
            channelName: value.connectorName
                ? selectDataSourceNameByProgrammaticName(store.getState(), value.connectorName)
                : "",
            channelReportedRevenue: value.channelReportedRevenue === 0 ? 0 : value.channelReportedRevenue || -1,
            channelReportedRoas: value.channelReportedRoas === 0 ? 0 : value.channelReportedRoas || -1,
            hasSecondOrderEffect: value.hasSecondOrderEffect,
            status: value.status || CampaignStatus.UNKNOWN,
            cac: value.cac ? isValueNaN(value.cac) : value.cac === 0 ? 0 : -1,
            amazonSellingPartnerRevenue: isValueNaN(value.amazonSellingPartnerRevenue),
            storeModeledRevenue: isValueNaN(value.storeModeledRevenue),
            baseRevenue: isValueNaN(value.baseRevenue),
            haloEffectRevenue: isValueNaN(value.haloEffectRevenue),
            tacticName: tactic ? tactic.displayName : NOT_SET_TACTIC.displayName,
            tactic: tactic ? tactic.tactic : NOT_SET_TACTIC.tactic,
            newCustomers:
                value.connectorName !== AMAZON_ADS
                    ? value.newCustomers
                        ? isValueNaN(value.newCustomers)
                        : value.newCustomers === 0
                        ? 0
                        : -1
                    : -1,
        };
        if (value.connectorName === "impact" && (!value.impressions || !value.cpm)) {
            attributionObj = { ...attributionObj, impressions: -1, cpm: -1 };
        }
        return attributionObj;
    });
};

export const formatArrayWithCamelCase = (data: any) => {
    const isDemoMode = store.getState().isDemoMode;
    const pathName = location.pathname.split("/");
    const connectorName = pathName[pathName.length - 2];
    const formattedData: any = [];

    data?.map((value: any) => {
        const finalConnectorName = value.connector_name || connectorName;
        const demoCampaignName = getDemoCampaignName(finalConnectorName);
        formattedData.push({
            ...keysToCamelCase(value),
            campaignName: isDemoMode ? demoCampaignName : value.campaign_name,
        });
    });

    return formattedData;
};

export const checkRangeValue = (
    { metric, condition, value }: IFieldItem,
    values: IFieldItems,
    connectors: IConnector[],
) => {
    let isValueBetweenInRange: boolean | undefined = true;
    const findMetric = values.metricConditionInputs.filter(
        (input) => input.value && input.condition && input.metric === metric && input.condition !== "eq",
    );

    const findMetricWithEq = values.metricConditionInputs.filter(
        (input) => input.value && input.condition && input.metric === metric && input.condition === "eq",
    );

    const metricName = getMetricNames(connectors);

    if (findMetricWithEq.length === 1 && findMetric.length === 1) {
        isValueBetweenInRange = compare(
            Number(findMetric[0].value),
            CAMPARE_CONDITIONS[findMetric[0].condition] + "=",
            Number(findMetricWithEq[0].value),
        );
        if (isValueBetweenInRange) {
            return `${metricName[findMetric[0].metric]} should be ${CAMPARE_CONDITIONS[findMetric[0].condition]} ${
                findMetric[0].value
            }.`;
        }
    }

    if (findMetric.length === 2) {
        isValueBetweenInRange = compare(
            Number(findMetric[0].value),
            CAMPARE_CONDITIONS[findMetric[0].condition],
            Number(findMetric[1].value),
        );
        if (isValueBetweenInRange) {
            return `${metricName[findMetric[0].metric]} should be ${CAMPARE_CONDITIONS[findMetric[0].condition]} ${
                findMetric[0].value
            }.`;
        }
    }

    return "";
};

export const isContainsDuplicateValues = (index: number, metricConditionInput: IFieldItem, values: IFieldItems) => {
    const { metric, condition, value } = metricConditionInput;
    const copyInputList = [...values.metricConditionInputs];
    copyInputList.splice(index, 1);
    const inputListString = copyInputList.map((list) => (list.metric + list.condition).toLowerCase());
    const isDuplicate = inputListString.includes((metric + condition).toLowerCase());
    return isDuplicate;
};

export const getFilteredPeformance = (performanceData: any, search: any, filterObj: any) => {
    if (performanceData && performanceData.length) {
        let resultPerformanceData = performanceData;
        const metricArr = getFormattedMetricQuery(filterObj);

        const groupedByMetricData = groupBy(metricArr, "metric");

        resultPerformanceData = resultPerformanceData.filter((data: any) => {
            const extractDataForCheckEmpty = {
                impressions: data.impressions,
                cpc: data.cpc,
                cpm: data.cpm,
                trueRoas: data.trueRoas,
                channelReportedRoas: data.channelReportedRoas,
                cac: data.cac,
                spend: data.spend,
                clicks: data.clicks,
                sessions: data.sessions,
                trueOrders: data.trueOrders,
                trueRevenue: data.trueRevenue,
                channelReportedRevenue: data.channelReportedRevenue,
                newCustomers: data.newCustomers,
                amazonSellingPartnerRevenue: data.amazonSellingPartnerRevenue,
                storeModeledRevenue: data.storeModeledRevenue,
            };

            const isEmptyCampaign = isEqual(extractDataForCheckEmpty, emptyTableCampaign);

            if (!isEmptyCampaign) {
                return data;
            }
        });

        if (filterObj && Object.keys(filterObj).length) {
            if (Object.keys(groupedByMetricData).length) {
                Object.keys(groupedByMetricData).map((filterD) => {
                    const noEqualArr = groupedByMetricData[filterD].filter((d) => d?.condition !== "eq");
                    const equalArr = groupedByMetricData[filterD].filter((d) => d?.condition === "eq");

                    if (groupedByMetricData[filterD].length === 1) {
                        const { metric, condition, value }: any = groupedByMetricData[filterD][0];
                        resultPerformanceData = resultPerformanceData.filter((d: any) => {
                            const fixedData = PERFORMANCE_ATTRIBUTION_HEADERS.find((h) => h.id === metric)?.fixed;
                            if (d[metric]) {
                                return compare(
                                    parseFloat(d[metric].toFixed(fixedData || 0)),
                                    CAMPARE_CONDITIONS[condition],
                                    parseFloat(value),
                                );
                            }
                        });
                    } else if (groupedByMetricData[filterD].length === 2) {
                        if (noEqualArr.length === 1 && equalArr.length === 1) {
                            resultPerformanceData = resultPerformanceData.filter(
                                (data: { [x: string]: { toFixed: (arg0: number) => number } }) => {
                                    if (noEqualArr[0] && equalArr[0]) {
                                        return compare(
                                            data[noEqualArr[0].metric].toFixed(0),
                                            CAMPARE_CONDITIONS[noEqualArr[0].condition] + "=",
                                            Number(equalArr[0].value),
                                        );
                                    }
                                },
                            );
                        } else if (noEqualArr.length === 2) {
                            resultPerformanceData = resultPerformanceData.filter((data: any) => {
                                const firstVl = noEqualArr[0];
                                const secondVal = noEqualArr[1];

                                let rangeResult;
                                if (firstVl?.condition === "lt") {
                                    rangeResult = inRange(
                                        data[firstVl.metric].toFixed(0),
                                        Number(firstVl.value),
                                        Number(secondVal?.value),
                                    );
                                }
                                if (firstVl?.condition === "gt") {
                                    rangeResult = inRange(
                                        data[firstVl.metric].toFixed(0),
                                        Number(secondVal?.value),
                                        Number(firstVl.value),
                                    );
                                }
                                return rangeResult;
                            });
                        }
                    } else if (
                        groupedByMetricData[filterD].length === 3 &&
                        noEqualArr.length === 2 &&
                        equalArr.length === 1
                    ) {
                        resultPerformanceData = resultPerformanceData.filter((data: any) => {
                            if (equalArr[0]) {
                                return compare(
                                    Number(data[equalArr[0].metric].toFixed(0)),
                                    "all",
                                    Number(equalArr[0].value),
                                );
                            }
                        });
                    }
                });
            }
            if (filterObj[PERFORMANCE_FILTER_LIST[0].key]) {
                resultPerformanceData = resultPerformanceData.filter((data: any) => {
                    const fixSearch = `${data.connectorName.toLowerCase()}`;
                    return !filterObj[PERFORMANCE_FILTER_LIST[0].key].includes(fixSearch.toLowerCase());
                });
            }

            if (filterObj[PERFORMANCE_FILTER_LIST[5].key]) {
                resultPerformanceData = resultPerformanceData.filter((data: any) => {
                    return !filterObj[PERFORMANCE_FILTER_LIST[5].key].split(",").includes(data.tactic);
                });
            }

            if (filterObj[PERFORMANCE_FILTER_LIST[1].items[0].key]) {
                const containsArr = filterObj[PERFORMANCE_FILTER_LIST[1].items[0].key].split(",");
                containsArr.forEach((d: string) => {
                    resultPerformanceData = resultPerformanceData.filter((data: any) => {
                        return data.campaignName.toLowerCase().includes(d.toLowerCase());
                    });
                });
            }

            if (filterObj[PERFORMANCE_FILTER_LIST[1].items[1].key]) {
                const containsArr = filterObj[PERFORMANCE_FILTER_LIST[1].items[1].key].split(",");
                containsArr.forEach((d: string) => {
                    resultPerformanceData = resultPerformanceData.filter((data: any) => {
                        return !data.campaignName.toLowerCase().includes(d.toLowerCase());
                    });
                });
            }

            if (filterObj[PERFORMANCE_FILTER_LIST[2].key]) {
                const data = filterObj[PERFORMANCE_FILTER_LIST[2].key];

                if (data.includes(PERFORMANCE_FILTER_LIST[2].items[1].key)) {
                    resultPerformanceData = resultPerformanceData.filter(
                        (el: { trueRoas: number | undefined }) => el.trueRoas !== undefined && el.trueRoas < 100,
                    );
                }
                if (data.includes(PERFORMANCE_FILTER_LIST[2].items[0].key)) {
                    resultPerformanceData = resultPerformanceData.filter(
                        (el: { trueRoas: number }) => el.trueRoas && el.trueRoas >= 100,
                    );
                }

                if (data.includes(PERFORMANCE_FILTER_LIST[2].items[2].key)) {
                    resultPerformanceData = resultPerformanceData.filter((data: any) => data.hasSecondOrderEffect);
                }
            }

            if (filterObj[PERFORMANCE_FILTER_LIST[4].key]) {
                const campaignIds = filterObj[PERFORMANCE_FILTER_LIST[4].key];
                resultPerformanceData = resultPerformanceData.filter((el: any) => {
                    return campaignIds.includes(el.campaignId);
                });
            }

            if (filterObj[PERFORMANCE_FILTER_LIST[6].key]) {
                const campaignCampaigns = filterObj[PERFORMANCE_FILTER_LIST[6].key];
                resultPerformanceData = resultPerformanceData.filter((el: any) =>
                    campaignCampaigns.split(",").includes(el.campaignName),
                );
            }
        }
        if (search) {
            resultPerformanceData = resultPerformanceData.filter((data: any) => {
                const fixSearch = `${data.campaignName?.toLowerCase()} ${data.channelName?.toLowerCase()} ${data.tactic?.toLowerCase()} ${data.tacticName?.toLowerCase()}`;
                return fixSearch.includes(search.toLowerCase());
            });
        }
        return resultPerformanceData;
    }
    return [];
};

export const getCheckBoxValue = (paramValue: string | string[] | undefined, normalValue: string): boolean => {
    if (!paramValue) {
        return false;
    }
    const values = Array.isArray(paramValue) ? paramValue : paramValue.split(",");
    return values.includes(normalValue);
};

export const getConditionValue = (paramKey: string, paramValue: string) => {
    const paramValueArr = paramValue?.split(",");
    if (paramValueArr.length) {
        const cArr = paramValueArr.map((a) => ({
            conditionType: paramKey,
            conditionValue: a,
        }));
        return cArr;
    } else {
        return [];
    }
};

export const getFilterList = (
    connectorCollection: any,
    tacticCollection: any,
    queryParamsObj: any,
    campaignIdOptions: any[],
) => {
    const { excluded_channels, properties, contains, not_contains, campaign_id, campaigns, excluded_tactics } =
        queryParamsObj;

    const channelList = {
        id: PERFORMANCE_FILTER_LIST[0].key,
        title: PERFORMANCE_FILTER_LIST[0].title,
        key: PERFORMANCE_FILTER_LIST[0].key,
        chipCondition: PERFORMANCE_FILTER_LIST[0].condition,
        type: PERFORMANCE_FILTER_LIST[0].type,
        items: connectorCollection.map((conn: string) => {
            return {
                id: conn,
                name: campaignIdOptions.find((c) => c.connectorName === conn).channelName,
                value: !getCheckBoxValue(excluded_channels, conn),
            };
        }),
        open: true,
    };

    let conditionInputList: Array<{
        conditionType: string;
        conditionValue: string;
    }> = [];

    if (contains) {
        conditionInputList = [
            ...getConditionValue(PERFORMANCE_FILTER_LIST[1].items[0].key, contains),
            ...conditionInputList,
        ];
    }
    if (not_contains) {
        conditionInputList = [
            ...getConditionValue(PERFORMANCE_FILTER_LIST[1].items[1].key, not_contains),
            ...conditionInputList,
        ];
    }

    const isPropertyOpen =
        getCheckBoxValue(properties, PERFORMANCE_FILTER_LIST[2].items[2].key) ||
        getCheckBoxValue(properties, PERFORMANCE_FILTER_LIST[2].items[0].key) ||
        getCheckBoxValue(properties, PERFORMANCE_FILTER_LIST[2].items[1].key);

    const filterList = [
        {
            ...channelList,
        },

        {
            id: PERFORMANCE_FILTER_LIST[4].key,
            key: PERFORMANCE_FILTER_LIST[4].key,
            title: PERFORMANCE_FILTER_LIST[4].title,
            type: PERFORMANCE_FILTER_LIST[4].type,
            items: campaign_id ? campaignIdOptions.filter((o) => campaign_id.split(",").includes(o.campaignId)) : [],
            open: !!campaign_id,
            apiKey: PERFORMANCE_FILTER_LIST[4].apiKey,
        },
        {
            id: PERFORMANCE_FILTER_LIST[1].key,
            title: PERFORMANCE_FILTER_LIST[1].title,
            key: PERFORMANCE_FILTER_LIST[1].key,
            type: PERFORMANCE_FILTER_LIST[1].type,
            items: [
                ...conditionInputList,
                {
                    conditionType: "contains",
                    conditionValue: "",
                },
            ],
            open: !!conditionInputList.length,
        },
        {
            id: PERFORMANCE_FILTER_LIST[3].key,
            title: PERFORMANCE_FILTER_LIST[3].title,
            key: PERFORMANCE_FILTER_LIST[3].key,
            type: PERFORMANCE_FILTER_LIST[3].type,
            items: [
                {
                    metric: [
                        ...getFormattedMetricQuery(queryParamsObj),
                        {
                            metric: "",
                            condition: "",
                            value: "",
                        },
                    ],
                },
            ],
            open: !!getFormattedMetricQuery(queryParamsObj).length,
        },
        {
            id: PERFORMANCE_FILTER_LIST[2].key,
            title: PERFORMANCE_FILTER_LIST[2].title,
            key: PERFORMANCE_FILTER_LIST[2].key,
            type: PERFORMANCE_FILTER_LIST[2].type,
            chipCondition: PERFORMANCE_FILTER_LIST[2].condition,
            items: [
                {
                    id: PERFORMANCE_FILTER_LIST[2].items[2].key,
                    name: PERFORMANCE_FILTER_LIST[2].items[2].title,
                    value: getCheckBoxValue(properties, PERFORMANCE_FILTER_LIST[2].items[2].key),
                },
                {
                    id: PERFORMANCE_FILTER_LIST[2].items[0].key,
                    name: PERFORMANCE_FILTER_LIST[2].items[0].title,
                    value: getCheckBoxValue(properties, PERFORMANCE_FILTER_LIST[2].items[0].key),
                },
                {
                    id: PERFORMANCE_FILTER_LIST[2].items[1].key,
                    name: PERFORMANCE_FILTER_LIST[2].items[1].title,
                    value: getCheckBoxValue(properties, PERFORMANCE_FILTER_LIST[2].items[1].key),
                },
            ],
            open: isPropertyOpen,
        },
        {
            id: PERFORMANCE_FILTER_LIST[5].key,
            title: PERFORMANCE_FILTER_LIST[5].title,
            key: PERFORMANCE_FILTER_LIST[5].key,
            type: PERFORMANCE_FILTER_LIST[5].type,
            chipCondition: PERFORMANCE_FILTER_LIST[5].condition,
            items: [NOT_SET_TACTIC, ...tacticCollection].map((tactic: any) => {
                return {
                    id: tactic.tactic,
                    name: tactic.displayName,
                    value: !getCheckBoxValue(excluded_tactics, tactic.tactic),
                };
            }),
            open: [NOT_SET_TACTIC, ...tacticCollection].some((tactic: any) =>
                getCheckBoxValue(excluded_tactics, tactic.tactic),
            ),
        },
        {
            id: PERFORMANCE_FILTER_LIST[6].key,
            title: PERFORMANCE_FILTER_LIST[6].title,
            key: PERFORMANCE_FILTER_LIST[6].key,
            type: PERFORMANCE_FILTER_LIST[6].type,
            items: campaigns ? campaignIdOptions.filter((o) => campaigns.split(",").includes(o.campaignName)) : [],
            open: !!campaigns,
            apiKey: PERFORMANCE_FILTER_LIST[6].apiKey,
        },
    ];
    return filterList;
};

export const getChannelName: { [key: string]: string } = {
    cpc: "CPC",
    cpm: "CPM",
    "true revenue": "True Revenue",
    revenue: "True Revenue",
    spend: "spend",
    impressions: "Impressions",
    clicks: "Clicks",
};

export const getDataSourceName = (programmaticName: string) => {
    return selectDataSourceNameByProgrammaticName(store.getState(), programmaticName);
};

export const calculateSum = (metricName: string, data: any) => {
    return +data
        .reduce(
            (previousValue: any, currentValue: { value: number; metricName: string }) =>
                currentValue.metricName === metricName ? previousValue + (currentValue.value || 0) : previousValue,
            0,
        )
        .toFixed(2);
};

export const calculateCPMCPCForChart = (
    data: any,
    source: string,
    performanceCampaignData: IMetricAttributionTableValuesTransformed,
) => {
    const sumOfValuesObj: { [key: string]: number } = { spend: 0, clicks: 0, impressions: 0, newCustomers: 0 };
    const channelName = getDataSourceName(source);

    sumOfValuesObj.spend = calculateSum("SPEND", data);
    sumOfValuesObj.clicks = calculateSum("CLICKS", data);
    sumOfValuesObj.impressions = calculateSum("IMPRESSIONS", data);
    sumOfValuesObj.revenue = calculateSum("REVENUE", data);
    sumOfValuesObj.channelReportedRevenue = calculateSum("CHANNEL_REPORTED_REVENUE", data);
    sumOfValuesObj.secondOrderSpend = performanceCampaignData?.spend ? performanceCampaignData.spend : 0;
    sumOfValuesObj.baseRoas = calculateSum("FIRST_ORDER_REVENUE", data);
    sumOfValuesObj.haloEffectRoas = calculateSum("SECOND_ORDER_REVENUE", data);
    sumOfValuesObj.newCustomers = calculateSum("NEW_CUSTOMERS", data);
    sumOfValuesObj.amazonSellingPartnerRevenue = calculateSum("AMAZON_SELLING_PARTNER_REVENUE", data);
    sumOfValuesObj.storeModeledRevenue = calculateSum("STORE_MODELED_REVENUE", data);

    sumOfValuesObj[`CPC - ${channelName}`] = calculateCPC(sumOfValuesObj.spend, sumOfValuesObj.clicks);
    sumOfValuesObj[`CPM - ${channelName}`] = calculateCPM(sumOfValuesObj.spend, sumOfValuesObj.impressions);
    sumOfValuesObj[`MMM ROAS - Prescient`] = calculateROAS(sumOfValuesObj.spend, sumOfValuesObj.revenue);
    sumOfValuesObj[`Channel ROAS - ${channelName}`] = calculateROAS(
        sumOfValuesObj.spend,
        sumOfValuesObj.channelReportedRevenue,
    );
    sumOfValuesObj[`MMM Base ROAS - Prescient`] = calculateROAS(
        sumOfValuesObj.secondOrderSpend,
        sumOfValuesObj.baseRoas,
    );
    sumOfValuesObj[`MMM Halo ROAS - Prescient`] = calculateROAS(
        sumOfValuesObj.secondOrderSpend,
        sumOfValuesObj.haloEffectRoas,
    );
    sumOfValuesObj[`MMM CAC - Prescient`] = calculateCAC(sumOfValuesObj.spend, sumOfValuesObj.newCustomers);

    return sumOfValuesObj;
};

export const getUpdatedChartDataForCustomChart = (
    apiData: any,
    customValue: string[],
    source: string,
    performanceCampaignData: IMetricAttributionTableValuesTransformed,
    connectors: IConnector[],
) => {
    const depedentMetrics = ["cpm", "cpc", "roas", "channelReportedRoas", "baseRoas", "haloEffectRoas", "cac"];
    let data: any[] = [];
    let legendValues: { [key: string]: number } = {};
    const grpByMetricName = groupBy(apiData, "metricName");
    const isSecondOrder = Object.keys(grpByMetricName).includes("SECOND_ORDER_REVENUE");

    if (isSecondOrder) {
        const grpByDate = groupBy(grpByMetricName.SECOND_ORDER_REVENUE, "date");
        const totalRevenueDateGRp = Object.entries(grpByDate).map(([key, value]) => {
            const totalRevenue = +value.reduce(
                (previousValue, currentValue) => previousValue + (currentValue.value || 0),
                0,
            );
            return {
                ...value[0],
                value: totalRevenue,
            };
        });
        grpByMetricName.SECOND_ORDER_REVENUE = totalRevenueDateGRp;
    }

    const metricNames = getMetricNames(connectors);
    customValue.map((customVal) => {
        if (depedentMetrics.includes(customVal)) {
            // default to only using connector data to create CPM and CPC
            const filteredData: any[] = Object.values(grpByMetricName)
                .flat(1)
                .filter((c: any) => c.connectorName === source);

            legendValues = {
                ...legendValues,
                ...calculateCPMCPCForChart(filteredData, source, performanceCampaignData),
            };
            const groupByDate = groupBy(filteredData, "date");

            Object.values(groupByDate).forEach((d) => {
                const spendData = find(d, (n) => n.metricName === "SPEND");
                const impressionsData = find(d, (n) => n.metricName === "IMPRESSIONS");
                const clicksData = find(d, (n) => n.metricName === "CLICKS");
                const revenueData = find(d, (n) => n.metricName === "REVENUE");
                const reportedRevenueData = find(d, (n) => n.metricName === "CHANNEL_REPORTED_REVENUE");
                const secondOrderSpend = find(d, (n) => n.metricName === "SECOND_ORDER_SPEND");
                const baseRevenue = find(d, (n) => n.metricName === "FIRST_ORDER_REVENUE");
                const haloEffectRevenue = find(d, (n) => n.metricName === "SECOND_ORDER_REVENUE");
                const newCustomers = find(d, (n) => n.metricName === "NEW_CUSTOMERS");

                if (["baseRoas", "haloEffectRoas"].includes(customVal) && spendData) {
                    data = [
                        ...data,
                        {
                            ...spendData,
                            metricName: customVal,
                            name: `${
                                customVal === "baseRoas"
                                    ? "MMM Base ROAS"
                                    : customVal === "haloEffectRoas"
                                    ? "MMM Halo ROAS"
                                    : ""
                            } - Prescient`,
                            value:
                                customVal === "baseRoas" && baseRevenue
                                    ? calculateROAS(spendData.value, baseRevenue.value)
                                    : customVal === "haloEffectRoas" && haloEffectRevenue
                                    ? calculateROAS(spendData.value, haloEffectRevenue.value)
                                    : 0,
                        },
                    ];
                } else if (!["baseRoas", "haloEffectRoas"].includes(customVal) && spendData) {
                    const calculateFun: IDictionary = {
                        cpc: calculateCPC(spendData.value, clicksData?.value),
                        cpm: calculateCPM(spendData.value, impressionsData?.value),
                        roas: calculateROAS(spendData.value, revenueData?.value),
                        channelReportedRoas: calculateROAS(spendData.value, reportedRevenueData?.value),
                        cac: calculateCAC(spendData.value, newCustomers?.value),
                    };

                    data = [
                        ...data,
                        {
                            ...spendData,
                            metricName: customVal,
                            name:
                                customVal === "cac"
                                    ? `MMM CAC - Prescient`
                                    : `${
                                          customVal === "roas"
                                              ? "MMM ROAS"
                                              : customVal === "channelReportedRoas"
                                              ? "Channel ROAS"
                                              : customVal.toUpperCase()
                                      } - ${customVal === "roas" ? "Prescient" : getDataSourceName(source)}`,
                            value: calculateFun[customVal] || 0,
                        },
                    ];
                }
            });
        } else {
            Object.entries(grpByMetricName).forEach(([key, value]: any) => {
                if (customValue.includes(key.toLowerCase())) {
                    value.forEach((c: any) => {
                        if (c) {
                            const formatMetricName = metricNames[key.toLowerCase()];
                            const legendValueKey = `${formatMetricName} - ${
                                c.modelDerived ? "Prescient" : getDataSourceName(c.connectorName)
                            }`;
                            legendValues[legendValueKey] = +apiData
                                .filter((d: any) => {
                                    return c.connectorName === d.connectorName;
                                })
                                .reduce(
                                    (previousValue: any, currentValue: { value: number; metricName: string }) =>
                                        currentValue.metricName.toLowerCase() === key.toLowerCase()
                                            ? previousValue + (currentValue.value || 0)
                                            : previousValue,
                                    0,
                                )
                                .toFixed(2);
                            data = [
                                ...data,
                                {
                                    ...c,
                                    name: legendValueKey,
                                },
                            ];
                        }
                    });
                }
            });
        }
    });
    return { data, legendValues };
};

export const getUpdatedChartDataForReturnChart = (
    apiData: any,
    returnValue: string | string[],
    source: string,
    connectors: IConnector[],
) => {
    let data: any[] = [];
    const legendValues: { [key: string]: number } = {};
    const metricNames = getMetricNames(connectors);
    apiData.forEach((c: { metricName: string; modelDerived: boolean; connectorName: string }) => {
        if (c) {
            const formatMetricName = metricNames[c.metricName.toLowerCase()];
            const legendValueKey = `${formatMetricName} - ${
                c.modelDerived ? "Prescient" : getDataSourceName(c.connectorName)
            }`;

            const apiMetricName = capitalizeFirstLetter(c.metricName.toLowerCase());
            legendValues[legendValueKey] = +apiData
                .filter((d: any) => {
                    return c.connectorName === d.connectorName;
                })
                .reduce(
                    (previousValue: any, currentValue: { value: number; metricName: string }) =>
                        currentValue.metricName.toLowerCase() === apiMetricName.toLowerCase()
                            ? previousValue + (currentValue.value || 0)
                            : previousValue,
                    0,
                );
            data = [
                ...data,
                {
                    ...c,
                    name: legendValueKey,
                },
            ];
        }
    });
    let finalRoasData = [];
    if (returnValue === MAP_VALUE_PARAMS.roasVsSpend) {
        const dateGRP = groupBy(apiData, "date");
        finalRoasData = Object.values(dateGRP)
            .map((d) => {
                const revenueData = find(d, (n) => n.metricName === "REVENUE");
                const spendData = find(d, (n) => n.metricName === "SPEND");
                if (spendData) {
                    const calculateRoas = calculateROAS(spendData.value, revenueData && revenueData.value);
                    return {
                        ...spendData,
                        metricName: "ROAS",
                        name: `MMM ROAS - Prescient`,
                        value: isNaN(calculateRoas) ? 0 : calculateRoas,
                        modelDerived: revenueData && revenueData.model_derived,
                        aggregationFunction: "AVERAGE",
                    };
                }
            })
            .filter((d) => d !== undefined);
    } else if (returnValue === MAP_VALUE_PARAMS.reportedRoasVsSpend) {
        const dateGRP = groupBy(apiData, "date");
        finalRoasData = Object.values(dateGRP)
            .map((d) => {
                const reportedRevenueData = find(d, (n) => n.metricName === "CHANNEL_REPORTED_REVENUE");
                const spendData = find(d, (n) => n.metricName === "SPEND");
                if (spendData) {
                    const calculateRoas = calculateROAS(
                        spendData.value,
                        reportedRevenueData && reportedRevenueData.value,
                    );
                    return {
                        ...spendData,
                        metricName: "ROAS",
                        name: `Channel ROAS - ${getDataSourceName(source)}`,
                        value: isNaN(calculateRoas) ? 0 : calculateRoas,
                        modelDerived: reportedRevenueData && reportedRevenueData.model_derived,
                        aggregationFunction: "AVERAGE",
                    };
                }
            })
            .filter((d) => d !== undefined);
    } else if (returnValue === MAP_VALUE_PARAMS.cacVsSpend) {
        const dateGRP = groupBy(apiData, "date");
        finalRoasData = Object.values(dateGRP)
            .map((d) => {
                const newCustomersData = find(d, (n) => n.metricName === "NEW_CUSTOMERS");
                const spendData = find(d, (n) => n.metricName === "SPEND");
                if (spendData) {
                    const calculateCac = calculateCAC(spendData.value, newCustomersData?.value);
                    return {
                        ...spendData,
                        metricName: "MMM CAC",
                        name: `MMM CAC - Prescient`,
                        value: isNaN(calculateCac) ? 0 : calculateCac,
                        modelDerived: newCustomersData && newCustomersData.model_derived,
                    };
                }
            })
            .filter((d) => d !== undefined);
    }
    data = [...data, ...finalRoasData];
    legendValues.spend = calculateSum("SPEND", apiData);
    legendValues.revenue = calculateSum("REVENUE", apiData);
    legendValues.channelReportedRevenue = calculateSum("CHANNEL_REPORTED_REVENUE", apiData);
    legendValues.newCustomers = calculateSum("NEW_CUSTOMERS", apiData);

    legendValues["MMM ROAS - Prescient"] = +(legendValues.revenue / legendValues.spend);
    legendValues[`Channel ROAS - ${getDataSourceName(source)}`] = +(
        legendValues.channelReportedRevenue / legendValues.spend
    );

    legendValues["MMM CAC - Prescient"] = calculateCAC(legendValues.spend, legendValues.newCustomers);

    return { data, legendValues };
};

export const removeNegativeTrueMetrics = (
    legendValues: {
        [key: string]: string | number;
    },
    currentData: any[],
    trueRevenue: number | undefined,
) => {
    Object.keys(legendValues).map((key) => {
        if (
            (key.toLowerCase().includes("Channel") || key.toLowerCase().includes("roas")) &&
            trueRevenue &&
            trueRevenue < 0
        ) {
            currentData = currentData.filter((d) => {
                const metricName = d.metricName.toLowerCase();
                return !["revenue", "roas"].includes(metricName);
            });
        }
    });

    return currentData;
};

export const getDefaultRange = (commnonDateArray: Range[]) => {
    const filterQueryParamsObj = queryString.parse(window.location.search);
    if (
        filterQueryParamsObj.start_date &&
        typeof filterQueryParamsObj.start_date === "string" &&
        filterQueryParamsObj.end_date &&
        typeof filterQueryParamsObj.end_date === "string"
    ) {
        const sd = toDate(filterQueryParamsObj.start_date);
        const ed = toDate(filterQueryParamsObj.end_date);
        return [
            {
                startDate: sd,
                endDate: ed,
                key: "selection",
            },
        ] as Range[];
    } else {
        return commnonDateArray as Range[];
    }
};

const metricName: IDictionary = {
    "mmm roas": "trueRoas",
    "channel roas": "channelReportedRoas",
    "mmm revenue": "trueRevenue",
    "channel revenue": "channelReportedRevenue",
    "mmm base revenue": "firstOrderRevenue",
    "mmm halo revenue": "secondOrderRevenue",
    "mmm base roas": "baseRoas",
    "mmm halo roas": "haloEffectRoas",
    "mmm new customers": "newCustomers",
    "mmm cac": "cac",
    "mmm amazon revenue": "amazon_selling_partner_revenue",
    "mmm store revenue": "store_modeled_revenue",
    "mmm shopify": "store_modeled_revenue",
    "mmm sfcc": "store_modeled_revenue",
    "mmm ga4": "store_modeled_revenue",
};

const emailMetricName: IDictionary = {
    "bounce rate": "bounceRate",
    "channel revenue": "channelReportedRevenue",
    sends: "emailSends",
    "total opens": "emailOpen",
    "unique opens": "emailOpenUnique",
    "total clicks": "emailClicks",
    "unique clicks": "emailClicksUnique",
    bounces: "emailBounces",
    unsubscribes: "emailUnsubscribes",
    "open rate": "openRate",
    "click rate": "clickRate",
    "unsubscribe rate": "unsubscribeRate",
};

export const getCurrentLegendNameForEmail = (currentLegend: string) => {
    return emailMetricName[currentLegend] || currentLegend;
};

export const getCurrentLegendName = (currentLegend: string) => {
    return metricName[currentLegend] || currentLegend;
};

const getParams = (customValue: string, isSpendIncludes: any) => {
    const spendURL = isSpendIncludes ? "" : "metric_names[]=spend&";
    const obj: { [key: string]: string } = {
        cpc: `${spendURL}metric_names[]=clicks`,
        cpm: `${spendURL}metric_names[]=impressions`,
        roas: `${spendURL}metric_names[]=revenue`,
        channelReportedRoas: `${spendURL}metric_names[]=channel_reported_revenue`,
        baseRoas: `metric_names[]=first_order_revenue`,
        haloEffectRoas: `metric_names[]=second_order_revenue`,
        cac: `${spendURL}metric_names[]=new_customers`,
    };
    return obj[customValue];
};

const getRateParams = (customValue: string) => {
    const obj: { [key: string]: string } = {
        open_rate: `metric_names[]=email_open`,
        click_rate: `metric_names[]=email_clicks`,
        bounce_rate: `metric_names[]=email_bounces`,
        unsubscribe_rate: `metric_names[]=email_unsubscribes`,
    };
    return `${obj[customValue]}`;
};

const getSpecialParams = (customValue: string) => {
    const obj: { [key: string]: string } = {
        aov: `metric_names[]=conversions`,
        rpc: `metric_names[]=email_clicks`,
        store_modeled_revenue: `metric_names[]=revenue`,
        amazon_selling_partner_revenue: `metric_names[]=revenue`,
    };
    return `${obj[customValue]}`;
};

export const getMetricParams = (customValue: string[]) => {
    const isSpendIncludes = customValue.find((c) => c === "spend");
    const spendDependMetrics = ["cpm", "cpc", "roas", "channelReportedRoas", "baseRoas", "haloEffectRoas", "cac"];
    const rateMetrics = ["open_rate", "click_rate", "bounce_rate", "unsubscribe_rate"];
    const specialMetrics = ["rpc", "aov"];
    const revenueMetric = ["store_modeled_revenue", "amazon_selling_partner_revenue"];
    return customValue
        .map((c) => {
            if (spendDependMetrics.includes(c)) {
                return `${getParams(c, isSpendIncludes || "")}`;
            } else if (rateMetrics.includes(c)) {
                return `${getRateParams(c || "")}&metric_names[]=email_sends`;
            } else if (specialMetrics.includes(c)) {
                return `${getSpecialParams(c || "")}&metric_names[]=channel_reported_revenue`;
            } else if (revenueMetric.includes(c)) {
                return `${getSpecialParams(c || "")}`;
            } else {
                return `metric_names[]=${c}`;
            }
        })
        .join("&");
};

export const getFilterChipList = (
    filterList: Array<{ items: any[]; key: string; title: any; chipCondition?: string | undefined }>,
    connectors: IConnector[],
) => {
    const queryParamsObj = queryString.parse(window.location.search);
    let finalChipData: any[] = [];
    const metricParam = getFormattedMetricQuery(queryParamsObj);
    const metricNames = getMetricNames(connectors);
    filterList.forEach((list: { items: any[]; key: string; title: any; chipCondition?: string }) => {
        if (location.search.includes(list.key) && !["campaign_id", "campaigns"].includes(list.key)) {
            finalChipData = [
                ...finalChipData,
                {
                    values: getFilterChipValues(
                        list,
                        list.key === PERFORMANCE_FILTER_LIST[0].key || list.key === PERFORMANCE_FILTER_LIST[5].key
                            ? false
                            : true,
                    ),
                    label: list.title,
                    condition: list.chipCondition,
                    key: list.key,
                },
            ];
        }
    });
    if (queryParamsObj[PERFORMANCE_FILTER_LIST[1].items[0].key]) {
        finalChipData = [
            ...finalChipData,
            {
                values: queryParamsObj[PERFORMANCE_FILTER_LIST[1].items[0].key],
                condition: PERFORMANCE_FILTER_LIST[1].items[0].condition,
                label: PERFORMANCE_FILTER_LIST[1].title,
                key: PERFORMANCE_FILTER_LIST[1].items[0].key,
            },
        ];
    }
    if (queryParamsObj[PERFORMANCE_FILTER_LIST[1].items[1].key]) {
        finalChipData = [
            ...finalChipData,
            {
                values: queryParamsObj[PERFORMANCE_FILTER_LIST[1].items[1].key],
                condition: PERFORMANCE_FILTER_LIST[1].items[1].condition,
                label: PERFORMANCE_FILTER_LIST[1].title,
                key: PERFORMANCE_FILTER_LIST[1].items[1].key,
            },
        ];
    }
    if (metricParam.length) {
        metricParam.forEach((m) => {
            if (m) {
                const a = {
                    values: m.value,
                    condition: CAMPARE_CONDITIONS[m.condition],
                    label: metricNames[m.metric],
                    key: `filter[${m.metric}][${m.condition}]`,
                    isArray: true,
                };
                finalChipData = [...finalChipData, a];
            }
        });
    }
    if (queryParamsObj.campaign_id && typeof queryParamsObj.campaign_id === "string") {
        finalChipData = [
            ...finalChipData,
            {
                values: queryParamsObj.campaign_id.replace(/,/g, ", "),
                condition: PERFORMANCE_FILTER_LIST[4].condition,
                label: PERFORMANCE_FILTER_LIST[4].title,
                key: PERFORMANCE_FILTER_LIST[4].key,
            },
        ];
    }
    if (queryParamsObj.campaigns && typeof queryParamsObj.campaigns === "string") {
        const campaigns = queryParamsObj.campaigns.split(",").map((campaign) => campaign.trim());
        const isMultipleCampaigns = campaigns.length > 1;
        const { title, condition, key } = PERFORMANCE_FILTER_LIST[6];
        const label = isMultipleCampaigns ? title : title.slice(0, -1);
        const conditionUpdate = isMultipleCampaigns ? "are" : condition;
        finalChipData = [
            ...finalChipData,
            {
                values: campaigns.join(", "),
                condition: conditionUpdate,
                label,
                key,
            },
        ];
    }

    return finalChipData.flat(1);
};

export const getPercentChangeInMetric = (currentValues: IDictionary, pastValues: IDictionary) => {
    const percentChange: IDictionary = {};

    Object.keys(currentValues).forEach((key) => {
        const currentLegend = key.split(" - ")[0].toLowerCase();
        const k = getCurrentLegendName(currentLegend);

        const per = ((currentValues[key] - pastValues[key]) / pastValues[key]) * 100;

        if (["trueRoas", "trueRevenue"].includes(k) && (currentValues[key] < 0 || pastValues[key] < 0)) {
            percentChange[key] = "-";
        } else {
            percentChange[key] = isFinite(per) ? per : 100;
        }
    });

    return percentChange;
};

export const getInitialColumnFilter = (
    filterQueryParamsObj: queryString.ParsedQuery<string>,
    connectors: IConnector[],
) => {
    if (filterQueryParamsObj.excluded_columns) {
        return `${filterQueryParamsObj.excluded_columns}`;
    }

    const performanceHeader: IHeadCell[] = [...PERFORMANCE_ATTRIBUTION_HEADERS];

    if (connectors.find((c) => c.service === AMAZON_SELLING_PARTNER)) {
        performanceHeader.splice(5, 0, ...getAmazonRevenueCols(connectors));
    }

    return performanceHeader
        .filter((item) => item.hidden)
        .map((p) => p.id)
        .join(",");
};

export const getPercentageFilteredData = (
    currentData: IMetricAttributionTableValuesTransformed[],
    pastData: IMetricAttributionTableValuesTransformed[],
    search: string,
    filterQueryParamsObj: any,
    withoutFilter: boolean,
    groupKey: "campaignId" | "connectorName" | "tactic",
) => {
    let finalData: IPerformancePercentageRows = {};
    let currentFilteredData: IMetricAttributionTableValuesTransformed[] = [];
    let pastFilteredData: IMetricAttributionTableValuesTransformed[] = [];
    let allConnectorsArr: any[] = [];

    if (currentData && pastData) {
        allConnectorsArr = [...new Set([...pastData, ...currentData].map((d) => d[groupKey]))];

        if (withoutFilter) {
            currentFilteredData = currentData;
            pastFilteredData = pastData;
        } else {
            currentFilteredData = getFilteredPeformance(currentData, search, filterQueryParamsObj);
            pastFilteredData = getFilteredPeformance(pastData, search, filterQueryParamsObj);
        }

        const allCampaignIds: Array<string | undefined | null> = [...currentFilteredData, ...pastFilteredData].map(
            (d) => d[groupKey],
        );

        const uniqueIds = [...new Set(allCampaignIds)];

        if (uniqueIds) {
            uniqueIds.map((key: string | undefined | null) => {
                if (key) {
                    const cd = currentFilteredData.find((c) => c[groupKey] === key);
                    const pd = pastFilteredData.find((c) => c[groupKey] === key);

                    const availableRow = cd || pd;
                    const isImpactChannel = availableRow?.connectorName === "impact";

                    const dummyObj = {
                        ...availableRow,
                        ...SAMPLE_IN_METRIC_ATTRIBUTION_TABLE,
                        impressions: isImpactChannel ? -1 : 0,
                        cpm: isImpactChannel ? -1 : 0,
                    };

                    // if key available in past data and not in current then 0 stored in current and vice versa
                    finalData = { ...finalData, [key]: { past: pd || dummyObj, current: cd || dummyObj } };
                }
            });
        }
    }

    return {
        finalData,
        currentFilteredData,
        pastFilteredData,
        allConnectorsArr,
    } as IPerformancePercentageTableData;
};

export const handleDeleteParamKey = (
    key: string | Array<string | null> | null,
    navigate: NavigateFunction,
    removeFilterEvent?: (filter: string) => void,
) => {
    const queryParamsObj = queryString.parse(location.search);

    if (key && typeof key === "string") {
        if (Object.keys(queryParamsObj).includes(key)) {
            const deleteParamValue = queryParamsObj[key];
            delete queryParamsObj[key];
            navigate(
                {
                    search: convertObjectToQueryParam(queryParamsObj),
                },
                { replace: true },
            );

            if (removeFilterEvent) {
                removeFilterEvent(`&${key}=${deleteParamValue}`);
            }
        }
    }
};

export const percentageCalculation = ({ currentRow, pastRow }: { currentRow: any; pastRow: any }) => {
    const copyRow = currentRow ? { ...currentRow } : { ...pastRow };

    const keys = Object.keys(copyRow);
    const excludeCellFromPercentage = [
        "campaignId",
        "campaignName",
        "connectorName",
        "insightAvailable",
        "channelName",
        "status",
        "hasSecondOrderEffect",
        "tactic",
        "tacticName",
    ];

    const negativeRevenueKeys = getNAColumnsForTable(copyRow.connectorName);

    keys.forEach((key) => {
        if (!excludeCellFromPercentage.includes(key)) {
            const currentValue = currentRow[key];
            const pastValue = pastRow[key];
            let newValue;

            if (negativeRevenueKeys.includes(key) && (currentValue < 0 || pastValue < 0)) {
                newValue = "-";
            } else {
                const percentage = ((currentValue - pastValue) / pastValue) * 100;
                newValue = isFinite(percentage) ? percentage : 100;
            }

            copyRow[key] = newValue;
        }
    });
    return copyRow;
};

export const filterGoogleAnalytics = (data: IPerformanceResponseMetric[]) => {
    return data.filter((c) => c.connector_name !== GOOGLE_ANALYTICS);
};

export const isTrendPostive = (value: number, isTrendUpPositive: boolean): boolean => {
    return (value > 0 && isTrendUpPositive) || (value < 0 && !isTrendUpPositive);
};

export const removeDateFilter = (filter: string) => {
    const queryParamsObj = queryString.parse(filter);

    delete queryParamsObj.start_date;
    delete queryParamsObj.end_date;
    delete queryParamsObj.comparison_start_date;
    delete queryParamsObj.comparison_end_date;
    delete queryParamsObj.search_text;

    return convertObjectToQueryParam(queryParamsObj) || "";
};

export const getLegendLabel = (title: string) => {
    const source = title.split(" - ")[1] ? ` - ${title.split(" - ")[1]}` : "";
    return `${title.split(" - ")[0]}${source}`;
};

const getOffsetTop = (element: any) => {
    let offsetTop = 0;
    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
};

export const fixedPerformanceHeader = () => {
    const appBar = document.getElementById("top-app-bar")!;
    const headerEle = document.getElementById("sticky-performance-header")!;
    const totalAmountElem = document.getElementById("sticky-total-amount")!;
    const performanceExtraInfo = document.getElementById("performance-info")!;

    const appBarHeight = appBar.clientHeight || 80;
    const tableEle = document.getElementById("performance-table");
    const tableTopPos = getOffsetTop(tableEle);
    const performanceInfoHeight = performanceExtraInfo?.clientHeight || 0;
    const calculatedTop = window.pageYOffset - tableTopPos + appBarHeight - performanceInfoHeight;

    if (headerEle) {
        const elements = totalAmountElem ? totalAmountElem.querySelectorAll("td") : [];

        if (window.pageYOffset + appBarHeight > tableTopPos + performanceInfoHeight) {
            const translate = "translate(0," + calculatedTop + "px)";
            headerEle.style.transform = translate;

            elements.forEach((elem) => {
                if (!elem.classList.contains("blocked-column")) {
                    elem.style.transform = translate;
                }
            });
        } else {
            headerEle.style.transform = "unset";

            elements.forEach((elem) => {
                if (!elem.classList.contains("blocked-column")) {
                    elem.style.transform = "unset";
                }
            });
        }
    }
};

export const getCSVPercentageTable = (
    finalPercentagePerformanceData: IPerformancePercentageTableData | IEmailPercentageTableData,
    order: Choice,
    orderBy: string,
    pastDateFilter: Range[],
    selectFormattedDates: Range[],
    activeTab: PerformanceTab = PerformanceTab.Campaigns,
) => {
    let finalPercentageTableData: any[] = [];

    const currentTotalAmount = getTotalAmountForPerformanceTable(finalPercentagePerformanceData.currentFilteredData);
    const pastTotalAmount = getTotalAmountForPerformanceTable(finalPercentagePerformanceData.pastFilteredData);
    const totalPercentageAmount = percentageCalculation({
        currentRow: currentTotalAmount,
        pastRow: pastTotalAmount,
    });

    stableSort(
        Object.values(finalPercentagePerformanceData.finalData).map((c: any) => c.current),
        getComparator(order, orderBy),
    ).forEach((currentRow: any, index: number) => {
        const pastRow = finalPercentagePerformanceData.finalData[currentRow[PerformanceTabKeyMapping[activeTab]]].past;
        finalPercentageTableData = [
            ...finalPercentageTableData,
            {
                ...percentageCalculation({
                    currentRow,
                    pastRow,
                }),
                isPercentage: true,
            },
            { ...pastRow, campaignName: getRangeFormattedTitle(pastDateFilter) },
            { ...currentRow, campaignName: getRangeFormattedTitle(selectFormattedDates) },
        ];
    });

    const total = [
        { ...totalPercentageAmount, isPercentage: true },
        { ...pastTotalAmount, campaignName: getRangeFormattedTitle(pastDateFilter) },
        { ...currentTotalAmount, campaignName: getRangeFormattedTitle(selectFormattedDates) },
    ];
    finalPercentageTableData = [...total, ...finalPercentageTableData];
    return finalPercentageTableData;
};

const calculateTotal = (array: any) => {
    return array.reduce(
        (previousValue: number, currentValue: { metricValue: number }) =>
            previousValue + (currentValue.metricValue || 0),
        0,
    );
};

export const getTotalKeyAttributionMetrics = (
    groupByMetricName: any,
    mappedObject: IDictionary,
    isFirst?: boolean,
    performanceCampaignRow?: IMetricAttributionTableValuesTransformed,
    isAmazon: boolean = false,
) => {
    let allCalculatedValues: any = {
        baseAmazonRevenue: 0,
        baseAmazonRoas: 0,
        haloAmazonRevenue: 0,
        haloAmazonRoas: 0,
        totalAmazonChannel: 0,
        baseRoas: 0,
        haloRoas: 0,
        totalROAS: 0,
        totalRevenue: 0,
        totalSpend: 0,
        totalFirstOrderRevenue: 0,
        totalSecondOrderRevenue: 0,
        totalDirectChannel: 0,
        totalOrganicChannel: 0,
        totalPaidChannel: 0,
        totalNewCustomers: 0,
        baseRevenue: 0,
        totalFirstOrderNewCustomers: 0,
        totalSecondOrderNewCustomers: 0,
        haloRevenue: 0,
    };
    const firstOrderRevenue = groupBy(groupByMetricName.FIRST_ORDER_REVENUE, "target");
    const secondOrderRevenue = groupBy(groupByMetricName.SECOND_ORDER_REVENUE, "target");

    Object.entries(groupByMetricName).map(([key, values]: any) => {
        allCalculatedValues = { ...allCalculatedValues, [mappedObject[key]]: calculateTotal(values) || 0 };
    });

    if (isFirst && performanceCampaignRow) {
        allCalculatedValues.totalSpend = performanceCampaignRow.spend;

        if (isAmazon) {
            allCalculatedValues.baseAmazonRevenue =
                firstOrderRevenue?.AMAZON_SELLING_PARTNER_REVENUE?.reduce((ac, m) => ac + m.metricValue, 0) || 0;
            allCalculatedValues.baseAmazonRoas = calculateROAS(
                performanceCampaignRow.spend,
                allCalculatedValues.baseAmazonRevenue,
            );

            allCalculatedValues.baseRevenue =
                allCalculatedValues.totalFirstOrderRevenue - allCalculatedValues.baseAmazonRevenue;
            allCalculatedValues.baseRoas = calculateROAS(performanceCampaignRow.spend, allCalculatedValues.baseRevenue);

            allCalculatedValues.haloAmazonRevenue =
                secondOrderRevenue.AMAZON_SELLING_PARTNER_REVENUE?.reduce((ac, m) => ac + m.metricValue, 0) || 0;
            allCalculatedValues.haloRevenue =
                allCalculatedValues.totalSecondOrderRevenue - allCalculatedValues.haloAmazonRevenue;
            allCalculatedValues.haloRoas = calculateROAS(performanceCampaignRow.spend, allCalculatedValues.haloRevenue);

            allCalculatedValues.haloAmazonRoas = calculateROAS(
                performanceCampaignRow.spend,
                allCalculatedValues.haloAmazonRevenue,
            );
        } else {
            allCalculatedValues.baseRevenue = allCalculatedValues.totalFirstOrderRevenue;
            allCalculatedValues.baseRoas = calculateROAS(
                performanceCampaignRow.spend,
                allCalculatedValues.totalFirstOrderRevenue,
            );

            allCalculatedValues.haloRevenue = allCalculatedValues.totalSecondOrderRevenue;
            allCalculatedValues.haloRoas = calculateROAS(
                performanceCampaignRow.spend,
                allCalculatedValues.totalSecondOrderRevenue,
            );
        }

        allCalculatedValues.baseCAC = calculateCAC(
            performanceCampaignRow.spend,
            allCalculatedValues.totalFirstOrderNewCustomers,
        );
        allCalculatedValues.haloCAC = calculateCAC(
            performanceCampaignRow.spend,
            allCalculatedValues.totalSecondOrderNewCustomers,
        );

        allCalculatedValues.totalRevenue =
            allCalculatedValues.totalFirstOrderRevenue + allCalculatedValues.totalSecondOrderRevenue;
        allCalculatedValues.totalROAS = calculateROAS(performanceCampaignRow.spend, allCalculatedValues.totalRevenue);

        allCalculatedValues.totalNewCustomers =
            allCalculatedValues.totalFirstOrderNewCustomers + allCalculatedValues.totalSecondOrderNewCustomers;

        allCalculatedValues.totalCAC = calculateCAC(
            performanceCampaignRow.spend,
            allCalculatedValues.totalNewCustomers,
        );
    } else {
        allCalculatedValues.totalHaloEffect =
            allCalculatedValues.totalDirectChannel +
                allCalculatedValues.totalOrganicChannel +
                allCalculatedValues.totalPaidChannel +
                allCalculatedValues.totalAmazonChannel || 0;
    }
    return allCalculatedValues;
};

export const handleHighLightChartSeries = (key: string, opacity: number, data: any, chart: any, option: any) => {
    const updateHoveredSeries = data.map((d: any) => {
        if (d.name === key) {
            return d;
        } else {
            return {
                ...d,
                lineStyle: {
                    opacity,
                },
                itemStyle: {
                    opacity,
                },
            };
        }
    });
    if (chart) {
        chart.setOption({
            ...option,
            series: updateHoveredSeries,
        });
    }
};

export const grpByLogic = (
    grpByPeriod: any,
    getStartDateOfGroup: any,
    selectedValue: string | string[],
    connectorName: string,
    getUpdatedDataForChart: (...args: any) => {
        data: any[];
        legendValues: {
            [key: string]: number;
        };
    },
    performanceCampaignData: IMetricAttributionTableValuesTransformed,
    connectors: IConnector[],
) => {
    const groupByMetric = Object.entries(grpByPeriod).map(([weekKey, value]: any) => {
        const metricNames = groupBy(value, "metricName");
        return Object.entries(metricNames).map(([key, value1]) => {
            const result = {
                ...value1[0],
                date: format(getStartDateOfGroup(toDate(value1[0].date)), "yyyy-MM-dd"),
                metricName: key,
                value: +value1.reduce((previousValue, currentValue) => previousValue + (currentValue.value || 0), 0),
            };
            return result;
        });
    });
    return getUpdatedDataForChart(groupByMetric.flat(1), selectedValue, connectorName || "", connectors);
};

export const getGroupByWeekData = (sortedValuesByDate: PartialIPerformanceMetric) => {
    return [...sortedValuesByDate].reduce((m: any, o: any) => {
        const obj = { ...o };
        obj.weekDate = format(startOfWeek(toDate(o.date)), "yyyy-MM-dd");
        m.push(obj);
        return m;
    }, []);
};

export const getGroupByMonthData = (sortedValuesByDate: PartialIPerformanceMetric) => {
    return groupBy(sortedValuesByDate, (item) => {
        return item.date?.substring(0, 7);
    });
};

export const getGroupByQuarterData = (sortedValuesByDate: PartialIPerformanceMetric) => {
    return groupBy(
        sortedValuesByDate.map((d) => ({
            ...d,
            quarter: `${getYear(toDate(d.date))}-${getQuarter(toDate(d.date))}`,
        })),
        (item) => {
            return item.quarter;
        },
    );
};

export const getGroupByYearData = (sortedValuesByDate: PartialIPerformanceMetric) => {
    return groupBy(sortedValuesByDate, (item) => {
        return getYear(toDate(item.date));
    });
};

export const getSpecificGrpByData = (
    grpBy: string,
    sortedValuesByDate: IPerformanceMetric[],
    selectedValue: string | string[],
    connectorName: string,
    data: {
        data: any[];
        legendValues: {
            [key: string]: number;
        };
    },
    getUpdatedDataForChart: (...args: any) => {
        data: any[];
        legendValues: {
            [key: string]: number;
        };
    },
    performanceCampaignData: IMetricAttributionTableValuesTransformed,
    connectors: IConnector[],
) => {
    let getDataWithLegend = { ...data };
    switch (grpBy) {
        case chartGrpKey.WEEK:
            const weeks = getGroupByWeekData(sortedValuesByDate);
            const grpByWeek = groupBy(weeks, "weekDate");
            getDataWithLegend = grpByLogic(
                grpByWeek,
                startOfWeek,
                selectedValue,
                connectorName,
                getUpdatedDataForChart,
                performanceCampaignData,
                connectors,
            );
            break;

        case chartGrpKey.MONTH:
            const groupedByMonth = getGroupByMonthData(sortedValuesByDate);
            getDataWithLegend = grpByLogic(
                groupedByMonth,
                startOfMonth,
                selectedValue,
                connectorName,
                getUpdatedDataForChart,
                performanceCampaignData,
                connectors,
            );
            break;

        case chartGrpKey.QUATER:
            const groupByQuarter = getGroupByQuarterData(sortedValuesByDate);
            getDataWithLegend = grpByLogic(
                groupByQuarter,
                startOfQuarter,
                selectedValue,
                connectorName,
                getUpdatedDataForChart,
                performanceCampaignData,
                connectors,
            );
            break;

        case chartGrpKey.YEAR:
            const groupedByYear = getGroupByYearData(sortedValuesByDate);
            getDataWithLegend = grpByLogic(
                groupedByYear,
                startOfYear,
                selectedValue,
                connectorName,
                getUpdatedDataForChart,
                performanceCampaignData,
                connectors,
            );
            break;

        default:
            break;
    }

    return getDataWithLegend as {
        data: any[];
        legendValues: {
            [key: string]: number;
        };
    };
};

export const getMetricNameForLegend = (currentLegend: string, chartId: string) => {
    if (chartId.includes("email_metric_chart")) {
        return getCurrentLegendNameForEmail(currentLegend);
    }
    return getCurrentLegendName(currentLegend);
};

const grpFun: IDictionary = {
    week: startOfWeek,
    month: startOfMonth,
    quarter: startOfQuarter,
    year: startOfYear,
};

const grpEndFun: IDictionary = {
    week: endOfWeek,
    month: endOfMonth,
    quarter: endOfQuarter,
    year: endOfYear,
};

const getPartialText = (
    text: string,
) => `<li style="list-style:none;display:flex;justify-content:space-between;font-size:12px;margin-bottom:5px">Partial date range -
${text}</li>`;

export const getMetricChartTooltip = (params: any, grpBy: string, metrics: any, id: string, dateRange?: Range) => {
    const uniqueParams: any[] = getUniqueListBy(params, "seriesName");
    const chartdate = getTooltipDateFormat(uniqueParams[0] || "", grpBy);

    let isPartialStaringDate = "";
    let isPartialEndDate = "";

    if (grpBy !== "day") {
        const currentStartDate = toDate(uniqueParams[0].value[0]);
        const currentEndDate = startOfDay(toDate(grpEndFun[grpBy](currentStartDate)));
        if (dateRange?.startDate && currentStartDate && toDate(currentStartDate) < toDate(dateRange.startDate)) {
            isPartialStaringDate = getPartialText(
                `${format(toDate(dateRange.startDate), "MMM dd, yyyy")} to ${format(
                    grpEndFun[grpBy](toDate(uniqueParams[0].value[0])),
                    "MMM dd, yyyy",
                )}`,
            );
        }

        if (dateRange?.endDate && currentEndDate && toDate(currentEndDate) > toDate(dateRange.endDate)) {
            isPartialEndDate = getPartialText(
                `${format(grpFun[grpBy](toDate(uniqueParams[0].value[0])), "MMM dd, yyyy")} to ${format(
                    toDate(dateRange.endDate),
                    "MMM dd, yyyy",
                )}`,
            );
        }
    }

    const tooltipData = uniqueParams
        .map((param: any) => {
            const currentLegend = param.seriesName.split(" - ")[0].toLowerCase();
            const k = getMetricNameForLegend(currentLegend, id);
            const specificSelectedMetric = metrics.filter((d: any) => d.id === k)[0];
            const value = param.value[1];
            return (
                '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block">' +
                param.marker +
                getLegendLabel(param.seriesName) +
                "</span><span style='margin-left:20px'><b>" +
                (value === undefined
                    ? "No data"
                    : formatValue(
                          value,
                          specificSelectedMetric ? specificSelectedMetric.sign || FORMATS.NUMERIC : FORMATS.NUMERIC,
                          specificSelectedMetric ? specificSelectedMetric.fixed || 0 : 0,
                      )) +
                "<span></b></li>"
            );
        })
        .join("");
    return chartdate + (isPartialStaringDate || isPartialEndDate) + tooltipData;
};

export const getNAColumnsForTable = (connectorName?: string) => {
    let negativeCols = [
        "trueRevenue",
        "channelReportedRevenue",
        "trueRoas",
        "channelReportedRoas",
        "cac",
        "newCustomers",
        "amazonSellingPartnerRevenue",
        "storeModeledRevenue",
        "baseRevenue",
        "haloEffectRevenue",
    ];
    if (connectorName && connectorName === "impact") {
        negativeCols = [...negativeCols, "impressions", "cpm"];
    }
    return negativeCols;
};

export const getRevenueWithAmazonAndStore = (data: IPerformanceMetric[]) => {
    let amazonRevenueData: IPerformanceMetric[] = [];
    let storeRevenueData: IPerformanceMetric[] = [];

    data.forEach((c) => {
        if (c.metricName === "REVENUE" && c.target) {
            if (c.target === AMAZON_SELLING_PARTNER_REVENUE.toUpperCase()) {
                amazonRevenueData = [
                    ...amazonRevenueData,
                    { ...c, metricName: AMAZON_SELLING_PARTNER_REVENUE.toUpperCase() },
                ];
            } else {
                storeRevenueData = [...storeRevenueData, { ...c, metricName: "STORE_MODELED_REVENUE" }];
            }
        }
    });
    return [...data, ...amazonRevenueData, ...storeRevenueData];
};

export const getRevenueTotalData = (data: IPerformanceMetric[]) => {
    const excludeRevenueData = data.filter((c) => c.metricName !== "REVENUE");
    const revenueData = data.filter((c) => c.metricName === "REVENUE");
    const groupByDate = groupBy(revenueData, "date");

    const totalRevenueData = Object.values(groupByDate).map((c) => {
        const revenueData = find(c, (n) => n.metricName === "REVENUE");
        return { ...revenueData, metricName: "REVENUE", value: c.reduce((a, d) => a + d.value, 0), target: "" };
    });
    return [...excludeRevenueData, ...totalRevenueData] as IPerformanceMetric[];
};

export const groupChannelData = (data: any[]) => {
    return Object.entries(groupBy(data, "connectorName")).map(([channel, campaigns]) => {
        const totalAmountForChannels = getTotalAmountForPerformanceTable(campaigns || []);
        return {
            channelName: campaigns[0].channelName,
            connectorName: channel,
            campaigns,
            ...totalAmountForChannels,
        };
    });
};

export const groupChannelTacticData = (data: any[]) => {
    const tacticsGroup = groupBy(data, "tacticName");
    return Object.keys(tacticsGroup).map((tacticKey) => {
        const campaigns = tacticsGroup[tacticKey];
        const totalAmountForChannels = getTotalAmountForPerformanceTable(campaigns || []);
        return {
            tacticName: tacticKey,
            tactic: campaigns[0].tactic,
            connectorName: campaigns[0].connectorName,
            campaigns,
            ...totalAmountForChannels,
        };
    });
};

export const getGroupDataBasedOnActiveTab = (data: any[], activeTab: PerformanceTab) => {
    if (activeTab === PerformanceTab.Channels) {
        return setNABasedOnConnector(groupChannelData(data));
    }
    if (activeTab === PerformanceTab.Tactics) {
        return groupChannelTacticData(data);
    }
    return data;
};

export const setNABasedOnConnector = (channelData: any[]) => {
    return channelData.map((data) => {
        if (data.connectorName === AMAZON_ADS) {
            const isNewCustNA = data.campaigns.every((c: { newCustomers: number }) =>
                [-1, 0, null].includes(c.newCustomers),
            );

            return {
                ...data,
                newCustomers: isNewCustNA ? -1 : data.newCustomers,
                cac: isNewCustNA ? -1 : data.newCustomers,
            };
        }
        if (data.connectorName === "impact" && (!data.impressions || !data.cpm)) {
            return { ...data, impressions: -1, cpm: -1 };
        }
        return data;
    });
};

export const getPercentageGroupDataBasedOnActiveTab = (
    activeTab: PerformanceTab,
    currentData: IMetricAttributionTableValuesTransformed[],
    pastData: IMetricAttributionTableValuesTransformed[],
    search: string,
    filterQueryParamsObj: queryString.ParsedQuery<string>,
) => {
    const isGroupedTab = activeTab === PerformanceTab.Channels || activeTab === PerformanceTab.Tactics;
    const groupCurrentData = isGroupedTab ? getGroupDataBasedOnActiveTab(currentData, activeTab) : currentData;
    const groupPastData = isGroupedTab ? getGroupDataBasedOnActiveTab(pastData, activeTab) : pastData;

    return getPercentageFilteredData(
        groupCurrentData,
        groupPastData,
        search,
        filterQueryParamsObj,
        false,
        PerformanceTabKeyMapping[activeTab] || PerformanceTabKeyMapping[PerformanceTab.Campaigns],
    );
};

const getExcludedChannels = (
    performanceData: IMetricAttributionTableValuesTransformed[],
    connectorName: string = "",
) => {
    return performanceData
        .filter((c) => c.connectorName !== connectorName)
        .map((c) => c.connectorName)
        .join(",");
};

const getTacticFilterParam = (allTacticsStringArr: string[], currentTactic: string = "") => {
    return currentTactic === NOT_SET_TACTIC.tactic
        ? allTacticsStringArr.join(",")
        : allTacticsStringArr.filter((t) => t !== currentTactic).join(",") + `,${NOT_SET_TACTIC.tactic}`;
};

export const setChannelsRedirectFilterForCampaign = (
    performanceData: IMetricAttributionTableValuesTransformed[],
    row: IMetricAttributionTableValuesTransformed,
    navigate: NavigateFunction,
    orgCode: string,
) => {
    const excludedChannels = getExcludedChannels(performanceData, row.connectorName);
    const searchParams = excludedChannels ? `&excluded_channels=${excludedChannels}` : "";
    navigate({
        pathname: `/org/${orgCode}${PERFORMANCE_CAMPAIGNS_PATH}`,
        search: `${location.search}${searchParams}`,
    });
};

export const setTacticsRedirectFilterForCampaign = (
    performanceData: IMetricAttributionTableValuesTransformed[],
    row: IMetricAttributionTableValuesTransformed,
    connectorsRef: IConnector[],
    navigate: NavigateFunction,
    allTacticsStringArr: string[],
    selectFormattedDates: Range[],
    orgCode: string,
    setSelectedTableColumns: React.Dispatch<React.SetStateAction<IHeadCell[]>>,
) => {
    const currentTactic = row.tactic || row.campaigns?.[0].tactic;
    const tacticFilterParam = getTacticFilterParam(allTacticsStringArr, currentTactic);
    const { startDate, endDate } = getDefaultRange(selectFormattedDates)[0];

    if (startDate && endDate) {
        const formattedStartDate = format(startDate, "yyyy-MM-dd");
        const formattedEndDate = format(endDate, "yyyy-MM-dd");

        const filterQueryParamsObj = queryString.parse(window.location.search);
        const updateDateFilter = {
            ...filterQueryParamsObj,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            excluded_columns: getInitialColumnFilter(filterQueryParamsObj, connectorsRef).replace(",tacticName", ""),
            excluded_tactics: tacticFilterParam,
        };
        setSelectedTableColumns((columns) =>
            columns.map((column) => (column.id === "tacticName" ? { ...column, hidden: false } : column)),
        );
        navigate({
            pathname: `/org/${orgCode}${PERFORMANCE_CAMPAIGNS_PATH}`,
            search: convertObjectToQueryParam(updateDateFilter),
        });
    }
};
