import React from "react";
import { FormControl, FormHelperText, IconButton, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { Flex } from "../core/Flex/Flex";
import { PERFORMANCE_FILTER_LIST } from "../../consts/performancePaidPage/performancePaidPage";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";

interface IFieldItem {
    conditionType: string;
    conditionValue: string;
}

interface IFieldItems {
    conditionInputs: IFieldItem[];
}

interface IDynamicInputs {
    handleFilter: (paramKey: string, item: { id: string }) => void;
    initialValues: { conditionInputs: Array<{ conditionType: string; conditionValue: string }> };
    classes: any;
}

export const DynamicInputs: React.FC<IDynamicInputs> = ({ handleFilter, initialValues, classes }) => {
    const theme = useTheme();
    return (
        <div>
            <Formik initialValues={initialValues} validateOnChange={false} onSubmit={() => {}}>
                {({ values, handleChange }: FormikProps<IFieldItems>) => {
                    const isContainsDuplicateValues = (index: number, conditionInput: IFieldItem) => {
                        const { conditionType, conditionValue } = conditionInput;
                        const copyInputList = [...values.conditionInputs];
                        copyInputList.splice(index, 1);
                        const inputListString = copyInputList.map((list) =>
                            (list.conditionType + list.conditionValue).toLowerCase(),
                        );
                        const isDuplicate = inputListString.includes((conditionType + conditionValue).toLowerCase());
                        return isDuplicate;
                    };

                    return (
                        <Form>
                            <FieldArray
                                name="conditionInputs"
                                render={({ remove, push }) => {
                                    const { conditionInputs } = values;
                                    let timeout: ReturnType<typeof setTimeout>;

                                    const addEntry = (conditionInput: IFieldItem) => {
                                        clearTimeout(timeout);
                                        push({
                                            conditionType: "contains",
                                            conditionValue: "",
                                        });
                                        handleFilter(conditionInput.conditionType, {
                                            id: conditionInput.conditionValue.toLowerCase(),
                                        });
                                    };

                                    const removeEntry = (conditionInput: IFieldItem, index: number) => {
                                        if (conditionInput.conditionType && conditionInput.conditionValue) {
                                            handleFilter(conditionInput.conditionType, {
                                                id: conditionInput.conditionValue.toLowerCase(),
                                            });
                                        }
                                        remove(index);
                                    };

                                    const setAutoFocus = (index: number) => {
                                        timeout = setTimeout(() => {
                                            if (document.activeElement) {
                                                (document.activeElement as HTMLElement).blur();
                                                document
                                                    .getElementById(`conditionInputs.${index}.conditionValue`)
                                                    ?.focus();
                                            }
                                        }, 0);
                                    };

                                    return (
                                        <div className={classes.inputWrapper}>
                                            {conditionInputs && conditionInputs.length > 0 ? (
                                                conditionInputs.map((conditionInput, index) => {
                                                    const isLastEntry = conditionInputs.length - 1 === index;
                                                    const isDuplicateEntryErr =
                                                        index !== 0 && isContainsDuplicateValues(index, conditionInput);

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Flex spacing="10px">
                                                                <FormControl sx={{ width: "30%" }}>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        placeholder="Condition"
                                                                        value={conditionInput.conditionType}
                                                                        name={`conditionInputs.${index}.conditionType`}
                                                                        onChange={handleChange}
                                                                        disabled={!isLastEntry}
                                                                        onClose={() => setAutoFocus(index)}
                                                                        autoFocus
                                                                    >
                                                                        {PERFORMANCE_FILTER_LIST[1].items.map(
                                                                            (option: {
                                                                                key: string;
                                                                                title: string;
                                                                            }) => (
                                                                                <MenuItem
                                                                                    key={option.key}
                                                                                    value={option.key}
                                                                                >
                                                                                    {option.title}
                                                                                </MenuItem>
                                                                            ),
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                                <TextField
                                                                    id={`conditionInputs.${index}.conditionValue`}
                                                                    placeholder="Enter value"
                                                                    variant="outlined"
                                                                    sx={{ width: "60%" }}
                                                                    name={`conditionInputs.${index}.conditionValue`}
                                                                    value={conditionInput.conditionValue}
                                                                    onChange={handleChange}
                                                                    onKeyDown={(e) => {
                                                                        if (
                                                                            e.key === "Enter" &&
                                                                            conditionInput.conditionType &&
                                                                            conditionInput.conditionValue &&
                                                                            !isDuplicateEntryErr
                                                                        ) {
                                                                            addEntry(conditionInput);
                                                                        }
                                                                    }}
                                                                    disabled={!isLastEntry}
                                                                />
                                                                <div
                                                                    className="btn-box"
                                                                    style={{ width: "10%", display: "flex" }}
                                                                >
                                                                    {isLastEntry && (
                                                                        <IconButton
                                                                            onClick={() => addEntry(conditionInput)}
                                                                            disabled={
                                                                                !conditionInput.conditionType ||
                                                                                !conditionInput.conditionValue ||
                                                                                isDuplicateEntryErr
                                                                            }
                                                                        >
                                                                            <SVGIconRenderer
                                                                                icon="plusIcon"
                                                                                strokeColor="rgba(0, 0, 0, 0.54)"
                                                                            />
                                                                        </IconButton>
                                                                    )}
                                                                    {!isLastEntry && (
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                removeEntry(conditionInput, index)
                                                                            }
                                                                        >
                                                                            <SVGIconRenderer
                                                                                icon="closeIcon"
                                                                                strokeColor="rgba(0, 0, 0, 0.54)"
                                                                            />
                                                                        </IconButton>
                                                                    )}
                                                                </div>
                                                            </Flex>
                                                            {isDuplicateEntryErr && (
                                                                <FormHelperText
                                                                    style={{
                                                                        color: theme.palette.error.main,
                                                                        margin: "0px 5px 5px",
                                                                    }}
                                                                >
                                                                    Please remove duplicate entry.
                                                                </FormHelperText>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })
                                            ) : (
                                                <IconButton
                                                    onClick={() =>
                                                        push({ conditionType: "", conditionValue: "", error: false })
                                                    }
                                                >
                                                    <SVGIconRenderer
                                                        icon="plusIcon"
                                                        strokeColor="rgba(0, 0, 0, 0.54)"
                                                    />
                                                </IconButton>
                                            )}
                                        </div>
                                    );
                                }}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
