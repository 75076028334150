import React from "react";
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

import { useStylesForHeaderInTable } from "../../../consts/StylesForTables";
import { Choice, ICells, OPTIMIZATION_PAGE_TABLE_HEADERS } from "../../../consts/optimizationPage/optimizationPage";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { initialSortDirection } from "src/services/utils";

interface IEnhancedHeaderProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ICells) => void;
    choice: Choice;
    choiceBy: string;
}

export const OptimizationTableHeader = (props: IEnhancedHeaderProps) => {
    const { onRequestSort, choice, choiceBy } = props;
    const classes = useStylesForHeaderInTable();
    const updatedHeader = [...OPTIMIZATION_PAGE_TABLE_HEADERS];
    const createSortHandler = (property: keyof ICells) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow className={classes.headerRow}>
                {updatedHeader.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={["name", "createdName", "scenarioType"].includes(headCell.id) ? "left" : "right"}
                        sortDirection={choiceBy === headCell.id ? choice : false}
                        className={classes.tableCell}
                    >
                        <TableSortLabel
                            active={choiceBy === headCell.id}
                            direction={initialSortDirection(choiceBy, headCell.id, choice)}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {choiceBy === headCell.id ? (
                                <Box component="span" style={{ display: "none" }}>
                                    {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                        {headCell.id === "isScenarioEditable" && (
                            <Tooltip
                                arrow
                                title="We automatically track the results of this scenario and compare actuals daily during the flight of the scenario"
                            >
                                <span>
                                    <SVGIconRenderer
                                        icon="infoIcon"
                                        width="15px"
                                        height="15px"
                                        style={{ margin: "0 0 -4px 2px" }}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
