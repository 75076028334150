import React, { useState } from "react";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

interface ISelectValue {
    label: string;
    value: string;
}

interface IDropDownProps {
    value: any;
    onChange?: any;
    label?: string;
    options: ISelectValue[];
    helperText?: string[];
    disabled?: boolean;
    inlineLabel?: boolean;
    className?: string;
    width?: string;
    border?: boolean;
    name?: string;
    error?: boolean;
}

export const DropDown: React.FC<IDropDownProps> = ({
    options,
    label,
    helperText,
    error,
    disabled = false,
    value,
    onChange,
    name,
    ...rest
}) => {
    const [state, setState] = useState<{ value: unknown }>({
        value: value || "",
    });

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setState({
            value: event.target.value,
        });
        onChange && onChange(event);
    };

    return (
        <FormControl variant="outlined" disabled={disabled} error={error} fullWidth>
            {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
            <Select native label={label} value={state.value} name={name} onChange={() => handleChange} {...rest}>
                {options.map((item) => (
                    <option value={item.value} key={item.value}>
                        {item.label}
                    </option>
                ))}
            </Select>
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </FormControl>
    );
};
