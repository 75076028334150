import { FC } from "react";
import {
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    FormControl,
    Select,
    Tooltip,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import { AggregateScenarioCard } from "../../../AggregateScenarioCard";
import { ISummary, TrackingViews } from "src/interfaces/entities/IScenario";
import { IDictionary } from "src/interfaces/IDictionary";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";

interface IITrackingSummaryProps {
    tableHeading: IDictionary<
        Array<{
            label: string;
            id: string;
        }>
    >;
    summaryArray: ISummary[];
    isLoading: boolean;
    classes: any;
    compareTo: TrackingViews;
    setCompareTo: React.Dispatch<React.SetStateAction<TrackingViews>>;
    compareToOption: Array<{
        lable: string;
        value: TrackingViews;
        isHidden?: boolean;
    }>;
    isCacScenario: boolean;
}

export const TrackingSummary: FC<IITrackingSummaryProps> = ({
    summaryArray,
    isLoading,
    classes,
    tableHeading,
    compareToOption,
    compareTo,
    setCompareTo,
    isCacScenario,
}) => {
    const handleCompareToChange = (event: SelectChangeEvent<any>) => {
        const { value } = event.target;
        setCompareTo(value);
    };

    const CompareToSelect = () => {
        return (
            <Stack direction="row" alignItems="center" justifyContent="end">
                <span>Optimal (</span>
                <FormControl component="span" variant="standard" className={classes.compareToStyle}>
                    <Select labelId="select-type-label" onChange={handleCompareToChange} value={compareTo} size="small">
                        {compareToOption.map(
                            (item) =>
                                !item.isHidden && (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.lable}
                                    </MenuItem>
                                ),
                        )}
                    </Select>
                </FormControl>
                <span>)</span>
                {compareTo === TrackingViews.InFlight && (
                    <Tooltip
                        arrow
                        title={`The Optimal (In-Flight) option compares your MMM Revenue earned thus far in the forecast timeframe versus the comparable optimal revenue for the same timeframe.`}
                    >
                        <span>
                            <SVGIconRenderer
                                icon="infoIcon"
                                height="16px"
                                width="16px"
                                style={{ margin: "5px 0 0 5px" }}
                            />
                        </span>
                    </Tooltip>
                )}
            </Stack>
        );
    };

    return (
        <Stack
            direction="row"
            flexWrap="wrap"
            gap={{ sm: "30px", md: "30px", lg: "50px" }}
            className={classes.recommandationStyle}
        >
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {tableHeading.tracking.map((st) => {
                                return (
                                    <TableCell
                                        key={st.id}
                                        align={st.id === "label" ? "left" : "right"}
                                        className={classes.projectionsTableHeader}
                                    >
                                        {st.id === "forecasted" ? <CompareToSelect /> : st.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <AggregateScenarioCard
                            isTableLoading={isLoading}
                            summaryArray={summaryArray}
                            classes={classes}
                            isCacScenario={isCacScenario}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};
