import { FC, useRef } from "react";
import { Stack } from "@mui/system";
import { Button, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import CommonStepper from "../CustomStepper/CustomStepper";
import { CONNECTOR_CREATION_STEPS } from "src/consts/connectorsPage/connectorsPage";
import { IConnector } from "src/interfaces/IConnector";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import {
    areAllRequiredConnectorCategoriesConnected,
    getCompletedSteps,
    getInCompletedSteps,
    getInCompletedStepsText,
    isIncompleteConnectorExist,
} from "src/services/onboardingPage/onboardingPage";
import { OnboardingTooltip, Placements } from "../OnboardingTooltip/OnboardingTooltip";

interface IConnectorStepper {
    activeStep: number;
    backBtnDisabled?: boolean;
    continueBtnDisabled?: boolean;
    handleNext: (isFinish?: boolean) => void;
    handleBack: () => void;
    handleStep: (step: number) => void;
}

export const ConnectorStepper: FC<IConnectorStepper> = ({
    activeStep,
    backBtnDisabled,
    continueBtnDisabled = false,
    handleNext,
    handleBack,
    handleStep,
}) => {
    const connectors: IConnector[] = useSelector(connectorsSelector);
    const isFirstStep = activeStep === 0;
    const isLastStep = activeStep === CONNECTOR_CREATION_STEPS.length - 1;
    const completedSteps = getCompletedSteps(connectors);
    const incompletedSteps = getInCompletedSteps(connectors);
    const isAllGrpConnected = areAllRequiredConnectorCategoriesConnected(connectors);
    const analyticsService = useAnalyticsService();

    // Check if all required connectors are connected from each group
    const isAllGrpCompleted = isAllGrpConnected && !isIncompleteConnectorExist(connectors);

    const handleSubmitBtnClick = () => {
        if (isLastStep && isAllGrpCompleted) {
            analyticsService.logEvent("Connectors Flow Finish Clicked", {});
            handleNext(true);
        } else {
            handleNext();
        }
    };

    const firstStepBtnRef = useRef<any>(null);
    const lastStepBtnRef = useRef<any>(null);

    const nextButtonDisabled = isLastStep ? !isAllGrpCompleted : continueBtnDisabled;
    const getNextButtonElement = (target?: any) => {
        return (
            <Button ref={target} variant="contained" color="secondary" disabled={nextButtonDisabled} onClick={handleSubmitBtnClick}>
                {isLastStep ? "Finish" : "Continue"}
            </Button>
        );
    };

    const stepClickHandler = (clickedStep: number) => {
        handleStep(clickedStep);
    };

    return (
        <Stack
            sx={{ textAlign: "left", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", width: "100%" }}
            direction="row"
            padding="0px 8px 16px 8px"
            gap={12}
        >
            <CommonStepper
                steps={CONNECTOR_CREATION_STEPS}
                activeStep={activeStep}
                completedSteps={completedSteps}
                orientation="horizontal"
                nonLinear={true}
                isStepClickDisabled={isFirstStep && !isAllGrpConnected}
                handleStep={stepClickHandler}
                style={{
                    gap: "8px",
                    " .MuiStep-root": {
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    },
                    " .MuiStepConnector-root": {
                        flex: "1 0 0",
                    },
                    " .MuiStepButton-root": {
                        textAlign: "left",
                    },
                }}
            />
            <Stack gap={2} direction="row" alignItems="flex-start" justifyContent="end" sx={{ minWidth: "176px" }}>
                <Button disabled={backBtnDisabled} onClick={() => handleBack()}>
                    Back
                </Button>
                {isFirstStep && !isAllGrpConnected ? (
                    <Stack position="relative">
                        {getNextButtonElement(firstStepBtnRef)}
                        <OnboardingTooltip
                            cards={[
                                {
                                    title: "",
                                    description: (
                                        <Stack gap={2} component="span">
                                            <Typography component="span">
                                                All of your data sources must be added in this step
                                            </Typography>
                                            <Divider component="span" />
                                            <Stack component="span" gap={1.5}>
                                                <Typography component="span">
                                                    All Revenue source data (such as Shopify and/or Amazon)
                                                </Typography>
                                                <Typography component="span">
                                                    All of your advertising channels (such as Facebook, Google, etc)
                                                </Typography>
                                                <Typography component="span">
                                                    Your analytics source (such as GA4)
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    ),
                                },
                            ]}
                            placement={Placements.BELOW}
                            targets={[firstStepBtnRef]}
                        />
                    </Stack>
                ) : isLastStep && !isAllGrpCompleted ? (
                    <Stack position="relative">
                        {getNextButtonElement(lastStepBtnRef)}
                        <OnboardingTooltip
                            cards={[
                                {
                                    title: "",
                                    description: `Complete step${
                                        incompletedSteps.length > 1 ? "s" : ""
                                    } ${getInCompletedStepsText(incompletedSteps)} before you can finish Onboarding.`,
                                },
                            ]}
                            placement={Placements.BELOW}
                            targets={[lastStepBtnRef]}
                        />
                    </Stack>
                ) : (
                    getNextButtonElement()
                )}
            </Stack>
        </Stack>
    );
};
