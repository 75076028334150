import cloneDeep from "lodash/cloneDeep";
import camelCase from "lodash/camelCase";
import { differenceInDays, endOfWeek, format, getQuarter, getYear, parseISO, startOfWeek } from "date-fns";
import queryString from "query-string";
import { NavigateFunction } from "react-router";
import { toDate } from "date-fns-tz";

import { IDataSource } from "../interfaces/IData";
import { FORMATS } from "../enums/Formats";
import { CONNECTORS_CHIPS_VALUE } from "../consts/connectorsPage/connectorsPage";
import { IMetricAttributionTableValuesTransformed } from "../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { IPerformanceDetailsResponseValuesTransformed } from "../interfaces/performanceDetails/IPerformanceDetailsResponse";
import { IDictionary } from "../interfaces/IDictionary";
import { PerformanceTab, chartGrpKey } from "../consts/performancePaidPage/performancePaidPage";
import { PERFORMANCE_CAMPAIGNS_PATH, PERFORMANCE_TACTICS_PATH } from "src/consts/path/path";
import { AMAZON_ADS } from "src/consts/connectors";

export enum ATTRIBUTION_FUNNEL_TYPES {
    OVERALL = "overall",
    DATA_SOURCE = "source",
    TAG = "tag",
    CAMPAIGN = "campaign",
}

export const buildConnectionCardUrl = (uri: string, type?: string, path?: string) => {
    let currentUrl = path;
    if (!currentUrl) {
        currentUrl = window.location.origin;
        currentUrl += window.location.pathname;
    }
    if (type) {
        currentUrl += `?type=${type}`;
    }
    return `${uri}${currentUrl}`;
};

export const filterDataSources = (
    dataSources: IDataSource[],
    search: string,
    filters: number[],
    allConnectors: IDataSource[],
) => {
    const filteredCategories = filters.map((item: number) => CONNECTORS_CHIPS_VALUE[item].label);

    const availableDataSources = filteredCategories
        .map((category: string) => {
            return allConnectors
                .filter((item) => item.categories.some((c) => c === category))
                .map((item) => item?.programmaticName);
        })
        .reduce((acc, val) => acc.concat(val), []);

    dataSources =
        filteredCategories.length > 0 && !filteredCategories.includes("All")
            ? dataSources.filter((dataSource: IDataSource) =>
                  availableDataSources.includes(dataSource.programmaticName),
              )
            : dataSources;

    return search
        ? dataSources.filter((dataSource: IDataSource) => dataSource.name.toLowerCase().includes(search.toLowerCase()))
        : dataSources;
};

export const changeDate = (currentDate: string | undefined) => {
    if (currentDate) {
        const newDate = toDate(currentDate);

        return newDate.toLocaleDateString();
    }

    return "";
};

export function numberWithCommas(nonFormattedNumber: number | string) {
    return typeof nonFormattedNumber === "string" ? nonFormattedNumber : nonFormattedNumber.toLocaleString("en-US");
}

export function getDateInYYYYMMDDFormat(date: Date) {
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
}

export const generateValue = (item: number, precision?: number) => {
    if (item < 1) {
        return `${item.toFixed(precision || 2)}`;
    } else if (item > 1 && item < 999) {
        return `${item.toFixed(precision)}`;
    } else if (item >= 1000 && item < 1000000) {
        return `${(item / 1000).toFixed(precision)}K`;
    } else if (item >= 1000000 && item < 1000000000) {
        return `${(item / 1000000).toFixed(precision)}M`;
    } else if (item >= 1000000000) {
        return `${(item / 1000000000).toFixed(precision)}B`;
    }

    return item.toFixed(precision || 2);
};

export const getPreparedValue = (
    sign: string | FORMATS,
    value: number | string,
    precision?: number,
    isFullNumConditon?: boolean,
) => {
    if (sign === "%") {
        return typeof value === "number" ? `${generateValue(value, precision || 0)}%` : value;
    }

    if (isFullNumConditon) {
        return typeof value === "number" && Math.abs(value) > 999
            ? `${sign}${generateValue(value, precision || 0)}`
            : value;
    }

    return typeof value === "number" ? `${sign}${generateValue(value, precision || 0)}` : value;
};

export const toCamelCase = (name: string) => {
    if (name === "ROAS") {
        return "trueRoas";
    }

    return name
        .toLowerCase()
        .replace(/([-_ ][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", "").replace(" ", ""));
};

export const toUnderlineCase = (name: string) => {
    return name.toLowerCase().replace(/([ ][a-z])/g, (group) => group.replace(" ", "_"));
};

export const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getToken = () => {
    return sessionStorage.getItem("token");
};

export const calculateAverageOrder = (revenue: number, orders: number) => {
    if (revenue && orders) {
        return revenue / orders;
    }
    return 0;
};

export const calculateCPC = (spend?: number, clicks?: number): number => {
    if (spend && clicks) {
        return spend / clicks;
    }

    return 0;
};

export const calculateCAC = (spend?: number, newCustomers?: number): number => {
    if (spend && newCustomers) {
        return spend / newCustomers;
    }

    return 0;
};

export const calculateCPM = (spend?: number, impressions?: number): number => {
    if (spend && impressions) {
        return (spend / impressions) * 1000;
    }
    return 0;
};

export const calculateROAS = (spend?: number, trueRevenue?: number): number => {
    if (spend && trueRevenue) {
        return +(trueRevenue / spend);
    }
    return 0;
};

export const formatValue = (
    value: string | number | null | boolean | undefined,
    format: FORMATS | null | undefined,
    fixed: number = 2,
) => {
    if (value === undefined) {
        return "";
    }

    if (value === null) {
        value = 0;
    }

    if (typeof value === "string") {
        return value;
    }

    if (typeof value === "boolean") {
        return value.toString();
    }

    if (format === FORMATS.NUMERIC) {
        return value
            .toFixed(fixed)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const formattedValue = value
        .toFixed(fixed)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (format === FORMATS.PERCENT) {
        return `${formattedValue}%`;
    }

    if (format === FORMATS.DOLLAR) {
        if (value < 0) {
            const formattedValue = Math.abs(value)
                .toFixed(fixed)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `-$${formattedValue}`;
        } else {
            return `$${formattedValue}`;
        }
    }

    return formattedValue;
};

export const addPositiveNum = (value: number | undefined) => {
    return value ? (value > 0 ? value : 0) : 0;
};

export const getTotalAmountForPerformanceTable = (
    array: IMetricAttributionTableValuesTransformed[],
    exludeAmazonSpend: boolean = false,
) => {
    const objectWithTotalsValues: IMetricAttributionTableValuesTransformed = {
        campaignId: "",
        campaignName: "Total",
    };

    objectWithTotalsValues.spend = +array.reduce(
        (previousValue, currentValue) => previousValue + (currentValue.spend || 0),
        0,
    );
    objectWithTotalsValues.impressions = +array
        .reduce((previousValue, currentValue) => previousValue + addPositiveNum(currentValue.impressions), 0)
        .toFixed(2);
    objectWithTotalsValues.clicks = +array
        .reduce((previousValue, currentValue) => previousValue + (currentValue.clicks || 0), 0)
        .toFixed(2);
    objectWithTotalsValues.cpc = calculateCPC(objectWithTotalsValues.spend, objectWithTotalsValues.clicks);
    objectWithTotalsValues.cpm = calculateCPM(objectWithTotalsValues.spend, objectWithTotalsValues.impressions);
    objectWithTotalsValues.sessions = +array
        .reduce((previousValue, currentValue) => previousValue + (currentValue.sessions || 0), 0)
        .toFixed(2);
    objectWithTotalsValues.trueCart = +array
        .reduce((previousValue, currentValue) => previousValue + (currentValue.trueCart || 0), 0)
        .toFixed(2);
    objectWithTotalsValues.trueOrders = +array
        .reduce((previousValue, currentValue) => previousValue + (currentValue.trueOrders || 0), 0)
        .toFixed(2);
    objectWithTotalsValues.trueRevenue = +array.reduce(
        (previousValue, currentValue) => previousValue + addPositiveNum(currentValue.trueRevenue),
        0,
    );
    objectWithTotalsValues.channelReportedRevenue = +array.reduce(
        (previousValue, currentValue) => previousValue + addPositiveNum(currentValue.channelReportedRevenue),
        0,
    );
    objectWithTotalsValues.amazonSellingPartnerRevenue = +array.reduce(
        (previousValue, currentValue) => previousValue + addPositiveNum(currentValue.amazonSellingPartnerRevenue),
        0,
    );
    objectWithTotalsValues.storeModeledRevenue = +array.reduce(
        (previousValue, currentValue) => previousValue + addPositiveNum(currentValue.storeModeledRevenue),
        0,
    );

    objectWithTotalsValues.baseRevenue = +array.reduce(
        (previousValue, currentValue) => previousValue + addPositiveNum(currentValue.baseRevenue),
        0,
    );

    objectWithTotalsValues.haloEffectRevenue = +array.reduce(
        (previousValue, currentValue) => previousValue + addPositiveNum(currentValue.haloEffectRevenue),
        0,
    );

    objectWithTotalsValues.newCustomers = +array
        .reduce((previousValue, currentValue) => previousValue + (addPositiveNum(currentValue.newCustomers) || 0), 0)
        .toFixed(0);

    const { spend, trueRevenue, channelReportedRevenue, newCustomers } = objectWithTotalsValues;

    const trueRoas = +calculateROAS(spend, trueRevenue).toFixed(2);
    const channelReportedRoas = +calculateROAS(spend, channelReportedRevenue).toFixed(2);

    const spendForCAC = +array.reduce(
        (previousValue, currentValue) =>
            previousValue + (currentValue?.connectorName === AMAZON_ADS ? 0 : currentValue.spend || 0),
        0,
    );
    const cac = calculateCAC(exludeAmazonSpend ? spendForCAC : spend, newCustomers);

    objectWithTotalsValues.trueRoas = isNaN(trueRoas) ? 0 : trueRoas;
    objectWithTotalsValues.channelReportedRoas = isNaN(channelReportedRoas) ? 0 : channelReportedRoas;

    objectWithTotalsValues.cac = isNaN(cac) ? 0 : cac;

    objectWithTotalsValues.spend = +objectWithTotalsValues.spend.toFixed(2);
    objectWithTotalsValues.trueRevenue = +objectWithTotalsValues.trueRevenue.toFixed(2);
    objectWithTotalsValues.amazonSellingPartnerRevenue = +objectWithTotalsValues.amazonSellingPartnerRevenue.toFixed(2);
    objectWithTotalsValues.storeModeledRevenue = +objectWithTotalsValues.storeModeledRevenue.toFixed(2);

    return objectWithTotalsValues;
};

export const stringToCapitalize = (phrase: string = "") => {
    return phrase
        .replace(/[_\-.]/g, " ")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

export const isValueNaN = (value: number | undefined) => {
    if (!value || isNaN(+value)) {
        return 0;
    }
    return +value;
};

export const getSumOfConnectors = (data: IPerformanceDetailsResponseValuesTransformed[]) => {
    const arrayWithNewObjects: IPerformanceDetailsResponseValuesTransformed[] = [];
    const copyData = cloneDeep(data);
    const results = copyData.reduce((results: any, org: any) => {
        if (org.connectorName) {
            (results[org.connectorName] = results[org.connectorName] || []).push(org);
        }
        return results;
    }, []);

    copyData.forEach((connector: IPerformanceDetailsResponseValuesTransformed) => {
        if (
            arrayWithNewObjects.length &&
            arrayWithNewObjects.filter((el) => el.connectorName === connector.connectorName).length
        ) {
            const newObjectElement: any = arrayWithNewObjects.filter(
                (el: any) => el.connectorName === connector.connectorName,
            )[0];
            if (connector.connectorName) {
                newObjectElement[String(camelCase(connector.connectorName + "Length"))] =
                    results[connector.connectorName].length || 1;
            }

            newObjectElement.spend += isValueNaN(connector.spend || 0);
            newObjectElement.impressions += isValueNaN(connector.impressions || 0);
            newObjectElement.clicks += isValueNaN(connector.clicks || 0);
            newObjectElement.sessions += isValueNaN(connector.sessions || 0);
            newObjectElement.trueOrders += isValueNaN(connector.trueOrders || 0);
            newObjectElement.trueRevenue += isValueNaN(connector.trueRevenue || 0);
        } else {
            arrayWithNewObjects.push({ ...connector });
        }
    });
    arrayWithNewObjects.forEach((calculatedConnectorData: IPerformanceDetailsResponseValuesTransformed) => {
        if (calculatedConnectorData.trueRevenue && calculatedConnectorData.spend) {
            calculatedConnectorData.trueRoas = +Number(
                (calculatedConnectorData.trueRevenue / calculatedConnectorData.spend) * 100,
            ).toFixed(0);
        }
    });
    return arrayWithNewObjects;
};

export const getImage = (service: string) => {
    try {
        return require(`../assets/data_sources/${service.toLowerCase()}.png`);
    } catch (e) {
        return require(`../assets/initialAvatar.png`);
    }
};

export const getDiffObjectKeys = (object1: any, object2: any) => {
    let keyFound = [];
    keyFound = Object.keys(object1)
        .map((key) => {
            if (object1[key] !== object2[key]) {
                return key;
            }
        })
        .filter((data) => data !== undefined);
    return keyFound || -1;
};

export const getPathForAnalytics = (path: string, search?: string) => {
    const fullPath = path.split("/");
    const removeOrgInfo = fullPath.slice(3).join("/");
    if (fullPath[1] === "org") {
        if (search) {
            return `/${removeOrgInfo}${search}`;
        }
        return `/${removeOrgInfo}`;
    }
    return path;
};

export const getDemoCampaignName = (connectorName: string) => {
    const randomNumber = Math.floor(Math.random() * 1001);
    const type = ["Awareness", "Retargeting", "Dynamic", "Brand Test"];
    const name = `${stringToCapitalize(connectorName)} ${
        type[Math.floor(Math.random() * type.length)]
    } #${randomNumber}`;
    return name;
};

export const customSortData = (data: any[], sortField: string, defaultSortBy: string[]) => {
    const sortByObject: {
        [key: string]: number;
    } = defaultSortBy.reduce((obj: any, item: any, index: any) => {
        return {
            ...obj,
            [item]: index,
        };
    }, {});
    return data.sort((a: any, b: any) => sortByObject[a[sortField]] - sortByObject[b[sortField]]);
};

export const getUniqueListBy = (arr: any, key: string) => {
    return [...new Map(arr.map((item: { [x: string]: any }) => [item[key], item])).values()];
};

export const firstCharIsVowel = (s: string) => {
    return ["a", "e", "i", "o", "u"].indexOf(s[0].toLowerCase()) !== -1;
};

export const isSameDay = (d1: Date, d2: Date) => {
    return d1.getFullYear() === d2.getFullYear() && d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth();
};

export const prepareDateFormatForServer = (date: Date): string => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const getDataFromLocalStorage = (key: string) => {
    try {
        return JSON.parse(localStorage.getItem(key) || "");
    } catch (error) {
        return null;
    }
};

const getvalidDate = (d: string | number | Date) => {
    return toDate(d);
};

export const validateDateBetweenTwoDates = (
    startDate: string | number | Date,
    endDate: string | number | Date,
    checkDate: string | number | Date,
) => {
    return getvalidDate(checkDate) <= getvalidDate(endDate) && getvalidDate(checkDate) >= getvalidDate(startDate);
};

export const checkConsecutiveDates = (dateArr: string[], N: number) => {
    const dateGroup = dateArr.reduce(
        (acc: any, date: any) => {
            const group = acc[acc.length - 1];
            if (differenceInDays(toDate(date), toDate(group[group.length - 1] || date)) > 1) {
                acc.push([date]);
            } else {
                group.push(date);
            }
            return acc;
        },
        [[]],
    );
    return dateGroup.some((datesInArr: any) => datesInArr.length > N);
};

export const convertObjectToQueryParam = (queryParamsObj: { [x: string]: any }) => {
    const paramStringify = queryString.stringify(queryParamsObj);
    const query = new URLSearchParams(paramStringify);

    const updatedQueryString = Object.keys(queryParamsObj)
        .map((key) => {
            const metricArr = { [key]: query.getAll(key) };
            return !key.includes("[]")
                ? key + "=" + `${queryParamsObj[key]}`
                : `${decodeURIComponent(queryString.stringify(metricArr))}`;
        })
        .join("&");
    return updatedQueryString;
};

export const handleSingleFilter = (key: string, value: string, navigate: NavigateFunction) => {
    const queryParamsObj = queryString.parse(window.location.search);

    // If value is falsy, remove the key from queryParamsObj
    if (!value) {
        delete queryParamsObj[key];
        navigate({
            search: convertObjectToQueryParam(queryParamsObj),
            pathname: location.pathname,
        });
        return;
    }

    // Update or add the key-value pair
    queryParamsObj[key] = value;
    navigate({
        search: convertObjectToQueryParam(queryParamsObj),
        pathname: location.pathname,
    });
};

export const handleFilteredData = (paramKey: string, item: { id: string }, navigate: NavigateFunction) => {
    let queryParamsObj = queryString.parse(window.location.search);

    const { id: filterValue } = item;
    const keys = Object.keys(queryParamsObj);
    let isFilterDelete = false;

    if (!keys.includes(paramKey) && !queryParamsObj[paramKey]) {
        queryParamsObj = { ...queryParamsObj, [paramKey]: filterValue };
    } else {
        const currentParamValue = queryParamsObj[paramKey];
        if (
            currentParamValue &&
            typeof currentParamValue === "string" &&
            currentParamValue.split(",").includes(filterValue)
        ) {
            const updatedPramaValue = currentParamValue.split(",").filter((c) => c !== filterValue);
            if (updatedPramaValue.length) {
                isFilterDelete = true;
                queryParamsObj = { ...queryParamsObj, [paramKey]: updatedPramaValue.join(",") };
            } else {
                isFilterDelete = true;
                delete queryParamsObj[paramKey];
            }
        } else {
            queryParamsObj = {
                ...queryParamsObj,
                [paramKey]: [queryParamsObj[paramKey], filterValue].join(","),
            };
        }
    }
    navigate({
        search: convertObjectToQueryParam(queryParamsObj),
    }, { preventScrollReset: true });
    return isFilterDelete;
};

export const getFilterChipValues = (
    list: { items: any; key?: string; title?: any; chipCondition?: string | undefined },
    value: boolean,
) => {
    return list.items
        .filter((i: { value: any }) => i.value === value)
        .map((c: { name: any }) => c.name)
        .join(", ");
};

export const formatType: IDictionary = {
    year: "{yyyy}",
    month: { year: "{yearStyle|{yyyy}}", month: "{monthStyle|{MMM}}" },
    quarter: "Q{Q}-{yy}",
};

export const getTooltipDateFormat = (date: any, grpBy: string) => {
    if (!date?.value?.[0]) {
        return "";
    }
    const dateFormatByGrp: IDictionary = {
        [chartGrpKey.DAY]: format(parseISO(date.value[0]), "EEE, MMM dd"),
        [chartGrpKey.WEEK]: `${format(startOfWeek(toDate(date.value[0])), "MMM dd, yyyy")} to ${format(
            endOfWeek(toDate(date.value[0])),
            "MMM dd, yyyy",
        )}`,
        [chartGrpKey.MONTH]: date ? format(parseISO(date.value[0]), "MMM yyyy") : "",
        [chartGrpKey.QUATER]: `Q${getQuarter(toDate(date.value[0]))}-${getYear(toDate(date.value[0]))}`,
        [chartGrpKey.YEAR]: `${getYear(toDate(date.value[0]))}`,
    };
    return dateFormatByGrp[grpBy] || "";
};

export const sortObjectByKey = (initialObject: IDictionary) => {
    return Object.keys(initialObject)
        .sort()
        .reduce((obj: any, key: any) => {
            obj[key] = initialObject[key];
            return obj;
        }, {});
};
export const compare = (post: number, operator: any, value: number, isRange?: boolean) => {
    switch (operator) {
        case ">":
            return post > value;
        case "<":
            return post < value;
        case "=":
            return Number(post) === value;
        case "<=":
            return post <= value;
        case ">=":
            return post >= value;
        case "all":
            return post >= value && post <= value;
    }
};

export const getFormattedMetricQuery = (queryParamsObj: any) => {
    const filterMetricParams = Object.keys(queryParamsObj)
        .map((key) => {
            if (key.startsWith("filter[")) {
                return { [key]: queryParamsObj[key] };
            }
        })
        .filter((v) => v !== undefined);

    const metricArr = filterMetricParams.map((data) => {
        if (data) {
            const key = Object.keys(data)[0];
            const formateKey = key.replace(/\[/g, ",").replace(/\]/g, "");
            const diffVal = formateKey.split(",");
            return { metric: diffVal[1], condition: diffVal[2], value: data[key] };
        }
    });
    return metricArr;
};

export const getUniqueArrayFrom2Array = (arr1: any[], arr2: any[]) => {
    const unique1 = arr1.filter((o) => arr2.indexOf(o) === -1);
    const unique2 = arr2.filter((o) => arr1.indexOf(o) === -1);

    const unique = unique1.concat(unique2);
    return unique;
};

export const checkDateIncludedInData = (sortedValuesByDate: any[], keys: string[], date: string, fun: any) => {
    const isDateIncluded = sortedValuesByDate.map((v) => v.date).includes(format(fun(toDate(date)), "yyyy-MM-dd"));
    if (!isDateIncluded) {
        let dummyData: any[] = [];
        keys.forEach((key) => {
            dummyData = [...dummyData, { date: format(fun(toDate(date)), "yyyy-MM-dd"), name: key }];
        });
        return [...sortedValuesByDate, ...dummyData];
    }
    return sortedValuesByDate;
};

export const isEuropeanUnionTimezone = () =>
    [
        "Europe/Vienna",
        "Europe/Brussels",
        "Europe/Sofia",
        "Europe/Zagreb",
        "Asia/Famagusta",
        "Asia/Nicosia",
        "Europe/Prague",
        "Europe/Copenhagen",
        "Europe/Tallinn",
        "Europe/Helsinki",
        "Europe/Paris",
        "Europe/Berlin",
        "Europe/Busingen",
        "Europe/Athens",
        "Europe/Budapest",
        "Europe/Dublin",
        "Europe/Rome",
        "Europe/Riga",
        "Europe/Vilnius",
        "Europe/Luxembourg",
        "Europe/Malta",
        "Europe/Amsterdam",
        "Europe/Warsaw",
        "Atlantic/Azores",
        "Atlantic/Madeira",
        "Europe/Lisbon",
        "Europe/Bucharest",
        "Europe/Bratislava",
        "Europe/Ljubljana",
        "Africa/Ceuta",
        "Atlantic/Canary",
        "Europe/Madrid",
        "Europe/Stockholm",
    ].includes(Intl.DateTimeFormat().resolvedOptions().timeZone);

export const isConsentRequired = () => isEuropeanUnionTimezone();

export const isProductionEnv = () => process.env.NODE_ENV === "production";

export const getCurrentPerformanceTab = (): PerformanceTab => {
    let currentTab = PerformanceTab.Channels;
    if (location.pathname.includes(PERFORMANCE_CAMPAIGNS_PATH)) {
        currentTab = PerformanceTab.Campaigns;
    }
    if (location.pathname.includes(PERFORMANCE_TACTICS_PATH)) {
        currentTab = PerformanceTab.Tactics;
    }
    return currentTab;
};

export const normalizeKeysWithUnderscores = (data: IDictionary) => {
    const modifiedData: IDictionary = {};
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const modifiedKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
            modifiedData[modifiedKey] = data[key];
        }
    }
    return modifiedData;
};

export const formattedArrayInSentence = (stringArray: string[], separation: string = "and") => {
    return stringArray.length > 1
        ? stringArray.slice(0, -1).join(", ") + ` ${separation} ` + stringArray[stringArray.length - 1]
        : stringArray[0];
};

export const handleClickWithTextSelectionCheck =
    (onEdit: (...args: any[]) => void) =>
    (...args: any[]) => {
        const selection = window.getSelection();
        if (selection && selection.toString().length > 0) {
            return;
        }
        onEdit(...args);
    };

export const calculatePercentage = (currentValue: number, total: number, fixed = true): number => {
    if (currentValue && total) {
        const percentage = +((currentValue / total) * 100);
        return fixed ? +percentage.toFixed(2) : percentage;
    } else {
        return 0;
    }
};

export const getPreparedOnboardingState = (
    isIncompletePagesExist: boolean,
    partialStateSelectorForHome: boolean,
    dataQaState: boolean,
) => {
    let onboardingState = "Ideal";
    if (isIncompletePagesExist) {
        onboardingState = "Empty";
    } else if (partialStateSelectorForHome) {
        onboardingState = "Partial";
    } else if (dataQaState) {
        onboardingState = "Data QA";
    }
    return onboardingState;
};

export const calculatePercentDiff = (currentValue: number, previousValue: number, fixed = true): number => {
    if (currentValue && previousValue) {
        const percentage = +((currentValue - previousValue) / Math.abs(previousValue)) * 100;
        return fixed ? +percentage.toFixed(2) : percentage;
    } else {
        return 0;
    }
};

export const recalculateSortDirection = (orderBy: string, order: string, property: string) => {
    const isAsc = orderBy !== property || order === "asc";
    return isAsc ? "desc" : "asc";
};

export const initialSortDirection = (
    choiceBy: string | undefined,
    initialState: string,
    choice: "asc" | "desc" | undefined,
) => {
    return choiceBy === initialState ? choice : "desc";
};
