import styled from "styled-components";
import { css } from "@emotion/css";
import { Theme, alpha } from "@mui/material";
import { useTheme } from "@mui/material";

import { PRIMARY_MAIN_COLOR, WHITE_COLOR } from "./colors";

export const useStylesTable = () => ({
    table: css({
        width: "100%",
    }),
    tableWrapper: css({
        width: "100%",
        [useTheme().breakpoints.down("md")]: {
            marginTop: "0.8em",
        },
    }),
    tableBody: css({
        width: "100%",
    }),
});

export const useStylesForTableToolbar = () => ({
    closeIcon: css({
        cursor: "pointer",
    }),
    deleteButton: css({
        color: useTheme().palette.error.main,
    }),
});

export const useStylesForHeaderInTable = () => ({
    tableCell: css({
        color: "#000000!important",
        borderBottom: "1.1px solid #D9D9D9 !important",
        padding: "5px 20px",
        fontWeight: "700",
    }),
    tableHeader: css({
        width: "100%",
    }),
    headerRow: css({
        borderBottom: "1.1px solid #D9D9D9",
        height: "48px",
    }),
});

export const useStylesForRowInTable = () => ({
    tableCell: css({
        color: "#000000!important",
        borderBottom: "1.1px solid #E0E0E0 !important",
        minHeight: "52px",
        padding: "15px 20px",
    }),
    tableCellAction: css({
        color: "#000000!important",
        borderBottom: "1.1px solid #E0E0E0 !important",
        minHeight: "52px",
        padding: "0",
    }),
    bodyRow: css({
        borderBottom: "1.1px solid #E0E0E0",
        minHeight: "52px",
    }),
});

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 95%;
`;

export const baseImageContainerClass = css`
    height: 24px !important;
    width: 24px !important;
`;

export const imageContainerClass = css`
    ${baseImageContainerClass}
    margin-right: 8px !important;
`;

export const customAvatarClass = css`
    font-size: 14px;
    background-color: ${PRIMARY_MAIN_COLOR};
    color: ${WHITE_COLOR};
`;

export const useStylesForAvatarGrp = () => ({
    avatarGroupClasses: css({
        marginLeft: "8px !important",
        "& a": {
            textDecoration: "none",
        },
    }),
    avatarClass: css({
        position: "inherit",
        background: "white",
        marginLeft: "-8px",
        border: "2px solid rgb(255, 255, 255)",
    }),
    customAvatarBG: css({
        backgroundColor: "rgb(96, 172, 179)",
    }),
    gropAvatar: css({
        order: -1,
    }),
});

export const useStylesForChips = (theme: Theme) => ({
    chipStyle: css({}),
    negativeChip: css({
        background: alpha(theme.palette.error.main, 0.1),
    }),
    positiveChip: css({
        background: alpha(theme.palette.success.main, 0.2),
    }),
    blueChip: css({
        background: "rgba(116, 95, 232, 0.1)",
    }),
    iconChip: css({
        width: "24px",
        height: "24px",
        marginLeft: "5px",
        marginTop: "-5px",
        "& span": {
            paddingLeft: 0,
            paddingRight: 0,
        },
        "& svg": {
            marginTop: "5px",
        },
    }),
    tooltip: css({
        fontSize: "14px",
    }),
});
