import { css } from "@emotion/css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, TableCell, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { VERIFY_QA_METRICS_PATH } from "src/consts/path/path";
import { selectCurrentlyViewingCode } from "src/reduxState/slices/organizationSlice";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";

interface IVerifyDataQaColumn {
    height: number;
    width: number;
    totalRow?: boolean;
}

export const VerifyDataQaColumn: FC<IVerifyDataQaColumn> = ({ height, width, totalRow = false }) => {
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const navigate = useNavigate();
    const analyticsService = useAnalyticsService();

    const useStylesForBlocker = () => ({
        blockerClass: css({
            backgroundColor: "#f8f8f8 !important",
            position: "relative",
            zIndex: "0 !important",
            padding: "0 10px",
            verticalAlign: height > 4 ? "top" : "middle",
        }),

        singleColumn: css({
            minWidth: "140px",
        }),

        flexDiv: css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }),
    });

    const classes = useStylesForBlocker();

    const verifyDataHandler = () => {
        analyticsService.logEvent("Performance Verify Metrics Clicked", {});
        navigate(`/org/${orgCode}${VERIFY_QA_METRICS_PATH}`);
    };

    return width < 1 ? null : (
        <TableCell
            rowSpan={height}
            colSpan={width}
            className={`${classes.blockerClass} ${width === 1 ? classes.singleColumn : ""} blocked-column`}
            data-cy="verify-data-qa-column"
        >
            <Stack spacing={1} alignItems={"center"} paddingY={height > 4 ? 10 : 2}>
                <Typography color="text.secondary" align="center" fontSize={"14px"} maxWidth={"178px"}>
                    Verify Your Data to Unlock MMM Insights
                </Typography>
                <Button size="small" variant="contained" endIcon={<ChevronRightIcon />} onClick={verifyDataHandler}>
                    Verify Data
                </Button>
            </Stack>
        </TableCell>
    );
};
