import { createTheme } from "@mui/material/styles";
import {
    BLACK_COLOR,
    ERROR_DARK_COLOR,
    ERROR_LIGHT_COLOR,
    ERROR_MAIN_COLOR,
    HOVER_ITEM_COLOR,
    INFO_DARK_COLOR,
    INFO_MAIN_COLOR,
    PRIMARY_DARK_COLOR,
    PRIMARY_LIGHT_COLOR,
    PRIMARY_MAIN_COLOR,
    SECONDARY_CONTRAST_TEXT_COLOR,
    SECONDARY_DARK_COLOR,
    SECONDARY_LIGHT_COLOR,
    SECONDARY_MAIN_COLOR,
    SELECTED_ITEM_COLOR,
    SUCCESS_CONTRAST_TEXT_COLOR,
    SUCCESS_DARK_COLOR,
    SUCCESS_LIGHT_COLOR,
    SUCCESS_MAIN_COLOR,
    TERTAIRY_CONTRAST_TEXT_COLOR,
    TERTAIRY_DARK_COLOR,
    TERTAIRY_LIGHT_COLOR,
    TERTAIRY_MAIN_COLOR,
    WARNING_DARK_COLOR,
    WARNING_LIGHT_COLOR,
    WARNING_MAIN_COLOR,
    WHITE_COLOR,
} from "../consts/colors";

declare module "@mui/material/Button" {
    // tslint:disable-next-line
    interface ButtonPropsColorOverrides {
        tertiary: true;
    }
}

declare module "@mui/material/styles" {
    // tslint:disable-next-line
    interface Palette {
        tertiary: Palette["primary"];
    }
    // tslint:disable-next-line
    interface PaletteOptions {
        tertiary?: PaletteOptions["primary"];
    }
}

const fontFamily = ["Noto Sans", "sans-serif"].join(",");

export const prescientLight = createTheme({
    palette: {
        primary: {
            main: PRIMARY_MAIN_COLOR,
            light: PRIMARY_LIGHT_COLOR,
            dark: PRIMARY_DARK_COLOR,
        },
        secondary: {
            main: SECONDARY_MAIN_COLOR,
            light: SECONDARY_LIGHT_COLOR,
            dark: SECONDARY_DARK_COLOR,
            contrastText: SECONDARY_CONTRAST_TEXT_COLOR,
        },
        tertiary: {
            main: TERTAIRY_MAIN_COLOR,
            light: TERTAIRY_LIGHT_COLOR,
            dark: TERTAIRY_DARK_COLOR,
            contrastText: TERTAIRY_CONTRAST_TEXT_COLOR,
        },
        error: {
            main: ERROR_MAIN_COLOR,
            light: ERROR_LIGHT_COLOR,
            dark: ERROR_DARK_COLOR,
        },
        warning: {
            main: WARNING_MAIN_COLOR,
            light: WARNING_LIGHT_COLOR,
            dark: WARNING_DARK_COLOR,
        },
        success: {
            main: SUCCESS_MAIN_COLOR,
            light: SUCCESS_LIGHT_COLOR,
            dark: SUCCESS_DARK_COLOR,
            contrastText: SUCCESS_CONTRAST_TEXT_COLOR,
        },
        info: {
            main: INFO_MAIN_COLOR,
            dark: INFO_DARK_COLOR,
        },
        text: {
            primary: PRIMARY_DARK_COLOR,
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "outlined", color: "tertiary" },
                    style: {
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        backgroundColor: WHITE_COLOR,
                    },
                },
            ],
        },
        MuiInputBase: {
            styleOverrides: {
                formControl: {
                    backgroundColor: WHITE_COLOR,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: BLACK_COLOR,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ":hover": {
                        backgroundColor: HOVER_ITEM_COLOR,
                    },
                    "&.Mui-selected": {
                        backgroundColor: SELECTED_ITEM_COLOR,
                        ":hover": {
                            backgroundColor: SELECTED_ITEM_COLOR,
                        },
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&.MuiTableRow-hover:hover": {
                        backgroundColor: HOVER_ITEM_COLOR,
                    },
                    "&.Mui-selected": {
                        backgroundColor: SELECTED_ITEM_COLOR,
                        ":hover": {
                            backgroundColor: SELECTED_ITEM_COLOR,
                        },
                    },
                },
            },
        },
        MuiChip: {
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        backgroundColor: WHITE_COLOR,
                    },
                },
            ],
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "1em",
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    fontFamily,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: PRIMARY_MAIN_COLOR,
                    color: WHITE_COLOR,
                },
            },
        },
    },
    typography: {
        fontFamily,
        h1: {
            fontSize: "36px",
            fontWeight: "700",
            lineHeight: "116.7%",
            letterSpacing: "-0.252px",
        },
        h2: {
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "116.7%",
            letterSpacing: "-0.168px",
        },
        h3: {
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "123.5%",
            letterSpacing: "0.25px",
        },
        h4: {
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "133.4%",
        },
        h5: {
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "160%",
            letterSpacing: "0.15px",
        },
        h6: {
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "160%",
            letterSpacing: "0.15px",
        },
        subtitle1: {
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "175%",
            letterSpacing: "0.15px",
        },
        subtitle2: {
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "157%",
            letterSpacing: "0.1px",
        },
        body1: {
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "150%",
            letterSpacing: "0.15px",
        },
        body2: {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "143%",
            letterSpacing: "0.17px",
        },
        caption: {
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "166%",
            letterSpacing: "0.4px",
        },
        button: {
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "200%",
            letterSpacing: "0px",
            textTransform: "capitalize",
        },
    },
    breakpoints: {
        values: {
            xs: 360,
            sm: 600,
            md: 992,
            lg: 1200,
            xl: 1536,
        },
    },
});
