import { IDictionary } from "src/interfaces/IDictionary";
import {
    DATA_INTEGRATION_PROVIDERS_PATH,
    DATA_INTEGRATION_SERVICES_PATH,
    DATA_INTEGRATION_SOURCES_PATH,
} from "../path/path";

export type Choice = "asc" | "desc";

export interface ICells {
    name: string;
    annotation: string;
    beta: boolean;
    colorCode: string;
    group: string;
    categories: string[];
    provider: string;
    deprecated: boolean;
}

interface IHeadCell {
    id: keyof ICells;
    label: string;
    numeric: boolean;
    align?: "inherit" | "left" | "center" | "right" | "justify";
}

export const DATA_SOURCE_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "name",
        numeric: false,
        label: "Name",
    },
    {
        id: "beta",
        numeric: false,
        label: "Beta",
    },
    {
        id: "deprecated",
        numeric: false,
        label: "Deprecated",
    },
    {
        id: "colorCode",
        numeric: false,
        label: "Color Code",
    },
    {
        id: "categories",
        numeric: false,
        label: "Categories",
    },
    {
        id: "provider",
        numeric: false,
        label: "Provider",
    },
];

export const DATA_SOURCE_PROVIDERS: IDictionary = { "1": "Fivetran", "2": "Prescient" };

export const API_KEY_TYPE = [
    { id: "string", name: "String" },
    { id: "number", name: "Number" },
    { id: "boolean", name: "Boolean" },
];

export enum DataSourceTabType {
    Details = "details",
    SchemaConfig = "schemaConfig",
    FivetranTables = "fivetranTables",
}

export const DATA_SOURCE_FORM_TABS = [
    { label: "Details", value: DataSourceTabType.Details, isHidden: false },
    { label: "Fivetran Tables", value: DataSourceTabType.FivetranTables, isHidden: false },
    { label: "Schema Configuration", value: DataSourceTabType.SchemaConfig, isHidden: false },
];

export enum DataSourceIntegrationTabs {
    DataSources = "sources",
    DataService = "services",
    DataProvider = "providers",
}

export const DATA_INTEGRATION_TABS = [
    { label: "Sources", value: DataSourceIntegrationTabs.DataSources, to: DATA_INTEGRATION_SOURCES_PATH },
    { label: "Services", value: DataSourceIntegrationTabs.DataService, to: DATA_INTEGRATION_SERVICES_PATH },
    { label: "Providers", value: DataSourceIntegrationTabs.DataProvider, to: DATA_INTEGRATION_PROVIDERS_PATH },
];
