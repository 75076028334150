import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { getDataSourceImage } from "src/services/dataSources/dataSources";

import { dataSourcesByProgrammaticNameSelector } from "src/reduxState/slices/supportedDataSourcesSlice";

interface IDataSourceAvatar {
    programmaticName?: string;
    className?: any;
    sx?: any;
}

export const DataSourceAvatar: FC<IDataSourceAvatar> = ({ programmaticName, className, sx }) => {
    const dataSourcesByProgrammaticName = useSelector(dataSourcesByProgrammaticNameSelector);

    useEffect(() => {}, [dataSourcesByProgrammaticName]);

    return (
        <Avatar
            src={getDataSourceImage(dataSourcesByProgrammaticName, programmaticName)}
            variant="circular"
            alt="image"
            className={className}
            sx={{ backgroundColor: "transparent", ...sx }}
        />
    );
};
