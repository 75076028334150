import React from "react";
import { Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/system";

import { IMetricAttributionTableValuesTransformed } from "../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import {
    CampaignStatus,
    IHeadCell,
    NEGATIVE_TREND_GOOD,
    PerformanceTab,
    TRUE_METRICS,
} from "../../../consts/performancePaidPage/performancePaidPage";
import { ActivePerformanceTabs } from "../../../enums/ActivePerfomanceTabs";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import {
    insightsModalSelector,
    openedInsightsModalIdsSelector,
    setInsightsModal,
} from "../../../reduxState/slices/insightsModalSlice";
import { PercentageTrendChip } from "../../core/Chips/PercentageTrendChip";
import { ModelEligibilityTooltip } from "../../ModelEligibilityTooltip";
import {
    capitalizeFirstLetter,
    formatValue,
    getImage,
    getPathForAnalytics,
    handleClickWithTextSelectionCheck,
} from "../../../services/utils";
import { FORMATS } from "../../../enums/Formats";
import { imageContainerClass, useStylesForRowInTable } from "../../../consts/StylesForTables";
import { getManageCampaignLink, SUPPORTED_EXTERNAL_PLATFORM_LINKS } from "../../../services/campaigns/management";
import { modelEligibilityByIdSelector } from "../../../reduxState/slices/modelEligibilitySlice";
import { useStylesForCampaignTableRow } from "../CampaignTable/CampaignTableRow";
import { cursor } from "../../../assets/styles/commonStyle";
import { SVGIconRenderer } from "../../SVGIconRenderer/SVGIconRenderer";
import { LightMenu } from "../../LightMenu/LightMenu";
import { NegativeReportedMetricTooltip } from "../NegativeReportedMetricTooltip.";
import { betaSupportedDataSourcesSelector } from "../../../reduxState/slices/supportedDataSourcesSlice";
import { BetaTooltip } from "../../../components/ConnectorsComponent/NewConnectorComponents/BetaTooltip";
import { getNAColumnsForTable } from "src/services/performancePage/performancePage";
import { DataSourceAvatar } from "../../DataSourceAvatar/DataSourceAvatar";

interface ICampaignPercentageTableRow {
    row: IMetricAttributionTableValuesTransformed | any;
    isImagesInTable?: boolean;
    totalAmount?: any;
    selectedTableColumns: IHeadCell[];
    firstColTitle?: string;
    isPercentRow?: boolean;
    isBothRowAvail?: boolean;
    allData?: {
        pastRow: IMetricAttributionTableValuesTransformed;
        currentRow: IMetricAttributionTableValuesTransformed;
    };
    activePerformanceTab: PerformanceTab;
    handleChannelClick?: (row: any) => void;
}

export const CampaignPercentageTableRow: React.FC<ICampaignPercentageTableRow> = ({
    row,
    isImagesInTable,
    totalAmount,
    selectedTableColumns,
    firstColTitle,
    isPercentRow,
    isBothRowAvail,
    allData,
    activePerformanceTab,
    handleChannelClick,
}) => {
    const isCampaignTabActive = activePerformanceTab === PerformanceTab.Campaigns;

    const dispatch = useDispatch();
    const analyticsService = useAnalyticsService();
    const insightsModal = useSelector(insightsModalSelector);
    const openedInsightsModalIds = useSelector(openedInsightsModalIdsSelector);
    const betaSupportedConnectors = useSelector(betaSupportedDataSourcesSelector);

    const isPerformanceRowSelected =
        openedInsightsModalIds?.length && row && row.campaignId
            ? openedInsightsModalIds.includes(row.campaignId)
            : false;
    // @ts-ignore
    const eligibilityData = useSelector((state) => modelEligibilityByIdSelector(state, row.campaignId));

    const classes = {
        ...useStylesForRowInTable(),
        ...useStylesForCampaignTableRow(useTheme(), isPerformanceRowSelected),
    };
    const isRevenueNegative = !totalAmount && row && row.trueRevenue && row.trueRevenue < 0;
    const isReportedRevenueNegative = !totalAmount && row.channelReportedRevenue && row.channelReportedRevenue < 0;
    const isReportedRoasNotAvailable = !totalAmount && row.channelReportedRoas && row.channelReportedRoas < 0;

    const getPercentageValue = (value: number, isPositiveTrendGood: boolean) => {
        return typeof value === "number" ? (
            <PercentageTrendChip value={value} isPositiveTrendGood={isPositiveTrendGood} />
        ) : (
            value
        );
    };

    const getPreparedValue = (element: any, title: any) => {
        return (element.id === "channelReportedRevenue" && isReportedRevenueNegative) ||
            (element.id === "channelReportedRoas" && isReportedRoasNotAvailable) ? (
            <NegativeReportedMetricTooltip classes={classes} performanceRow={row} />
        ) : element.id === "status" ? (
            ""
        ) : (
            title
        );
    };

    const getCellValue = (element: any, title: any, isPercentRow: boolean) => {
        const formattedPercentageVal = totalAmount
            ? getPercentageValue(totalAmount[element.id], NEGATIVE_TREND_GOOD.indexOf(element.id.toUpperCase()) === -1)
            : !isBothRowAvail
            ? `--`
            : getPercentageValue(row[element.id], NEGATIVE_TREND_GOOD.indexOf(element.id.toUpperCase()) === -1);
        const isModelEligibility =
            !totalAmount && isRevenueNegative && (element.id === "trueRevenue" || element.id === "trueRoas");

        return isPercentRow ? (
            formattedPercentageVal
        ) : title === "All Campaigns" ? (
            element.id === "campaignId" ? (
                firstColTitle || ""
            ) : (
                "Total"
            )
        ) : isModelEligibility ? (
            <ModelEligibilityTooltip modelEligibilityData={eligibilityData} classes={classes} />
        ) : (
            getPreparedValue(element, title)
        );
    };

    const openModalHandler = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const parent = document.getElementById(`${row.campaignId}-action`) as HTMLElement;
        const child = e.target as HTMLElement;
        if (parent.contains(child)) {
            return false;
        } else {
            if (allData?.currentRow && !openedInsightsModalIds.includes(row.campaignId)) {
                dispatch(
                    setInsightsModal({
                        data: allData.currentRow,
                        isMinimize: false,
                        activeTab:
                            allData.currentRow.hasSecondOrderEffect && insightsModal.length
                                ? insightsModal[0].activeTab
                                : ActivePerformanceTabs.Performance,
                        pastData: allData.pastRow,
                        activeMetric: "revenue",
                    }),
                );

                analyticsService.logEvent("Performance Detail Clicked", {
                    Page: getPathForAnalytics(location.pathname, location.search),
                    "Campaign name": row.campaignName,
                    "Halo available": allData.currentRow.hasSecondOrderEffect ? "True" : "False",
                    "Drawer status": !openedInsightsModalIds.length ? "None" : "Opened",
                });
            }
        }
    };

    const handleLinkClick = () => {
        const link = getManageCampaignLink(row.connectorName || "", row.campaignId || "", row.accountId || "");
        if (link) {
            analyticsService.logEvent("Performance Deep Link Clicked", {
                Page: getPathForAnalytics(location.pathname, location.search),
                Channel: row.channelName,
            });
            window.open(link);
        }
    };

    const actionList = [
        {
            label: `Manage Campaign on ${row.channelName}`,
            key: row.campaignId || row.campaignName || "12",
            onClick: handleLinkClick,
        },
    ];

    const supportActions = SUPPORTED_EXTERNAL_PLATFORM_LINKS.includes(row.connectorName || "");

    const handleTableRowClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isCampaignTabActive && !totalAmount) {
            handleClickWithTextSelectionCheck(openModalHandler)(e);
        } else {
            handleChannelClick && handleClickWithTextSelectionCheck(handleChannelClick)(row);
        }
    };

    return (
        <TableRow
            hover={isPercentRow}
            id={`performance-row-${row.campaignId}` || ""}
            className={`${classes.bodyRow} ${classes.selectedRow} ${
                isPercentRow && isCampaignTabActive && cursor("pointer")
            }`}
            onClick={handleTableRowClick}
        >
            {selectedTableColumns.map((element, index) => {
                const isCampaignNameCell = element.id === "campaignName";
                const negativeCols = getNAColumnsForTable(row.connectorName);
                const title = totalAmount
                    ? formatValue(totalAmount[element.id], element.sign || FORMATS.NUMERIC, element.fixed || 0)
                    : isCampaignNameCell
                    ? row.campaignName || row.campaignId || ""
                    : formatValue(row[element.id], element.sign || FORMATS.NUMERIC, element.fixed || 0);
                const isTotalRow = totalAmount && totalAmount.campaignName === "Total";
                const currentCellValue = row[element.id] ? row[element.id] : row[element.id] === 0 ? 0 : -1;
                const isTrueMetricCell = TRUE_METRICS.includes(element.id);
                const isNotValidData =
                    !totalAmount &&
                    !isPercentRow &&
                    negativeCols.includes(element.id) &&
                    currentCellValue &&
                    currentCellValue < 0;

                return (
                    !element.hidden && (
                        <TableCell
                            component="td"
                            scope="row"
                            sx={{
                                borderBottom: "1px solid #E0E0E0",
                                color: "#000000!important",
                                backgroundColor: isTrueMetricCell ? "rgba(32, 219, 94, 0.12)" : "inherit",
                                minHeight: "52px",
                                padding: "1px 16px",
                                position: "relative",
                            }}
                            key={index}
                            align={isCampaignNameCell ? "left" : "right"}
                            title={firstColTitle && isCampaignNameCell ? firstColTitle : ""}
                        >
                            <span className={isCampaignNameCell ? classes.campaignName : ""}>
                                {isImagesInTable && isCampaignNameCell ? (
                                    <DataSourceAvatar
                                        programmaticName={row.connectorName}
                                        className={imageContainerClass}
                                    />
                                ) : null}

                                {isCampaignNameCell ? (
                                    <Typography
                                        component="div"
                                        className={`${
                                            totalAmount && isTotalRow ? classes.boldText : classes.usualText
                                        } ${isPercentRow && !totalAmount && classes.underLine}`}
                                        variant="body2"
                                        sx={{
                                            paddingLeft: (totalAmount && isTotalRow) || isPercentRow ? 0 : "32px",
                                        }}
                                    >
                                        {firstColTitle}
                                        {isPercentRow &&
                                            row.connectorName &&
                                            betaSupportedConnectors.includes(row.connectorName) && (
                                                <Typography component="span" sx={{ marginLeft: "8px" }}>
                                                    <BetaTooltip />
                                                </Typography>
                                            )}
                                    </Typography>
                                ) : (
                                    <Typography
                                        component="div"
                                        className={`${
                                            totalAmount && isTotalRow ? classes.boldText : classes.usualText
                                        } ${isCampaignNameCell && !isTotalRow && classes.underLine}`}
                                        variant="body2"
                                    >
                                        {element.id === "status" && !totalAmount
                                            ? isPercentRow
                                                ? capitalizeFirstLetter(
                                                      row.status && row.status !== CampaignStatus.UNKNOWN
                                                          ? row.status.toLocaleLowerCase()
                                                          : "",
                                                  )
                                                : ""
                                            : isNotValidData
                                            ? "N/A"
                                            : getCellValue(element, title, isPercentRow || false)}
                                    </Typography>
                                )}
                            </span>
                        </TableCell>
                    )
                );
            })}
            <TableCell
                key="campaign-row-actions"
                component="td"
                scope="row"
                className={classes.tableCellAction}
                align="right"
                id={`${row.campaignId}-action`}
            >
                {isPercentRow && !totalAmount && isCampaignTabActive && supportActions && (
                    <LightMenu
                        items={actionList}
                        title={<SVGIconRenderer icon="overflowIcon" />}
                        header={"Actions"}
                        extraButtonClass={classes.actionCell}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};
