import { FC, Fragment, ReactNode, useContext } from "react";
import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
    Alert,
    Tooltip,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { Stack } from "@mui/system";
import { css } from "@emotion/css";

import { optimizationStyle } from "../../../containers/Optimization/optimizationStyle";
import {
    IForecastedTotalMetric,
    IScenario,
    ISummary,
    ISummaryHeader,
    MetricRate,
    Views,
} from "../../../interfaces/entities/IScenario";
import { getConfidenceLevel } from "../../../services/optimizationPage/optimization";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { IDictionary } from "../../../interfaces/IDictionary";
import { CustomRadioGroup } from "../../../components/core/CustomRadioGroup/CustomRadioGroup";
import { OptimizationOutcomeContext } from "../../../containers/OptimizationOutcomePage/OptimizationOutcomePage";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { AggregateScenarioCard } from "./AggregateScenarioCard";
import { SCENARIO_TYPE } from "src/consts/optimizationPage/optimizationPage";

interface IScenarioProjections {
    totalAllocationCampaigns: IForecastedTotalMetric;
    selectedScenario: IScenario;
    isTableLoading: boolean;
    setCompareTo: React.Dispatch<React.SetStateAction<Views>>;
    compareTo: Views;
    compareToOption: Array<{
        lable: string;
        value: string;
        disabled?: boolean;
    }>;
    summaryArray: ISummary[];
    recommendationsTableHeading: IDictionary<ISummaryHeader[]>;
    predictionSentence: string;
}

const projectionStyle = () => ({
    title: css({
        boxShadow: "0px -1px 0px 0px #D9D9D9 inset",
        paddingBottom: "5px",
    }),
    recommandationStyle: css({
        backgroundColor: "white",
        padding: "20px 40px",
        border: "1px solid #D9D9D9",
        borderRadius: "5px",
    }),
    compareToStyle: css({
        minWidth: 110,
        backgroundColor: "white",
        "& .MuiSelect-select": {
            fontWeight: "bold",
        },
        "& .MuiInput-input": {
            paddingBottom: 0,
        },
        "& .MuiInput-underline:before": {
            borderBottom: 0,
        },
        "& .MuiSvgIcon-root": {
            color: "black",
        },
    }),
    radioContainer: css({ display: "flex", flexDirection: "row", alignItems: "center", gap: "1em" }),
});

export const ScenarioProjections: FC<IScenarioProjections> = ({
    totalAllocationCampaigns,
    selectedScenario,
    isTableLoading,
    setCompareTo,
    compareTo,
    compareToOption,
    summaryArray,
    recommendationsTableHeading,
    predictionSentence,
}) => {
    const classes = { ...projectionStyle(), ...optimizationStyle(useTheme()) };
    const analyticsService = useAnalyticsService();
    const optimizationOutcomeContext = useContext(OptimizationOutcomeContext);
    const projectionsSummaryHeader = recommendationsTableHeading[selectedScenario.scenarioType];
    const isForecastScenario = selectedScenario.scenarioType?.toLowerCase() === SCENARIO_TYPE.FORECAST;
    const isCacScenario = selectedScenario.scenarioType?.toLowerCase() === SCENARIO_TYPE.CAC;

    const budgetWarning = (allocationData: IForecastedTotalMetric, scenarioType = ""): ReactNode => {
        const { totalRoasPercentages, totalForecastedRevenuePercentages } = allocationData;

        if (
            totalRoasPercentages < 0 &&
            totalForecastedRevenuePercentages < 0 &&
            scenarioType.toLowerCase() !== SCENARIO_TYPE.FORECAST
        ) {
            return (
                <Alert severity="warning" sx={{ marginTop: 1 }}>
                    Scenarios that produce negative revenue and ROAS outcomes are usually due to irregular spending
                    patterns last month. Try removing highly variable or seasonal spending campaigns and re-running the
                    scenario.
                </Alert>
            );
        }

        return null;
    };

    const handleCompareToChange = (event: SelectChangeEvent<any>) => {
        const { value } = event.target;
        const compareLabel = compareToOption.find((op) => op.value === value)?.lable || "";
        if (value === Views.LastDays) {
            analyticsService.logEvent(`Outcome Optimization ${compareLabel} Clicked`, {});
        } else {
            analyticsService.logEvent(`Outcome Optimization ${compareLabel} Clicked`, {});
        }
        setCompareTo(value);
    };

    const CompareToSelect = () => {
        const content = isForecastScenario ? (
            "No Change"
        ) : (
            <span>
                <FormControl component="span" variant="standard" className={classes.compareToStyle}>
                    <Select labelId="select-type-label" onChange={handleCompareToChange} value={compareTo} size="small">
                        {compareToOption
                            .filter((op) => !op.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.lable}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </span>
        );

        return (
            <Stack direction="row" alignItems="center" justifyContent="end">
                <span>Expected ({content})</span>
                {compareTo === "noChange" && (
                    <Tooltip
                        arrow
                        title={`The No Change comparison is a forecast that is automatically calculated by the optimizer, assuming your budget remains the same for the next ${selectedScenario.forecastTimeframe} days as it was the prior ${selectedScenario.forecastTimeframe} days for all campaigns in this scenario`}
                    >
                        <span>
                            <SVGIconRenderer
                                icon="infoIcon"
                                height="16px"
                                width="16px"
                                style={{ margin: "5px 0 0 5px" }}
                            />
                        </span>
                    </Tooltip>
                )}
            </Stack>
        );
    };

    return (
        <Fragment>
            <Stack gap="30px">
                <Stack className={classes.title} direction="row" justifyContent="space-between">
                    <Typography color="#000" variant="h2">
                        Projections
                    </Typography>
                    {optimizationOutcomeContext && (
                        <CustomRadioGroup
                            className={classes.radioContainer}
                            items={[
                                { label: "Total", value: MetricRate.Total },
                                { label: "Daily", value: MetricRate.Daily },
                            ]}
                            title="Metric Rate:"
                            handleChange={optimizationOutcomeContext.handleMetricRateChange}
                            value={optimizationOutcomeContext.metricRate}
                        />
                    )}
                </Stack>
                <Stack gap="20px">
                    {isTableLoading ? (
                        <Skeleton />
                    ) : (
                        <Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                {!isForecastScenario && (
                                    <Typography data-cy="predictionSentence" color="#000" variant="h4">
                                        {predictionSentence}
                                    </Typography>
                                )}
                                {!!selectedScenario.confidenceScore && (
                                    <Stack ml="auto" justifyContent="center" alignItems="center">
                                        <Typography variant="h2" color="black">
                                            {`${(selectedScenario.confidenceScore * 100).toFixed(
                                                0,
                                            )}% ${getConfidenceLevel(selectedScenario.confidenceScore)}`}
                                        </Typography>
                                        <Typography variant="subtitle1" color="black">
                                            Confidence Score{" "}
                                            <Tooltip
                                                arrow
                                                title="Confidence is an algorithmically calculated score for all campaigns in this scenario which takes into account the volume of data available, the width of the upper and lower bounds, and finally the density of days at the allocated spend level over time."
                                            >
                                                <span>
                                                    <SVGIconRenderer
                                                        strokeColor="black"
                                                        icon="infoIcon"
                                                        width="16px"
                                                        height="16px"
                                                        style={{ marginBottom: "-2px" }}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Typography>
                                    </Stack>
                                )}
                            </Stack>
                            {selectedScenario.scenarioType !== SCENARIO_TYPE.CAC
                                ? budgetWarning(totalAllocationCampaigns, selectedScenario.scenarioType)
                                : ""}
                        </Stack>
                    )}
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        gap={{ sm: "30px", md: "30px", lg: "50px" }}
                        className={classes.recommandationStyle}
                    >
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow data-cy="projectionHeaderRow">
                                        {projectionsSummaryHeader.map((header) => {
                                            return (
                                                <TableCell
                                                    key={header.id}
                                                    align={header.align}
                                                    className={classes.projectionsTableHeader}
                                                >
                                                    {header.id === "last" ? <CompareToSelect /> : header.label}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <AggregateScenarioCard
                                        isTableLoading={isTableLoading}
                                        summaryArray={summaryArray}
                                        classes={classes}
                                        isCacScenario={isCacScenario}
                                    />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Stack>
            </Stack>
        </Fragment>
    );
};
