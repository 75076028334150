import { TableCell, TableRow, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";

import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import { TableActionCell } from "src/components/core/TableActionCell/TableActionCell";
import { useStylesForRowInTable } from "src/consts/StylesForTables";
import { IDataSourceForm } from "src/interfaces/IData";
import { demoModeSelector } from "src/reduxState/slices/demoModeSlice";
import { capitalizeFirstLetter } from "src/services/utils";
import { DataSourceLabel } from "./DataSourceLabel";

interface IDataSourceTableRow {
    dataSource: IDataSourceForm;
    deleteClick: (e: React.MouseEvent<HTMLButtonElement>, dataSource: IDataSourceForm) => void;
    editClick: (e: React.MouseEvent<HTMLButtonElement>, dataSource: IDataSourceForm) => void;
}

export const DataSourceTableRow: React.FC<IDataSourceTableRow> = ({ deleteClick, editClick, dataSource }) => {
    const classes = useStylesForRowInTable();
    const isDemoMode = useSelector(demoModeSelector);

    return (
        <TableRow key={dataSource.id} className={classes.bodyRow}>
            <TableCell>
                <Stack gap={2} direction="row" alignItems="center" justifyContent="flex-start">
                    <DataSourceAvatar programmaticName={dataSource.programmaticName} />
                    <Tooltip title={dataSource.annotation} arrow>
                        <DataSourceLabel dataSource={dataSource} />
                    </Tooltip>
                </Stack>
            </TableCell>
            <TableCell>{dataSource.beta ? "Yes" : "No"}</TableCell>
            <TableCell>{dataSource.deprecated ? "Yes" : "No"}</TableCell>
            <TableCell>
                {dataSource.colorCode ? (
                    <div
                        style={{
                            backgroundColor: dataSource.colorCode,
                            height: "25px",
                            textAlign: "center",
                            width: "100px",
                            border: "0.5px solid",
                        }}
                    >
                        {dataSource.colorCode}
                    </div>
                ) : (
                    ""
                )}
            </TableCell>
            <TableCell>{dataSource.categories?.join(", ")}</TableCell>
            <TableCell>{capitalizeFirstLetter(dataSource.provider || "")}</TableCell>
            {!isDemoMode && (
                <TableActionCell
                    actionIcons={[
                        {
                            title: "Edit",
                            onClick: editClick,
                            data: dataSource,
                            icon: "editIcon2",
                            id: `id-${dataSource.id}-editDataSource`,
                        },
                        {
                            title: "Delete",
                            onClick: deleteClick,
                            data: dataSource,
                            icon: "trashIcon",
                            id: `id-${dataSource.id}deleteDataSource`,
                        },
                    ]}
                />
            )}
        </TableRow>
    );
};
