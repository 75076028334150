import React, { FC } from "react";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useSelector } from "react-redux";

import { CONNECTORS_TABLE_HEADERS, Choice, IConnectorsCells } from "../../../consts/connectorsPage/connectorsPage";
import { useStylesForHeaderInTable } from "../../../consts/StylesForTables";
import { currentUserRoleByCompanyIdSelector, isSuperAdminSelector } from "../../../reduxState/slices/userSlice";
import { UserRole } from "../../../enums/UserRole";
import { demoModeSelector } from "../../../reduxState/slices/demoModeSlice";
import { ConnectorView } from "src/containers/Connectors/ConnectorsV2";
import { Box } from "@mui/system";
import { initialSortDirection } from "src/services/utils";

interface IConnectorTableHeader {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IConnectorsCells) => void;
    choice: Choice;
    choiceBy: string;
    selectedView: ConnectorView | undefined;
}

export const ConnectorTableHeader: FC<IConnectorTableHeader> = ({
    selectedView = ConnectorView.YOUR_CONNECTORS,
    onRequestSort,
    choice,
    choiceBy,
}) => {
    const classes = useStylesForHeaderInTable();

    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const isDemoMode = useSelector(demoModeSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const isActionVisible =
        !isDemoMode &&
        (isSuperAdmin || userRole !== UserRole.MEMBER) &&
        selectedView !== ConnectorView.AVAILABLE_CONNECTORS;

    const createSortHandler = (property: keyof IConnectorsCells) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow className={classes.headerRow}>
                {CONNECTORS_TABLE_HEADERS.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        className={classes.tableCell}
                        sx={{ padding: "16px !important", width: headCell.width }}
                    >
                        <TableSortLabel
                            active={choiceBy === headCell.id}
                            direction={initialSortDirection(choiceBy, headCell.id, choice)}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {choiceBy === headCell.id ? (
                                <Box component="span" style={{ display: "none" }}>
                                    {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {isActionVisible && (
                    <TableCell
                        className={classes.tableCell}
                        key="action"
                        align="right"
                        sx={{ padding: "16px 48px !important", width: "248px" }}
                    >
                        Edit
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};
