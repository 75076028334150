import React from "react";
import Avatar from "@mui/material/Avatar";
import useAvatar from "../MemberCard/useAvatar";
import { useTheme } from "@mui/material";
import { WHITE_COLOR } from "src/consts/colors";

interface ICustomAvatarProps {
    avatarUrl: string;
    avatarText: string;
    className?: string;
    sx?: React.CSSProperties;
    size?: string;
}

const CustomAvatar: React.FC<ICustomAvatarProps> = ({ avatarUrl, avatarText, className, sx, size = "40px" }) => {
    const url = useAvatar(avatarUrl);

    return (
        <Avatar
            className={className}
            src={url || ""}
            sx={{
                fontSize: "15px",
                fontWeight: "700",
                lineHeight: "22.5px",
                width: size,
                height: size,
                backgroundColor: url ? `${WHITE_COLOR} !important` : `${useTheme().palette.primary.main} !important`,
                ...sx,
            }}
            color="primary"
        >
            {!url && avatarText.toUpperCase()}
        </Avatar>
    );
};

export default CustomAvatar;
