import { FC, ReactNode } from "react";
import { ButtonProps, Dialog } from "@mui/material";

import { DialogElements } from "./DialogElement";

export interface IAction {
    label: string;
    onClick: () => void;
    style?: any;
    disabled?: boolean | undefined;
    endIcon?: ReactNode | undefined;
    startIcon?: ReactNode | undefined;
    variant?: "outlined" | "contained" | "text" | undefined;
    props?: {
        [key: string]: any;
    };
}

interface ICustomModal {
    title: JSX.Element | string;
    closeIcon: boolean;
    instanceSlot: JSX.Element;
    closeModal: () => void;
    actions: {
        [key: number]: IAction;
    };
    sx?: any;
}

export const CustomModal: FC<ICustomModal> = ({ title, closeIcon, instanceSlot, actions, closeModal, sx }) => {
    return (
        <Dialog open={true} onClose={closeModal} sx={sx}>
            <DialogElements
                title={title}
                closeIcon={closeIcon}
                closeModal={closeModal}
                instanceSlot={instanceSlot}
                actions={actions}
            />
        </Dialog>
    );
};
