import React, { FC } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/system";
import { css } from "@emotion/css";

import { Button, ButtonColor, ButtonVariant } from "../core/Button/Button";
import { IConnector } from "../../interfaces/IConnector";
import { IDataSource, IDataSourceForm } from "../../interfaces/IData";
import { IMember } from "../../interfaces/entities/IMember";
import { LoadingButton } from "../core/LoadingButton/LoadingButton";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { IAnnouncement } from "../../interfaces/entities/IAnnouncement";
import { IScenario } from "../../interfaces/entities/IScenario";
import { ICampaignForInclusionWithMap } from "src/interfaces/entities/ICampaignForInclusion";
import { IDataService } from "src/interfaces/IDataServices/IDataServices";

type sourceType =
    | IConnector
    | IDataSource
    | IMember
    | IOrganization
    | IAnnouncement
    | IScenario
    | ICampaignForInclusionWithMap
    | IDataSourceForm
    | IDataService;

interface IConnectorModal {
    selectedConnector: sourceType;
    title: string;
    subTitle: string;
    buttonName: string;
    details: any;
    disableBtn: boolean;
    type: "error" | "info" | "success" | "warning";
    constraintMatch?: boolean;

    closeModal: () => void;
    handleAction(...args: any): any;
}
const useStyles = (paletteColor: "error" | "info" | "success" | "warning") => (theme: Theme) => {
    const color = theme.palette[paletteColor].main;
    return {
        paper: css({
            width: "500px",
            overflow: "visible",
            padding: "10px 35px",
            borderRadius: "8px",
        }),
        headerTextStyle: css({
            textAlign: "center",
            color,
        }),
        subListClass: css({
            margin: 0,
            padding: 0,
            listStyleType: "none",
            "& .MuiTypography-body2": {
                textIndent: "-10px",
            },
        }),
        actionButtonClass: css({
            minWidth: "35%",
            textTransform: "capitalize",
            boxShadow: "none",
            height: "36px",
        }),
    };
};

export const CustomModal: FC<IConnectorModal> = ({
    closeModal,
    selectedConnector,
    handleAction,
    disableBtn,
    title,
    subTitle,
    details,
    buttonName,
    type,
    constraintMatch,
}) => {
    const classes = useStyles(type)(useTheme());

    const handleConnector = (connector: sourceType) => {
        handleAction(connector);
    };

    return (
        <Dialog
            classes={{
                paper: classes.paper,
            }}
            open={true}
        >
            <DialogTitle variant="h5" className={classes.headerTextStyle}>
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography
                    mb={2}
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                >
                    {subTitle}
                </Typography>
                <Box component="ul" className={classes.subListClass}>
                    {details}
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", paddingTop: 0 }}>
                <Button
                    className={classes.actionButtonClass}
                    variant="outlined"
                    color={ButtonColor.Tertiary}
                    onClick={closeModal}
                    disabled={disableBtn}
                >
                    Cancel
                </Button>

                <LoadingButton
                    className={classes.actionButtonClass}
                    disabled={constraintMatch}
                    loading={disableBtn}
                    variant={ButtonVariant.Container}
                    color={ButtonColor.Error}
                    onClick={() => handleConnector(selectedConnector)}
                    loadingIndicator={<CircularProgress color={"inherit"} size={16} />}
                    text={buttonName}
                />
            </DialogActions>
        </Dialog>
    );
};
