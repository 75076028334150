import { css } from "@emotion/css";
import { Theme } from "@mui/material";
import { PRIMARY_DARK_COLOR, SECONDARY_MAIN_COLOR } from "src/consts/colors";

export const DATE_RANGE_COLORS = {
    selection: PRIMARY_DARK_COLOR,
    compare: SECONDARY_MAIN_COLOR,
};

export const useStylesForDateRange = (theme: Theme, isTransparent: boolean = false, isUpgraded: boolean = false) => {
    const border = `1px solid ${isTransparent ? "rgba(0, 0, 0, 0.23)" : "#275469"}`;
    const btnStyle = {
        fontSize: "15px",
        minHeight: isUpgraded ? "56px" : "46px",
        height: "46px",
        padding: "8px 16px !important",
        border,
        "&:hover": {
            backgroundColor: "white !important",
            border: `1px solid ${theme.palette.primary.main} !important`,
        },
    };

    const openMenuBtnStyle = isTransparent
        ? {
              border: `1px solid ${theme.palette.primary.main} !important`,
          }
        : {};

    return {
        dateIconStyle: css({
            marginRight: "8px",
        }),
        buttonClass: css({
            "& #current-date-filter-btn": {
                ...btnStyle,
                "& .MuiButton-endIcon": {
                    marginLeft: "16px",
                },
            },
        }),
        openMenuBtnStyle: css({
            "& #current-date-filter-btn": {
                ...btnStyle,
                ...openMenuBtnStyle,
                "& .MuiButton-endIcon": {
                    marginLeft: "16px",
                },
            },
        }),
    };
};
