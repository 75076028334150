import { FC, useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import {
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
} from "src/interfaces/entities/IScenario";
import { recommendationsSentence } from "src/services/optimizationPage/optimization";
import { OutcomeCampaignTable } from "../OutcomeTable/OutcomeCampaignTable";
import { IForecastCampaignHeadCell, SCENARIO_TYPE } from "src/consts/optimizationPage/optimizationPage";
import { IDictionary } from "src/interfaces/IDictionary";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { useEditScenarioCampaignMutation } from "src/reduxState/apis/optimizationApi";
import { OptimizationOutcomeContext } from "src/containers/OptimizationOutcomePage/OptimizationOutcomePage";

interface ICampaignsRecommendationsTab {
    outcomeCampaignHeader?: IForecastCampaignHeadCell[];
    selectedScenario: IScenario;
    forecastCampaign: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    isTableLoading: boolean;
    increaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    decreaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    compareToLabel: string;
}

export const CampaignsRecommendationsTab: FC<ICampaignsRecommendationsTab> = ({
    outcomeCampaignHeader,
    selectedScenario,
    forecastCampaign,
    isTableLoading,
    increaseSpendCampaigns,
    decreaseSpendCampaigns,
    compareToLabel,
}) => {
    const OptimizationOutcomeContextProps = useContext(OptimizationOutcomeContext);
    const params = useParams();
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const [editScenarioCampaign] = useEditScenarioCampaignMutation();

    const isForecastScenario = selectedScenario.scenarioType === SCENARIO_TYPE.FORECAST;
    const [increaseSpendCampaignsForTrack, setIncreaseSpendCampaignsForTrack] = useState<
        IMetricAttributionTableValuesTransformedWithSpendAllocation[]
    >([]);

    const [decreaseSpendCampaignsForTrack, setDecreaseSpendCampaignsForTrack] = useState<
        IMetricAttributionTableValuesTransformedWithSpendAllocation[]
    >([]);

    const [forecastCampaignForTrack, setForecastCampaignForTrack] = useState<
        IMetricAttributionTableValuesTransformedWithSpendAllocation[]
    >([]);

    useEffect(() => {
        setIncreaseSpendCampaignsForTrack(increaseSpendCampaigns);
        setDecreaseSpendCampaignsForTrack(decreaseSpendCampaigns);
        setForecastCampaignForTrack(forecastCampaign);
    }, [
        forecastCampaign,
        increaseSpendCampaigns,
        decreaseSpendCampaigns,
        OptimizationOutcomeContextProps?.selectedScenario.scenarioCampaigns,
    ]);

    const updateCampaigns = (
        prevCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[],
        campaignRow: IMetricAttributionTableValuesTransformedWithSpendAllocation,
    ) => {
        return prevCampaigns.map((c) => {
            if (c.campaignId !== campaignRow.campaignId) {
                return c;
            }
            return campaignRow;
        });
    };

    const saveCampaign = (campaignRow: IMetricAttributionTableValuesTransformedWithSpendAllocation) => {
        if (!campaignRow.scenarioCampaignId || !params.scenarioId) {
            return;
        }

        const changedCampaigns = {
            id: campaignRow.scenarioCampaignId,
            campaign_id: campaignRow.campaignId,
            is_accepted: campaignRow.isAccepted,
            implementation_date: campaignRow.implementationDate,
            decline_reason: campaignRow.declineReason,
        };
        return editScenarioCampaign({
            body: changedCampaigns,
            orgId: currentOrgId,
            scenarioId: params.scenarioId,
        })
            .unwrap()
            .then((scenarioCampaignResponse) => {
                const inc = increaseSpendCampaignsForTrack.map((c) =>
                    c.campaignId === campaignRow.campaignId ? { ...campaignRow, isEdited: false } : c,
                );
                const desc = decreaseSpendCampaignsForTrack.map((c) =>
                    c.campaignId === campaignRow.campaignId ? { ...campaignRow, isEdited: false } : c,
                );

                if (OptimizationOutcomeContextProps?.setSelectedScenario) {
                    const scenario = OptimizationOutcomeContextProps?.selectedScenario;
                    const updateCampaigns = scenario?.scenarioCampaigns?.map((sc) => {
                        if (sc.campaignId === campaignRow.campaignId) {
                            return {
                                ...sc,
                                ...scenarioCampaignResponse,
                                isEdited: false,
                                campaignName: sc.campaignName,
                            };
                        }
                        const findEditedCampaign = [...inc, ...desc].find((c) => c.campaignId === sc.campaignId);

                        if (findEditedCampaign?.isEdited) {
                            return {
                                ...sc,
                                isAccepted: findEditedCampaign.isAccepted,
                                implementationDate: findEditedCampaign.implementationDate,
                                declineReason: findEditedCampaign.declineReason,
                                isEdited: findEditedCampaign.isEdited,
                            };
                        }
                        return sc;
                    });

                    OptimizationOutcomeContextProps.setSelectedScenario(() => ({
                        ...scenario,
                        scenarioCampaigns: updateCampaigns || scenario.scenarioCampaigns,
                    }));
                }
            });
    };

    const updateCampaignInputForTracking = (
        campaignRow: IMetricAttributionTableValuesTransformedWithSpendAllocation,
        saveButtonAction: boolean = false,
    ) => {
        if (saveButtonAction) {
            saveCampaign(campaignRow);
            return;
        }
        setIncreaseSpendCampaignsForTrack((prev) => updateCampaigns(prev, campaignRow));
        setDecreaseSpendCampaignsForTrack((prev) => updateCampaigns(prev, campaignRow));
        setForecastCampaignForTrack((prev) => updateCampaigns(prev, campaignRow));
    };

    return (
        <Stack gap="20px">
            {isForecastScenario ? (
                <Stack gap="20px">
                    <Typography color="#000" variant="h6" fontWeight="bold" fontSize="18px">
                        {recommendationsSentence(selectedScenario)} spend on the following campaign(s)
                    </Typography>
                    <OutcomeCampaignTable
                        outcomeCampaignHeader={outcomeCampaignHeader || []}
                        campaignData={forecastCampaignForTrack}
                        scenario={selectedScenario}
                        isTableLoading={isTableLoading}
                        compareToLabel={compareToLabel}
                        updateCampaignInputForTracking={updateCampaignInputForTracking}
                    />
                </Stack>
            ) : (
                <>
                    {!!increaseSpendCampaignsForTrack.length && (
                        <Stack gap="20px">
                            <Typography color="#000" variant="h6" fontWeight="bold" fontSize="18px">
                                Increase spend on the following campaign(s)
                            </Typography>
                            <OutcomeCampaignTable
                                outcomeCampaignHeader={outcomeCampaignHeader || []}
                                campaignData={increaseSpendCampaignsForTrack}
                                scenario={selectedScenario}
                                isTableLoading={isTableLoading}
                                compareToLabel={compareToLabel}
                                updateCampaignInputForTracking={updateCampaignInputForTracking}
                            />
                        </Stack>
                    )}
                    {!!decreaseSpendCampaignsForTrack.length && (
                        <Stack gap="20px">
                            <Typography color="#000" variant="h6" fontWeight="bold" fontSize="18px">
                                Decrease spend on the following campaign(s)
                            </Typography>
                            <OutcomeCampaignTable
                                outcomeCampaignHeader={outcomeCampaignHeader || []}
                                campaignData={decreaseSpendCampaignsForTrack}
                                scenario={selectedScenario}
                                isTableLoading={isTableLoading}
                                compareToLabel={compareToLabel}
                                updateCampaignInputForTracking={updateCampaignInputForTracking}
                            />
                        </Stack>
                    )}
                </>
            )}
        </Stack>
    );
};
