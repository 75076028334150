import { css } from "@emotion/css";
import { Collapse, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC, Fragment, useContext, useEffect, useState } from "react";

import { cursor } from "src/assets/styles/commonStyle";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { TableRowsLoader } from "src/components/TableLoader/TableLoader";
import {
    FORECASTED_CAMPAIGNS_HEADERS,
    IForecastCampaignHeadCell,
    SCENARIO_TYPE,
} from "src/consts/optimizationPage/optimizationPage";
import { optimizationStyle } from "src/containers/Optimization/optimizationStyle";
import { FORMATS } from "src/enums/Formats";
import { getConfidenceLevel } from "src/services/optimizationPage/optimization";
import { formatValue, handleClickWithTextSelectionCheck } from "src/services/utils";

import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import { OptimizationOutcomeContext } from "src/containers/OptimizationOutcomePage/OptimizationOutcomePage";
import { IDictionary } from "src/interfaces/IDictionary";
import { IScenario } from "src/interfaces/entities/IScenario";
import { getComparator, stableSort } from "src/utils/sort";
import { FormattedValue } from "../OutcomeTable/FormattedValue";
import { OutcomeTableHeader } from "../OutcomeTable/OutcomeTableHeader";
import { OutcomeTableRow } from "../OutcomeTable/OutcomeTableRow";
import { OutcomeTableTotalRow } from "../OutcomeTable/OutcomeTableTotalRow";
import { recommendationsTableStyle } from "./RecommandationTab";

interface IChannelsRecommendationsTab {
    outcomeCampaignHeader: IForecastCampaignHeadCell[];
    scenario: IScenario;
    isTableLoading: boolean;
    totalAllocationCampaigns: IDictionary;
    channelGroupData: any;
    compareToLabel: string;
}

export const ChannelsRecommendationsTab: FC<IChannelsRecommendationsTab> = ({
    outcomeCampaignHeader,
    channelGroupData,
    scenario,
    isTableLoading,
    totalAllocationCampaigns,
    compareToLabel,
}) => {
    const classes = { ...recommendationsTableStyle(), ...optimizationStyle(useTheme()) };
    const optimizationOutcomeContext = useContext(OptimizationOutcomeContext);
    const filteredHeader = outcomeCampaignHeader.filter((h) => !h.hidden);
    const isCacScenario = scenario.scenarioType === SCENARIO_TYPE.CAC;

    const [openChannelList, setOpenChannelList] = useState("");
    const [openCampaignId, setOpenCampaignId] = useState("");

    useEffect(() => {
        setOpenCampaignId("");
    }, [compareToLabel, optimizationOutcomeContext?.metricRate]);

    const channelRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, channelName: string) => {
        e.stopPropagation();
        setOpenChannelList((openedId: string) => (channelName === openedId ? "" : channelName));
        setOpenCampaignId("");
    };

    const campaignRowClick = (campaignId: string) => {
        setOpenCampaignId((id) => (id === campaignId ? "" : campaignId));
    };

    const tableRowObj = (channelRow: any): IDictionary => {
        return {
            campaignName: (
                <Stack direction="row" alignItems="center" gap="20px">
                    <div style={{ textAlign: "center" }}>
                        <SVGIconRenderer
                            icon={openChannelList === channelRow.channelName ? "chevronDownIcon" : "chevronRightIcon"}
                            strokeColor="black"
                        />
                    </div>
                    <DataSourceAvatar programmaticName={channelRow.connectorName} className={classes.avatarImage} />
                    <Typography className={classes.campaignNameOverflow}>{channelRow.cleanChannelName}</Typography>
                </Stack>
            ),
            confidenceScore: channelRow.confidenceScore
                ? `${(channelRow.confidenceScore * 100).toFixed(0)}% ${getConfidenceLevel(channelRow.confidenceScore)}`
                : "",
            spend: formatValue(channelRow.spend, FORMATS.DOLLAR, 0),
            trueRevenue: formatValue(channelRow.trueRevenue, FORMATS.DOLLAR, 0),
            trueRoas: formatValue(channelRow.trueRoas, FORMATS.NUMERIC, 2),
            newCustomers:
                channelRow.newCustomers && channelRow.newCustomers < 0
                    ? 0
                    : formatValue(channelRow.newCustomers, FORMATS.NUMERIC, 0),
            cac: channelRow.cac && channelRow.cac < 0 ? 0 : formatValue(channelRow.cac, FORMATS.DOLLAR, 2),
            allocationSpend: (
                <>
                    {formatValue(channelRow.allocationSpend, FORMATS.DOLLAR, 0)}
                    <br />
                    <FormattedValue value={channelRow.totalSpendDiff} format={FORMATS.DOLLAR} precision={0} />
                </>
            ),
            forecastedRevenue: (
                <>
                    {formatValue(channelRow.forecastedRevenue, FORMATS.DOLLAR, 0)}
                    <br />
                    <FormattedValue value={channelRow.totalRevenueDiff} format={FORMATS.DOLLAR} precision={0} />
                </>
            ),
            forecastedRoas: (
                <>
                    {formatValue(channelRow.forecastedRoas, FORMATS.NUMERIC, 2)}
                    <br />
                    <FormattedValue value={channelRow.totalRoasDiff} format={FORMATS.NUMERIC} precision={2} />
                </>
            ),
            forecastedNewCustomers: (
                <>
                    {formatValue(channelRow.forecastedNewCustomers, FORMATS.NUMERIC, 0)}
                    <br />
                    <FormattedValue value={channelRow.totalNewCustomersDiff} format={FORMATS.NUMERIC} precision={0} />
                </>
            ),
            forecastedCac: (
                <>
                    {formatValue(channelRow.forecastedCac, FORMATS.DOLLAR, 2)}
                    <br />
                    <FormattedValue
                        isOpposite={isCacScenario}
                        value={channelRow.totalCacDiff}
                        format={isCacScenario ? FORMATS.DOLLAR : FORMATS.NUMERIC}
                        precision={2}
                    />
                </>
            ),
            lastScenarioTimeframeMMMCac: formatValue(channelRow.lastScenarioTimeframeMMMCac, FORMATS.DOLLAR, 2),
        };
    };

    return (
        <Stack gap="20px">
            <TableContainer className={classes.recommendationTable}>
                <Table>
                    <OutcomeTableHeader
                        scenario={scenario}
                        isChannelHeader={true}
                        compareToLabel={compareToLabel}
                        outcomeCampaignHeader={outcomeCampaignHeader}
                    />
                    {isTableLoading ? (
                        <TableRowsLoader
                            rowsNum={channelGroupData.length + 1}
                            colsNum={FORECASTED_CAMPAIGNS_HEADERS.length}
                        />
                    ) : (
                        <TableBody className={classes.tableBodyStyle}>
                            {channelGroupData.map((channelRow: any) => {
                                return (
                                    <Fragment key={channelRow.channelName}>
                                        <TableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            className={cursor("pointer")}
                                            hover
                                            onClick={(e) => {
                                                handleClickWithTextSelectionCheck(channelRowClick)(
                                                    e,
                                                    channelRow.channelName || "",
                                                );
                                            }}
                                            selected={openChannelList === channelRow.channelName}
                                        >
                                            {filteredHeader.map((element: any) => {
                                                return (
                                                    <TableCell
                                                        key={element.id}
                                                        className={element.className}
                                                        align={element.align}
                                                    >
                                                        {tableRowObj(channelRow)[element.id]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>

                                        <TableRow>
                                            <TableCell style={{ padding: 0 }} colSpan={outcomeCampaignHeader.length}>
                                                <Collapse in={openChannelList === channelRow.channelName}>
                                                    <Table>
                                                        <TableBody className={classes.tableBodyStyle}>
                                                            {stableSort(
                                                                channelRow.campaigns,
                                                                getComparator("asc", "campaignName"),
                                                            ).map((campaignRow: any) => {
                                                                return (
                                                                    <OutcomeTableRow
                                                                        outcomeCampaignHeader={outcomeCampaignHeader}
                                                                        rowClick={() =>
                                                                            campaignRowClick(campaignRow.campaignId)
                                                                        }
                                                                        key={campaignRow.campaignId}
                                                                        campaignRow={campaignRow}
                                                                        classes={classes}
                                                                        campaignOpenId={openCampaignId}
                                                                        scenario={scenario}
                                                                        campaignData={channelRow.campaigns}
                                                                        isChannelTab={true}
                                                                        compareToLabel={compareToLabel}
                                                                    />
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                );
                            })}
                            <OutcomeTableTotalRow header={filteredHeader} row={totalAllocationCampaigns} />
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Stack>
    );
};
