import { FC, useState, ChangeEvent } from "react";
import { Typography, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Stack } from "@mui/system";

import { CustomModal } from "../../core/CustomDialog/CustomModal";

interface IAmazonSellingPartnerModal {
    onContinueClicked: any;
    isLoading: boolean;
    closeModal: () => void;
}

const AMAZON_SELLER_CENTRAL = "amazon_seller_central";
const AMAZON_VENDOR_CENTRAL = "amazon_vendor_central";
const ASP_NAME_MAPPING = {
    [AMAZON_SELLER_CENTRAL]: "Amazon Seller Central",
    [AMAZON_VENDOR_CENTRAL]: "Amazon Vendor Central",
};
type ASPType = typeof AMAZON_SELLER_CENTRAL | typeof AMAZON_VENDOR_CENTRAL;

export const AmazonSellingPartnerModal: FC<IAmazonSellingPartnerModal> = ({
    closeModal,
    onContinueClicked,
    isLoading,
}) => {
    const [type, setType] = useState<string>("amazon_seller_central");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setType((event.target as HTMLInputElement).value);
    };

    return (
        <CustomModal
            title="Select Vendor Central or Seller Central"
            instanceSlot={
                <Stack gap={1}>
                    <Typography variant="body1">
                        Amazon Selling Partner is an API that supports both Vendor and Seller Central accounts.
                    </Typography>
                    <Typography variant="body1">
                        Select the account type you would like to connect to Prescient:
                    </Typography>
                    <Stack>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="asp-account-type-label"
                                defaultValue={AMAZON_SELLER_CENTRAL}
                                name="asp-button-group"
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value={AMAZON_SELLER_CENTRAL}
                                    control={<Radio />}
                                    label={ASP_NAME_MAPPING[AMAZON_SELLER_CENTRAL]}
                                />
                                <FormControlLabel
                                    value={AMAZON_VENDOR_CENTRAL}
                                    control={<Radio />}
                                    label={ASP_NAME_MAPPING[AMAZON_VENDOR_CENTRAL]}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                </Stack>
            }
            actions={{
                2: {
                    label: "Cancel",
                    onClick: closeModal,
                    disabled: isLoading,
                },
                1: {
                    label: `Proceed with ${ASP_NAME_MAPPING[type as ASPType]}`,
                    onClick: onContinueClicked(type === AMAZON_VENDOR_CENTRAL),
                    disabled: isLoading,
                },
            }}
            closeIcon
            closeModal={closeModal}
            sx={{ ".MuiPaper-root": { maxWidth: "700px" } }}
        />
    );
};
