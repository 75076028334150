import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import uniqueId from "lodash/uniqueId";
import isEqual from "lodash/isEqual";
import { useEditUserSettingsMutation } from "src/reduxState/apis/userSettingsUpdateApi";
import { userIdSelector } from "src/reduxState/slices/userSlice";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { IHeadCell } from "src/consts/performancePaidPage/performancePaidPage";
import { setUserSettings } from "src/reduxState/slices/settingsSlice";
import { DraggableColumnSelect } from "../DraggableColumnSelect/DraggableColumnSelect";

interface IHeroMetricColumnSelector {
    selectedTableColumns: IHeadCell[];
    setselectedTableColumns: React.Dispatch<React.SetStateAction<IHeadCell[]>>;
    handleTableCol: (column: IHeadCell) => void;
}

export const HeroMetricColumnSelector: React.FC<IHeroMetricColumnSelector> = ({
    handleTableCol,
    selectedTableColumns,
    setselectedTableColumns,
}) => {
    const [editUserSettings] = useEditUserSettingsMutation();
    const dispatch = useDispatch();
    const currentUserId = useSelector(userIdSelector);
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const { enqueueSnackbar } = useSnackbar();

    const [prevCards, setPrevCards] = useState<IHeadCell[]>(selectedTableColumns);

    const updateColumnsInSettingsApi = useCallback(
        (selectedCols: IHeadCell[]) => {
            if (!isEqual(selectedCols, prevCards)) {
                const selectedColIds = selectedCols.map((col) => ({ id: col.id, isHidden: col.hidden ?? false }));
                editUserSettings({ orgId: currentOrgId, userId: currentUserId, performanceHeroMetrics: selectedColIds })
                    .unwrap()
                    .then(() => {
                        dispatch(
                            setUserSettings({
                                settings: selectedColIds,
                                fromApi: false,
                                key: "performanceHeroMetrics",
                            }),
                        );
                        enqueueSnackbar("Your metrics have been saved and will persist for your account.", {
                            id: uniqueId(),
                            variant: "success",
                        });
                    })
                    .catch((error) => {
                        setselectedTableColumns(prevCards);
                        enqueueSnackbar("Something went wrong!", {
                            id: uniqueId(),
                            variant: "error",
                        });
                        throw new Error(`column reorder error ${error}`);
                    });
            }
        },
        [prevCards, currentUserId, currentOrgId],
    );

    const onClickHandleOpen = useCallback(() => {
        setPrevCards([...selectedTableColumns]);
    }, [selectedTableColumns]);

    return (
        <DraggableColumnSelect
            handleTableCol={handleTableCol}
            selectedTableColumns={selectedTableColumns}
            setselectedTableColumns={setselectedTableColumns}
            updateColumnsInSettingsApi={updateColumnsInSettingsApi}
            onClickHandleOpen={onClickHandleOpen}
            cards={selectedTableColumns}
            setCards={setselectedTableColumns}
            keepFirstRow={false}
            title="Metrics"
            visibleScroll={false}
        />
    );
};
