import React from "react";
import { FormControl, FormHelperText, IconButton, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { Flex } from "../../components/core/Flex/Flex";
import {
    getMetricNames,
    CAMPARE_CONDITIONS,
    PERFORMANCE_FILTER_LIST,
    IHeadCell,
} from "../../consts/performancePaidPage/performancePaidPage";
import { isContainsDuplicateValues, checkRangeValue } from "../../services/performancePage/performancePage";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { useSelector } from "react-redux";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";

export interface IFieldItem {
    metric: string;
    condition: string;
    value: string;
}

export interface IFieldItems {
    metricConditionInputs: IFieldItem[];
}

interface IMetricFilterInputs {
    handleFilter: (paramKey: string, item: { id: string }, isArray?: boolean) => void;
    initialValues: { metricConditionInputs: IFieldItem[] };
    classes: any;
    selectedTableColumns: IHeadCell[];
}

export const MetricFilterInputs: React.FC<IMetricFilterInputs> = ({
    handleFilter,
    initialValues,
    classes,
    selectedTableColumns,
}) => {
    const theme = useTheme();
    const metricArray = selectedTableColumns.map((col) => ({ key: col.id, title: col.label }));
    const updatedInitialValues = initialValues.metricConditionInputs.map((conditionInput, index) => {
        if (conditionInput.metric === "") {
            return {
                metric: metricArray[0].key,
                condition: "",
                value: "",
            };
        } else {
            return conditionInput;
        }
    });

    const handleFilterCaller = ({ metric, condition, value }: IFieldItem) => {
        handleFilter(`filter[${metric}][${condition}]`, {
            id: `${value.toLowerCase()}`,
        });
        return;
    };

    const connectors = useSelector(connectorsSelector);
    const metricNames = getMetricNames(connectors);

    return (
        <div>
            <Formik
                initialValues={{ metricConditionInputs: updatedInitialValues }}
                validateOnChange={false}
                onSubmit={() => {}}
            >
                {({ values, handleChange }: FormikProps<IFieldItems>) => {
                    return (
                        <Form>
                            <FieldArray
                                name="metricConditionInputs"
                                render={({ remove, push }) => {
                                    const { metricConditionInputs } = values;
                                    let timeout: ReturnType<typeof setTimeout>;

                                    const addEntry = (conditionInput: IFieldItem) => {
                                        clearTimeout(timeout);
                                        push({
                                            metric: metricArray[0].key,
                                            condition: "",
                                            value: "",
                                        });
                                        handleFilterCaller(conditionInput);
                                    };

                                    const removeEntry = (conditionInput: IFieldItem, index: number) => {
                                        if (conditionInput.metric && conditionInput.value) {
                                            handleFilterCaller(conditionInput);
                                        }
                                        remove(index);
                                    };

                                    const setAutoFocus = (nextEl: string) => {
                                        timeout = setTimeout(() => {
                                            if (document.activeElement) {
                                                (document.activeElement as HTMLElement).blur();
                                                document.getElementById(nextEl)?.focus();
                                            }
                                        }, 0);
                                    };

                                    return (
                                        <div className={classes.inputWrapper}>
                                            {metricConditionInputs && metricConditionInputs.length > 0 ? (
                                                metricConditionInputs.map((conditionInput, index) => {
                                                    const isLastEntry = metricConditionInputs.length - 1 === index;
                                                    const isDuplicateEntryErr =
                                                        index !== 0 &&
                                                        isContainsDuplicateValues(index, conditionInput, values);

                                                    let isValueBetweenInRangeError: string | undefined = "";

                                                    if (index !== 0 && conditionInput.value) {
                                                        isValueBetweenInRangeError = checkRangeValue(
                                                            conditionInput,
                                                            values,
                                                            connectors,
                                                        );
                                                    }

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Flex spacing="10px">
                                                                <FormControl sx={{ width: "40%" }}>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id={`metricConditionInputs.${index}.metric`}
                                                                        placeholder="Condition"
                                                                        value={conditionInput.metric}
                                                                        name={`metricConditionInputs.${index}.metric`}
                                                                        onChange={handleChange}
                                                                        disabled={!isLastEntry}
                                                                        onClose={() =>
                                                                            setAutoFocus(
                                                                                `metricConditionInputs.${index}.condition`,
                                                                            )
                                                                        }
                                                                        autoFocus
                                                                    >
                                                                        {metricArray.map(
                                                                            (option: {
                                                                                key: string;
                                                                                title: string;
                                                                            }) => (
                                                                                <MenuItem
                                                                                    key={option.key}
                                                                                    value={option.key}
                                                                                >
                                                                                    {option.title}
                                                                                </MenuItem>
                                                                            ),
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                                <FormControl sx={{ width: "20%" }}>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id={`metricConditionInputs.${index}.condition`}
                                                                        placeholder="Condition"
                                                                        value={conditionInput.condition}
                                                                        name={`metricConditionInputs.${index}.condition`}
                                                                        onChange={handleChange}
                                                                        disabled={!isLastEntry}
                                                                        onClose={() =>
                                                                            setAutoFocus(
                                                                                `metricConditionInputs.${index}.value`,
                                                                            )
                                                                        }
                                                                    >
                                                                        {PERFORMANCE_FILTER_LIST[3].items[0].condition.map(
                                                                            (option: {
                                                                                key: string;
                                                                                title: string;
                                                                            }) => (
                                                                                <MenuItem
                                                                                    key={option.key}
                                                                                    value={option.key}
                                                                                >
                                                                                    {option.title}
                                                                                </MenuItem>
                                                                            ),
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                                <TextField
                                                                    id={`metricConditionInputs.${index}.value`}
                                                                    placeholder="Enter value"
                                                                    variant="outlined"
                                                                    sx={{ width: "60%" }}
                                                                    name={`metricConditionInputs.${index}.value`}
                                                                    value={conditionInput.value}
                                                                    onChange={(e) => {
                                                                        if (/^[0-9.\b]*$/.test(e.target.value)) {
                                                                            handleChange(e);
                                                                        }
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (
                                                                            e.key === "Enter" &&
                                                                            conditionInput.metric &&
                                                                            conditionInput.condition &&
                                                                            conditionInput.value &&
                                                                            !isValueBetweenInRangeError &&
                                                                            !isDuplicateEntryErr
                                                                        ) {
                                                                            addEntry(conditionInput);
                                                                        }
                                                                    }}
                                                                    disabled={!isLastEntry}
                                                                    autoFocus
                                                                />
                                                                <div
                                                                    className="btn-box"
                                                                    style={{ width: "10%", display: "flex" }}
                                                                >
                                                                    {isLastEntry && (
                                                                        <IconButton
                                                                            onClick={() => addEntry(conditionInput)}
                                                                            disabled={
                                                                                !conditionInput.metric ||
                                                                                !conditionInput.condition ||
                                                                                !conditionInput.value ||
                                                                                !!isValueBetweenInRangeError ||
                                                                                isDuplicateEntryErr
                                                                            }
                                                                        >
                                                                            <SVGIconRenderer
                                                                                icon="plusIcon"
                                                                                strokeColor="rgba(0, 0, 0, 0.54)"
                                                                            />
                                                                        </IconButton>
                                                                    )}
                                                                    {!isLastEntry && (
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                removeEntry(conditionInput, index)
                                                                            }
                                                                        >
                                                                            <SVGIconRenderer
                                                                                icon="closeIcon"
                                                                                strokeColor="rgba(0, 0, 0, 0.54)"
                                                                            />
                                                                        </IconButton>
                                                                    )}
                                                                </div>
                                                            </Flex>
                                                            {isLastEntry && isDuplicateEntryErr && (
                                                                <FormHelperText
                                                                    style={{
                                                                        color: theme.palette.error.main,
                                                                        margin: "0px 5px 5px",
                                                                    }}
                                                                >
                                                                    {`${metricNames[conditionInput.metric]} with ${
                                                                        CAMPARE_CONDITIONS[conditionInput.condition]
                                                                    } is already exists, please remove duplicate entry.`}
                                                                </FormHelperText>
                                                            )}
                                                            {isLastEntry && isValueBetweenInRangeError && (
                                                                <FormHelperText
                                                                    style={{
                                                                        color: theme.palette.error.main,
                                                                        margin: "0px 5px 5px",
                                                                    }}
                                                                >
                                                                    {isValueBetweenInRangeError}
                                                                </FormHelperText>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })
                                            ) : (
                                                <IconButton
                                                    onClick={() => push({ metric: "", value: "", error: false })}
                                                >
                                                    <SVGIconRenderer
                                                        icon="plusIcon"
                                                        strokeColor="rgba(0, 0, 0, 0.54)"
                                                    />
                                                </IconButton>
                                            )}
                                        </div>
                                    );
                                }}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
