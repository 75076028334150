import React from "react";
import { Avatar, Chip, Tooltip, Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";
import { Box, useTheme } from "@mui/system";

import { formatDate } from "../../../services/members/members";
import { demoModeSelector } from "../../../reduxState/slices/demoModeSlice";
import { customAvatarClass, useStylesForChips, useStylesForRowInTable } from "../../../consts/StylesForTables";
import { PRIMARY_MAIN_COLOR } from "../../../consts/colors";
import { capitalizeFirstLetter, handleClickWithTextSelectionCheck } from "../../../services/utils";
import { cursor } from "../../../assets/styles/commonStyle";
import useAvatar from "../../../components/MemberCard/useAvatar";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { TableActionCell } from "../../../components/core/TableActionCell/TableActionCell";

const useStyles = () => ({
    statusClass: css({
        textTransform: "uppercase",
        fontWeight: "bold",
        width: "fit-content",
        height: "32px",
        fontSize: "13px",
        lineHeight: "18px",
        letterSpacing: "0.16px",
    }),
    expiredStatus: css({
        backgroundColor: "#f443362b",
        color: "#d14838",
        borderColor: "#d14838",
    }),
    pendingStatus: css({
        backgroundColor: "#eeeeee",
        color: "#888888",
        borderColor: "#888888",
    }),
    arrowClass: css({
        fontSize: "10px",
        marginLeft: "2px",
    }),
    iconClass: css({ height: "100%", display: "flex", alignItems: "center" }),
});

interface ISuperAdminMemberPageRow {
    onClickAction: any;
    labelId: string;
    member: any;
    companies: any;
    statusClick: (e: any, member: any) => void;
    deleteClick: (e: React.MouseEvent<any>, member: any) => void;
    editClick: (e: React.MouseEvent<any>, member: any) => void;
}

export const SuperAdminMembersTableRow: React.FC<ISuperAdminMemberPageRow> = ({
    member,
    onClickAction,
    labelId,
    companies,
    statusClick,
    editClick,
    deleteClick,
}) => {
    const classes = { ...useStyles(), ...useStylesForRowInTable() };
    const memberCompanies = companies.filter((c: any) => member.companies.includes(c.id));
    const isDemoMode = useSelector(demoModeSelector);
    const avatarUrl = useAvatar(member.avatarMediumUrl || "");
    const chipClasses = useStylesForChips(useTheme());

    return (
        <TableRow
            hover
            aria-checked={member.isChecked}
            onClick={() => handleClickWithTextSelectionCheck(onClickAction)(member.id)}
            key={member.id}
            className={`${classes.bodyRow} ${cursor("pointer")}`}
            data-cy={`member-${member.id}`}
        >
            <TableCell className={classes.tableCell}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {avatarUrl ? (
                        <Avatar
                            src={avatarUrl}
                            sx={{ width: "32px !important", height: "32px !important", marginRight: "8px" }}
                        />
                    ) : (
                        <Avatar
                            className={`${customAvatarClass}`}
                            sx={{ width: "32px !important", height: "32px !important", marginRight: "8px" }}
                        >
                            {member.firstName && member.lastName
                                ? `${member.firstName[0].toUpperCase()}${member.lastName[0].toUpperCase()}`
                                : ""}
                        </Avatar>
                    )}
                    <span>
                        {member.firstName} {member.lastName}
                    </span>
                </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2" color={PRIMARY_MAIN_COLOR}>
                    {member.email}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography variant="body2" component="div">
                    {member.isSuperadmin === true ? (
                        <Chip label="Super Admin" className={chipClasses.positiveChip} />
                    ) : (
                        <Chip label="User" className={chipClasses.blueChip} />
                    )}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>{formatDate(member.createdAt.toString())}</TableCell>
            {!isDemoMode && (
                <TableCell className={classes.tableCell}>
                    {member.invitationState === "expired" || member.invitationState === "pending" ? (
                        <Typography>
                            {capitalizeFirstLetter(member.invitationState)}
                            <span style={{ margin: "0 2px" }}>&#x2022;</span>
                            <span onClick={(e) => statusClick(e, member)} style={{ color: "#009985" }}>
                                Resend
                            </span>
                        </Typography>
                    ) : (
                        ""
                    )}
                </TableCell>
            )}
            <TableCell className={classes.tableCell}>
                <Typography color={PRIMARY_MAIN_COLOR}>{memberCompanies.length}</Typography>
            </TableCell>
            {!isDemoMode && (
                <TableActionCell
                    actionIcons={[
                        { title: "Edit", onClick: editClick, data: member, icon: "editIcon2" },
                        { title: "Delete", onClick: deleteClick, data: member, icon: "trashIcon" },
                    ]}
                />
            )}
        </TableRow>
    );
};
