import React, { FC, Fragment } from "react";
import { format } from "date-fns";
import capitalize from "lodash/capitalize";
import { Stack } from "@mui/system";
import { Typography, Container, useTheme } from "@mui/material";
import { IEventLog } from "../../../interfaces/entities/IEventLog";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { makeRenderable } from "../../../utils/format";
import { useStyles } from "../../../components/DeleteOrganizationModal/DeleteOrganizationModal";

interface IEventModal {
    detailView: IEventLog;
}

const getDetail = (key: string, value: string) => {
    if (!value) {
        return null;
    }
    return (
        <Typography key={key}>
            <Typography component="span" fontWeight="600" color="text.secondary">
                {`${key}: `}
            </Typography>
            {value}
        </Typography>
    );
};

export const EventModal: FC<IEventModal> = ({ detailView }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const userInfoArray = [
        {
            key: "Name",
            value: detailView.username,
        },
        {
            key: "Type",
            value: detailView.userType,
        },
        {
            key: "Id",
            value: detailView.userId,
        },
        {
            key: "Address",
            value: detailView.remoteAddress,
        },
    ];

    const actionInfoArray = [
        { key: "Action", value: detailView.action },
        {
            key: "Changed Date",
            value: `${format(new Date(detailView.createdAt), "MMM d, yyyy")}`,
        },
        {
            key: "Changed Time",
            value: `${format(new Date(detailView.createdAt), "hh:mm:ss a")}`,
        },
        { key: "Log id", value: detailView.id },
        { key: "Auditable Type", value: detailView.auditableType },
    ];

    const auditableInfoArray = [
        { key: "Type", value: detailView.auditableType },
        { key: "Id", value: detailView.auditableId },
        { key: "Company", value: detailView.companyName || "" },
        { key: "Company Id", value: detailView.associatedId || "" },
    ];

    const getColor = () => {
        switch (detailView.action.toLowerCase()) {
            case "create":
                return theme.palette.success.dark;
            case "update":
                return theme.palette.info.dark;
            case "destroy":
                return theme.palette.error.main;
            default:
                return theme.palette.text.secondary;
        }
    };

    return (
        <>
            <Container
                disableGutters
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "min-content",
                    overflow: "hidden",
                    gap: "1rem",
                    marginBottom: "1rem",
                    flexWrap: "wrap",
                }}
            >
                <Stack sx={{ margin: 0, padding: "0", flexGrow: 1 }}>
                    <Typography className={classes.italicText} sx={{ padding: "5px 0" }}>
                        User
                    </Typography>
                    <Stack sx={{ padding: ".5rem 1rem", border: `2px ${theme.palette.text.secondary} solid`, borderRadius: "10px" }}>
                        {userInfoArray.map(({ key, value }) => getDetail(key, value))}
                    </Stack>
                </Stack>
                <Stack sx={{ margin: 0, padding: 0, height: "100%", display: "flex" }}>
                    <Typography className={classes.italicText} sx={{ padding: "5px 0" }}>
                        Action
                    </Typography>
                    <Container disableGutters sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                        <Stack sx={{ justifyContent: "center", flexGrow: 1 }}>
                            <Typography color={getColor} sx={{ fontSize: "1.5rem" }}>
                                {capitalize(detailView.action)}
                            </Typography>
                            <Typography className={classes.italicText}>
                                {actionInfoArray.find((obj) => obj.key === "Changed Date")?.value}
                            </Typography>
                            <Typography className={classes.italicText}>
                                {actionInfoArray.find((obj) => obj.key === "Changed Time")?.value}
                            </Typography>
                            <Typography color={theme.palette.text.secondary}>
                                {`Log Id: ${actionInfoArray.find((obj) => obj.key === "Log id")?.value}`}
                            </Typography>
                        </Stack>
                        <Typography>
                            <SVGIconRenderer
                                icon="arrowRightIcon"
                                height="1.5rem"
                                width="1.5rem"
                                style={{ cursor: "default" }}
                            />
                        </Typography>
                    </Container>
                </Stack>
                <Stack sx={{ margin: 0, padding: "0", flexGrow: 1 }}>
                    <Typography className={classes.italicText} sx={{ padding: "5px 0" }}>
                        Auditable
                    </Typography>
                    <Stack sx={{ padding: ".5rem 1rem", border: `2px ${theme.palette.text.secondary} solid`, borderRadius: "10px" }}>
                        {auditableInfoArray.map(({ key, value }) => getDetail(key, value))}
                    </Stack>
                </Stack>
            </Container>
            <Stack>
                <Typography className={classes.italicText} sx={{ padding: "5px 0" }}>
                    Audited Fields
                </Typography>
                <Container
                    disableGutters
                    sx={{ overflow: "auto", display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}
                >
                    {Object.entries(detailView.auditedChanges).map(([key, value]) => {
                        return (
                            <Fragment key={key}>
                                <Typography
                                    sx={{ marginRight: "1rem" }}
                                    component="span"
                                    fontWeight="600"
                                    color="text.secondary"
                                >
                                    {`${key}: `}
                                </Typography>
                                {Array.isArray(value) ? (
                                    <>
                                        <Typography sx={{ marginRight: "1rem" }}>{makeRenderable(value[0])}</Typography>
                                        <Typography sx={{ marginRight: "1rem" }}>
                                            <SVGIconRenderer
                                                icon="arrowRightIcon"
                                                strokeColor={theme.palette.primary.main}
                                                height="1.5rem"
                                                width="1.5rem"
                                                style={{ cursor: "default" }}
                                            />
                                        </Typography>
                                        <Typography>{makeRenderable(value[1])}</Typography>
                                    </>
                                ) : (
                                    <Typography>{makeRenderable(value)}</Typography>
                                )}
                            </Fragment>
                        );
                    })}
                </Container>
            </Stack>
        </>
    );
};
