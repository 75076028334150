import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ClickAwayListener, PopperPlacementType } from "@mui/base";
import { Button, Grow, MenuItem, Paper, Popper, Theme, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { css } from "@emotion/css";

import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { WHITE_COLOR } from "src/consts/colors";

interface IChildProps {
    closeDialog: () => void;
}

interface ILightMenuProps {
    items?: Array<{
        label: string | JSX.Element | null;
        key: string;
        onClick: () => void;
        isSelected?: boolean;
        isDisableMenu?: boolean;
    }>;
    isDataStyled?: boolean;
    title?: JSX.Element | string | null;
    header?: string;
    onClickHandleOpen?: () => void;
    isDisabled?: boolean;
    endIcon?: boolean;
    startIcon?: any;
    openCustomEndIcon?: any;
    closeCustomEndIcon?: any;
    children?: JSX.Element;
    isList?: boolean;
    placement?: PopperPlacementType;
    isIconButton?: boolean;
    extraButtonClass?: any;
    menuClass?: any;
    id?: string;
    buttonTitle?: string;
    transformOrigin?: string;
    menuCloseHandle?: () => void;
    overFlow?: boolean;
    dataCy?: string;
    wideset?: boolean;
    variant?: "text" | "outlined" | "contained";
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
}

const useStylesForLightMenu = (theme: Theme, isDataStyled: boolean, isIconButton: boolean) => ({
    title: css({
        textTransform: isDataStyled ? "none" : "capitalize",
        padding: "6px 16px",
        minWidth: "206px",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "16px",
        lineHeight: "150%",
        letterSpacing: "0.17px",
        borderBottom: "1px solid #E0E0E0",
    }),
    buttonWithHeader: css({
        textTransform: "none",
        fontSize: isDataStyled ? "16px" : "12px",
        lineHeight: isDataStyled ? "24px" : "166%",
        letterSpacing: isDataStyled ? "0.15px" : "0.4px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px !important",
        },
    }),
    defaultButton: css({
        textTransform: "inherit",
        "&:hover": {
            background: isIconButton ? "rgba(0, 0, 0, 0.04) !important" : "rgba(66, 150, 133, 0.04) !important",
        },
    }),
    selectedClass: css({
        flexGrow: 1,
    }),
    defaultClass: css({}),
    arrowIcon: css({
        marginLeft: "20px",
    }),
    customMenu: css({
        zIndex: theme.zIndex.modal,
    }),
    buttonClass: css({
        height: "38px",
        minWidth: "35px",
        padding: "7px 16px",
        whiteSpace: "pre",
    }),
    iconButton: css({
        height: "40px",
        minWidth: "40px",
        padding: 0,
        borderRadius: "50%",
        color: WHITE_COLOR,
    }),
    widesetButton: css({
        justifyContent: "space-between",
    }),
});

export const LightMenu = forwardRef<IChildProps, ILightMenuProps>(
    (
        {
            isDataStyled = false,
            items,
            title,
            header,
            onClickHandleOpen,
            isDisabled = false,
            endIcon = false,
            openCustomEndIcon,
            closeCustomEndIcon,
            startIcon = false,
            children,
            isList = true,
            placement,
            isIconButton = false,
            extraButtonClass,
            id,
            buttonTitle = "",
            transformOrigin,
            menuCloseHandle,
            overFlow = true,
            menuClass,
            dataCy,
            wideset = false,
            ...rest
        },
        ref,
    ) => {
        const classes = useStylesForLightMenu(useTheme(), isDataStyled, isIconButton);

        const [open, setOpen] = useState(false);
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

        useImperativeHandle(ref, () => ({
            closeDialog() {
                setOpen(false);
            },
        }));

        const menuCloseAction = () => {
            if (menuCloseHandle) {
                menuCloseHandle();
            }
        };

        const menuOpenAction = () => {
            if (onClickHandleOpen) {
                onClickHandleOpen();
            }
        };

        const handleToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setAnchorEl(event.currentTarget);
            setOpen((prevOpen) => {
                if (prevOpen === true) {
                    menuCloseAction();
                } else {
                    menuOpenAction();
                }
                return !prevOpen;
            });
        };

        const handleClose = (event: MouseEvent | TouchEvent) => {
            const target = event.target as HTMLElement;
            if (target?.tagName === "BODY" || (anchorEl && anchorEl.contains(target))) {
                return;
            }
            menuCloseAction();
            setOpen(false);
        };

        const handleMenuItemClick = (onClick: () => void) => {
            if (onClick) {
                onClick();
                setOpen(false);
            }
        };

        return (
            <div className={extraButtonClass}>
                <Button
                    {...rest}
                    id={id || "composition-button"}
                    data-cy={dataCy}
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    disabled={isDisabled}
                    endIcon={
                        endIcon ? (
                            <SVGIconRenderer
                                icon="chevronRightIcon"
                                className={classes.arrowIcon}
                                height="20px"
                                width="20px"
                            />
                        ) : (
                            openCustomEndIcon && (open ? closeCustomEndIcon || openCustomEndIcon : openCustomEndIcon)
                        )
                    }
                    startIcon={startIcon && startIcon}
                    className={`${header ? classes.buttonWithHeader : classes.defaultButton} ${
                        !header && (isIconButton ? classes.iconButton : classes.buttonClass)
                    } ${wideset && classes.widesetButton}`}
                    title={open ? "" : buttonTitle}
                >
                    {title}
                </Button>
                <Popper
                    className={`${classes.customMenu} ${menuClass}`}
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement || "bottom-start"}
                    transition
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === "bottom-start" ? "left top" : transformOrigin,
                            }}
                        >
                            <Paper
                                elevation={8}
                                style={{
                                    maxHeight: isList ? "calc(100vh - 75px)" : overFlow ? "calc(100vh - 200px)" : "",
                                    overflow: overFlow ? "auto" : "unset",
                                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div>
                                        {isList ? (
                                            <div className="itemsContainer" style={{ padding: "8px 0px" }}>
                                                {header ? (
                                                    <div>
                                                        <Typography className={classes.title}>{header}</Typography>
                                                    </div>
                                                ) : null}
                                                {items && items.length > 0
                                                    ? items.map((item) => (
                                                          <MenuItem
                                                              id={item.key}
                                                              key={item.key}
                                                              onClick={() => handleMenuItemClick(item.onClick)}
                                                          >
                                                              <Typography
                                                                  component="span"
                                                                  variant="body1"
                                                                  className={
                                                                      item.isSelected
                                                                          ? classes.selectedClass
                                                                          : classes.defaultClass
                                                                  }
                                                              >
                                                                  {item.label}
                                                              </Typography>
                                                              {item.isSelected ? (
                                                                  <SVGIconRenderer
                                                                      icon="checkIcon"
                                                                      strokeColor="primary"
                                                                  />
                                                              ) : null}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </div>
                                        ) : (
                                            <>{children}</>
                                        )}
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    },
);
