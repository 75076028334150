import { Typography } from "@mui/material";

interface IVerificationHeaderProps {
    mainText: string;
    subText: string | JSX.Element;
}

export const VerificationHeader = ({ mainText, subText }: IVerificationHeaderProps) => (
    <>
        <Typography>{mainText}</Typography>
        <Typography>{subText}</Typography>
    </>
);
