import { css } from "@emotion/css";
import {
    Checkbox,
    Collapse,
    FormHelperText,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { cursor } from "../../../../assets/styles/commonStyle";
import { TableRowsLoader } from "../../../../components/TableLoader/TableLoader";
import { campaignSelectionForFormTableHeader, Choice } from "../../../../consts/optimizationPage/optimizationPage";
import { CampaignStatus, TRUE_METRICS } from "../../../../consts/performancePaidPage/performancePaidPage";
import { FORMATS } from "../../../../enums/Formats";
import { IDictionary } from "../../../../interfaces/IDictionary";
import { ICampaignData, IScenario, ISelectedTotalMetric } from "../../../../interfaces/entities/IScenario";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { isScenarioEditableSelector } from "../../../../reduxState/slices/scenarioSlice";
import {
    calculateTotalForSelectedCampaigns,
    campaignSelectClickLogic,
} from "../../../../services/optimizationPage/optimization";
import { capitalizeFirstLetter, formatValue } from "../../../../services/utils";
import { getComparator, stableSort } from "../../../../utils/sort";
import { DataSourceAvatar } from "../../../DataSourceAvatar/DataSourceAvatar";
import { SVGIconRenderer } from "../../../SVGIconRenderer/SVGIconRenderer";
import { CampaignTableHead } from "./CampaignTableHead";
import { OPTIIMIZATION_CREATE_PATH } from "src/consts/path/path";

type channelData = Array<{
    channelName: string;
    spend: number;
    trueRevenue: number;
    trueRoas: number;
    isLocked: boolean;
    currentEstimatedDailySpend: number;
    campaigns: IMetricAttributionTableValuesTransformed[];
}>;

interface ICampaignTable {
    classes: any;
    errors: any;
    touched: any;
    channelTableData: channelData;
    orderBy: string;
    order: Choice;
    selectedCampaings: ICampaignData;
    values: IScenario;
    lastDaysTooltip: string;
    setSelectedCampaigns: React.Dispatch<React.SetStateAction<ICampaignData>>;
    handleRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    channelGrp: IDictionary<IMetricAttributionTableValuesTransformed[]>;
    openChannelList: string;
    setOpenChannelList: React.Dispatch<React.SetStateAction<string>>;
    isLoading?: boolean;
    eligibleCampaigns: IMetricAttributionTableValuesTransformed[];
    updateScenarioInputs: (list: ICampaignData) => void;
    filterChangeEvent: (timeframe: string | number, campaigns: ICampaignData) => void;
}

const campaignTableStyle = () => ({
    tableBodyStyle: css({
        ".channelName": {
            border: "solid rgba(224, 224, 224, 1)",
            borderWidth: "0 0 1px 0",
            textAlign: "left",
        },
    }),
});

export const CampaignTable: React.FC<ICampaignTable> = ({
    classes,
    errors,
    channelTableData,
    orderBy,
    order,
    handleRequestSort,
    selectedCampaings: selectedCampaigns,
    setSelectedCampaigns,
    channelGrp,
    openChannelList,
    setOpenChannelList,
    values,
    isLoading,
    touched,
    updateScenarioInputs,
    eligibleCampaigns,
    filterChangeEvent,
    lastDaysTooltip,
}) => {
    const campaignTableClass = campaignTableStyle();
    const eligibleCampaignIds = eligibleCampaigns.map((campaign) => campaign.campaignId);
    const isChannelSelected = (name: string) => selectedCampaigns.map((c) => c.channel).indexOf(name) !== -1;
    const isCampaignSelected = (name: string) => selectedCampaigns.map((c) => c.campaignId).indexOf(name) !== -1;
    const isScenarioEditable =
        location.pathname.includes(OPTIIMIZATION_CREATE_PATH) || useSelector(isScenarioEditableSelector);

    const [tableDataWithLock, setTableDataWithLock] = useState(channelTableData);
    const [selectedCampaignsTotal, setSelectedCampaignsTotal] = useState<ISelectedTotalMetric>({
        spend: 0,
        trueRevenue: 0,
        trueRoas: 0,
        currentEstimatedDailySpend: 0,
        currentEstimatedSpend: 0,
        newCustomers: 0,
        cac: 0,
    });

    const calculateTotal = () => {
        // calculate spend, revenue and ROAS depend on campaign selection
        const selectedCampaignIds = values.campaignData.map((c) => c.campaignId);
        const allCampaigns = Object.values(channelGrp)
            .flat(1)
            .filter((c) => selectedCampaignIds.includes(c.campaignId || ""));
        setSelectedCampaignsTotal(calculateTotalForSelectedCampaigns(allCampaigns));
    };

    useEffect(() => {
        setTableDataWithLock(channelTableData);
    }, [channelTableData]);

    useEffect(() => {
        calculateTotal();
    }, [channelGrp, values.campaignData]);

    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const getEligibleCampaigns = (campaigns: ICampaignData) => {
        return campaigns.filter((c) => eligibleCampaignIds.includes(c.campaignId));
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (isScenarioEditable) {
            if (checked) {
                const allSelectedCampaigns = tableDataWithLock
                    .map((channel) => channel.campaigns)
                    .flat(1)
                    .map((c) => ({
                        campaignId: c.campaignId || "",
                        channel: c.connectorName || "",
                        isLocked: c.isLocked || false,
                        budgetAllocated: c.currentEstimatedDailySpend || 0,
                        currentEstimatedSpend: c.currentEstimatedSpend || 0,
                    }));

                const eligibleSelectedCampaigns: ICampaignData = getEligibleCampaigns(allSelectedCampaigns);

                setSelectedCampaigns(eligibleSelectedCampaigns);
                updateScenarioInputs(eligibleSelectedCampaigns);
                filterChangeEvent(values.forecastTimeframe, eligibleSelectedCampaigns);
            } else {
                setSelectedCampaigns([]);
                updateScenarioInputs([]);
                filterChangeEvent(values.forecastTimeframe, []);
            }
        }
    };

    const handleChannelRowClick = (event: React.MouseEvent<unknown>, connectorName: string) => {
        if (isScenarioEditable) {
            const selectedChannels = campaignSelectClickLogic(
                [...new Set(selectedCampaigns.map((s) => s.channel || ""))],
                connectorName,
            );

            const newSelectedChannelCampaigns = selectedChannels
                .map((channel) =>
                    channel === connectorName
                        ? channelGrp[channel].map((c: any) => ({
                              campaignId: c.campaignId,
                              channel: c.connectorName,
                              isLocked: c.isLocked,
                              budgetAllocated: c.currentEstimatedDailySpend || 0,
                              currentEstimatedSpend: c.currentEstimatedSpend,
                          }))
                        : selectedCampaigns.filter((c) => c.channel !== connectorName),
                )
                .flat(1);

            const eligibleSelectedCampaigns = getEligibleCampaigns(newSelectedChannelCampaigns);

            const allSelectedCampaigns: ICampaignData = [
                ...new Map(eligibleSelectedCampaigns.map((item) => [item.campaignId, item])).values(),
            ];

            setSelectedCampaigns(allSelectedCampaigns);
            updateScenarioInputs(allSelectedCampaigns);
            filterChangeEvent(values.forecastTimeframe, allSelectedCampaigns);
        }
    };

    const handleCampaignRowClick = (event: React.MouseEvent<unknown>, campaignId: string) => {
        if (isScenarioEditable) {
            const newSelectedCampaigns = campaignSelectClickLogic(
                selectedCampaigns.map((s) => s.campaignId),
                campaignId,
            );

            let selectedChannelCampaign: ICampaignData = [];
            Object.entries(channelGrp).forEach(([key, campaigns]) => {
                campaigns.filter((camp) => {
                    if (camp.campaignId && newSelectedCampaigns.includes(camp.campaignId || "")) {
                        selectedChannelCampaign = [
                            ...selectedChannelCampaign,
                            {
                                campaignId: camp.campaignId,
                                channel: camp.connectorName || "",
                                isLocked: camp.isLocked || false,
                                budgetAllocated: camp.currentEstimatedDailySpend || 0,
                                currentEstimatedSpend: camp.currentEstimatedSpend || 0,
                            },
                        ];
                    }
                });
            });
            const allSelectedCampaigns = [
                ...new Map(selectedChannelCampaign.map((item) => [item.campaignId, item])).values(),
            ];
            setSelectedCampaigns(allSelectedCampaigns);
            updateScenarioInputs(allSelectedCampaigns);
            filterChangeEvent(values.forecastTimeframe, allSelectedCampaigns);
        }
    };

    const openCollapse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, channelName: string) => {
        e.stopPropagation();
        setOpenChannelList((openedId) => (channelName === openedId ? "" : channelName));
    };

    const selectedCampaignsCount = selectedCampaigns.length;
    const channelCount =
        tableDataWithLock.length > 0
            ? tableDataWithLock
                  .map((channel) => channel.campaigns)
                  .flat(1)
                  .map((c) => c.campaignId).length
            : 0;

    const selectedCampaignForChannel = (channelName: string) => {
        const channelGroup = channelGrp[channelName];
        if (!channelGroup || channelGroup.length === 0) {
            return 0;
        }
        const selectedCampaignIds = new Set(selectedCampaigns.map((c) => c.campaignId));
        let count = 0;
        for (const { campaignId = "" } of channelGroup) {
            if (selectedCampaignIds.has(campaignId)) {
                count++;
            }
        }

        return count;
    };

    const getLockStatusInSelectedCampaign = (allSelectedCampaignsIds: string[], updatedData: channelData) => {
        return selectedCampaigns.map((s) => {
            if (allSelectedCampaignsIds.includes(s.campaignId)) {
                const f = updatedData
                    .map((c) => c.campaigns)
                    .flat(1)
                    .find((d) => d.campaignId === s.campaignId);
                return {
                    ...s,
                    isLocked: f ? f.isLocked || false : false,
                };
            } else {
                return s;
            }
        });
    };

    const handleLocking = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowName: string, isChannel: boolean) => {
        e.stopPropagation();
        if (isScenarioEditable) {
            if (isChannel) {
                const updatedData = tableDataWithLock.map((channel) => {
                    if (channel.channelName === rowName) {
                        return {
                            ...channel,
                            isLocked: !channel.isLocked,
                            campaigns: channel.campaigns.map((ele) => {
                                return {
                                    ...ele,
                                    isLocked: !channel.isLocked,
                                    budgetAllocated: channel.currentEstimatedDailySpend || 0,
                                };
                            }),
                        };
                    }
                    return channel;
                });

                const allSelectedCampaignsIds = updatedData
                    .filter((c) => c.isLocked || false)
                    .map((data) => data.campaigns.map((c) => c.campaignId || ""))
                    .flat(1);

                setTableDataWithLock(updatedData);
                setSelectedCampaigns(getLockStatusInSelectedCampaign(allSelectedCampaignsIds, updatedData));
            } else {
                const updatedData = tableDataWithLock.map((channel) => {
                    return {
                        ...channel,
                        campaigns: channel.campaigns.map((ele) => {
                            if (ele.campaignId === rowName) {
                                return { ...ele, isLocked: !ele.isLocked };
                            }
                            return ele;
                        }),
                    };
                });

                const allSelectedCampaignsIds = updatedData
                    .map((c) => c.campaigns.map((c) => c.campaignId || ""))
                    .flat(1);

                setTableDataWithLock(updatedData);
                setSelectedCampaigns(getLockStatusInSelectedCampaign(allSelectedCampaignsIds, updatedData));
            }
        }
    };

    const tableHeader = campaignSelectionForFormTableHeader(values.scenarioType);

    return (
        <Stack gap="10px">
            {values.campaignData.length === 0 && Boolean(touched.campaignData && errors.campaignData) && (
                <FormHelperText className={classes.formError}>{errors.campaignData}</FormHelperText>
            )}
            <TableContainer
                component={Paper}
                sx={{
                    border: "1px solid #D9D9D9",
                    boxShadow: "none",
                    backgroundColor: !isScenarioEditable ? "rgba(0, 0, 0, 0.05)" : "#fff",
                }}
            >
                <Table size="small">
                    <CampaignTableHead
                        keyPrefix={"main"}
                        classes={classes}
                        orderBy={orderBy}
                        order={order}
                        selectedCampaignsCount={selectedCampaignsCount}
                        channelCount={channelCount}
                        values={values}
                        lastDaysTooltip={lastDaysTooltip}
                        handleSelectAllClick={handleSelectAllClick}
                        createSortHandler={createSortHandler}
                        tableHeader={tableHeader}
                    />
                    {isLoading ? (
                        <TableRowsLoader
                            rowsNum={tableHeader.length}
                            colsNum={tableHeader.length}
                            style={{ height: "55px" }}
                        />
                    ) : !tableDataWithLock.length ? (
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" colSpan={tableHeader.length + 1} sx={{ height: "54px" }}>
                                    <Typography color="text.secondary">No channels/campaigns found</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody className={campaignTableClass.tableBodyStyle}>
                            {stableSort(tableDataWithLock, getComparator(order, orderBy)).map(
                                (channelRow: any, index: number) => {
                                    const isItemSelected = isChannelSelected(channelRow.connectorName || "");
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <Fragment key={channelRow.channelName}>
                                            <TableRow
                                                onClick={(event) =>
                                                    handleChannelRowClick(event, channelRow.connectorName || "")
                                                }
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                                className={cursor("pointer")}
                                                hover
                                            >
                                                {tableHeader.map((channelHeader) => {
                                                    const isChannelNameCell = channelHeader.id === "channelName";
                                                    const isLockCell = channelHeader.id === "lock";
                                                    return (
                                                        <TableCell
                                                            key={`${channelRow.channelName}-${channelHeader.id}`}
                                                            align={
                                                                channelRow[channelHeader.id] === "channelName"
                                                                    ? "left"
                                                                    : "right"
                                                            }
                                                            className={`${classes.tableCellFont} ${channelHeader.id}`}
                                                        >
                                                            {isChannelNameCell ? (
                                                                <Stack direction="row" alignItems="center" gap="10px">
                                                                    <div
                                                                        style={{ textAlign: "center" }}
                                                                        onClick={(e) => {
                                                                            openCollapse(
                                                                                e,
                                                                                channelRow.channelName || "",
                                                                            );
                                                                        }}
                                                                    >
                                                                        <SVGIconRenderer
                                                                            icon={
                                                                                openChannelList ===
                                                                                channelRow.channelName
                                                                                    ? "chevronDownIcon"
                                                                                    : "chevronRightIcon"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <Checkbox
                                                                        size="small"
                                                                        color="primary"
                                                                        inputProps={{
                                                                            "aria-labelledby": labelId,
                                                                        }}
                                                                        checked={
                                                                            isItemSelected ||
                                                                            selectedCampaignForChannel(
                                                                                channelRow.channelName,
                                                                            ) ===
                                                                                channelGrp[channelRow.channelName]
                                                                                    ?.length
                                                                        }
                                                                        indeterminate={
                                                                            selectedCampaignForChannel(
                                                                                channelRow.channelName,
                                                                            ) > 0 &&
                                                                            selectedCampaignForChannel(
                                                                                channelRow.channelName,
                                                                            ) <
                                                                                channelGrp[channelRow.channelName]
                                                                                    ?.length
                                                                        }
                                                                    />
                                                                    <DataSourceAvatar
                                                                        programmaticName={channelRow.connectorName}
                                                                        className={classes.avatarImage}
                                                                    />
                                                                    <Typography className={classes.campaignCell}>
                                                                        {channelRow.cleanChannelName}
                                                                    </Typography>
                                                                </Stack>
                                                            ) : channelRow[channelHeader.id] < 0 &&
                                                              TRUE_METRICS.includes(channelHeader.id) ? (
                                                                "N/A"
                                                            ) : channelRow[channelHeader.id] ? (
                                                                formatValue(
                                                                    channelRow[channelHeader.id],
                                                                    channelHeader.sign || FORMATS.NUMERIC,
                                                                    channelHeader.fixed || 0,
                                                                )
                                                            ) : isLockCell ? (
                                                                <IconButton
                                                                    sx={{ padding: "8px" }}
                                                                    onClick={(e) =>
                                                                        handleLocking(e, channelRow.channelName, true)
                                                                    }
                                                                >
                                                                    <SVGIconRenderer
                                                                        icon={
                                                                            channelRow.isLocked
                                                                                ? "lockIcon"
                                                                                : "unlockIcon"
                                                                        }
                                                                        title={
                                                                            !channelRow.isLocked
                                                                                ? "Lock allocation"
                                                                                : "Unlock allocation"
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ padding: 0 }} colSpan={8}>
                                                    <Collapse in={openChannelList === channelRow.channelName}>
                                                        <Table size="small">
                                                            {/** this second table head is hidden but keeps the styling consistent with the other parts of table */}
                                                            <CampaignTableHead
                                                                keyPrefix={"hiddenHead"}
                                                                classes={classes}
                                                                orderBy={orderBy}
                                                                order={order}
                                                                selectedCampaignsCount={selectedCampaignsCount}
                                                                channelCount={channelCount}
                                                                values={values}
                                                                lastDaysTooltip={lastDaysTooltip}
                                                                hidden
                                                                tableHeader={tableHeader}
                                                            />
                                                            <TableBody>
                                                                {stableSort(
                                                                    channelRow.campaigns,
                                                                    getComparator(order, orderBy),
                                                                ).map((campaignRow: any) => {
                                                                    const isItemSelected = isCampaignSelected(
                                                                        campaignRow.campaignId || "",
                                                                    );
                                                                    return (
                                                                        <TableRow
                                                                            key={campaignRow.campaignId}
                                                                            onClick={(e) =>
                                                                                handleCampaignRowClick(
                                                                                    e,
                                                                                    campaignRow.campaignId,
                                                                                )
                                                                            }
                                                                            className={cursor("pointer")}
                                                                            role="checkbox"
                                                                            hover
                                                                            aria-checked={isItemSelected}
                                                                            tabIndex={-1}
                                                                            selected={isItemSelected}
                                                                        >
                                                                            {tableHeader.map((channelHeader, i) => {
                                                                                const isChannelNameCell =
                                                                                    channelHeader.id === "channelName";

                                                                                const isStatusCell =
                                                                                    channelHeader.id === "status";

                                                                                const isLockCell =
                                                                                    channelHeader.id === "lock";

                                                                                return (
                                                                                    <TableCell
                                                                                        key={`campaign-${campaignRow.campaignId}-${i}-${channelHeader.id}`}
                                                                                        align={
                                                                                            isChannelNameCell
                                                                                                ? "left"
                                                                                                : "right"
                                                                                        }
                                                                                        sx={{
                                                                                            paddingLeft:
                                                                                                isChannelNameCell
                                                                                                    ? "50px"
                                                                                                    : "16px",
                                                                                        }}
                                                                                        className={channelHeader.id}
                                                                                    >
                                                                                        {isChannelNameCell ? (
                                                                                            <Stack
                                                                                                direction="row"
                                                                                                alignItems="center"
                                                                                                gap="10px"
                                                                                            >
                                                                                                <Checkbox
                                                                                                    size="small"
                                                                                                    color="primary"
                                                                                                    inputProps={{
                                                                                                        "aria-labelledby":
                                                                                                            labelId,
                                                                                                    }}
                                                                                                    checked={selectedCampaigns
                                                                                                        .map(
                                                                                                            (c) =>
                                                                                                                c.campaignId,
                                                                                                        )
                                                                                                        .includes(
                                                                                                            campaignRow.campaignId ||
                                                                                                                "",
                                                                                                        )}
                                                                                                />
                                                                                                <DataSourceAvatar
                                                                                                    programmaticName={
                                                                                                        campaignRow.connectorName
                                                                                                    }
                                                                                                    className={
                                                                                                        classes.avatarImage
                                                                                                    }
                                                                                                />
                                                                                                <Typography
                                                                                                    title={
                                                                                                        campaignRow.campaignName
                                                                                                    }
                                                                                                    className={
                                                                                                        classes.campaignCell
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        campaignRow.campaignName
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Stack>
                                                                                        ) : campaignRow[
                                                                                              channelHeader.id
                                                                                          ] < 0 &&
                                                                                          TRUE_METRICS.includes(
                                                                                              channelHeader.id,
                                                                                          ) ? (
                                                                                            "N/A"
                                                                                        ) : isStatusCell ? (
                                                                                            capitalizeFirstLetter(
                                                                                                campaignRow[
                                                                                                    channelHeader.id
                                                                                                ] &&
                                                                                                    campaignRow[
                                                                                                        channelHeader.id
                                                                                                    ] !==
                                                                                                        CampaignStatus.UNKNOWN
                                                                                                    ? campaignRow[
                                                                                                          channelHeader
                                                                                                              .id
                                                                                                      ].toLocaleLowerCase()
                                                                                                    : "",
                                                                                            )
                                                                                        ) : isLockCell ? (
                                                                                            <IconButton
                                                                                                sx={{
                                                                                                    padding: "8px",
                                                                                                }}
                                                                                                onClick={(e) =>
                                                                                                    handleLocking(
                                                                                                        e,
                                                                                                        campaignRow.campaignId,
                                                                                                        false,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <SVGIconRenderer
                                                                                                    icon={
                                                                                                        campaignRow.isLocked
                                                                                                            ? "lockIcon"
                                                                                                            : "unlockIcon"
                                                                                                    }
                                                                                                    title={
                                                                                                        campaignRow[
                                                                                                            channelHeader
                                                                                                                .id
                                                                                                        ]
                                                                                                            ? "Lock allocation"
                                                                                                            : "Unlock allocation"
                                                                                                    }
                                                                                                />
                                                                                            </IconButton>
                                                                                        ) : (
                                                                                            formatValue(
                                                                                                campaignRow[
                                                                                                    channelHeader.id
                                                                                                ],
                                                                                                channelHeader.sign ||
                                                                                                    FORMATS.NUMERIC,
                                                                                                channelHeader.fixed ||
                                                                                                    0,
                                                                                            )
                                                                                        )}
                                                                                    </TableCell>
                                                                                );
                                                                            })}
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    );
                                },
                            )}
                            <TableRow sx={{ height: "55px" }}>
                                {tableHeader.map((c) => {
                                    return (
                                        <React.Fragment key={c.id}>
                                            {c.id === "channelName" ? (
                                                <TableCell className={classes.boldText}>Total Selected</TableCell>
                                            ) : (
                                                <TableCell align={c.numeric ? "right" : "left"}>
                                                    {formatValue(selectedCampaignsTotal[c.id], c.sign, c.fixed)}
                                                </TableCell>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Stack>
    );
};
