import { FC } from "react";
import { Table, TableBody, TableContainer, TableRow, TableCell, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { OutcomeTableHeader } from "../../../OutcomeTable/OutcomeTableHeader";
import { TableRowsLoader } from "src/components/TableLoader/TableLoader";
import { FORECASTED_CAMPAIGNS_HEADERS_WITH_INPUTS } from "src/consts/optimizationPage/optimizationPage";
import { OutcomeTableRow } from "../../../OutcomeTable/OutcomeTableRow";
import { getTotalCampaignData } from "src/services/optimizationPage/optimization";
import { IDictionary } from "src/interfaces/IDictionary";
import {
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    ScenarioOutcomeTab,
} from "src/interfaces/entities/IScenario";
import { getComparator, stableSort } from "src/utils/sort";

interface ICampaignsTableProps {
    isLoading: boolean;
    campaignsWithPerformanceData: any[];
    selectedScenario: any;
    performanceDataTotal: IDictionary;
    classes: any;
    campaignTableHeader: any[];
    compareTo: string;
}

export const CampaignsTable: FC<ICampaignsTableProps> = ({
    isLoading,
    campaignsWithPerformanceData,
    selectedScenario,
    performanceDataTotal,
    classes,
    campaignTableHeader,
    compareTo,
}) => {
    const filteredHeader = campaignTableHeader.filter((h: any) => !h.hidden);

    return (
        <TableContainer className={classes.recommandarionTabel}>
            <Table>
                <OutcomeTableHeader
                    scenario={selectedScenario}
                    compareToLabel={compareTo}
                    outcomeCampaignHeader={campaignTableHeader}
                    activeTab={ScenarioOutcomeTab.Tracking}
                />
                {isLoading ? (
                    <TableRowsLoader
                        rowsNum={campaignsWithPerformanceData.length + 1}
                        colsNum={FORECASTED_CAMPAIGNS_HEADERS_WITH_INPUTS.length}
                    />
                ) : (
                    <TableBody className={classes.tableBodyStyle}>
                        {campaignsWithPerformanceData.length ? (
                            <>
                                {stableSort(campaignsWithPerformanceData, getComparator("asc", "campaignName")).map(
                                    (campaignRow: any) => (
                                        <OutcomeTableRow
                                            outcomeCampaignHeader={campaignTableHeader}
                                            key={campaignRow.campaignId}
                                            campaignRow={
                                                campaignRow as IMetricAttributionTableValuesTransformedWithSpendAllocation
                                            }
                                            classes={classes}
                                            scenario={selectedScenario}
                                            campaignData={campaignsWithPerformanceData}
                                            activeTab={ScenarioOutcomeTab.Tracking}
                                            rowClick={() => {}}
                                            compareToLabel=""
                                            campaignOpenId=""
                                        />
                                    ),
                                )}
                                <TableRow className={classes.totalRow}>
                                    {filteredHeader.map((element: any) => (
                                        <TableCell key={element.id} align={element.align}>
                                            {getTotalCampaignData(performanceDataTotal)[element.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </>
                        ) : (
                            <TableRow>
                                <TableCell
                                    className={classes.outcomeTableCell}
                                    colSpan={FORECASTED_CAMPAIGNS_HEADERS_WITH_INPUTS.length}
                                    align="center"
                                    sx={{ color: "rgba(0, 0, 0, 0.6)!important" }}
                                >
                                    No campaigns
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};
