import * as React from "react";
import Spinner from "@mui/material/CircularProgress";
import { Stack } from "@mui/system";

import { Button, ButtonVariant } from "../Button/Button";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

export interface ILoginFormProps {
    submitBtnProps?: {
        text: string;
        disabled?: boolean;
        isLoading?: boolean;
        onActionBtnClick?: any;
        testId?: string;
    };
    cancelBtnProps?: {
        text: string;
        disabled?: boolean;
        isLoading?: boolean;
        onCancelBtnClick?: any;
        startIcon?: boolean;
        endIcon?: boolean;
        cancelBtnStyle?: any;
        testId?: string;
    };
    flexDirection?: any;
    gap?: string;
}

export const FooterForm: React.FC<ILoginFormProps> = ({ submitBtnProps, cancelBtnProps, flexDirection, gap }) => {
    return (
        <Stack flexDirection={flexDirection || "column"} gap={gap || "30px"}>
            {submitBtnProps && (
                <Button
                    disabled={submitBtnProps.disabled}
                    data-cy={submitBtnProps.testId}
                    type="submit"
                    fullWidth
                    sx={(theme) => ({
                        textTransform: "inherit",
                        fontSize: "16px",
                        height: "54px",
                        [theme.breakpoints.down("sm")]: {
                            width: "100%",
                            height: "40px",
                        },
                    })}
                    onClick={submitBtnProps.onActionBtnClick && submitBtnProps.onActionBtnClick}
                >
                    {submitBtnProps.isLoading ? <Spinner size={25} /> : submitBtnProps.text}
                </Button>
            )}

            {cancelBtnProps && (
                <Button
                    variant={ButtonVariant.Text}
                    color="secondary"
                    onClick={cancelBtnProps.onCancelBtnClick}
                    data-cy={cancelBtnProps.testId}
                    fullWidth
                    sx={(theme) => ({
                        justifyContent: "flex-start",
                        textTransform: "inherit",
                        fontSize: "16px",
                        padding: 0,

                        [theme.breakpoints.down("sm")]: {
                            fontSize: "12px!important",
                        },
                        ...cancelBtnProps.cancelBtnStyle,
                    })}
                    startIcon={
                        cancelBtnProps.startIcon && <SVGIconRenderer icon="arrowLeftIcon" strokeColor="primary" />
                    }
                    endIcon={
                        cancelBtnProps.endIcon && (
                            <SVGIconRenderer
                                strokeColor="primary"
                                icon="arrowRightIcon"
                                style={{ marginLeft: "4px" }}
                            />
                        )
                    }
                >
                    {cancelBtnProps.text}
                </Button>
            )}
        </Stack>
    );
};
