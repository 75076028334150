import { Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { format } from "date-fns";
import { toDate } from "date-fns-tz";
import { capitalize } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { IDictionary } from "src/interfaces/IDictionary";
import { IQaMetric } from "src/interfaces/entities/IQaMetric";
import { useGetQaMetricTypesQuery } from "src/reduxState/apis/qaMetricsApi";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { MetricsList } from "./sharedComponent/MetricList";
import { VerificationHeader } from "./sharedComponent/VerificationHeader";

export const VerificationSubmittedDataStep2 = ({
    meta,
    metricsWithConnector,
    isAllMetricsAccepted,
}: {
    meta: any;
    metricsWithConnector: IQaMetric[];
    isAllMetricsAccepted: boolean;
}) => {
    const orgId = useSelector(selectCurrentlyViewingId);
    const metricTypes = useGetQaMetricTypesQuery({ orgId }).data?.map((item) => item.name) || [];

    const categorizedMetrics = useMemo(() => {
        const metrics: IDictionary<IQaMetric[]> = metricsWithConnector.length
            ? metricsWithConnector.reduce((metrics, item) => {
                  (metrics[item.metricType] = metrics[item.metricType] || []).push(item);
                  return metrics;
              }, {} as IDictionary<IQaMetric[]>)
            : {};

        Object.keys(metrics).forEach((metricType) => {
            metrics[metricType].sort((a, b) => {
                const nameA = a.connector?.connectorName?.toLowerCase() || "";
                const nameB = b.connector?.connectorName?.toLowerCase() || "";
                return nameA.localeCompare(nameB);
            });
        });

        return metrics;
    }, [metricsWithConnector]);

    return (
        <Stack gap={2}>
            <VerificationHeader
                mainText={
                    isAllMetricsAccepted
                        ? "Explore your new home dashboard while your data propagates. Please note, this process can take up to 72 hours, and Amazon data may take up to 2 weeks."
                        : "We’re reviewing the declined data. We'll contact you to schedule a call to review the details, or you can book a time now at your convenience."
                }
                subText={
                    <>
                        <Typography component="span">Metrics Timeframe:</Typography>
                        <Typography fontWeight={700} component="span">
                            {meta.startDate && meta.endDate
                                ? ` ${format(toDate(meta.startDate), "MMMM d")} - ${format(
                                      toDate(meta.endDate),
                                      "MMMM d, yyyy",
                                  )}`
                                : " --"}
                        </Typography>
                    </>
                }
            />
            {metricTypes.map((metricType) => (
                <Stack key={metricType}>
                    <Stack gap={1}>
                        <Typography variant="h6">{capitalize(metricType)}</Typography>
                        <Divider variant="fullWidth" sx={{ height: "2px", width: "100%" }} />
                    </Stack>
                    <MetricsList metricsWithConnector={categorizedMetrics[metricType]} isDataSubmitted={true} />
                </Stack>
            ))}
        </Stack>
    );
};
