import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RemoveRoadOutlinedIcon from "@mui/icons-material/RemoveRoadOutlined";
import { Button, IconButton, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Stack } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";

import { IosShareOutlined } from "@mui/icons-material";
import { DataSourceLabel } from "src/components/DataSource/DataSourceTable/DataSourceLabel";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import { useStylesForRowInTable } from "src/consts/StylesForTables";
import { UserRole } from "src/enums/UserRole";
import { ConnectorStatus, IConnector } from "src/interfaces/IConnector";
import { IDataSourceWithConnected } from "src/interfaces/IData";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { selectCurrentlyViewingCompanyById } from "src/reduxState/slices/organizationSlice";
import {
    areAllRequiredConnectorCategoriesConnected,
    checkIsIncompletePagesExist,
    isIncompleteConnectorExist,
} from "src/services/onboardingPage/onboardingPage";
import { demoModeSelector } from "../../../reduxState/slices/demoModeSlice";
import { currentUserRoleByCompanyIdSelector, isSuperAdminSelector } from "../../../reduxState/slices/userSlice";
import { formatDate } from "../../../services/members/members";
import { ConnectorStatusChip } from "./ConnectorStatusChip";

interface IConnectorTableRow {
    connector: IConnector & { isConnected: boolean; dataSource: IDataSourceWithConnected };
    isListingPage: boolean;
    isConnectionInProgress: boolean;
    onDeleteConnector: (e: React.MouseEvent<HTMLButtonElement>, connector: IConnector, action: string) => void;
    onPurgeConnector: (e: React.MouseEvent<HTMLButtonElement>, connector: IConnector, action: string) => void;
    onResyncClick: (e: React.MouseEvent<HTMLButtonElement>, connector: IConnector, action: string) => void;
    onEditConnector: (e: React.MouseEvent<HTMLButtonElement>, connector: IConnector) => void;
    handleConnect?: (e: React.MouseEvent<HTMLButtonElement>, connector: IConnector, action: string) => void;
    handleCreateConnectorWithFivetran?: (e: React.MouseEvent<HTMLButtonElement>, connector: any) => void;
    onShareConnector?: (e: React.MouseEvent<HTMLButtonElement>, connector: IConnector, action: string) => void;
    brokenStatusClick?: (e: React.MouseEvent<any>, connector: any) => void;
}

export const ConnectorTableRow: React.FC<IConnectorTableRow> = ({
    onDeleteConnector,
    connector,
    onPurgeConnector,
    onResyncClick,
    onEditConnector,
    handleConnect,
    onShareConnector,
    isListingPage,
    handleCreateConnectorWithFivetran,
    brokenStatusClick,
    isConnectionInProgress,
}) => {
    const extraClasses = useStylesForRowInTable();
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const isDemoMode = useSelector(demoModeSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const currentCompany = useSelector(selectCurrentlyViewingCompanyById);

    const connectors: IConnector[] = useSelector(connectorsSelector);

    const isConnectorSetup = !checkIsIncompletePagesExist(currentCompany?.onboardingState);
    const isAllGrpCompleted = isConnectorSetup
        ? true
        : areAllRequiredConnectorCategoriesConnected(connectors) && !isIncompleteConnectorExist(connectors);

    const status = connector.status;
    const isIncompleteConnector = status === ConnectorStatus.INCOMPLETE;

    const isActionVisible =
        !isDemoMode && (isSuperAdmin || userRole === UserRole.ADMIN) && (!isListingPage || connector.isConnected);

    const isPurgeAndSyncVisible = isSuperAdmin && isAllGrpCompleted && !isIncompleteConnector;

    const connectConnectorFun = isIncompleteConnector
        ? isListingPage
            ? handleCreateConnectorWithFivetran && handleCreateConnectorWithFivetran
            : handleConnect && handleConnect
        : undefined;

    const isBrokenConnector = status === ConnectorStatus.BROKEN;

    const renderText = (text: string) => <Typography variant="body1">{text}</Typography>;

    const getCreationData = () => {
        if (isIncompleteConnector) {
            return renderText("Not Connected");
        }

        if (isBrokenConnector) {
            return (
                <Typography variant="body2" color="error">
                    Connection Interrupted
                </Typography>
            );
        }

        return renderText(formatDate(connector.createdAt.toString()));
    };

    return (
        <TableRow key={connector.id} className={extraClasses.bodyRow}>
            <TableCell align="left" className={extraClasses.tableCell}>
                <Stack gap={2} direction="row" alignItems="center" justifyContent="flex-start">
                    <DataSourceAvatar
                        programmaticName={connector.dataSource?.programmaticName}
                        sx={{ width: "40px", height: "40px" }}
                    />
                    <DataSourceLabel dataSource={connector.dataSource} />
                </Stack>
            </TableCell>
            <TableCell align="center">
                {isIncompleteConnector && connectConnectorFun ? (
                    <Button
                        disabled={isConnectionInProgress}
                        onClick={(e) => connectConnectorFun(e, connector, "connect")}
                        variant="outlined"
                    >
                        {connector.isConnected ? "Re-Connect" : "Connect"}
                    </Button>
                ) : (
                    <ConnectorStatusChip connector={connector} brokenStatusClick={brokenStatusClick} />
                )}
            </TableCell>
            <TableCell align="center" className={extraClasses.tableCell}>
                {renderText(connector.dataSource.categories.join(", "))}
            </TableCell>
            <TableCell align="center" className={extraClasses.tableCell}>
                {getCreationData()}
            </TableCell>
            {isActionVisible && (
                <TableCell align="center" className={extraClasses.tableCell}>
                    <Stack direction="row" justifyContent="end">
                        {isPurgeAndSyncVisible && (
                            // all required connectors are connected and is super admin
                            <>
                                <Tooltip title="Historical Resync" cy-data="resync-connector-button">
                                    <IconButton onClick={(e) => onResyncClick(e, connector, "resync")}>
                                        <LoopOutlinedIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Purge" cy-data="purge-connector-button">
                                    <IconButton onClick={(e) => onPurgeConnector(e, connector, "purge")}>
                                        <RemoveRoadOutlinedIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {isIncompleteConnector ? (
                            onShareConnector && (
                                <Tooltip title="Share" cy-data="share-connector-button">
                                    <IconButton onClick={(e) => onShareConnector(e, connector, "share")}>
                                        <IosShareOutlined color="primary" />
                                    </IconButton>
                                </Tooltip>
                            )
                        ) : (
                            <Tooltip title="Edit" cy-data="edit-connector-button">
                                <IconButton onClick={(e) => onEditConnector(e, connector)}>
                                    <EditOutlinedIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="Delete" cy-data="delete-connector-button">
                            <IconButton onClick={(e) => onDeleteConnector(e, connector, "delete")}>
                                <RemoveCircleOutlineIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </TableCell>
            )}
        </TableRow>
    );
};
