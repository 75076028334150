import React, { FC } from "react";
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Theme,
    useTheme,
} from "@mui/material";
import { css } from "@emotion/css";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { exportCsvOptions } from "../../consts/performancePaidPage/performancePaidPage";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";

interface IExportTableData {
    handleMenuItemClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => void;
    anchorRef?: React.RefObject<HTMLDivElement>;
    exportVisibleColumns: () => void;
    openExportMenu: boolean;
    handleExportToggle: () => void;
    handleExportMenuClose: (event: Event) => void;
    noPopup?: boolean;
    leftMargin?: boolean;
}

const useStyles = (theme: Theme, noPopup: boolean) => ({
    headerButton: css({
        padding: noPopup ? "16px 12px" : "6px 8px",
        height: noPopup ? "56px" : "38px",
    }),
    headerButtonGroupRight: css({
        "&:hover": {
            borderRight: "2px solid",
        },
    }),
    headerButtonGroupLeft: css({
        "&:hover": {
            borderRight: "2px solid",
        },
    }),
});

export const ExportTableData: FC<IExportTableData> = ({
    handleMenuItemClick,
    anchorRef,
    exportVisibleColumns,
    openExportMenu,
    handleExportToggle,
    handleExportMenuClose,
    noPopup = false,
    leftMargin = false,
}) => {
    const theme = useTheme();
    const classes = useStyles(theme, noPopup);

    return (
        <div style={{ minHeight: "100%", alignSelf: "stretch", display: "flex", alignContent: "stretch" }}>
            {noPopup ? (
                <Button
                    title="Export CSV of table"
                    variant="outlined"
                    color="primary"
                    onClick={exportVisibleColumns}
                    className={classes.headerButton}
                    endIcon={<SVGIconRenderer height="18px" strokeColor="primary" icon="exportIcon" />}
                    sx={{ marginLeft: leftMargin ? 2 : 0, height: "56px !important" }}
                >
                    Export Report
                </Button>
            ) : (
                <>
                    <ButtonGroup ref={anchorRef} aria-label="split button">
                        <Button
                            title="Export CSV of table"
                            variant="outlined"
                            color="primary"
                            onClick={exportVisibleColumns}
                            className={classes.headerButton + " " + classes.headerButtonGroupRight}
                            startIcon={<FileDownloadOutlinedIcon />}
                            sx={{ paddingRight: 0 }}
                        >
                            Export Performance
                        </Button>
                        <Button
                            aria-controls={openExportMenu ? "export-button-menu" : undefined}
                            aria-expanded={openExportMenu ? "true" : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleExportToggle}
                            variant="outlined"
                            color="primary"
                            className={classes.headerButton + " " + classes.headerButtonGroupLeft}
                        >
                            <SVGIconRenderer
                                icon="chevronDownIcon"
                                height="16px"
                                width="16px"
                                strokeColor={theme.palette.primary.main}
                            />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        open={openExportMenu}
                        anchorEl={anchorRef?.current}
                        role={undefined}
                        transition
                        placement="bottom-end"
                        sx={{ zIndex: 1 }}
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: "top right",
                                }}
                            >
                                <Paper elevation={8}>
                                    <ClickAwayListener onClickAway={handleExportMenuClose}>
                                        <MenuList id="export-button-menu" autoFocusItem>
                                            {exportCsvOptions.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === 0}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            )}
        </div>
    );
};
