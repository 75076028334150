import React from "react";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";

import { Choice, ICells } from "../../../consts/membersPage/membersPage";
import { SUPER_ADMIN_MEMBERS_PAGE_TABLE_HEADERS } from "../../../consts/SuperAdminMemberTable/SuperAdminMemberPage";
import { demoModeSelector } from "../../../reduxState/slices/demoModeSlice";
import { useStylesForHeaderInTable } from "../../../consts/StylesForTables";
import { initialSortDirection } from "src/services/utils";

interface IEnhancedHeaderProps {
    handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof ICells) => void;
    choice: Choice;
    choiceBy: string;
    selectedMembers: number;
    handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useStyles = () => ({
    orgCell: css({
        width: "300px",
    }),
});

export const SuperAdminMembersTableHeader = (props: IEnhancedHeaderProps) => {
    const { selectedMembers, choiceBy, choice, handleRequestSort } = props;
    const classes = { ...useStyles(), ...useStylesForHeaderInTable() };
    const isDemoMode = useSelector(demoModeSelector);

    const createSortHandler = (property: keyof ICells) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow className={classes.headerRow}>
                {SUPER_ADMIN_MEMBERS_PAGE_TABLE_HEADERS.map((headCell, index) => {
                    return headCell.id === "invitationState" ? (
                        !isDemoMode && (
                            <TableCell
                                key="invitationState"
                                className={classes.tableCell}
                                sortDirection={choiceBy === "invitationState" ? choice : false}
                            >
                                <TableSortLabel
                                    active={choiceBy === "invitationState"}
                                    direction={initialSortDirection(choiceBy, "invitationState", choice)}
                                    onClick={createSortHandler("invitationState")}
                                >
                                    Invitation Status
                                    {choiceBy === "invitationState" ? (
                                        <Box component="span" style={{ display: "none" }}>
                                            {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    ) : (
                        <TableCell
                            key={headCell.id}
                            className={`${classes.tableCell} ${headCell.id === "companyCount" && classes.orgCell}`}
                            sortDirection={choiceBy === headCell.id ? choice : false}
                        >
                            <TableSortLabel
                                active={choiceBy === headCell.id}
                                direction={initialSortDirection(choiceBy, headCell.id, choice)}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {choiceBy === headCell.id ? (
                                    <Box component="span" style={{ display: "none" }}>
                                        {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
                {!isDemoMode && (
                    <TableCell align={"right"} className={classes.tableCell} key={"activation"}></TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};
