import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";

import { useCreateCustomerBillingSessionMutation } from "../../reduxState/apis/organizationApi";
import { selectCurrentlyViewingId } from "../../reduxState/slices/organizationSlice";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../services/utils";
import { openNewWindow } from "../../utils/window";
import { SettingComponent } from "../../components/SettingComponent/SettingComponent";

const useStyles = () => ({
    boxStyle: css({
        border: "1px solid rgba(0, 0, 0, 0.5)",
        padding: "30px 30px 50px",
        borderRadius: "5px",
        backgroundColor: "white",
        color: "#000000",
    }),
});

export const Billing = () => {
    const classes = useStyles();

    const analyticsService = useAnalyticsService();
    const [createBillingSession] = useCreateCustomerBillingSessionMutation();
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const [errState, setErrState] = useState();

    useEffect(() => {
        analyticsService.logEvent("Billing Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, [location.pathname]);

    const manageBill = () => {
        analyticsService.logEvent("Manage Billing Clicked", {
            Page: getPathForAnalytics(location.pathname),
        });
        createBillingSession(+currentOrgId)
            .unwrap()
            .then(({ redirectUrl }) => {
                openNewWindow(redirectUrl);
            })
            .catch((error) => {
                setErrState(error.data.errors[0].detail);
            });
    };

    return (
        <>
            <Stack gap="30px" className={classes.boxStyle} sx={{ marginTop: 3 }}>
                {errState && <Alert severity="error">{errState}</Alert>}
                <h2>Billing</h2>
                <SettingComponent
                    title="Stripe Customer Portal"
                    description="Prescient uses Stripe to securely manage your payment information. You can manage your Stripe
                        account on the Stripe Customer Portal by clicking Manage Stripe Billing below."
                    btnTitle="Manage Stripe Billing"
                    submitClick={manageBill}
                />
            </Stack>
        </>
    );
};
