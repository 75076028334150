import { FC } from "react";
import { Backdrop, ButtonProps } from "@mui/material";
import { Stack } from "@mui/system";
import { CustomDialog } from "../core/CustomDialog/CustomDialog";

interface IDataQaModalProps {
    placeholderImage: string;
    title: string;
    instanceSlot: JSX.Element;
    actions: {
        [key: number]: {
            label: string;
            onClick: () => void;
            style?: any;
            disabled?: boolean;
            props?: ButtonProps;
        };
    };
}

export const DataQaModal: FC<IDataQaModalProps> = ({ placeholderImage, title, instanceSlot, actions }) => {
    return (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                alignItems: "start",
            }}
            open={true}
        >
            <Stack sx={{ width: "320px", position: "absolute", top: "307px" }}>
                <img src={placeholderImage} style={{ borderRadius: "4px 4px 0 0" }} />
                <CustomDialog
                    title={title}
                    instanceSlot={instanceSlot}
                    actions={actions}
                    closeIcon={false}
                    sx={{
                        width: "100%",
                        borderRadius: "0 0 4px 4px",
                        " .MuiDialogTitle-root": {
                            padding: "24px 16px 8px 16px",
                        },
                        " .MuiDialogTitle-root h3": {
                            fontSize: "16px",
                        },
                        " .MuiDialogContent-root": {
                            padding: "16px",
                            paddingTop: "0!important",
                        },
                        " .MuiDialogActions-root": {
                            padding: "16px",
                            paddingTop: "0!important",
                        },
                    }}
                />
            </Stack>
        </Backdrop>
    );
};
