import React, { FC, useState, useMemo } from "react";
import { TabPanel } from "@mui/lab";
import { css } from "@emotion/css";

import { CustomTabContext } from "src/components/CustomTabContext/CustomTabContext";
import {
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
    RecommandationTab,
} from "src/interfaces/entities/IScenario";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { getComparator, stableSort } from "src/utils/sort";
import { CampaignsRecommendationsTab } from "./CampaignsRecommendationsTab";
import { ChannelsRecommendationsTab } from "./ChannelsRecommendationsTab";
import { TacticsRecommendationsTab } from "./TacticsRecommendationsTab";
import {
    getChannelGroupData,
    getSearchedCampaigns,
    getTacticGroupData,
    getTotalAllocation,
    getTotalCampaignData,
} from "src/services/optimizationPage/optimization";
import { IDictionary } from "src/interfaces/IDictionary";
import { SearchField } from "src/components/SearchField/SearchField";
import { IForecastCampaignHeadCell } from "src/consts/optimizationPage/optimizationPage";

interface IRecommendationTab {
    selectedScenario: IScenario;
    forecastCampaign: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    isRecalculateScenarioDisabled: boolean;
    isPerformanceLoading: boolean;
    increaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    decreaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    compareToLabel: string;
    outcomeCampaignHeader: IForecastCampaignHeadCell[];
    setRecommendationsTabValue: React.Dispatch<React.SetStateAction<RecommandationTab>>;
    recommendationsTabValue: RecommandationTab;
}

export const recommendationsTableStyle = () => ({
    recommendationTable: css({
        border: "1px solid #D9D9D9",
        backgroundColor: "white",
        borderRadius: "5px",
        "& td, & th": {
            color: "#000",
        },
    }),
    tableBodyStyle: css({
        ".campaignNameCell": {
            width: "525px !important",
            minWidth: "525px !important",
            border: "solid rgba(224, 224, 224, 1)",
            borderWidth: "0 0 1px 0",
        },
        ".confidenceCell, .forecastedRevenueCell, .spendCell": {
            fontSize: "15px",
            border: "solid rgba(224, 224, 224, 1)",
            borderWidth: "0 0 1px 0",
            width: "150px !important",
            minWidth: "150px !important",
        },
        ".forecastedRoasCell": {
            fontSize: "15px",
            width: "130px !important",
            minWidth: "130px !important",
        },
        ".mmmCacCell": {
            width: "150px !important",
            minWidth: "150px !important",
        },
        ".newCustomersCell": {
            fontSize: "15px",
            width: "200px !important",
            minWidth: "200px !important",
        },
    }),
});

export const RecommendationTab: FC<IRecommendationTab> = ({
    selectedScenario,
    forecastCampaign,
    isRecalculateScenarioDisabled,
    isPerformanceLoading,
    increaseSpendCampaigns,
    decreaseSpendCampaigns,
    compareToLabel,
    outcomeCampaignHeader,
    setRecommendationsTabValue,
    recommendationsTabValue,
}) => {
    const analyticsService = useAnalyticsService();
    const tabs = [
        { label: "Channels", value: RecommandationTab.Channels },
        { label: "Tactics", value: RecommandationTab.Tactics },
        { label: "Campaigns", value: RecommandationTab.Campaigns },
    ];
    const [searchCampaigns, setSearchCampaigns] = useState("");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue === RecommandationTab.Tactics) {
            analyticsService.logEvent(`Optimization Tactics Page Viewed`, {});
        }
        setRecommendationsTabValue(newValue as RecommandationTab);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = e.target;
        setSearchCampaigns(value);
    };

    const searchedIncreasedCampaignsData = useMemo(() => {
        return getSearchedCampaigns(increaseSpendCampaigns, searchCampaigns);
    }, [increaseSpendCampaigns, searchCampaigns]);

    const searchedDecreasedCampaignsData = useMemo(() => {
        return getSearchedCampaigns(decreaseSpendCampaigns, searchCampaigns);
    }, [decreaseSpendCampaigns, searchCampaigns]);

    const searchedCampaigns = useMemo(() => {
        return [...searchedIncreasedCampaignsData, ...searchedDecreasedCampaignsData];
    }, [searchedIncreasedCampaignsData, searchedDecreasedCampaignsData]);

    const searchedChannelGroupData = useMemo(() => {
        return getChannelGroupData(searchedCampaigns).filter((channel) => channel.campaigns.length > 0);
    }, [searchedCampaigns]);

    const searchedTacticsGroupData = useMemo(() => {
        return getTacticGroupData(searchedCampaigns).filter((channel) => channel.campaigns.length > 0);
    }, [searchedCampaigns]);

    const totalAllocationBasedOnSearchedCampaigns: IDictionary = useMemo(() => {
        return getTotalCampaignData(getTotalAllocation(searchedCampaigns));
    }, [searchedCampaigns]);

    return (
        <CustomTabContext tabValue={recommendationsTabValue} handleChange={handleChange} tabs={tabs}>
            <>
                <SearchField
                    placeholder={`Search ${recommendationsTabValue}`}
                    onChange={handleSearchChange}
                    value={searchCampaigns}
                />
                <TabPanel sx={{ padding: 0 }} value="campaigns">
                    <CampaignsRecommendationsTab
                        outcomeCampaignHeader={outcomeCampaignHeader}
                        selectedScenario={selectedScenario}
                        forecastCampaign={forecastCampaign}
                        isTableLoading={isRecalculateScenarioDisabled || isPerformanceLoading}
                        increaseSpendCampaigns={searchedIncreasedCampaignsData}
                        decreaseSpendCampaigns={searchedDecreasedCampaignsData}
                        compareToLabel={compareToLabel}
                    />
                </TabPanel>
                <TabPanel sx={{ padding: 0 }} value="channels">
                    <ChannelsRecommendationsTab
                        outcomeCampaignHeader={outcomeCampaignHeader}
                        channelGroupData={stableSort(
                            searchedChannelGroupData,
                            getComparator("asc", "cleanChannelName"),
                        )}
                        scenario={selectedScenario}
                        totalAllocationCampaigns={totalAllocationBasedOnSearchedCampaigns}
                        isTableLoading={isRecalculateScenarioDisabled || isPerformanceLoading}
                        compareToLabel={compareToLabel}
                    />
                </TabPanel>
                <TabPanel sx={{ padding: 0 }} value="tactics">
                    <TacticsRecommendationsTab
                        outcomeCampaignHeader={outcomeCampaignHeader}
                        tacticGroupData={searchedTacticsGroupData}
                        scenario={selectedScenario}
                        totalAllocationCampaigns={totalAllocationBasedOnSearchedCampaigns}
                        isTableLoading={isRecalculateScenarioDisabled || isPerformanceLoading}
                        compareToLabel={compareToLabel}
                    />
                </TabPanel>
            </>
        </CustomTabContext>
    );
};
