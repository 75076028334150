import { FC } from "react";
import { Paper, Stack } from "@mui/material";
import { CustomTablePagination } from "src/components/core/CustomTablePagination/CustomTablePagination";

interface IEventListPagination {
    paginationProps: {
        count: number;
        page: any;
        handleChangePage: (event: unknown, newPage: number) => void;
        handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
        extraInfo?: string;
        className?: any;
    };
    children: React.ReactNode;

    tableProps?: { id?: string };
    className?: any;
}

const paperStyle = { width: "100%", overflow: "hidden", boxShadow: "none",border: "1px solid rgba(0, 0, 0, 0.5)"};

export const EventListPagination: FC<IEventListPagination> = ({ children, paginationProps, tableProps, className }) => {
    return (
        <Paper className={className} sx={paperStyle}>
            <Stack sx={{ width: "100%" }} {...tableProps}>
                {children}
            </Stack>
            <CustomTablePagination {...paginationProps} />
        </Paper>
    );
};
