import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ButtonVariant } from "src/components/core/Button/Button";
import { HOME_PATH } from "src/consts/path/path";
import { IQaMetric } from "src/interfaces/entities/IQaMetric";
import { selectCurrentlyViewingCode } from "src/reduxState/slices/organizationSlice";
import { getCalenderUrl } from "src/reduxState/slices/userSlice";
import { VerificationSubmittedDataStep2 } from "../VerificationSubmittedDataStep2";
import { VerifyDataAccuracyStep1 } from "../VerifyDataAccuracyStep1";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";

interface IMetricDialogProps {
    isDataSubmitted: boolean;
    meta: any;
    metricsWithConnector: IQaMetric[];
    setMetricsWithConnector: React.Dispatch<React.SetStateAction<IQaMetric[]>>;
    onSubmit: (isSubmitBtnClicked: boolean) => void;
    isAllMetricsProcessed: boolean;
    isSubmitLoading: boolean;
    setIsDataSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    isAllMetricsAccepted: boolean;
}

interface IActionProps {
    label: string;
    onClick: () => void;
    disabled: boolean;
    props?: {
        variant?: "text" | "outlined" | "contained";
        "data-cy"?: string;
    };
}

export const MetricDialog: FC<IMetricDialogProps> = ({
    isDataSubmitted,
    meta,
    metricsWithConnector,
    setMetricsWithConnector,
    onSubmit,
    isAllMetricsProcessed,
    isSubmitLoading,
    setIsDataSubmitted,
    isAllMetricsAccepted,
}) => {
    const analyticsService = useAnalyticsService();
    const navigate = useNavigate();
    const calenderUrl = useSelector(getCalenderUrl);
    const orgCode = useSelector(selectCurrentlyViewingCode);

    const title = isDataSubmitted
        ? isAllMetricsAccepted
            ? "Glad Everything Checks Out!"
            : "We’re Taking a Closer Look!"
        : "Verify Data Accuracy";

    const instanceSlot = isDataSubmitted ? (
        <VerificationSubmittedDataStep2
            meta={meta}
            metricsWithConnector={metricsWithConnector}
            isAllMetricsAccepted={isAllMetricsAccepted}
        />
    ) : (
        <VerifyDataAccuracyStep1
            meta={meta}
            metricsWithConnector={metricsWithConnector}
            setMetricsWithConnector={setMetricsWithConnector}
        />
    );

    const onSaveHanlder = () => {
        analyticsService.logEvent("Data QA Save Clicked");
        onSubmit(false);
    };

    const onSubmitHanlder = () => {
        analyticsService.logEvent("Data QA Submit Clicked");
        onSubmit(true);
    };

    const actions: Record<string, IActionProps> = isDataSubmitted
        ? {
              leftAction: {
                  label: "Edit",
                  onClick: () => setIsDataSubmitted(false),
                  disabled: !isAllMetricsProcessed || isSubmitLoading,
              },
              rightAction: {
                  label: "Schedule a Review",
                  onClick: () => window.open(calenderUrl),
                  disabled: !isAllMetricsProcessed || isSubmitLoading,
              },
              editAction: {
                  label: "Close",
                  onClick: () => navigate(`/org/${orgCode}${HOME_PATH}?data_qa=incompleted`),
                  disabled: isSubmitLoading,
                  props: {
                      "data-cy": "closeSubmittedData",
                  },
              },
          }
        : {
              rightAction: {
                  label: "Submit",
                  onClick: () => onSubmitHanlder(),
                  disabled: !isAllMetricsProcessed || isSubmitLoading,
                  props: {
                      "data-cy": "submitMetricQAData",
                  },
              },
              leftAction: {
                  label: "Save",
                  onClick: () => onSaveHanlder(),
                  disabled: isSubmitLoading,
                  props: {
                      variant: ButtonVariant.Text,
                  },
              },
          };

    return (
        <Stack
            gap={2}
            sx={{
                maxWidth: "620px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Typography variant="h3">{title}</Typography>
            <Box sx={{ flex: 1 }}>{instanceSlot}</Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
                gap={1}
                sx={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "white",
                    padding: "16px",
                    borderTop: "1px solid #0000001F",
                }}
            >
                {actions.editAction && (
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onClick={actions.editAction.onClick}
                        disabled={actions.editAction.disabled}
                        {...actions.editAction.props}
                    >
                        {actions.editAction.label}
                    </Button>
                )}
                <Stack direction="row" gap={3} marginLeft="auto">
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onClick={actions.leftAction.onClick}
                        disabled={actions.leftAction.disabled}
                        {...actions.leftAction.props}
                        sx={{ marginLeft: "auto" }}
                    >
                        {actions.leftAction.label}
                    </Button>
                    {(!isDataSubmitted || !isAllMetricsAccepted) && (
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={actions.rightAction.onClick}
                            disabled={actions.rightAction.disabled}
                            {...actions.rightAction.props}
                        >
                            {actions.rightAction.label}
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
