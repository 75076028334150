import React from "react";
import { Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useSelector } from "react-redux";

import { IFeature } from "../../../interfaces/entities/IFeature";
import { useStylesForRowInTable } from "../../../consts/StylesForTables";
import { PRIMARY_MAIN_COLOR } from "../../../consts/colors";
import { demoModeSelector } from "../../../reduxState/slices/demoModeSlice";
import { TableActionCell } from "../../../components/core/TableActionCell/TableActionCell";

interface IFeaturesPageRow {
    feature: IFeature;
    onDeleteFeature: (e: any, feature: IFeature) => void;
    onEditFeature: (e: any, feature: IFeature) => void;
}

export const FeaturesTableRow: React.FC<IFeaturesPageRow> = ({ onDeleteFeature, onEditFeature, feature }) => {
    const classes = useStylesForRowInTable();
    const isDemoMode = useSelector(demoModeSelector);

    return (
        <TableRow key={feature.id} className={classes.bodyRow}>
            <TableCell align="left" className={classes.tableCell}>
                <Typography style={{ marginLeft: "5px" }} variant="body2">
                    {feature.displayName}
                </Typography>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
                <Typography style={{ marginLeft: "5px" }} variant="body2">
                    {feature.cleanName}
                </Typography>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
                <Typography style={{ marginLeft: "5px" }} variant="body2">
                    {feature.description}
                </Typography>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
                <Typography color={PRIMARY_MAIN_COLOR}>{feature.companyInfo.length}</Typography>
            </TableCell>
            {!isDemoMode && (
                <TableActionCell
                    actionIcons={[
                        { title: "Edit", onClick: onEditFeature, data: feature, icon: "editIcon2" },
                        { title: "Delete", onClick: onDeleteFeature, data: feature, icon: "trashIcon" },
                    ]}
                />
            )}
        </TableRow>
    );
};
