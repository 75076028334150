import optimizerImage from "src/assets/marketing-mix-optimizer-launch-main-image.png";
import { EmptyViewerWrapper } from "./EmptyViewerWrapper";
import { OnboardingStateValue } from "src/consts/onboardingPage/onboardingPage";

interface IDescriptionType {
    empty: JSX.Element;
    [OnboardingStateValue.ready]: JSX.Element;
}

const description: IDescriptionType = {
    empty: (
        <>
            We’ve reached out to your administrator to set up the necessary data channels.
            <br /> In the meantime, here is a sneak peek to what is possible.
        </>
    ),
    [OnboardingStateValue.ready]: (
        <>While you wait, explore the article below and discover the possibilities of this feature.</>
    ),
};

export const OnboardingViewerOptimizationPage = ({ state }: { state: keyof IDescriptionType }) => {
    return (
        <EmptyViewerWrapper
            title={<>We're Busy Building Your Optimizer Dashboard!</>}
            description={description[state]}
            blog={{
                link: "https://www.prescient-ai.io/blog/introducing-marketing-mix-optimizer",
                image: optimizerImage,
                writter: "Michael True • 26 Sep 2023",
                heading: "Introducing Our Newest Feature: Marketing Mix Optimizer",
                description:
                    "We’re thrilled to announce the public launch of our newest feature, which helps our clients shape their media plan to maximize revenue and ROAS outcomes.",
            }}
        />
    );
};
