import rootReducer from "./rootReducer";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { useDispatch } from "react-redux";

import { actionListenerEnhancer } from "../enhancers/actionListener";
import baseCreateApi from "../apis/baseCreateApi";

declare global {
    // tslint:disable-next-line
    interface Window {
        Cypress: any;
        store: any;
        CC: any;
        initCookieConsent: any;
        Vitally: any;
    }
}

const persistConfig = {
    key: "root",
    version: 4,
    storage,
    whitelist: [
        "currentUser",
        "organizations",
        "settings",
        "connectLaterCount",
        "isDemoMode",
        "adBlockSkipCount",
        "settings",
        "filterView",
        "connectors",
        "features",
        "supportedDataSources",
        "closedBrokenConnectors",
        "dateFilter",
        "announcements",
        "scenarios",
        "tactics",
    ],
};

const reducers = combineReducers({
    [baseCreateApi.reducerPath]: baseCreateApi.reducer,
    ...rootReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    enhancers: [actionListenerEnhancer],
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(baseCreateApi.middleware),
});

if (window.Cypress) {
    window.store = store;
}

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch;
