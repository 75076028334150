import React, { useEffect } from "react";
import { css } from "@emotion/css";
import { Box, Tab, Tabs, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";

import { HOVER_ITEM_COLOR, PRIMARY_MAIN_COLOR } from "../../consts/colors";

const useStylesForTabs = (theme: Theme) => ({
    tab: css({
        padding: "8px 16px",
        minWidth: "220px",
        borderBottom: "1px solid rgba(217, 217, 217, 1)",
        textTransform: "capitalize",
        fontSize: "15px",
        alignItems: "start",
        color: "rgba(0, 0, 0, 1)",
        textAlign: "left",
        position: "relative",
        "&.Mui-selected": {
            borderRight: `8px solid ${theme.palette.secondary.main}`,
            background: "rgba(32, 219, 94, 0.12)",
            color: "rgba(0, 0, 0, 1)",
        },
        "&:hover": {
            backgroundColor: HOVER_ITEM_COLOR,
        },
    }),
    icon: css({
        "svg path": {
            stroke: "rgba(0, 0, 0, 0.6)",
        },
        "&.Mui-selected": {
            "svg path": {
                stroke: PRIMARY_MAIN_COLOR,
            },
        },
    }),
});

interface IVerticalLightTabsProps {
    tabs: any[];
    height?: string;
    scrollButtons?: boolean;
    initialValue: string | boolean;
    style?: any;
    callback(tab: any): void;
}

export const VerticalLightTabs: React.FC<IVerticalLightTabsProps> = ({
    tabs,
    callback,
    scrollButtons = true,
    height,
    initialValue,
    style,
}) => {
    const theme = useTheme();
    const classes = useStylesForTabs(theme);
    const [value, setValue] = React.useState(initialValue === false ? false : 0);

    useEffect(() => {
        setValue(initialValue === false ? false : tabs.findIndex((t) => t.id === initialValue));
    }, [initialValue]);

    const handleChange = (newValue: any) => {
        setValue(tabs.findIndex((t) => t.id === newValue.id));
        callback(newValue);
    };

    return (
        <Box sx={style}>
            <Tabs
                value={value}
                variant="scrollable"
                aria-label="full width tabs example"
                scrollButtons={scrollButtons}
                orientation={"vertical"}
                sx={{
                    "button:first-of-type": {
                        borderTop: "1px solid rgba(217, 217, 217, 1)",
                    },
                    ".MuiTabs-scroller": {
                        maxHeight: height || "auto",
                    },
                }}
                TabIndicatorProps={{
                    sx: {
                        width: "8px",
                        backgroundColor: theme.palette.secondary.main,
                    },
                }}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        className={classes.tab}
                        key={index}
                        label={
                            <Stack
                                style={{ display: "flex", alignItems: "center" }}
                                justifyContent={"space-between"}
                                direction={"row"}
                                width={"100%"}
                            >
                                {tab.info ? (
                                    <Tooltip arrow title={tab.info} placement="right">
                                        <span
                                            className={`${classes.icon} ${value === index ? "Mui-selected" : ""}`}
                                            style={{ margin: "4px 0" }}
                                        >
                                            <Typography fontSize="15px">{tab.label}</Typography>
                                            {tab.subLable && (
                                                <Typography variant="body2" color="text.secondary">
                                                    {tab.subLable}
                                                </Typography>
                                            )}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <Typography fontSize="15px" sx={{ margin: "4px 0", textTransform: "initial" }}>
                                        {tab.label}
                                    </Typography>
                                )}
                            </Stack>
                        }
                        onClick={() => handleChange(tab)}
                    />
                ))}
            </Tabs>
        </Box>
    );
};
