import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { format } from "date-fns";
import groupBy from "lodash/groupBy";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
    MetricNameTypeForPayload,
    defaultDateRangeForHomePage,
    groupByChannelOption,
} from "src/consts/homeDashboard/homeDashboard";
import { IDateSelectionRange } from "src/interfaces/dashboard/trend";
import { dataQaReady, selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { DashboardCardHeader } from "../CardComponents/CardHeader/CardHeader";
import { VerifyDataQa } from "../CardComponents/VerifyDataQa";
import { currentUserRoleByCompanyIdSelector, isSuperAdminSelector } from "src/reduxState/slices/userSlice";
import { UserRole } from "src/enums/UserRole";
import { useLazyGetAtiributionMetricTimeSeriesByNameQuery } from "src/reduxState/apis/historicalApi";
import { BaseVsHaloChart } from "./BaseVsHaloChart";
import { dataSourcesByProgrammaticNameSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import { capitalizeFirstLetter } from "src/services/utils";
import { TargetSelect } from "../CardComponents/TargetSelect";
import SimpleReactDateRange from "src/components/DateRangeFilter/SimpleReactDateRange";
import { DefaultDateRangeInText, getRangeFormattedTitle } from "src/components/DateRangeFilter/reactDateRangeUtils";
import { HomepageCardContainer } from "../CardComponents/HomepageCardContainer";
import { partialStateSelectorForHome } from "src/reduxState/slices/settingsSlice";
import { HomePagePartialCard } from "../CardComponents/HomePagePartialCard";
import { ExportTableData } from "src/components/ExportTableData/ExportTableData";
import { IExportHead, exportCSV, prepareDataForCSV } from "src/services/exportCSV";
import { IDictionary } from "src/interfaces/IDictionary";
import { IChartItem } from "src/interfaces/chart/IChartItem";
import { FORMATS } from "src/enums/Formats";
import { getComparator, stableSort } from "src/utils/sort";

export interface IBaseHaloColumn {
    [key: string]: number | string | undefined;
}

interface IBaseVsHaloCard {
    tooltipRef: React.RefObject<HTMLDivElement>;
}

export const BaseVsHaloCard: FC<IBaseVsHaloCard> = ({ tooltipRef }) => {
    const orgId = useSelector(selectCurrentlyViewingId);
    const dataSources = useSelector(dataSourcesByProgrammaticNameSelector);
    const partialStateForHome = useSelector(partialStateSelectorForHome);

    const chartTitle = useMemo(() => "Base vs Halo Effect Rollup", []);

    const [startRange, setStartRange] = useState<IDateSelectionRange>(defaultDateRangeForHomePage);
    const [grpBy, setGrpBy] = useState<string>(groupByChannelOption[0].id);

    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);

    const isAdmin = userRole === UserRole.ADMIN || isSuperAdmin;

    const [getAtiributionMetricTimeSeriesByName, { data = { data: [] }, isFetching, isLoading }] =
        useLazyGetAtiributionMetricTimeSeriesByNameQuery();

    const isDataQaReady = useSelector(dataQaReady);

    useEffect(() => {
        setGrpBy(groupByChannelOption[0].id);
        setStartRange(defaultDateRangeForHomePage);
    }, [orgId]);

    useEffect(() => {
        const { startDate, endDate } = startRange.selection;
        getAtiributionMetricTimeSeriesByName({
            orgId,
            start_date: format(startDate, "yyyy-MM-dd"),
            end_date: format(endDate, "yyyy-MM-dd"),
            metric_names: [MetricNameTypeForPayload.FirstOrderRevenue, MetricNameTypeForPayload.SecondOrderRevenue],
        });
    }, [startRange, orgId]);

    const dateRangeChangeHandler = (dateRange: IDateSelectionRange, selectedPreset: DefaultDateRangeInText) => {
        setStartRange(dateRange);
    };

    const handleGrpByChange = (value: string) => {
        setGrpBy(value);
    };

    const grpByTarget = () => {
        const dataWithChannelName = data.data.map((c) => ({
            ...c,
            channelName: dataSources[c.name]?.name || capitalizeFirstLetter(c.name),
        }));
        const groupedData = groupBy(dataWithChannelName, "target");
        return grpBy === "total" ? dataWithChannelName : groupedData[`${grpBy}_revenue`];
    };

    const isApiLoading = isFetching || isLoading;

    const partialHomeElement = (
        <HomePagePartialCard
            icon={
                <DataThresholdingOutlinedIcon
                    color="primary"
                    sx={(theme) => ({
                        width: "56px",
                        height: "56px",
                        opacity: "100%",
                        color: theme.palette.secondary.main,
                    })}
                />
            }
            heading="Assembling Base vs Halo Effect Rollup"
            description={
                <>
                    Results typically begins to flow 1 week after data verification is complete. Please allow more time
                    for Amazon. <br /> Check back later!
                </>
            }
            actionButton={
                <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    endIcon={<ChevronRightIcon />}
                    href="https://help.prescient-ai.io/en/articles/9293715-new-feature-tour-homepage#h_8b9ffd8156"
                    target="_blank"
                >
                    Learn More About Base vs Halo Effect Rollup
                </Button>
            }
            style={{
                padding: "var(--4, 32px) 345px",
            }}
        />
    );
    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setOpenExportMenu(false);
    };

    const seriesData = grpByTarget();

    const metrics: IDictionary = useMemo(
        () => ({
            first_order_revenue: {
                name: "Base",
            },
            second_order_revenue: {
                name: "Halo",
            },
        }),
        [grpBy],
    );

    const anchorRef = useRef<HTMLDivElement>(null);

    const exportVisibleColumns = () => {
        // group seriesData by metricName and then by target
        const grpByMetricName: IDictionary<IChartItem[]> = groupBy(
            seriesData.map((s) => ({ ...s, date: `${s.date}=${s.channelName}` })),
            "metricName",
        );
        const targetObject: IDictionary<IDictionary<IChartItem[]>> = Object.keys(grpByMetricName)
            .sort()
            .reduce((acc, key) => {
                acc[key] = groupBy(grpByMetricName[key], "target");
                return acc;
            }, {} as IDictionary);

        // function to group by date and calculate total values
        const groupByDateAndCalculateTotal = (revenueArray: IChartItem[]) =>
            revenueArray.reduce((acc, { date, value, metricName, target }) => {
                if (target && metricName) {
                    const formattedTarget = capitalizeFirstLetter(
                        target.replace(/_/g, " ").replace("revenue", "").trim(),
                    );
                    const seriesName = `${metrics[metricName].name} - ${formattedTarget}`;

                    if (!acc[date]) {
                        acc[date] = { date, seriesName, value: 0 };
                    }
                    acc[date].value += value;
                }
                return acc;
            }, {} as Record<string, { date: string; seriesName: string; value: number }>);

        // prepare CSV data
        const csvData = Object.entries(targetObject)
            .flatMap(([_, values]) =>
                Object.values(values).flatMap((revenueArray) =>
                    Object.values(groupByDateAndCalculateTotal(revenueArray)),
                ),
            )
            .reduce((acc, item) => {
                if (!acc.has(item.date)) {
                    acc.set(item.date, { date: item.date.split("=")[0] });
                }
                const dataItem = acc.get(item.date);
                dataItem.Channel = item.date.split("=")[1];
                dataItem.Total = (dataItem.Total || 0) + item.value;
                dataItem[item.seriesName] = item.value;
                return acc;
            }, new Map<string, any>());

        const finalCsvData: any[] = stableSort(Array.from(csvData.values()), getComparator("asc", "date"));

        // create column headers
        const columns = Object.keys(finalCsvData[0]).map((key) => ({
            id: key,
            label: key === "date" ? "Day" : key,
            sign: FORMATS.DOLLAR,
            fixed: 2,
        }));

        // sum values by keys
        const totalData = () =>
            finalCsvData.reduce((acc, obj) => {
                Object.keys(obj).forEach((key) => {
                    if (!["Channel", "date"].includes(key)) {
                        acc[key] = (acc[key] || 0) + obj[key];
                    }
                });
                return acc;
            }, {} as Record<string, number>);

        exportCSV(
            `Base vs Halo Effect ${getRangeFormattedTitle([startRange.selection])}`,
            prepareDataForCSV(
                [
                    {
                        date: `Total (From ${format(startRange.selection.startDate, "MMM d, yyyy")} To ${format(
                            startRange.selection.endDate,
                            "MMM d, yyyy",
                        )})`,
                        ...totalData(),
                    },
                    ...finalCsvData,
                ],
                columns as IExportHead[],
            ),
        );
    };

    const [openExportMenu, setOpenExportMenu] = useState(false);

    const handleExportToggle = () => {
        setOpenExportMenu((prevOpen) => !prevOpen);
    };

    const handleExportMenuClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenExportMenu(false);
    };

    return (
        <HomepageCardContainer>
            <Stack gap="15px">
                <DashboardCardHeader
                    title="Base vs Halo Effect Rollup"
                    tooltipRef={tooltipRef}
                    tooltip="Overall base to halo mix for your business"
                    instances={[
                        <TargetSelect
                            value={grpBy}
                            handleChannelGrpByChange={handleGrpByChange}
                            isDisabled={isApiLoading}
                            chartTitle={chartTitle}
                            isUpgraded={true}
                        />,
                        <SimpleReactDateRange
                            compareView={false}
                            startRange={startRange}
                            applyRangeHandler={(selectedRange, selectedPreset) =>
                                dateRangeChangeHandler(selectedRange, selectedPreset)
                            }
                            isDisabled={isApiLoading}
                            chartTitle={chartTitle}
                            isUpgraded={true}
                        />,
                        <ExportTableData
                            handleMenuItemClick={handleMenuItemClick}
                            anchorRef={anchorRef}
                            exportVisibleColumns={exportVisibleColumns}
                            openExportMenu={openExportMenu}
                            handleExportToggle={handleExportToggle}
                            handleExportMenuClose={handleExportMenuClose}
                            noPopup
                        />,
                    ]}
                />
            </Stack>
            {partialStateForHome ? (
                isDataQaReady && isAdmin ? (
                    <VerifyDataQa tabName="Base vs Halo Effect" />
                ) : (
                    partialHomeElement
                )
            ) : (
                <Stack style={{ width: "98%", overflow: "auto", height: "700px" }}>
                    <BaseVsHaloChart seriesData={grpByTarget()} isFetching={isApiLoading} grpBy={grpBy} />
                </Stack>
            )}
        </HomepageCardContainer>
    );
};
