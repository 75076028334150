import { FC } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

import { IColumnFilterProps, ISheetData } from "src/interfaces/entities/IScenario";
import { ExportOutcomeSheet } from "../ExportOutcomeSheet/ExportOutcomeSheet";
import { CustomColumnFilter } from "src/components/CustomColumnFilter/CustomColumnFilter";
import { IDictionary } from "src/interfaces/IDictionary";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { isScenarioEditableSelector } from "src/reduxState/slices/scenarioSlice";
import { getScenarioTypeLabel } from "src/services/optimizationPage/optimization";

interface IRecommandationSubHeader {
    downloadSheetData: ISheetData;
    columnFilterProps: IColumnFilterProps;
    modalProps: { recomendationsModalToggle: () => void; disabled: boolean };
}

export const RecommandationSubHeader: FC<IRecommandationSubHeader> = ({
    downloadSheetData,
    columnFilterProps,
    modalProps,
}) => {
    const { outcomeCampaignHeader, handleTableCol, compareToLabel } = columnFilterProps;
    const isScenarioEditable = useSelector(isScenarioEditableSelector);

    const columnHeader: IDictionary = {
        expected: `Expected (${compareToLabel})`,
        optimal: getScenarioTypeLabel[downloadSheetData.selectedScenario.scenarioType],
    };

    return (
        <>
            {outcomeCampaignHeader && (
                <CustomColumnFilter
                    cards={outcomeCampaignHeader}
                    handleTableCol={handleTableCol}
                    isDraggable={false}
                    groupByName={"groupName"}
                    columnHeader={columnHeader}
                    variant="text"
                />
            )}
            <ExportOutcomeSheet sheetData={downloadSheetData} />
            {isScenarioEditable && (
                <Button
                    variant="text"
                    sx={(theme) => ({
                        textTransform: "inherit",
                        "span svg path": {
                            stroke: theme.palette.primary.main,
                        },
                    })}
                    startIcon={<SVGIconRenderer icon="filterIcon" />}
                    onClick={modalProps.recomendationsModalToggle}
                    disabled={modalProps.disabled}
                >
                    Customize Recomendations
                </Button>
            )}
        </>
    );
};
