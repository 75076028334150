import { css } from "@emotion/css";
import React, { useEffect, useState, useRef } from "react";
import { Typography, Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { JustifyContent, Flex } from "../../components/core/Flex/Flex";
import { FlexItem } from "../../components/core/FlexItem/FlexItem";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";

export interface IOnboardingItemTooltip {
    title: string;
    description: string | JSX.Element;
}

export enum Placements {
    RIGHT = "right",
    BELOW = "below",
}

interface IOnboardingTooltipProps {
    cards: IOnboardingItemTooltip[];
    targets: Array<React.RefObject<HTMLElement>>;
    placement: Placements;
    delay?: number;
    onFinishCloseTooltip?: () => void;
    showCloseIcon?: boolean;
}

export const OnboardingTooltip: React.FC<IOnboardingTooltipProps> = ({
    cards,
    targets,
    placement,
    delay,
    onFinishCloseTooltip,
    showCloseIcon = true,
}) => {
    const [shouldDisplay, setShouldDisplay] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const isBackDisabled = () => currentIndex === 0;
    const isNextDisabled = () => currentIndex === cards.length - 1;

    const handleDismiss = () => {
        if (onFinishCloseTooltip) {
            onFinishCloseTooltip();
        }
        setShouldDisplay(false);
    };

    const handleNext = () => {
        if (isNextDisabled()) {
            if (onFinishCloseTooltip) {
                onFinishCloseTooltip();
                return;
            }
            return;
        }
        setCurrentIndex(currentIndex + 1);
    };

    const handlePrevious = () => {
        if (isBackDisabled()) {
            return;
        }

        setCurrentIndex(currentIndex - 1);
    };

    const rightPlacement = () => css`
        &:before {
            z-index: 10;
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: 0;
            bottom: 0;
            top: calc(50% - 5px);
            left: 0;
            box-sizing: border-box;
            border: 5px solid #fff;
            border-color: #FFF;
            transform-origin: 0 0;
        }
        &:after {
            z-index: -10;
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: 0;
            bottom: 0;
            top: calc(50% - 10px);
            left: 0;
            box-sizing: border-box;
            border: 5px solid #fff;
            border-color: #FFF;
            transform-origin: 0 0;
            transform: rotate(45deg);
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
        }
        left: 100%;
        margin-left: 16px;
        top: 50%;
        transform: translateY(-50%);
    `;

    const belowPlacement = () => css`
        &:before {
            z-index: 10;
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: -10px;
            top: 0px;
            left: calc(50%);
            box-sizing: border-box;
            border: 8px solid #fff;
            border-color: #FFF;
            transform-origin: 0 0;
        }
        &:after {
            z-index: -10;
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: 0;
            top: -7px;
            left: calc(50%);
            box-sizing: border-box;
            border: 5px solid #fff;
            border-color: #FFF;
            transform-origin: 0 0;
            transform: rotate(45deg);
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
        }
        top: calc(100% + 15px);
        left: 50%;
        transform: translateX(-50%);
    `;


    const tooltipWrapper = () => css`
        ${placement === Placements.RIGHT ? rightPlacement() : belowPlacement()}
        position: absolute;
        width: 314px;
        box-sizing: border-box;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        z-index: 10;
        background-color: #fff;
    `;

    const flexStyle = () => css`
        padding: 6px 16px 6px 16px;
        gap: 4px;
    `;

    const updateTooltipPosition = () => {
        if (cards.length > 1 && shouldDisplay && targets[currentIndex]?.current && tooltipRef.current) {
            const target = targets[currentIndex].current;
            const tooltip = tooltipRef.current;

            if (target) {
                const targetRect = target.getBoundingClientRect();
                const tooltipRect = tooltip.getBoundingClientRect();

                let top = 0;
                let left = 0;

                if (placement === Placements.RIGHT) {
                    top = targetRect.top + window.scrollY + targetRect.height / 2 - tooltipRect.height / 2;
                    left = targetRect.left + window.scrollX + targetRect.width + 16;
                } else if (placement === Placements.BELOW) {
                    top = targetRect.top + window.scrollY + targetRect.height + 15;
                    left = targetRect.left + window.scrollX + targetRect.width / 2 - tooltipRect.width / 2;
                }

                tooltip.style.top = `${top}px`;
                tooltip.style.left = `${left}px`;
                window.scrollTo({ top: top - 220, behavior: "smooth" });
            }
        }
    };

    useEffect(() => {
        if (delay) {
            setShouldDisplay(false);
            setTimeout(() => {
                setShouldDisplay(true);
            }, delay);
        } else {
            setShouldDisplay(true);
        }
    }, [delay]);

    useEffect(() => {
        updateTooltipPosition();
    }, [shouldDisplay, currentIndex, placement, targets]);

    useEffect(() => {
        window.addEventListener("resize", updateTooltipPosition);
        return () => {
            window.removeEventListener("resize", updateTooltipPosition);
        };
    }, [shouldDisplay, currentIndex, placement, targets]);

    return (
        <>
            {shouldDisplay && (
                <div className={tooltipWrapper()} ref={tooltipRef}>
                    <Flex className={`${flexStyle()} container`}>
                        <FlexItem className="content" style={{ padding: "8px 0px 8px 0px" }}>
                            <Typography test-id="title" variant="h5" style={{ lineHeight: "1", marginBottom: "10px" }}>
                                {cards[currentIndex].title}
                            </Typography>
                            <Typography test-id="description" variant="body2" color="text.secondary" component="div">
                                {cards[currentIndex].description}
                            </Typography>
                        </FlexItem>
                        {showCloseIcon && (
                            <FlexItem style={{ padding: "6px 14px 0px 14px" }}>
                                <SVGIconRenderer icon="closeIcon" onClick={handleDismiss} height="18px" width="18px" />
                            </FlexItem>
                        )}
                    </Flex>
                    {cards.length > 1 && (
                        <div style={{ padding: "8px", textAlign: "center" }}>
                            <Flex justifyContent={JustifyContent.SpaceBetween}>
                                <FlexItem>
                                    {!isBackDisabled() && (
                                        <Button
                                            test-id="back-btn"
                                            size="small"
                                            onClick={handlePrevious}
                                            startIcon={<ChevronLeftIcon />}
                                        >
                                            Back
                                        </Button>
                                    )}
                                </FlexItem>
                                <FlexItem>
                                    <Typography variant="body2" style={{ paddingTop: "6px" }}>
                                        {currentIndex + 1}/{cards.length}
                                    </Typography>
                                </FlexItem>
                                <FlexItem>
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={onFinishCloseTooltip ? false : isNextDisabled()}
                                        test-id="next-btn"
                                    >
                                        {isNextDisabled() && onFinishCloseTooltip ? "Get Started" : "Next"}
                                    </Button>
                                </FlexItem>
                            </Flex>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
