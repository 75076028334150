import { TabContext } from "@mui/lab";
import { Tab, Tabs, Tooltip, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

import { OptimizationTab } from "src/consts/optimizationPage/optimizationPage";
import { PerformanceTab } from "src/consts/performancePaidPage/performancePaidPage";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { SettingsTab } from "src/containers/OrgSettings/OrgSettingsV3";
import { DataSourceIntegrationTabs } from "src/consts/dataSourcePage/dataSourcePage";

type TabType = PerformanceTab | OptimizationTab | SettingsTab | DataSourceIntegrationTabs;

export const PageHeaderTabContext = ({
    handleChange,
    value,
    tabs,
    campaignAvailable = true,
}: {
    handleChange: (event: React.SyntheticEvent, newValue: any) => void;
    tabs: Array<{
        label: string;
        value: TabType;
        icon?: string | JSX.Element;
        to?: string;
        filter?: string;
        isHidden?: boolean;
    }>;
    value: TabType;
    campaignAvailable?: boolean;
}) => {
    return (
        <TabContext value={value}>
            <Box>
                <Tabs
                    sx={(theme) => ({
                        minHeight: "42px",
                        "& .MuiTabs-flexContainer": {
                            minHeight: "42px",
                        },
                        "& .MuiTab-root": {
                            fontSize: "14px",
                            minHeight: "unset",
                            fontWeight: 500,
                        },
                        "& .MuiTab-root.Mui-selected": {
                            svg: {
                                "& path": {
                                    stroke: theme.palette.primary.main,
                                },
                            },
                        },
                        "& .MuiTabs-indicator": {
                            height: "2px",
                            backgroundColor: theme.palette.secondary.main,
                        },
                    })}
                    onChange={handleChange}
                    value={value}
                >
                    {tabs.map((tab) => {
                        const iconProps = {
                            to: tab.to,
                            component: Link,
                            ...(tab.icon && {
                                icon:
                                    typeof tab.icon === "string" ? (
                                        <SVGIconRenderer
                                            strokeColor="#00000099"
                                            icon={tab.icon}
                                            width="16px"
                                            height="16px"
                                        />
                                    ) : (
                                        tab.icon
                                    ),
                            }),
                        };
                        return tab.value === PerformanceTab.Campaigns && !campaignAvailable
                            ? !tab.isHidden && (
                                  <RemoveProps key={tab.value}>
                                      <Tooltip
                                          title={
                                              <Typography variant="body2">There are no channels connected</Typography>
                                          }
                                          arrow
                                      >
                                          <div>
                                              <Tab
                                                  sx={{ textTransform: "inherit", padding: "9px 16px" }}
                                                  label={tab.label}
                                                  value={tab.value}
                                                  data-cy={tab.value}
                                                  iconPosition="start"
                                                  {...iconProps}
                                                  disabled
                                              />
                                          </div>
                                      </Tooltip>
                                  </RemoveProps>
                              )
                            : !tab.isHidden && (
                                  <Tab
                                      sx={{ textTransform: "inherit", padding: "9px 16px" }}
                                      label={tab.label}
                                      value={tab.value}
                                      key={tab.value}
                                      data-cy={tab.value}
                                      iconPosition="start"
                                      {...iconProps}
                                  />
                              );
                    })}
                </Tabs>
            </Box>
        </TabContext>
    );
};

function RemoveProps(props: any) {
    const { children, style } = props;
    return <div style={{ ...style }}>{children}</div>;
}
