import React from "react";
import { Avatar, Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useSelector } from "react-redux";
import { css } from "@emotion/css";

import useAvatar from "../../MemberCard/useAvatar";
import { formatDate } from "../../../services/members/members";
import { memberRoleForContextSelector } from "../../../reduxState/slices/organizationSlice";
import { UserRole } from "../../../enums/UserRole";
import { currentUserRoleByCompanyIdSelector, isSuperAdminSelector } from "../../../reduxState/slices/userSlice";
import { demoModeSelector } from "../../../reduxState/slices/demoModeSlice";
import { customAvatarClass, useStylesForRowInTable } from "../../../consts/StylesForTables";
import { PRIMARY_MAIN_COLOR } from "../../../consts/colors";
import { capitalizeFirstLetter } from "../../../services/utils";
import { TableActionCell } from "../../../components/core/TableActionCell/TableActionCell";

const useStyles = () => ({
    downloadIcon: css({
        cursor: "pointer",
    }),
    blockWithFilenameAndIcon: css({
        display: "flex",
        alignItems: "center",
    }),
    statusClass: css({
        textTransform: "uppercase",
        fontWeight: "bold",
        width: "fit-content",
        height: "32px",
        fontSize: "13px",
        lineHeight: "18px",
        letterSpacing: "0.16px",
    }),
    expiredStatus: css({
        backgroundColor: "#f443362b",
        color: "#d14838",
        borderColor: "#d14838",
    }),
    pendingStatus: css({
        backgroundColor: "#eeeeee",
        color: "#888888",
        borderColor: "#888888",
    }),
    arrowClass: css({
        fontSize: "10px",
        marginLeft: "2px",
    }),
});

interface IMembersPageRow {
    labelId: string;
    member: any;
    isAllAcceptedMembers: boolean;

    onDeleteMember: (e: any, member: any) => void;
    onEditMember: (e: any, member: any) => void;
    statusClick: (e: any, member: any) => void;
}

export const MembersTableRow: React.FC<IMembersPageRow> = ({
    member,
    labelId,
    isAllAcceptedMembers,
    onDeleteMember,
    onEditMember,
    statusClick,
}) => {
    const classes = { ...useStyles(), ...useStylesForRowInTable() };
    const avatarUrl = useAvatar(member.avatarMediumUrl || "");
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const isDemoMode = useSelector(demoModeSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const isActionVisible = !isDemoMode && (isSuperAdmin || userRole !== UserRole.MEMBER);

    // @ts-ignore
    const currentRole = useSelector((state) => memberRoleForContextSelector(state, member));

    const getRole = (role: string | undefined) => {
        if (!role) {
            return "";
        } else if (role === "superadmin") {
            return "Super admin";
        } else if (role === "member") {
            return "Viewer";
        } else {
            return role.charAt(0).toUpperCase() + role.slice(1);
        }
    };

    return (
        <TableRow aria-checked={member.isChecked} key={member.id} className={classes.bodyRow}>
            <TableCell className={classes.tableCell}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {avatarUrl ? (
                        <Avatar
                            src={avatarUrl}
                            sx={{ width: "32px !important", height: "32px !important", marginRight: "8px" }}
                        />
                    ) : (
                        <Avatar
                            className={`${customAvatarClass}`}
                            sx={{ width: "32px !important", height: "32px !important", marginRight: "8px" }}
                        >
                            {member.firstName && member.lastName
                                ? `${member.firstName[0].toUpperCase()}${member.lastName[0].toUpperCase()}`
                                : ""}
                        </Avatar>
                    )}
                    <span>
                        {member.firstName} {member.lastName}
                    </span>
                </div>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
                <Typography variant="body2" color={PRIMARY_MAIN_COLOR}>
                    {member.email}
                </Typography>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
                <Typography style={{ marginLeft: "5px" }} variant="body2">
                    {getRole(currentRole)}
                </Typography>
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {formatDate(member.createdAt.toString())}
            </TableCell>
            {isActionVisible && (
                <>
                    {!isAllAcceptedMembers && (
                        <TableCell align="center" className={classes.tableCell}>
                            {member.invitationState === "expired" || member.invitationState === "pending" ? (
                                <Typography>
                                    {capitalizeFirstLetter(member.invitationState)}
                                    <span style={{ margin: "0 2px" }}>&#x2022;</span>
                                    <span
                                        onClick={(e) => statusClick(e, member)}
                                        style={{ color: "#009985", cursor: "pointer" }}
                                    >
                                        Resend
                                    </span>
                                </Typography>
                            ) : (
                                ""
                            )}
                        </TableCell>
                    )}
                    <TableActionCell
                        actionIcons={[
                            { title: "Edit", onClick: onEditMember, data: member, icon: "editIcon2", id: "memberEdit" },
                            {
                                title: "Delete",
                                onClick: onDeleteMember,
                                data: member,
                                icon: "trashIcon",
                                id: "memberDelete",
                            },
                        ]}
                    />
                </>
            )}
        </TableRow>
    );
};
