import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/system";
import groupBy from "lodash/groupBy";
import { useDispatch, useSelector } from "react-redux";

import { SubHeader } from "src/components/SubHeader/SubHeader.v2";
import Loader from "src/components/core/Loader/Loader";
import { useLazyGetAllDataSourcesQuery } from "src/reduxState/apis/dataSourcesApi";
import { getSearchedAndFilteredDataSources } from "src/services/dataSources/dataSources";
import { DataSourceTable } from "src/components/DataSource/DataSourceTable/DataSourceTable";
import { IDataSourceForm } from "src/interfaces/IData";
import { useLazyGetFivetranTablesQuery } from "src/reduxState/apis/fivetranTablesApi";
import { setSupportedDataSources, supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";

export const DataSources = ({
    openEditDataSourceModal,
    openDeleteModal,
}: {
    openEditDataSourceModal: (dataSource: IDataSourceForm) => void;
    openDeleteModal: (dataSource: IDataSourceForm) => void;
}) => {
    const supportedDataSources = useSelector(supportedDataSourcesSelector);
    const dispatch = useDispatch();

    const [getFivetranTables] = useLazyGetFivetranTablesQuery();
    const [getAllDataSources, { isLoading }] = useLazyGetAllDataSourcesQuery();
    const [search, setSearch] = useState<string>("");

    const fetchData = useCallback(async () => {
        try {
            const dataSources = await getAllDataSources({}).unwrap();
            const fivetranTables = await getFivetranTables({}).unwrap();

            const groupedFivetranTables = groupBy(fivetranTables, "dataSourceId");
            const dataSourcesWithFivetranTable = dataSources.map((dataSource) => ({
                ...dataSource,
                fivetranTable: groupedFivetranTables[dataSource.id] || [],
            }));

            dispatch(setSupportedDataSources(dataSourcesWithFivetranTable));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const filteredData = useMemo(
        () => getSearchedAndFilteredDataSources(supportedDataSources, search),
        [supportedDataSources, search],
    );

    return (
        <Stack>
            {isLoading ? (
                <Loader />
            ) : (
                <Fragment>
                    <SubHeader
                        itemsLength={filteredData.length}
                        title="Data Source(s)"
                        onSearchChange={handleSearchChange}
                    />
                    <DataSourceTable
                        data={filteredData}
                        editClick={openEditDataSourceModal}
                        deleteClick={openDeleteModal}
                    />
                </Fragment>
            )}
        </Stack>
    );
};
