import React, { FC } from "react";
import { Button, TextField, InputAdornment, Stack, Popper } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { formatValue } from "src/services/utils";
import { FORMATS } from "src/enums/Formats";
import { IQaMetric } from "src/interfaces/entities/IQaMetric";
import { OnboardingTooltip, Placements } from "src/components/OnboardingTooltip/OnboardingTooltip";

interface IDeclineTooltipProps {
    metricData: IQaMetric;
    userReportedData?: {
        id: string;
        value: string;
    };
    currentCheckboxAnchorEl: HTMLElement | null;
    onExpectedValueChange?: (value: string, id: string) => void;
    onDeclineReasonSave?: (metricData: IQaMetric) => void;
    onDeclineReasonClose?: (metricData: IQaMetric) => void;
}

export const DeclineTooltip: FC<IDeclineTooltipProps> = ({
    metricData,
    userReportedData,
    currentCheckboxAnchorEl,
    onExpectedValueChange,
    onDeclineReasonSave,
    onDeclineReasonClose,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/,/g, "");
        if (/^\d*\.?\d*$/.test(value)) {
            onExpectedValueChange && onExpectedValueChange(value, metricData.id);
        }
    };

    return (
        <Popper
            open={Boolean(currentCheckboxAnchorEl)}
            anchorEl={currentCheckboxAnchorEl}
            placement="right"
            disablePortal={false}
            modifiers={[
                {
                    name: "flip",
                    enabled: false,
                    options: {
                        altBoundary: false,
                        rootBoundary: "document",
                        padding: 8,
                    },
                },
                {
                    name: "offset",
                    options: { offset: [0, 60] },
                },
                {
                    name: "preventOverflow",
                    enabled: true,
                    options: {
                        tether: true,
                        rootBoundary: "document",
                        padding: 8,
                    },
                },
            ]}
        >
            <Stack
                position="relative"
                sx={{
                    ".container": {
                        padding: "16px 16px 8px 16px",
                    },
                    "div .content": {
                        width: "100%",
                        padding: "0!important",
                        h5: {
                            marginBottom: "24px!important",
                        },
                        ".MuiFormControl-root": {
                            margin: 0,
                        },
                    },
                }}
            >
                <OnboardingTooltip
                    showCloseIcon={false}
                    cards={[
                        {
                            title: "Reason for Decline",
                            description: (
                                <Stack
                                    gap={2}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <TextField
                                        placeholder="Expected Value"
                                        id="filled-start-adornment"
                                        sx={{
                                            margin: "6px",
                                        }}
                                        InputProps={{
                                            startAdornment:
                                                metricData.metricType !== "SESSIONS" ? (
                                                    <InputAdornment position="start">
                                                        <AttachMoneyIcon />
                                                    </InputAdornment>
                                                ) : null,
                                        }}
                                        variant="standard"
                                        onChange={handleChange}
                                        value={
                                            userReportedData?.value
                                                ? formatValue(+userReportedData.value, FORMATS.NUMERIC, 0)
                                                : ""
                                        }
                                    />
                                    <Stack justifyContent="space-between" direction="row">
                                        <Button
                                            size="small"
                                            variant="text"
                                            sx={{
                                                width: "fit-content",
                                            }}
                                            onClick={() => onDeclineReasonClose && onDeclineReasonClose(metricData)}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="text"
                                            sx={{
                                                width: "fit-content",
                                            }}
                                            onClick={() => onDeclineReasonSave && onDeclineReasonSave(metricData)}
                                        >
                                            Save
                                        </Button>
                                    </Stack>
                                </Stack>
                            ),
                        },
                    ]}
                    placement={Placements.RIGHT}
                    targets={[currentCheckboxAnchorEl] as unknown as Array<React.RefObject<HTMLElement>>}
                />
            </Stack>
        </Popper>
    );
};
