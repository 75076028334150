import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { FormikHelpers } from "formik";

import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../services/utils";
import { selectCurrentlyViewingCompanyById, selectCurrentlyViewingId } from "../../reduxState/slices/organizationSlice";
import { listFeaturesSelector } from "../../reduxState/slices/featuresSlice";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { SimpleOrganizationForm } from "../../components/SimpleOrganizationForm/SimpleOrganizationForm";
import { useEditOrganizationMutation, useLazyGetOrganizationsQuery } from "../../reduxState/apis/organizationApi";
import { transformApiErrors } from "../../utils/transformApiErrors";
import { Modal } from "../../components/core/Modal/Modal";
import { currentUserRoleByCompanyIdSelector, userSuperAdminRoleSelector } from "../../reduxState/slices/userSlice";
import { UserRole } from "../../enums/UserRole";
import { useStylesForHeaderInTable, useStylesTable } from "../../consts/StylesForTables";
import { isShopifyConnectedSelector, shopifyConnectedSelector } from "../../reduxState/slices/connectorsSlice";
import { SettingComponent } from "../../components/SettingComponent/SettingComponent";
import { IRevenueTagForTable, TagModal } from "../../components/TagModal/TagModal";
import {
    useCreateRevenueTagMutation,
    useDeleteRevenueTagMutation,
    useLazyGetRevenueTagQuery,
} from "../../reduxState/apis/revenueTagAPi";

const useStyles = () => ({
    boxStyle: css({
        border: "1px solid rgba(0, 0, 0, 0.5)",
        padding: "30px 30px 50px",
        borderRadius: "5px",
        backgroundColor: "white",
        color: "#000000",
    }),
});

export default function GeneralSettings() {
    const classes = { ...useStyles(), ...useStylesForHeaderInTable(), ...useStylesTable() };
    const [isOpen, setIsOpen] = useState(false);
    const [isTagModalOpen, setIsTagModalOpen] = useState(false);

    const featuresList = useSelector(listFeaturesSelector);
    const [editOrganization] = useEditOrganizationMutation();
    const [getOrganizations, { isLoading }] = useLazyGetOrganizationsQuery();
    const [errState, setErrState] = useState();

    const [getRevenueTag, { data, isLoading: isRevenueTagLoading, isFetching: isRevenueTagFetching }] =
        useLazyGetRevenueTagQuery();
    const [createRevenueTag, { isLoading: isCreateLoading }] = useCreateRevenueTagMutation();
    const [deleteRevenueTag, { isLoading: isDeleteLoading }] = useDeleteRevenueTagMutation();
    const organization = useSelector(selectCurrentlyViewingCompanyById);
    const orgId = useSelector(selectCurrentlyViewingId);
    const isShopifyConnected = useSelector(isShopifyConnectedSelector);
    const shopifyConnector = useSelector(shopifyConnectedSelector);

    const analyticsService = useAnalyticsService();

    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const isSuperAdmin = useSelector(userSuperAdminRoleSelector);
    const isMember = userRole === UserRole.MEMBER && !isSuperAdmin;

    const [historyModalOpen, setHistoryModalOpen] = useState(false);

    useEffect(() => {
        analyticsService.logEvent("Settings Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, []);

    useEffect(() => {
        if (shopifyConnector) {
            getRevenueTag({
                orgId,
                connectorId: shopifyConnector.id,
            });
        }
    }, []);

    const editOrganizationAction = async (values: IOrganization, formikHelpers: FormikHelpers<IOrganization>) => {
        const changedFields = [];
        if (organization.displayName !== values.displayName) {
            changedFields.push("Display Name");
        }
        if (values.avatar) {
            changedFields.push("Logo");
        }
        analyticsService.logEvent("Organization Edited", {
            Page: getPathForAnalytics(location.pathname),
            Fields: changedFields,
        });
        await editOrganization({
            params: { id: +values.id },
            body: {
                ...values,
                avatar: values.avatar || "",
                send_insights: values.sendInsights,
            },
        })
            .unwrap()
            .then(() => getOrganizations(""))
            .catch(() => {
                const errors = transformApiErrors({ message: "Error" });
                formikHelpers.setErrors(errors);
            })
            .finally(() => {
                setIsOpen(false);
                formikHelpers.setSubmitting(false);
            });
    };

    const handleSubmit = async (values: IOrganization, formikHelpers: FormikHelpers<IOrganization>) => {
        await editOrganizationAction(values, formikHelpers);
    };

    const openModal = () => {
        analyticsService.logEvent("Organization Customization Button Clicked", {
            Page: getPathForAnalytics(location.pathname),
        });
        setIsOpen(true);
    };

    const toggleTagModal = () => {
        setIsTagModalOpen((isTagModalOpen) => !isTagModalOpen);
    };

    const onTagSave = (values: IRevenueTagForTable[]) => {
        let apiResponse = new Promise((resolve, reject) => resolve(""));
        if (shopifyConnector?.id) {
            values.forEach((tagDetails) => {
                if (!tagDetails.id && tagDetails.checked) {
                    apiResponse = createRevenueTag({
                        orgId,
                        connectorId: shopifyConnector.id,
                        body: { tagType: tagDetails.name },
                    });
                } else {
                    if (!tagDetails.checked && tagDetails.id && data?.map((d) => d.id).includes(tagDetails.id)) {
                        apiResponse = deleteRevenueTag({
                            orgId,
                            connectorId: shopifyConnector.id,
                            tagId: tagDetails.id,
                        });
                    }
                }
            });
            apiResponse.then(async (response) => {
                if (response !== "") {
                    await getRevenueTag({
                        orgId,
                        connectorId: shopifyConnector.id,
                    });
                }
                setIsTagModalOpen(false);
            });
        }
    };

    const isApiLoading = isRevenueTagLoading || isRevenueTagFetching || isCreateLoading || isDeleteLoading;

    return (
        <>
            <Stack gap="30px" className={classes.boxStyle} sx={{ marginTop: 3 }}>
                {errState && <Alert severity="error">{errState}</Alert>}
                <h2>General Settings</h2>
                <SettingComponent
                    title="Name & Logo"
                    description="Update your organization's name or logo in Prescient."
                    btnTitle="Update Name or Logo"
                    buttonTooltipTitle={isMember ? "Only admins can change name or logo" : ""}
                    submitClick={() => openModal()}
                    isDisabledBtn={isMember}
                    id="orgUpdate"
                />
            </Stack>

            {isOpen && !isMember && (
                <Modal
                    isOpen={isOpen}
                    title={"Edit organization"}
                    onClose={() => setIsOpen(false)}
                    content={
                        <SimpleOrganizationForm
                            isEditMode={true}
                            initialValues={organization as IOrganization}
                            onSubmit={handleSubmit}
                            featuresList={featuresList}
                        />
                    }
                />
            )}

            {isTagModalOpen && data && (
                <Modal
                    isOpen={isTagModalOpen}
                    title={"Manage Wholesale Tags"}
                    onClose={() => !isApiLoading && toggleTagModal()}
                    width="800px"
                    maxWidth="md"
                    content={
                        <TagModal
                            isApiLoading={isApiLoading}
                            data={data}
                            onTagSave={onTagSave}
                            toggleTagModal={toggleTagModal}
                        />
                    }
                />
            )}
        </>
    );
}
