import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";

export enum ChartModes {
    LINES = "lines",
    STACKED = "stacked",
}

export type ChartMode = ChartModes.LINES | ChartModes.STACKED;

const chartSlice = createSlice({
    name: "chart",
    initialState: ChartModes.LINES,
    reducers: {
        setChartMode: (state, { payload }: PayloadAction<ChartMode>) => payload,
    },
});

export const { setChartMode } = chartSlice.actions;

export default chartSlice.reducer;

// Selectors
export const chartModeSelector = (state: RootState) => state.chart;
