import { keysToCamelCase } from "src/utils/format";
import baseCreateApi from "./baseCreateApi";
import { IFivetranTableDTO } from "src/interfaces/DTO/IFivetranTableDTO";
import { IFivetranTable } from "src/interfaces/entities/IFivetranTable";

const transformFivetranResponse = (data: IFivetranTableDTO): IFivetranTable => ({
    ...keysToCamelCase(data.attributes),
    id: data.id,
});

export const fivetranTables = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getFivetranTables: builder.query<IFivetranTable[], {}>({
            query: () => ({
                url: `fivetran_tables`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            transformResponse: (response: { data: IFivetranTableDTO[] }) =>
                response.data.map(transformFivetranResponse),
        }),
        saveFivetranTable: builder.mutation<IFivetranTable, { name: string; dataSourceId: string }>({
            query: ({ name, dataSourceId }) => ({
                url: `fivetran_tables`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            data_source_id: dataSourceId,
                            name,
                        },
                        type: "fivetran_table",
                    },
                }),
            }),
            transformResponse: (response: { data: IFivetranTableDTO }) => transformFivetranResponse(response.data),
        }),
        editFivetranTable: builder.mutation<IFivetranTable, { fivetranTableId: string; name: string }>({
            query: ({ fivetranTableId, name }) => ({
                url: `fivetran_tables/${fivetranTableId}`,
                method: "PUT",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            name,
                        },
                        type: "fivetran_table",
                    },
                }),
            }),
            transformResponse: (response: { data: IFivetranTableDTO }) => transformFivetranResponse(response.data),
        }),
        deleteFivetranTable: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `fivetran_tables/${id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useLazyGetFivetranTablesQuery,
    useSaveFivetranTableMutation,
    useEditFivetranTableMutation,
    useDeleteFivetranTableMutation,
} = fivetranTables;
