import { TableCell, TableRow, useTheme } from "@mui/material";
import { optimizationStyle } from "src/containers/Optimization/optimizationStyle";
import { IDictionary } from "src/interfaces/IDictionary";
import { ICard } from "src/interfaces/entities/IScenario";

interface IOutcomeTableTotalRow {
    row: IDictionary<any>;
    header: ICard[];
}

export const OutcomeTableTotalRow = ({ header, row }: IOutcomeTableTotalRow) => {
    const classes = { ...optimizationStyle(useTheme()) };

    return (
        <TableRow className={classes.totalRow}>
            {header.map((element: any) => {
                return (
                    <TableCell key={element.id} align={element.align}>
                        {row[element.id]}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};
