import React, { FC, useEffect, useState } from "react";
import {
    Typography,
    Skeleton,
    Checkbox,
    FormControl,
    Select,
    MenuItem,
    ListSubheader,
    ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import groupBy from "lodash/groupBy";
import { Stack } from "@mui/system";

import {
    formattedCompareDateStringSelector,
    formattedCurrentDateStringSelector,
} from "../../../reduxState/slices/dateFilterSlice";
import { emailInsightsModalSelector } from "../../../reduxState/slices/emailInsightsModalSlice";
import { IPerformanceCampaignMetrics } from "../../../interfaces/IPerformanceCampaignMetrics";
import { useLazyGetPerformanceMetricQuery } from "../../../reduxState/apis/performancePageApi";
import { selectCurrentlyViewingId } from "../../../reduxState/slices/organizationSlice";
import {
    getMetricParams,
    getPercentChangeInMetric,
    getSpecificGrpByData,
    removeNegativeTrueMetrics,
} from "../../../services/performancePage/performancePage";
import GroupBySelection from "../../../components/CampaignComponents/InsightsModal/PerformanceTab/GroupBySelection";
import { MetricChart } from "../../../components/CampaignComponents/InsightsModal/PerformanceTab/MetricChart";
import { EMAIL_ATTRIBUTION_HEADERS } from "../../../consts/emailPage/emailPage";
import { getUpdatedChartDataForCustomChartForEmail } from "../../../services/emailPage/emailPage";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";

interface ICustomChart {
    performanceCampaignData: any;
    classes: any;
}

export interface ICustomOptionsType {
    group: string;
    id: string;
    label: string;
    isGroupHeader?: boolean;
    isSecondOrder?: boolean;
    columnName: string;
}

export const CustomChart: FC<ICustomChart> = ({ performanceCampaignData, classes }) => {
    const { campaignId, connectorName, trueRevenue } = performanceCampaignData;

    const currentDate = useSelector(formattedCurrentDateStringSelector);
    const pastDate = useSelector(formattedCompareDateStringSelector);
    const insightsModal = useSelector(emailInsightsModalSelector);
    const connectors = useSelector(connectorsSelector);

    const [grpBy, setGrpBy] = useState("day");

    const [
        getPerformanceMetric,
        {
            data: chartData = {
                data: [],
                percentageData: {},
                meta: { comparisonData: [] },
            } as unknown as IPerformanceCampaignMetrics,
            isFetching,
            isSuccess,
            isUninitialized,
        },
    ] = useLazyGetPerformanceMetricQuery();

    const currentOrgId = useSelector(selectCurrentlyViewingId);

    const metricOptions: ICustomOptionsType[] = [
        { columnName: "Dollar", id: "Dollar($)", label: "Dollar($)", group: "Dollar($)", isGroupHeader: true },
        {
            columnName: "channelReportedRevenue",
            id: "channel_reported_revenue",
            label: "Channel Revenue",
            group: "Dollar($)",
        },
        { columnName: "rpc", id: "rpc", label: "RPC", group: "Dollar($)" },
        { columnName: "aov", id: "aov", label: "AOV", group: "Dollar($)" },
        { columnName: "Numeric", id: "Numeric(#)", label: "Numeric(#)", group: "Numeric(#)", isGroupHeader: true },
        { columnName: "reportedOrders", id: "reported_orders", label: "Reported Orders", group: "Numeric(#)" },
        { columnName: "emailSends", id: "email_sends", label: "Sends", group: "Numeric(#)" },
        { columnName: "emailOpen", id: "email_open", label: "Total Opens", group: "Numeric(#)" },
        { columnName: "emailOpenUnique", id: "email_open_unique", label: "Unique Opens", group: "Numeric(#)" },
        {
            columnName: "emailClicks",
            id: "email_clicks",
            label: "Total Clicks",
            group: "Numeric(#)",
            isSecondOrder: true,
        },
        {
            columnName: "emailClicksUnique",
            id: "email_clicks_unique",
            label: "Unique Clicks ",
            group: "Numeric(#)",
            isSecondOrder: true,
        },
        {
            columnName: "emailBounces",
            id: "email_bounces",
            label: "Bounces",
            group: "Numeric(#)",
            isSecondOrder: true,
        },
        {
            columnName: "emailUnsubscribes",
            id: "email_unsubscribes",
            label: "Unsubscribes",
            group: "Numeric(#)",
            isSecondOrder: true,
        },
        {
            columnName: "Percentage",
            id: "Percentage(%)",
            label: "Percentage(%)",
            group: "Percentage(%)",
            isGroupHeader: true,
        },
        { columnName: "openRate", id: "open_rate", label: "Open Rate", group: "Percentage(%)" },
        { columnName: "clickRate", id: "click_rate", label: "Click Rate", group: "Percentage(%)" },
        { columnName: "bounceRate", id: "bounce_rate", label: "Bounce Rate", group: "Percentage(%)" },
        { columnName: "unsubscribeRate", id: "unsubscribe_rate", label: "Unsubscribe Rate", group: "Percentage(%)" },
    ];

    const [customValue, setCustomValue] = useState<string[]>(["email_open_unique"]);

    useEffect(() => {
        if (campaignId && currentDate[0].startDate && currentDate[0].endDate) {
            getPerformanceMetric({
                orgId: currentOrgId,
                data: {
                    campaign_id: campaignId,
                    start_date: currentDate[0].startDate,
                    end_date: currentDate[0].endDate,
                    metrics: getMetricParams(customValue),
                },
                pastDate: pastDate[0],
            });
        }
    }, [performanceCampaignData, customValue, JSON.stringify(currentDate)]);

    let currentData = [...chartData.data];
    const pastData = [...chartData.meta.comparisonData];

    const getComparisonDataWithLegend = getUpdatedChartDataForCustomChartForEmail(
        pastData,
        customValue,
        connectorName || "",
    );

    const handleChange = (options: string[]) => {
        let selectedOptionStr;
        const selectedOptions = metricOptions.filter((o) => options.includes(o.id));
        if (Object.keys(groupBy(selectedOptions, "group")).length > 1) {
            selectedOptionStr = [options[options.length - 1]];
            setCustomValue([options[options.length - 1]]);
        } else {
            selectedOptionStr = options;
            setCustomValue(options);
        }
    };

    const sortedValuesByDate = [...currentData].sort((a, b) => +new Date(a.date) - +new Date(b.date));
    let getDataWithLegend = getUpdatedChartDataForCustomChartForEmail(currentData, customValue, connectorName || "");

    getDataWithLegend = getSpecificGrpByData(
        grpBy,
        sortedValuesByDate,
        customValue,
        connectorName || "",
        getDataWithLegend,
        getUpdatedChartDataForCustomChartForEmail,
        performanceCampaignData,
        connectors,
    );

    const legendValues = getDataWithLegend.legendValues;
    currentData = removeNegativeTrueMetrics(legendValues, currentData, trueRevenue);
    const percentageData = getPercentChangeInMetric(legendValues, getComparisonDataWithLegend.legendValues);

    const isChartDataReceived = Boolean(!isFetching && !isUninitialized && isSuccess);

    currentData = getDataWithLegend.data;

    const handleGrpByChange = (grp: string) => {
        setGrpBy(grp);
    };

    const selectedSpecificMetric = () =>
        EMAIL_ATTRIBUTION_HEADERS.find((h) =>
            metricOptions
                .filter((op) => customValue.includes(op.id))
                .map((c) => c.columnName)
                .includes(h.id),
        );

    return (
        <div className="customChart">
            <Stack direction="row" justifyContent="space-between">
                <Typography
                    variant="h2"
                    display="block"
                    gutterBottom
                    color="secondary.main"
                    className={classes.boldText}
                >
                    CUSTOM
                </Typography>
                <GroupBySelection grpBy={grpBy} handleGrpByChange={handleGrpByChange} />
            </Stack>
            <FormControl variant="standard" sx={{ mb: 2, minWidth: 210, fontSize: "18px" }}>
                <Select
                    multiple
                    value={customValue}
                    renderValue={(selected) => {
                        const labels = metricOptions.filter((s) => selected.includes(s.id)).map((s) => s.label);
                        return labels.join(", ");
                    }}
                    onChange={(e: any) => handleChange(e.target.value)}
                    id="custom-grouped-select"
                    MenuProps={{ disableScrollLock: true }}
                    sx={{
                        ".MuiSelect-select.MuiInput-input": {
                            whiteSpace: "normal",
                        },
                    }}
                >
                    {metricOptions.map((o) => {
                        return o.isGroupHeader ? (
                            <ListSubheader key={o.id}>{o.label}</ListSubheader>
                        ) : (
                            <MenuItem value={o.id} key={o.id}>
                                <Checkbox checked={customValue.indexOf(o.id) > -1} />
                                <ListItemText primary={o.label} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {isChartDataReceived && currentData.length ? (
                <>
                    <MetricChart
                        chartData={currentData}
                        customValue={customValue.join(",")}
                        id={`email_metric_chart_${campaignId}`}
                        performanceCampaignData={performanceCampaignData}
                        legendValues={legendValues}
                        percentageData={percentageData}
                        pastLegendValues={getComparisonDataWithLegend.legendValues}
                        grpBy={grpBy}
                        metrics={[...EMAIL_ATTRIBUTION_HEADERS]}
                        selectedUnit={selectedSpecificMetric()}
                    />
                </>
            ) : (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="512px"
                    height="200px"
                    style={{ marginTop: "1rem" }}
                />
            )}
        </div>
    );
};
