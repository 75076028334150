import React from "react";
import { useLocation } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/system";
import { css } from "@emotion/css";

import { LightMenu } from "../LightMenu/LightMenu";
import { getFilterName } from "../../services/homeDashboard/homeDashboard";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../services/utils";
import { PRIMARY_MAIN_COLOR } from "../../consts/colors";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";

export enum RelativeDatesInText {
    LAST_7_DAYS = "Last 7 days",
    LAST_14_DAYS = "Last 14 days",
    LAST_30_DAYS = "Last 30 days",
    LAST_90_DAYS = "Last 90 days",
    LAST_12_MONTHS = "Last 12 months",
}

export enum RelativeDatesInNumber {
    LAST_7_DAYS = 7,
    LAST_14_DAYS = 14,
    LAST_30_DAYS = 30,
    LAST_90_DAYS = 90,
    LAST_12_MONTHS = 365,
}

interface IDataFilter {
    title: RelativeDatesInNumber;
    chartTitle?: string;
    isDataStyled?: boolean;
    isDisabled?: boolean;
    className?: string;
    callback(item: RelativeDatesInNumber): void;
}

export const useStylesForDateFilter = (theme: Theme, isDataStyled?: boolean) => ({
    menuBlock: css({
        display: "flex",
        alignItems: "center",
    }),
    dataFilter: css({
        display: "flex",
        alignItems: "center",
        height: isDataStyled ? "56px" : "",
        padding: theme.spacing(1),
        "& .custom-date-picker": {
            textTransform: "capitalize",
        },
    }),
    dateIconStyle: css({
        marginRight: "8px",
        fontSize: "24px",
    }),
    arrowIcon: css({
        marginLeft: 0,
    }),
});

export const DateFilter: React.FC<IDataFilter> = ({
    isDataStyled,
    callback,
    title,
    chartTitle = "Global",
    isDisabled = false,
    className,
}) => {
    const analyticsService = useAnalyticsService();
    const location = useLocation();
    const classes = useStylesForDateFilter(useTheme(), isDataStyled);

    const handleClick = (text: RelativeDatesInText, days: RelativeDatesInNumber) => {
        analyticsService.logEvent("Dates Filtered", {
            Page: getPathForAnalytics(location.pathname),
            Chart: chartTitle,
            "Date selected": text,
        });
        callback(days);
    };

    return (
        <>
            <div className={`${classes.dataFilter} ${className}`}>
                <LightMenu
                    isDisabled={isDisabled}
                    isDataStyled={isDataStyled}
                    items={[
                        {
                            label: RelativeDatesInText.LAST_7_DAYS,
                            onClick: () =>
                                handleClick(RelativeDatesInText.LAST_7_DAYS, RelativeDatesInNumber.LAST_7_DAYS),
                            isSelected: title === RelativeDatesInNumber.LAST_7_DAYS,
                            key: "7days",
                        },
                        {
                            label: RelativeDatesInText.LAST_14_DAYS,
                            onClick: () =>
                                handleClick(RelativeDatesInText.LAST_14_DAYS, RelativeDatesInNumber.LAST_14_DAYS),
                            isSelected: title === RelativeDatesInNumber.LAST_14_DAYS,
                            key: "14days",
                        },
                        {
                            label: RelativeDatesInText.LAST_30_DAYS,
                            onClick: () =>
                                handleClick(RelativeDatesInText.LAST_30_DAYS, RelativeDatesInNumber.LAST_30_DAYS),
                            isSelected: title === RelativeDatesInNumber.LAST_30_DAYS,
                            key: "30days",
                        },
                        {
                            label: RelativeDatesInText.LAST_90_DAYS,
                            onClick: () =>
                                handleClick(RelativeDatesInText.LAST_90_DAYS, RelativeDatesInNumber.LAST_90_DAYS),
                            isSelected: title === RelativeDatesInNumber.LAST_90_DAYS,
                            key: "90days",
                        },
                        {
                            label: RelativeDatesInText.LAST_12_MONTHS,
                            onClick: () =>
                                handleClick(RelativeDatesInText.LAST_12_MONTHS, RelativeDatesInNumber.LAST_12_MONTHS),
                            isSelected: title === RelativeDatesInNumber.LAST_12_MONTHS,
                            key: "12months",
                        },
                    ]}
                    title={
                        <>
                            <SVGIconRenderer
                                icon="calendarIcon"
                                className={classes.dateIconStyle}
                                strokeColor={PRIMARY_MAIN_COLOR}
                            />

                            {getFilterName(title)}
                        </>
                    }
                    header={getFilterName(title)}
                    endIcon
                />
            </div>
        </>
    );
};
