import { css } from "@emotion/css";
import pickBy from "lodash/pickBy";
import queryString from "query-string";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CreateCustomConnectorModal } from "src/components/CustomConnectorModal/CreateCustomConnectorModal";
import { ICustomConnectorFormData } from "src/interfaces/ICustomConnectorFormData";
import { IDictionary } from "src/interfaces/IDictionary";
import { selectDataSourceNameByProgrammaticName } from "src/reduxState/slices/supportedDataSourcesSlice";
import { isLegacyCustomConnector } from "src/services/connectors/connectors";
import { normalizeKeysWithUnderscores } from "src/services/utils";
import {
    IEditConnectorBody,
    useEditConnectorMutation,
    useLazyGetLegacyCustomConnectorsQuery,
    useLegacyCustomConnectorCredentialsAddMutation,
    useLegacyCustomConnectorCredentialsEditMutation,
} from "../../../../reduxState/apis/connectorsApi";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "../../../../reduxState/slices/organizationSlice";
import { AppLovinConnectorModalContent } from "./AppLovinConnectorModalContent";
import { GrinConnectorModalContent } from "./GrinConnectorModalContent";
import { KargoCtvModalContent } from "./KargoCtvModalContent";
import { MountainConnectorModalContent } from "./MountainConnectorModalContent";
import { NeonPixelConnectorModalContent } from "./NeonPixelConnectorModalContent";
import { PartnerizeAffiliateModalContent } from "./PartnerizeAffiliateModalContent";
import { TatariConnectorCredentialModalContent } from "./TatariConnectorCredentialModalContent";

const useStyles = () => ({
    heading: css({
        fontSize: "1.3rem",
        marginTop: "2rem",
        color: "black",
    }),
    info: css({ color: "rgba(0, 0, 0, 0.6)" }),
    link: css({ textDecoration: "none" }),
});

export const CustomConnectorCredentialForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const orgId = useSelector(selectCurrentlyViewingId);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const latestQueryParamsObj = queryString.parse(window.location.search);

    const [formData, setFormData] = useState({} as ICustomConnectorFormData);

    const {
        connectorName = "",
        connectorId = "",
        isConfigSet = false,
        path = "",
    } = (latestQueryParamsObj as {
        connectorName?: string;
        connectorId?: string;
        isConfigSet?: string;
        path?: string;
    }) || {};

    const [legacyCustomConnectorCredentialsAdd, { isLoading }] = useLegacyCustomConnectorCredentialsAddMutation();
    const [legacyCustomConnectorCredentialsEdit] = useLegacyCustomConnectorCredentialsEditMutation();
    const [editConnector] = useEditConnectorMutation();
    const [getLegacyCustomConnectors, { data: legacyCustomConnectorIds, error }] =
        useLazyGetLegacyCustomConnectorsQuery();
    const isConfigSetAlready =
        (isLegacyCustomConnector(connectorName) && !!legacyCustomConnectorIds?.length) || isConfigSet === "true";

    useEffect(() => {
        if (isLegacyCustomConnector(connectorName)) {
            getLegacyCustomConnectors({
                orgId,
                connectorName: connectorName.includes("kargo") ? "kargo" : connectorName,
                connectorId,
            });
        } else {
        }
    }, []);

    // @ts-ignore
    const formattedConnectorName = useSelector((state) => selectDataSourceNameByProgrammaticName(state, connectorName));
    const displayName = formattedConnectorName || connectorName;

    const submitCredentialsHandler = async (formData: any) => {
        isLegacyCustomConnector(connectorName)
            ? submitLegacyCustomConnectorCredentials(formData)
            : submitCustomConnectorCredentials(formData);
    };

    const submitLegacyCustomConnectorCredentials = async (formData: any) => {
        let apiBody: {
            api_key?: string;
            api_username?: string;
            api_password?: string;
            schema?: string;
        } = {
            api_key: formData.apiKey,
            api_username: formData.username,
            api_password: formData.password,
        };

        if (connectorName.includes("kargo")) {
            apiBody = {
                ...apiBody,
                schema: connectorName,
            };
        }

        const baseParams = {
            body: { ...pickBy(apiBody) },
            orgId,
            connectorName: connectorName.includes("kargo") ? "kargo" : connectorName,
            connectorId,
        };

        const apiResponse = isConfigSetAlready
            ? await legacyCustomConnectorCredentialsEdit({
                  ...baseParams,
                  customConnectorId: legacyCustomConnectorIds?.[0].id,
              })
            : await legacyCustomConnectorCredentialsAdd(baseParams);

        if (apiResponse) {
            navigate(path || `/org/${orgCode}/connectors`);
        }
    };

    const submitCustomConnectorCredentials = async (formData: any) => {
        const apiBody: IEditConnectorBody = {
            config: normalizeKeysWithUnderscores(formData),
        };

        const apiResponse = await editConnector({
            body: apiBody,
            orgId,
            connectorId,
        });
        if (apiResponse) {
            navigate(path || `/org/${orgCode}/connectors?connector_id=${connectorId}`);
        }
    };

    const handleFormDataChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((formData) => ({ ...formData, [name]: value }));
    };

    const kargo = {
        component: <KargoCtvModalContent classes={classes} displayName={displayName} />,
        prerequisites: `To connect Kargo, you need a Kargo API OKTA account. Contact your Kargo representative to help get this set up.`,
    };

    const tatari = {
        component: <TatariConnectorCredentialModalContent classes={classes} />,
        prerequisites: [
            "You must have a Tatari account.",
            "There may be a fee associated with the data transfer from Tatari. Please speak to your Tatari representative for more details.",
            "Your Tatari S3 reports may only be available for dates after Tatari enabled your S3 reporting feature, but you may request that they populate earlier data by reaching out to your Tatari representative. Prescient will automatically populate all available S3 report data.",
        ],
    };

    const renderConnector: IDictionary = {
        partnerize_affiliate: {
            component: <PartnerizeAffiliateModalContent classes={classes} />,
            prerequisites: "You must have a Partnerize account.",
        },
        applovin: {
            component: <AppLovinConnectorModalContent classes={classes} displayName={displayName} />,
        },
        neonpixel: { component: <NeonPixelConnectorModalContent classes={classes} /> },
        mountain: { component: <MountainConnectorModalContent classes={classes} displayName={displayName} /> },
        grin: { component: <GrinConnectorModalContent classes={classes} displayName={displayName} /> },
        kargo_ctv: kargo,
        kargo_display: kargo,
        tatari_linear: tatari,
        tatari_streaming: tatari,
    };

    return (
        <CreateCustomConnectorModal
            submitCredentialsHandler={submitCredentialsHandler}
            displayName={displayName}
            isLoading={isLoading}
            error={error}
            isConfigSetAlready={isConfigSetAlready}
            instructions={renderConnector[connectorName]}
            formData={formData}
            handleFormDataChange={handleFormDataChange}
        >
            <Fragment>{renderConnector[connectorName]?.component}</Fragment>
        </CreateCustomConnectorModal>
    );
};
