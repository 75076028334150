import React, { FC } from "react";
import { Breadcrumbs, Theme, Typography, useTheme } from "@mui/material";
import { BreadcrumbData } from "use-react-router-breadcrumbs";
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import { matchPath } from "react-router";
import { useSelector } from "react-redux";

import { allAvailablePaths } from "../../containers/Home/ProtectedOrgRoutes";
import { selectCurrentlyViewingCode } from "../../reduxState/slices/organizationSlice";
import { getDataFromLocalStorage } from "../../services/utils";
import { CONNECTORS_PATH, HOME_PATH, SETTINGS_PATH } from "../../consts/path/path";
import { userStateSelector } from "../../reduxState/slices/userSlice";

interface ICustomBreadCrumbs {
    breadCrumbs: Array<BreadcrumbData<string>>;
    title?: string;
}

const useStyles = (theme: Theme) => ({
    linkClass: css({
        color: theme.palette.text.secondary,
        textDecoration: "none",
        "& :hover": {
            textDecoration: "underLine",
        },
    }),
});

const CustomBreadCrumbs: FC<ICustomBreadCrumbs> = ({ breadCrumbs, title }) => {
    const classes = useStyles(useTheme());

    const orgCode = useSelector(selectCurrentlyViewingCode);
    const campaignDetailsMatch = matchPath("/org/:orgName/campaigns/:campaignId/:tabView", location.pathname);

    const emailCampaignDetailsMatch = matchPath("/org/:orgName/email/:campaignId/:tabView", location.pathname);
    const emailCampaignData = getDataFromLocalStorage("currentEmailCampaignDetails");

    const newConnectorBreadCrumbs = {
        "new-connector": [
            {
                breadcrumb: "Home",
                match: { pathname: "/" },
            },
            {
                breadcrumb: "Settings",
                match: { pathname: `/org/${orgCode}${SETTINGS_PATH}` },
            },
            {
                breadcrumb: "Connectors",
                match: { pathname: `/org/${orgCode}${CONNECTORS_PATH}` },
            },
        ],
    };

    let finalBreadCrumbs = breadCrumbs.filter((props) => {
        const { match } = props;
        if (match.pathname === "/" || allAvailablePaths.map((route) => route.path).includes(match.pattern?.path)) {
            return props;
        }
    });

    finalBreadCrumbs = location.pathname.includes("/new-connector")
        ? (newConnectorBreadCrumbs["new-connector"] as Array<BreadcrumbData<string>>)
        : finalBreadCrumbs;

    const getParentRoute = (breadcrumb: React.ReactNode, match: any) => {
        return location.pathname.includes("/adm")
            ? "Superadmin"
            : location.pathname.includes(match?.params?.campaignId)
            ? title || emailCampaignData?.campaignName
            : breadcrumb;
    };

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
                "& .MuiBreadcrumbs-separator": {
                    color: "#000",
                },
            }}
            data-cy="breadcrumb"
        >
            {finalBreadCrumbs.map(({ match, breadcrumb }, index) => {
                let navigationLink = match.pathname;
                if (
                    (campaignDetailsMatch?.params?.campaignId &&
                        match.pathname.includes(campaignDetailsMatch.params.campaignId)) ||
                    (emailCampaignDetailsMatch?.params?.campaignId &&
                        match.pathname.includes(emailCampaignDetailsMatch.params.campaignId))
                ) {
                    navigationLink = `${navigationLink}/performance/${location.search}`;
                }
                if (!location.pathname.includes("/adm") && navigationLink === "/") {
                    navigationLink = `/org/${orgCode}${HOME_PATH}`;
                }
                return index === finalBreadCrumbs.length - 1 ? (
                    <Typography sx={{ color: "#000" }} key={navigationLink}>
                        {breadcrumb}
                    </Typography>
                ) : (
                    <span key={navigationLink}>
                        <Link className={classes.linkClass} to={navigationLink}>
                            {getParentRoute(breadcrumb, match)}
                        </Link>
                    </span>
                );
            })}
        </Breadcrumbs>
    );
};
export default CustomBreadCrumbs;
