import { CustomConnectorCredentialForm } from "../../components/ConnectorsComponent/Modals/customConnectorsModals/CustomConnectorCredentialForm";
import MainScenarioFormV2 from "../../components/Optimization/Forms/MainScenarioForm.v2";
import {
    BILLING_PATH,
    CAMPAIGNS_PATH,
    CAMPAIGN_INCLUSION_PATH,
    CONNECTORS_PATH,
    CUSTOM_CREATE_CONNECTOR_PATH,
    DATA_INTEGRATION_PATH,
    EMAIL_PATH,
    HOME_PATH,
    MEMBER_PATH,
    OPTIIMIZATION_CREATE_PATH,
    OPTIIMIZATION_PATH,
    PERFORMANCE_CAMPAIGNS_PATH,
    PERFORMANCE_CHANNELS_PATH,
    PERFORMANCE_PAID_PATH,
    PERFORMANCE_TACTICS_PATH,
    SETTINGS_PATH,
    SUPERADMIN_ANNOUNCEMENT_PATH,
    SUPERADMIN_EVENT_LOGS_PATH,
    SUPERADMIN_FEATURES_PATH,
    SUPERADMIN_MEMBERS_PATH,
    SUPERADMIN_ORG_PATH,
    TACTICS_PATH,
    USER_SETTINGS_PATH,
    VERIFY_DATA_PATH,
} from "../../consts/path/path";
import { UserRole } from "../../enums/UserRole";
import Announcement from "../Announcement/Announcement";
import { CampaignDetailsPage } from "../CampaignDetailsPage/CampaignDetailsPage";
import { CampaignPage as CampaignsOverview } from "../CampaignPageV2/CampaignPage";
import { VerifyData } from "../Connectors/DataVerify/VerifyData";
import { DataIntegration } from "../DataSources/DataIntegration";
import { Email } from "../Email/Email";
import { EmailCampaignDetailPage } from "../EmailCampaignDetailPage/EmailCampaignDetailPage";
import { EventLogs } from "../EventLogs/EventLogs";
import { Features } from "../Features/Features";
import { HomePageV1 } from "../HomePage/HomePageV1";
import { Optimization } from "../Optimization/Optimization";
import { OptimizationOutcomePage } from "../OptimizationOutcomePage/OptimizationOutcomePage";
import { OrgSettings } from "../OrgSettings/OrgSettingsV3";
import { Organizations } from "../Organizations/Organizations";
import { SuperAdminMembers } from "../SuperAdminMembers/SuperAdminMembers";

export const ProtectedOrgRoutes = [
    {
        component: <CustomConnectorCredentialForm />,
        path: `/org/:orgName${CUSTOM_CREATE_CONNECTOR_PATH}`,
        accessRoles: [UserRole.ADMIN],
    },
    {
        component: <OrgSettings />,
        path: `/org/:orgName${CONNECTORS_PATH}/*`,
        breadcrumb: "Connectors",
        accessRoles: [UserRole.ADMIN],
    },
    {
        component: <OrgSettings />,
        path: `/org/:orgName${CAMPAIGN_INCLUSION_PATH}/*`,
        breadcrumb: "Campaign Inclusions",
        accessRoles: [UserRole.ADMIN],
    },
    {
        component: <OrgSettings />,
        path: `/org/:orgName${MEMBER_PATH}/*`,
        breadcrumb: "Members",
        accessRoles: [UserRole.ADMIN],
    },
    {
        component: <OrgSettings />,
        path: `/org/:orgName${TACTICS_PATH}/*`,
        breadcrumb: "Tactics",
        accessRoles: [UserRole.ADMIN],
    },
    {
        component: <VerifyData />,
        path: `/org/:orgName${VERIFY_DATA_PATH}`,
        breadcrumb: "Validation",
        accessRoles: [UserRole.ADMIN],
    },
    {
        component: <CampaignsOverview />,
        path: `/org/:orgName${PERFORMANCE_PAID_PATH}`,
        breadcrumb: "Campaigns",
    },
    {
        component: <CampaignsOverview />,
        path: `/org/:orgName${PERFORMANCE_CHANNELS_PATH}`,
        breadcrumb: "Performance",
    },
    {
        component: <CampaignsOverview />,
        path: `/org/:orgName${PERFORMANCE_TACTICS_PATH}`,
        breadcrumb: "Performance",
    },
    {
        component: <CampaignsOverview />,
        path: `/org/:orgName${PERFORMANCE_CAMPAIGNS_PATH}`,
        breadcrumb: "Performance",
    },
    {
        component: <CampaignDetailsPage />,
        path: `/org/:orgName${CAMPAIGNS_PATH}/:campaignId/:tabView`,
    },
    { component: <HomePageV1 />, path: `/org/:orgName${HOME_PATH}`, breadcrumb: "" },
    {
        component: <CampaignDetailsPage />,
        path: `/org/:orgName${CAMPAIGNS_PATH}/:campaignId/:tabView`,
    },
    {
        component: <OrgSettings />,
        path: `/org/:orgName${BILLING_PATH}/*`,
        breadcrumb: "Billing",
        accessRoles: [UserRole.ADMIN],
    },
    {
        component: <Email />,
        path: `/org/:orgName${EMAIL_PATH}`,
        breadcrumb: "Email",
    },
    {
        component: <EmailCampaignDetailPage />,
        path: `/org/:orgName${EMAIL_PATH}/:campaignId/:tabView`,
    },
    {
        component: <Optimization />,
        path: `/org/:orgName${OPTIIMIZATION_PATH}`,
        breadcrumb: "Optimization",
    },
    {
        component: <MainScenarioFormV2 />,
        path: `/org/:orgName${OPTIIMIZATION_PATH}/:scenarioId/edit`,
        breadcrumb: "Optimization",
    },
    {
        component: <OptimizationOutcomePage />,
        path: `/org/:orgName${OPTIIMIZATION_PATH}/:scenarioId`,
        breadcrumb: "Optimization",
    },
    {
        component: <MainScenarioFormV2 />,
        path: `/org/:orgName${OPTIIMIZATION_CREATE_PATH}`,
    },
    {
        component: <OrgSettings />,
        path: `/org/:orgName${SETTINGS_PATH}/*`,
        breadcrumb: "Settings",
        accessRoles: [UserRole.ADMIN],
    },
];

export const superAdminRoutes = [
    {
        component: <SuperAdminMembers />,
        path: SUPERADMIN_MEMBERS_PATH,
        breadcrumb: "Users",
    },
    {
        component: <Organizations />,
        path: SUPERADMIN_ORG_PATH,
        breadcrumb: "Organizations",
    },
    {
        component: <Features />,
        path: SUPERADMIN_FEATURES_PATH,
        breadcrumb: "Features",
    },
    {
        component: <EventLogs />,
        path: SUPERADMIN_EVENT_LOGS_PATH,
        breadcrumb: "Event Logs",
    },
    {
        component: <Announcement />,
        path: SUPERADMIN_ANNOUNCEMENT_PATH,
        breadcrumb: "Announcement",
    },
    {
        component: <DataIntegration />,
        path: `${DATA_INTEGRATION_PATH}/:dataIntegrationTab`,
        breadcrumb: "Data Integrations",
    },
];

export const allAvailablePaths = [
    ...ProtectedOrgRoutes,
    ...superAdminRoutes,
    { path: USER_SETTINGS_PATH, breadcrumb: "User settings" },
    { path: `/org/:orgName${CAMPAIGNS_PATH}/:campaignId` },
    { path: `/org/:orgName${EMAIL_PATH}/:campaignId` },
    { path: "/org/:orgName" },
].filter((p) => p.path !== `/org/:orgName${HOME_PATH}`);
