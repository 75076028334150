import {
    Button,
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC, Fragment, useCallback, useContext } from "react";
import { addDays, format } from "date-fns";
import { toDate } from "date-fns-tz";

import { cursor } from "src/assets/styles/commonStyle";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { Flex } from "src/components/core/Flex/Flex";
import { FORECASTED_CAMPAIGNS_HEADERS, SCENARIO_TYPE } from "src/consts/optimizationPage/optimizationPage";
import { FORMATS } from "src/enums/Formats";
import { declineReasonOptions, getConfidenceLevel } from "src/services/optimizationPage/optimization";
import { formatValue, handleClickWithTextSelectionCheck } from "src/services/utils";
import { ScenarioForecastChart } from "../ScenarioForecastChart";
import {
    ICard,
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
    ScenarioOutcomeTab,
} from "src/interfaces/entities/IScenario";
import { FormattedValue } from "./FormattedValue";
import { IDictionary } from "src/interfaces/IDictionary";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import { OptimizationOutcomeContext } from "src/containers/OptimizationOutcomePage/OptimizationOutcomePage";

interface IOutcomeTableRow {
    outcomeCampaignHeader: ICard[];
    rowClick: () => void;
    campaignRow: IMetricAttributionTableValuesTransformedWithSpendAllocation;
    classes: any;
    campaignOpenId: string;
    scenario: IScenario;
    campaignData: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    isChannelTab?: boolean;
    compareToLabel: string;
    updateCampaignInputForTracking?: (
        campaignRow: IMetricAttributionTableValuesTransformedWithSpendAllocation,
        saveButtonAction?: boolean,
    ) => void;
    activeTab?: ScenarioOutcomeTab;
}

export const OutcomeTableRow: FC<IOutcomeTableRow> = ({
    outcomeCampaignHeader,
    rowClick,
    campaignRow,
    classes,
    campaignOpenId,
    scenario,
    campaignData,
    isChannelTab = false,
    compareToLabel,
    updateCampaignInputForTracking,
    activeTab = ScenarioOutcomeTab.Overview,
}) => {
    const contextProps = useContext(OptimizationOutcomeContext);
    const selectedScenario = contextProps?.selectedScenario;
    const isTrackingTabActive = activeTab === ScenarioOutcomeTab.Tracking;
    const isCacScenario = scenario.scenarioType === SCENARIO_TYPE.CAC;

    let filteredHeader = outcomeCampaignHeader ? outcomeCampaignHeader.filter((h: any) => h.hidden === false) : [];

    if (!filteredHeader) {
        filteredHeader = [...FORECASTED_CAMPAIGNS_HEADERS];
    }

    const getCreatedAtDate = useCallback(() => {
        return selectedScenario?.createdAt ? selectedScenario.createdAt.split("T")[0] : "";
    }, [selectedScenario]);

    const handleChangeInput = (
        e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
        campaign: IMetricAttributionTableValuesTransformedWithSpendAllocation,
        isSave: boolean = false,
    ) => {
        e.stopPropagation();
        if (updateCampaignInputForTracking) {
            updateCampaignInputForTracking(campaign, isSave);
        }
    };

    const getTableRowData = (): IDictionary => {
        return {
            campaignName: (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="20px"
                    sx={{ position: "relative", paddingLeft: isChannelTab ? "50px" : 0 }}
                >
                    {activeTab !== ScenarioOutcomeTab.Tracking && (
                        <SVGIconRenderer
                            icon={campaignOpenId === campaignRow.campaignId ? "chevronDownIcon" : "chevronRightIcon"}
                            strokeColor="black"
                            style={isChannelTab ? { position: "absolute", left: "15px", top: "5px", zIndex: 2 } : {}}
                        />
                    )}
                    <DataSourceAvatar programmaticName={campaignRow.connectorName} className={classes.avatarImage} />
                    <Flex>
                        <Typography className={classes.campaignNameOverflow} title={campaignRow.campaignName || ""}>
                            {campaignRow.campaignName}
                        </Typography>
                    </Flex>
                </Stack>
            ),
            confidenceScore: campaignRow.confidenceScore
                ? `${(campaignRow.confidenceScore * 100).toFixed(0)}% ${getConfidenceLevel(
                      campaignRow.confidenceScore,
                  )}`
                : "",
            spend: formatValue(campaignRow.spend, FORMATS.DOLLAR, 0),
            trueRevenue:
                campaignRow.trueRevenue && campaignRow.trueRevenue < 0
                    ? 0
                    : formatValue(campaignRow.trueRevenue, FORMATS.DOLLAR, 0),
            trueRoas:
                campaignRow.trueRevenue && campaignRow.trueRevenue < 0
                    ? 0
                    : formatValue(campaignRow.trueRoas, FORMATS.NUMERIC, 2),
            newCustomers:
                campaignRow.newCustomers && campaignRow.newCustomers < 0
                    ? 0
                    : formatValue(campaignRow.newCustomers, FORMATS.NUMERIC, 0),
            cac: campaignRow.cac && campaignRow.cac < 0 ? 0 : formatValue(campaignRow.cac, FORMATS.DOLLAR, 2),
            allocationSpend: (
                <>
                    {formatValue(campaignRow.allocationSpend, FORMATS.DOLLAR, 0)}
                    <br />
                    <FormattedValue value={campaignRow.totalSpendDiff} format={FORMATS.DOLLAR} precision={0} />
                </>
            ),
            forecastedRevenue: (
                <>
                    {formatValue(campaignRow.forecastedRevenue, FORMATS.DOLLAR, 0)}
                    <br />
                    <FormattedValue value={campaignRow.totalRevenueDiff} format={FORMATS.DOLLAR} precision={0} />
                </>
            ),
            forecastedRoas: (
                <>
                    {formatValue(campaignRow.forecastedRoas, FORMATS.NUMERIC, 2)}
                    <br />
                    <FormattedValue value={campaignRow.totalRoasDiff} format={FORMATS.NUMERIC} precision={2} />
                </>
            ),
            lastScenarioTimeframeMMMCac: formatValue(campaignRow.lastScenarioTimeframeMMMCac, FORMATS.DOLLAR, 2),
            daysInFlight: <Typography>{campaignRow.daysInFlight}</Typography>,
            forecastedNewCustomers: (
                <>
                    {formatValue(campaignRow.forecastedNewCustomers, FORMATS.NUMERIC, 0)}
                    <br />
                    <FormattedValue value={campaignRow.totalNewCustomersDiff} format={FORMATS.NUMERIC} precision={0} />
                </>
            ),
            forecastedCac: (
                <>
                    {formatValue(campaignRow.forecastedCac, FORMATS.NUMERIC, 2)}
                    <br />
                    <FormattedValue
                        isOpposite={isCacScenario}
                        value={campaignRow.totalCacDiff}
                        format={isCacScenario ? FORMATS.DOLLAR : FORMATS.NUMERIC}
                        precision={2}
                    />
                </>
            ),
            isAccepted: (
                <Stack gap={1}>
                    {isTrackingTabActive ? (
                        campaignRow.isAccepted ? (
                            "Accepted"
                        ) : (
                            campaignRow.declineReason || "--"
                        )
                    ) : (
                        <>
                            <FormGroup
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "nowrap",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={campaignRow.isAccepted === true}
                                            onChange={(e) =>
                                                handleChangeInput(e, {
                                                    ...campaignRow,
                                                    isAccepted: campaignRow.isAccepted === true ? null : true,
                                                    implementationDate: format(
                                                        toDate(getCreatedAtDate()),
                                                        "yyyy-MM-dd",
                                                    ),
                                                    isEdited: true,
                                                })
                                            }
                                        />
                                    }
                                    label="Accept"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={campaignRow.isAccepted === false}
                                            onChange={(e) =>
                                                handleChangeInput(e, {
                                                    ...campaignRow,
                                                    isAccepted: campaignRow.isAccepted === false ? null : false,
                                                    isEdited: true,
                                                })
                                            }
                                        />
                                    }
                                    label="Decline"
                                />
                            </FormGroup>
                        </>
                    )}
                </Stack>
            ),
            implementationDate: isTrackingTabActive ? (
                campaignRow.implementationDate && scenario.createdAt && campaignRow.implementationEndDate ? (
                    `${format(toDate(campaignRow.implementationDate), "MM/dd/yyyy")} - ${format(
                        toDate(campaignRow.implementationEndDate),
                        "MM/dd/yyyy",
                    )}`
                ) : (
                    "--"
                )
            ) : (
                <Stack direction="row">
                    {campaignRow.isAccepted === false ? (
                        <FormControl
                            sx={{ width: "200px", alignSelf: "center", textAlign: "left" }}
                            variant="outlined"
                            size="small"
                        >
                            <InputLabel id="decline-reason-label">Decline Reason</InputLabel>
                            <Select
                                labelId="decline-reason-label"
                                id="decline-reason-select"
                                value={campaignRow.declineReason || ""}
                                onChange={(e) =>
                                    updateCampaignInputForTracking &&
                                    updateCampaignInputForTracking({
                                        ...campaignRow,
                                        declineReason: e.target.value,
                                        isEdited: true,
                                    })
                                }
                                label="Decline Reason"
                            >
                                {declineReasonOptions.map((reason) => (
                                    <MenuItem key={reason} value={reason}>
                                        {reason}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            sx={{ width: "200px", margin: 0 }}
                            type="date"
                            value={campaignRow.implementationDate || ""}
                            onChange={(e) =>
                                updateCampaignInputForTracking &&
                                updateCampaignInputForTracking({
                                    ...campaignRow,
                                    implementationDate: e.target.value,
                                    isEdited: true,
                                })
                            }
                            inputProps={{
                                min: getCreatedAtDate(),
                                max: format(addDays(toDate(getCreatedAtDate()), 7), "yyyy-MM-dd"),
                            }}
                            fullWidth
                            margin="normal"
                            size="small"
                            disabled={!campaignRow.isAccepted}
                        />
                    )}
                    {campaignRow.isEdited && (
                        <Button
                            onClick={(e) => {
                                handleChangeInput(e, campaignRow, true);
                            }}
                            sx={{ marginLeft: "8px" }}
                        >
                            Save
                        </Button>
                    )}
                </Stack>
            ),
        };
    };

    const ignoreCellClick = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, id: string) => {
        if (["isAccepted", "implementationDate"].includes(id)) {
            e.stopPropagation();
        }
    };

    return (
        <Fragment>
            <TableRow onClick={handleClickWithTextSelectionCheck(rowClick)} hover className={cursor("pointer")}>
                {filteredHeader.map((element) => {
                    return (
                        <TableCell
                            key={element.id}
                            className={element.className}
                            align={element.align || "right"}
                            onClick={(e) => ignoreCellClick(e, element.id)}
                        >
                            {getTableRowData()[element.id]}
                        </TableCell>
                    );
                })}
            </TableRow>

            <TableRow>
                <TableCell style={{ padding: 0, borderBottom: 0 }} colSpan={FORECASTED_CAMPAIGNS_HEADERS.length}>
                    <Collapse in={campaignOpenId === campaignRow.campaignId} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                margin: 0,
                                padding: 2,
                                border: "1px solid rgba(0,0,0,0.06)",
                            }}
                        >
                            <ScenarioForecastChart
                                performanceCampaignData={campaignData.find(
                                    (d: any) => d.campaignId === campaignRow.campaignId,
                                )}
                                scenario={scenario}
                                compareToLabel={compareToLabel}
                                campaignRow={campaignRow}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};
