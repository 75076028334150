import { subDays } from "date-fns";

import { IDictionary } from "../../interfaces/IDictionary";
import { ICustomOptionsType } from "../../components/EmailComponents/InsightsModal/CustomChart";
import { FORMATS } from "../../enums/Formats";
import { IMetricAttributionTableValuesTransformed } from "../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { IConnector } from "../../interfaces/IConnector";
import { IDataSource } from "../../interfaces/IData";
import {
    AMAZON_SELLING_PARTNER,
    BIGQUERY_ECOMMERCE,
    GOOGLE_ANALYTICS_4,
    SALESFORCE_COMMERCE_CLOUD,
    SHOPIFY,
} from "src/consts/connectors";

export enum chartGrpKey {
    DAY = "day",
    WEEK = "week",
    MONTH = "month",
    QUATER = "quarter",
    YEAR = "year",
}

export enum CampaignStatus {
    ARCHIVED = "ARCHIVED",
    DELETED = "DELETED",
    PAUSED = "PAUSED",
    ACTIVE = "ACTIVE",
    UNKNOWN = "UNKNOWN",
}

// Array of items that should indicate positive trend when trend is negative. ex: -10% CPM is a good thing so make it green.
export const NEGATIVE_TREND_GOOD = ["CPM", "CPC", "CAC", "MODELED_CAC"];

// These orgs share a SFCC connector. Ideally each org should have its own connector.
export const SFCC_ORG_CODES_MISSING_REVENUE_CONNECTOR = [
    "fhf_gear",
    "phelps_game_calls",
    "the_meateater_store",
    "first_lite",
];

export type Choice = "asc" | "desc";

export interface IPerformanceAttributionTableCells {
    campaignId: string;
    campaignName: string;
    spend?: string;
    impressions?: string;
    cpm?: string;
    clicks?: string;
    cpc?: string;
    sessions?: string;
    trueCart?: string;
    trueOrders?: string;
    trueRevenue?: string;
    trueRoas?: string;
    channelReportedRevenue?: string;
    channelReportedRoas?: string;
    hasSecondOrderEffect?: boolean;
    action?: string;
    status: string;
    newCustomers?: number;
    cac?: number;
    amazonSellingPartnerRevenue?: string;
    storeModeledRevenue?: string;
    baseRevenue: number;
    haloEffectRevenue: number;
    tacticName: number;
}
export interface IHeadCell {
    id: keyof IPerformanceAttributionTableCells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    sign?: FORMATS;
    fixed?: number;
    exportFixed?: number; // used to give more granularity in export values.
    hidden?: boolean;
    width?: string;
    align?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
    className?: string;
    groupName?: string;
}

export interface IExportHead extends Omit<IHeadCell, "id"> {
    id: keyof IPerformanceAttributionTableCells | "channelName";
}

export interface IExtraPercentageRevenue extends Omit<IHeadCell, "id"> {
    id: keyof IPerformanceAttributionTableCells | "modeledPercentage" | "reportedPercentage";
}

export const PERFORMANCE_ATTRIBUTION_HEADERS: readonly IHeadCell[] = [
    {
        id: "campaignName",
        numeric: false,
        disablePadding: false,
        label: "Campaigns",
        hidden: false,
        width: "350px",
    },
    {
        id: "campaignId",
        numeric: false,
        disablePadding: false,
        label: "Campaign Id",
        hidden: true,
    },
    {
        id: "trueRevenue",
        numeric: true,
        disablePadding: false,
        label: "MMM Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
        hidden: false,
        width: "9%",
    },
    {
        id: "channelReportedRevenue",
        numeric: true,
        disablePadding: false,
        label: "Channel Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "trueRoas",
        numeric: true,
        disablePadding: false,
        label: "MMM ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
        width: "8%",
    },
    {
        id: "channelReportedRoas",
        numeric: true,
        disablePadding: false,
        label: "Channel ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "spend",
        numeric: true,
        disablePadding: false,
        label: "Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "impressions",
        numeric: true,
        disablePadding: false,
        label: "Impressions",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
    },
    {
        id: "cpm",
        numeric: true,
        disablePadding: false,
        label: "CPM",
        sign: FORMATS.DOLLAR,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "clicks",
        numeric: true,
        disablePadding: false,
        label: "Clicks",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
    },
    {
        id: "cpc",
        numeric: true,
        disablePadding: false,
        label: "CPC",
        sign: FORMATS.DOLLAR,
        fixed: 2,
        exportFixed: 2,
        hidden: true,
    },
    {
        id: "baseRevenue",
        numeric: true,
        disablePadding: false,
        label: "MMM Base Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
        hidden: true,
    },
    {
        id: "haloEffectRevenue",
        numeric: true,
        disablePadding: false,
        label: "MMM Halo Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
        hidden: true,
    },
    {
        id: "tacticName",
        numeric: false,
        disablePadding: false,
        label: "Tactic",
        hidden: true,
    },
];

export const PERFORMANCE_CAC_METRICS: readonly IHeadCell[] = [
    {
        id: "newCustomers",
        numeric: true,
        disablePadding: false,
        label: "MMM New Customers",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
    },
    {
        id: "cac",
        numeric: true,
        disablePadding: false,
        label: "MMM CAC",
        sign: FORMATS.DOLLAR,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
    },
];

export const HALO_EFFECT_COLUMNS = [
    {
        id: "firstOrderRevenue",
        numeric: true,
        disablePadding: false,
        label: "MMM Base Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "secondOrderRevenue",
        numeric: true,
        disablePadding: false,
        label: "MMM Halo Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "baseRoas",
        numeric: true,
        disablePadding: false,
        label: "MMM Base ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "haloEffectRoas",
        numeric: true,
        disablePadding: false,
        label: "MMM Halo ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
    },
];

export const MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE: IDictionary = {
    [SHOPIFY]: `Shopify`,
    [SALESFORCE_COMMERCE_CLOUD]: `SFCC`,
    [GOOGLE_ANALYTICS_4]: `GA4`,
    [BIGQUERY_ECOMMERCE]: `BigQuery Ecommerce`,
};

export const getModeledMetricLabelBasedOnService = (service: string, addMmmPreset: boolean = true) => {
    const getSource = MODELED_REVENUE_MAPPING_LABEL_BY_SERVICE[service] || "Store";
    if (addMmmPreset) {
        return `MMM ${getSource}`;
    }
    return getSource;
};

export const storeModeledRevenueLabel = (connectors: IConnector[] = []) => {
    if (connectors.find((c) => c.service === SHOPIFY)) {
        return "MMM Shopify";
    } else if (connectors.find((c) => c.service === SALESFORCE_COMMERCE_CLOUD)) {
        return "MMM SFCC";
    } else if (connectors.find((c) => c.dataSource?.programmaticName === BIGQUERY_ECOMMERCE)) {
        return "MMM BigQuery Ecommerce";
    } else if (connectors.find((c) => c.service === GOOGLE_ANALYTICS_4)) {
        return "MMM GA4";
    }
    return "MMM Store Revenue";
};

export const getAmazonRevenueCols = (connectors: IConnector[]) => {
    const columns: IHeadCell[] = [
        {
            id: "storeModeledRevenue",
            numeric: true,
            disablePadding: false,
            label: storeModeledRevenueLabel(connectors),
            sign: FORMATS.DOLLAR,
            fixed: 0,
            exportFixed: 2,
            hidden: true,
        },
        {
            id: "amazonSellingPartnerRevenue",
            numeric: true,
            disablePadding: false,
            label: "MMM Amazon Revenue",
            sign: FORMATS.DOLLAR,
            fixed: 0,
            exportFixed: 2,
            hidden: true,
        },
    ];
    return columns;
};

export const getAmazonRevenueMetricConfig = (connectors: IConnector[]) => {
    return [
        {
            columnName: "store_modeled_revenue",
            id: "store_modeled_revenue",
            label: storeModeledRevenueLabel(connectors),
            group: "Dollar($)",
            isSecondOrder: false,
            sign: FORMATS.DOLLAR,
            fixed: 0,
        },
        {
            columnName: "amazon_selling_partner_revenue",
            id: "amazon_selling_partner_revenue",
            label: "MMM Amazon Revenue",
            group: "Dollar($)",
            isSecondOrder: false,
            sign: FORMATS.DOLLAR,
            fixed: 0,
        },
    ];
};

export const getAllPerformanceTableHeader = (connectors: IConnector[]) => {
    return [...PERFORMANCE_ATTRIBUTION_HEADERS, ...PERFORMANCE_CAC_METRICS, ...getAmazonRevenueCols(connectors)];
};

export const getAllMetricsForAttributionTab = (connectors: IConnector[]) => {
    return [
        ...PERFORMANCE_ATTRIBUTION_HEADERS,
        ...HALO_EFFECT_COLUMNS,
        ...PERFORMANCE_CAC_METRICS,
        ...getAmazonRevenueMetricConfig(connectors),
    ];
};

export const isCACAvailableForCompany = (connService: string = "", orgCode: string = "") => {
    return (
        [SHOPIFY, SALESFORCE_COMMERCE_CLOUD, BIGQUERY_ECOMMERCE].includes(connService) ||
        SFCC_ORG_CODES_MISSING_REVENUE_CONNECTOR.includes(orgCode)
    );
};

export const getPerformanceWithCAC = (
    defaultRevenueConnectorSource: string = "",
    connectors: IConnector[],
    orgCode: string = "",
) => {
    const performanceHeader: IHeadCell[] = [...PERFORMANCE_ATTRIBUTION_HEADERS];

    if (connectors.find((c) => c.service === AMAZON_SELLING_PARTNER)) {
        performanceHeader.splice(5, 0, ...getAmazonRevenueCols(connectors));
    }

    // Add CAC metrics for Shopify, SFCC, BigQuery Ecommerce and orgs with missing revenue connector
    if (isCACAvailableForCompany(defaultRevenueConnectorSource, orgCode)) {
        performanceHeader.splice(5, 0, PERFORMANCE_CAC_METRICS[0], PERFORMANCE_CAC_METRICS[1]);
    }

    return performanceHeader;
};

export const getPerformanceHeaderWithRevenuePercentage = (
    defaultRevenueConnectorSource: string,
    connectors: IConnector[],
) => {
    const performanceHeader: IExtraPercentageRevenue[] = getPerformanceWithCAC(
        defaultRevenueConnectorSource,
        connectors,
    );
    performanceHeader.splice(3, 0, {
        id: "modeledPercentage",
        numeric: true,
        disablePadding: false,
        label: "MMM % of Total",
        sign: FORMATS.PERCENT,
        fixed: 2,
    });
    performanceHeader.splice(5, 0, {
        id: "reportedPercentage",
        numeric: true,
        disablePadding: false,
        label: "Channel % of Total",
        sign: FORMATS.PERCENT,
        fixed: 2,
    });

    return performanceHeader;
};

export const TOTAL_IN_METRIC_ATTRIBUTION_TABLE: IMetricAttributionTableValuesTransformed = {
    campaignId: "All Campaigns",
    campaignName: "All Campaigns",
    spend: 0,
    impressions: 0,
    trueCart: 0,
    trueOrders: 0,
    trueRevenue: 0,
    trueRoas: 0,
    sessions: 0,
    cpc: 0,
    clicks: 0,
    cpm: 0,
    channelReportedRevenue: 0,
    hasSecondOrderEffect: false,
};

export const MAP_VALUE_PARAMS = {
    revenueVsSpend: "metric_names[]=revenue&metric_names[]=spend",
    roasVsSpend: "metric_names[]=spend&metric_names[]=revenue",
    reportedRevenueVsSpend: "metric_names[]=channel_reported_revenue&metric_names[]=spend",
    reportedRoasVsSpend: "metric_names[]=spend&metric_names[]=channel_reported_revenue",
    cacVsSpend: "metric_names[]=spend&metric_names[]=new_customers",
    newCustomersVsSpend: "metric_names[]=new_customers&metric_names[]=spend",
};

export const getPaidDataSources = (dataSources: IDataSource[]) => {
    return dataSources
        .reduce((acc: string[], dataSource) => {
            if (dataSource.displayPerformance) {
                acc.push(dataSource.programmaticName);
            }
            return acc;
        }, [])
        .join(",");
};

export const CAMPARE_CONDITIONS: { [key: string]: string } = {
    lt: "<",
    gt: ">",
    eq: "=",
};

export const PERFORMANCE_FILTER_LIST: Array<{
    key: string;
    apiKey?: string;
    title: string;
    condition: string;
    type: string;
    items: any;
}> = [
    {
        key: "excluded_channels",
        title: "Channel",
        condition: "is NOT",
        type: "checkbox",
        items: [{ key: "", title: "", condition: "" }],
    },
    {
        key: "campaign_name",
        title: "Campaign Name",
        condition: "contains",
        type: "conditionInputs",
        items: [
            { key: "contains", title: "Contains", condition: "contains" },
            { key: "not_contains", title: "Does not contain", condition: "does NOT contain" },
        ],
    },
    {
        key: "properties",
        title: "Property",
        condition: "is",
        type: "checkbox",
        items: [
            { key: "high_return", title: "High Return", condition: "is" },
            { key: "low_return", title: "Low Return", condition: "is" },
            { key: "has_halo_effects", title: "Has Halo Effects", condition: "is" },
        ],
    },
    {
        key: "metric",
        title: "Metric",
        condition: "contains",
        type: "metricConditionInputs",
        items: [
            {
                metric: [],
                condition: [
                    { key: "gt", title: CAMPARE_CONDITIONS.gt, condition: CAMPARE_CONDITIONS.gt },
                    { key: "lt", title: CAMPARE_CONDITIONS.lt, condition: CAMPARE_CONDITIONS.lt },
                    { key: "eq", title: CAMPARE_CONDITIONS.eq, condition: CAMPARE_CONDITIONS.eq },
                ],
                value: "",
            },
        ],
    },
    {
        key: "campaign_id",
        apiKey: "campaignId",
        title: "Campaign Id",
        condition: "is",
        type: "select",
        items: [],
    },
    {
        key: "excluded_tactics",
        title: "Tactic",
        condition: "is NOT",
        type: "checkbox",
        items: [{ key: "", title: "", condition: "" }],
    },
    // {
    //     key: "excluded_state",
    //     title: "Campaign Status",
    //     condition: "is NOT",
    //     type: "checkbox",
    //     items: [
    //         { key: CampaignStatus.ARCHIVED, title: "Archived", condition: "is NOT" },
    //         { key: CampaignStatus.DELETED, title: "Deleted", condition: "is NOT" },
    //         { key: CampaignStatus.PAUSED, title: "Paused", condition: "is NOT" },
    //         { key: CampaignStatus.ACTIVE, title: "Active", condition: "is NOT" },
    //         { key: CampaignStatus.UNKNOWN, title: "Unknown", condition: "is NOT" },
    //     ],
    // },
    {
        key: "campaigns",
        apiKey: "campaignName",
        title: "Campaigns",
        condition: "is",
        type: "select",
        items: [],
    },
];

export const getMetricNames = (connectors: IConnector[]) => {
    const metricNames: { [key: string]: string } = {
        amazonSellingPartnerRevenue: "MMM Amazon Revenue",
        haloEffectRevenue: "MMM Halo Revenue",
        baseRevenue: "MMM Base Revenue",
        storeModeledRevenue: storeModeledRevenueLabel(connectors),
        spend: "Spend",
        impressions: "Impressions",
        cpm: "CPM",
        clicks: "Clicks",
        cpc: "CPC",
        trueRevenue: "MMM Revenue",
        trueRoas: "MMM ROAS",
        revenue: "MMM Revenue",
        roas: "MMM ROAS",
        channelReportedRevenue: "Channel Revenue",
        channel_reported_revenue: "Channel Revenue",
        channelReportedRoas: "Channel ROAS",
        channel_reported_roas: "Channel ROAS",
        first_order_revenue: "MMM Base Revenue",
        second_order_revenue: "MMM Halo Revenue",
        baseRoas: "MMM Base ROAS",
        haloEffectRoas: "MMM Halo ROAS",
        cac: "MMM CAC",
        tacticName: "Tactic",
        new_customers: "MMM New Customers",
        email_sends: "Sends",
        email_open: "Total Opens",
        email_open_unique: "Open Unique",
        email_clicks: "Clicks",
        email_clicks_unique: "Clicks Unique",
        email_bounces: "Bounces",
        email_unsubscribes: "Unsubscribes",
        amazon_selling_partner_revenue: "MMM Amazon Revenue",
        store_modeled_revenue: storeModeledRevenueLabel(connectors),
    };
    return metricNames;
};

export const exportCsvOptions = ["Export with visible columns only", "Export with all columns"];

// here -1 indicate that it will N/A in table row when campaign not exists
export const SAMPLE_IN_METRIC_ATTRIBUTION_TABLE: IMetricAttributionTableValuesTransformed = {
    hasSecondOrderEffect: false,
    spend: 0,
    impressions: 0,
    trueCart: 0,
    sessions: 0,
    cpc: 0,
    clicks: 0,
    cpm: 0,
    trueRevenue: -1,
    trueRoas: -1,
    channelReportedRevenue: -1,
    channelReportedRoas: -1,
    cac: -1,
    newCustomers: -1,
    storeModeledRevenue: -1,
    amazonSellingPartnerRevenue: -1,
    baseRevenue: -1,
    haloEffectRevenue: -1,
};

export const emptyTableCampaign = {
    impressions: 0,
    cpc: null,
    cpm: null,
    trueRoas: -1,
    channelReportedRoas: -1,
    cac: -1,
    spend: 0,
    clicks: 0,
    sessions: 0,
    trueRevenue: -1,
    channelReportedRevenue: -1,
    newCustomers: -1,
    amazonSellingPartnerRevenue: -1,
    storeModeledRevenue: -1,
    baseRevenue: -1,
    haloEffectRevenue: -1,
};

export const TRUE_METRICS = [
    "trueRoas",
    "trueRevenue",
    "cac",
    "newCustomers",
    "amazonSellingPartnerRevenue",
    "storeModeledRevenue",
    "haloEffectRevenue",
    "baseRevenue",
];

export interface IForecastTableCell {
    label: string;
    id: keyof IForecastTableRow;
    width?: string;
    action: boolean;
}

export interface IForecastTableRow {
    id: string;
    label: string;
    days28: string;
    tooltip?: string;
    forecast1: string;
    forecast2: string;
}

export const FORECAST_TABLE_HEADERS_V2: readonly IForecastTableCell[] = [
    {
        label: "Scenario",
        id: "label",
        action: false,
        width: "200px",
    },
    {
        label: "No Change",
        id: "forecast1",
        action: true,
    },
    {
        label: "10% Spend",
        id: "forecast2",
        action: true,
    },
];

export const RETURN_VALUE = [
    { value: MAP_VALUE_PARAMS.revenueVsSpend, label: "MMM Revenue vs Spend" },
    { value: MAP_VALUE_PARAMS.roasVsSpend, label: "MMM ROAS vs Spend" },
    { value: MAP_VALUE_PARAMS.reportedRevenueVsSpend, label: "Channel Revenue vs Spend" },
    { value: MAP_VALUE_PARAMS.reportedRoasVsSpend, label: "Channel ROAS vs Spend" },
    { value: MAP_VALUE_PARAMS.cacVsSpend, label: "MMM CAC vs Spend" },
    { value: MAP_VALUE_PARAMS.newCustomersVsSpend, label: "MMM New Customers vs Spend" },
];

export const CHART_GRP_BY_OPTIONS = [
    { id: chartGrpKey.DAY, label: "Day", isDisabled: false },
    { id: chartGrpKey.WEEK, label: "Week", isDisabled: false },
    { id: chartGrpKey.MONTH, label: "Month", isDisabled: false },
    { id: chartGrpKey.QUATER, label: "Quarter", isDisabled: false },
    { id: chartGrpKey.YEAR, label: "Year", isDisabled: false },
];

export const EXCLUDE_OPTIONS = {
    EXCLUDE_FOR_MONTH3: [chartGrpKey.MONTH, chartGrpKey.QUATER, chartGrpKey.YEAR],
    EXCLUDE_FOR_MONTH6: [chartGrpKey.QUATER, chartGrpKey.YEAR],
    EXCLUDE_FOR_MONTH9: [chartGrpKey.YEAR],
};

export const CUSTOM_METRIC_OPTION: ICustomOptionsType[] = [
    { columnName: "Dollar", id: "Dollar($)", label: "Dollar($)", group: "Dollar($)", isGroupHeader: true },
    { columnName: "spend", id: "spend", label: "Spend", group: "Dollar($)" },
    { columnName: "cpm", id: "cpm", label: "CPM", group: "Dollar($)" },
    { columnName: "cpc", id: "cpc", label: "CPC", group: "Dollar($)" },
    { columnName: "trueRevenue", id: "revenue", label: "MMM Revenue", group: "Dollar($)" },
    {
        columnName: "channelReportedRevenue",
        id: "channel_reported_revenue",
        label: "Channel Revenue",
        group: "Dollar($)",
    },
    {
        columnName: "cac",
        id: "cac",
        label: "MMM CAC",
        group: "Dollar($)",
    },
    {
        columnName: "firstOrderRevenue",
        id: "first_order_revenue",
        label: "MMM Base Revenue",
        group: "Dollar($)",
        isSecondOrder: true,
    },
    {
        columnName: "secondOrderRevenue",
        id: "second_order_revenue",
        label: "MMM Halo Revenue",
        group: "Dollar($)",
        isSecondOrder: true,
    },
    { columnName: "Numeric", id: "Numeric(#)", label: "Numeric(#)", group: "Numeric(#)", isGroupHeader: true },
    { columnName: "impressions", id: "impressions", label: "Impressions", group: "Numeric(#)" },
    { columnName: "clicks", id: "clicks", label: "Clicks", group: "Numeric(#)" },
    { columnName: "trueRoas", id: "roas", label: "MMM ROAS", group: "Numeric(#)" },
    { columnName: "channelReportedRoas", id: "channelReportedRoas", label: "Channel ROAS", group: "Numeric(#)" },
    { columnName: "newCustomers", id: "new_customers", label: "MMM New Customers", group: "Numeric(#)" },
    { columnName: "baseRoas", id: "baseRoas", label: "MMM Base ROAS", group: "Numeric(#)", isSecondOrder: true },
    {
        columnName: "haloEffectRoas",
        id: "haloEffectRoas",
        label: "MMM Halo ROAS",
        group: "Numeric(#)",
        isSecondOrder: true,
    },
];

export enum DataViewAs {
    DAILY = 1,
    DAYS_7 = 7,
    DAYS_14 = 14,
    DAYS_28 = 28,
}

export const getDateRange: IDictionary<{ date: Date; dayCount: number }> = {
    [DataViewAs.DAYS_7]: { date: subDays(new Date(), 7), dayCount: 7 },
    [DataViewAs.DAYS_14]: { date: subDays(new Date(), 14), dayCount: 14 },
    [DataViewAs.DAYS_28]: { date: subDays(new Date(), 28), dayCount: 28 },
};

export const dataViewOption = [
    { lable: "Daily", value: DataViewAs.DAILY },
    { lable: "7 Days", value: DataViewAs.DAYS_7 },
    { lable: "14 Days", value: DataViewAs.DAYS_14 },
    { lable: "28 Days", value: DataViewAs.DAYS_28 },
];

export enum PerformanceTab {
    Channels = "channels",
    Campaigns = "campaigns",
    Tactics = "tactics",
}

export const PerformanceTabKeyMapping: IDictionary = {
    [PerformanceTab.Channels]: "connectorName",
    [PerformanceTab.Tactics]: "tactic",
    [PerformanceTab.Campaigns]: "campaignId",
    default: "campaignId",
};
