import React, { FC, useEffect } from "react";
import { TableBody } from "@mui/material";
import { Range } from "react-date-range";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/system";

import { fixedPerformanceHeader } from "../../../services/performancePage/performancePage";
import { getComparator, stableSort } from "../../../utils/sort";
import { getRangeFormattedTitle } from "../../DateRangeFilter/reactDateRangeUtils";
import { paginationCountSelector } from "../../../reduxState/slices/settingsSlice";
import { formattedCompareDateSelector } from "../../../reduxState/slices/dateFilterSlice";
import { CustomTableStructure } from "../../core/CustomTableStructure/CustomTableStructure";
import { EmailCampaignTableHeader } from "../EmailCampaignTable/EmailCampaignTableHeader";
import { EmailCampaignPercentageTableRow } from "./EmailCampaignPercentageTableRow";
import { IHeadCell, Choice, TOTAL_IN_EMAIL_METRIC_ATTRIBUTION_TABLE } from "../../../consts/emailPage/emailPage";
import { getTotalAmountForEmail, percentageCalculationForEmail } from "../../../services/emailPage/emailPage";
import { IEmail } from "../../../interfaces/entities/IEmail";
import { useStylesForCampaignTable } from "../../../components/CampaignComponents/CampaignTable/CampaignTable";
import { IEmailPercentageTableData } from "../../../interfaces/emailDetails/IEmailPercentage";
import { recalculateSortDirection } from "src/services/utils";

interface IEmailCampaignPercentageTable {
    selectedTableColumns: IHeadCell[];
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    currentDateFilter: Range[];
    finalPercentagePerformanceData: IEmailPercentageTableData;
    setOrder: React.Dispatch<React.SetStateAction<Choice>>;
    order: Choice;
    orderBy: string;
    setOrderBy: React.Dispatch<React.SetStateAction<string>>;
}

export const EmailCampaignPercentageTable: FC<IEmailCampaignPercentageTable> = ({
    selectedTableColumns,
    page,
    setPage,
    currentDateFilter,
    finalPercentagePerformanceData,
    order,
    orderBy,
    setOrder,
    setOrderBy,
}) => {
    const { finalData, currentFilteredData, pastFilteredData } = finalPercentagePerformanceData;
    const classes = useStylesForCampaignTable(useTheme());
    const rowsPerPage = useSelector(paginationCountSelector);
    const pastDateFilter = useSelector(formattedCompareDateSelector);

    const currentTotalAmount = getTotalAmountForEmail(currentFilteredData);
    const pastTotalAmount = getTotalAmountForEmail(pastFilteredData);
    const totalPercentageAmount = percentageCalculationForEmail({
        currentRow: currentTotalAmount,
        pastRow: pastTotalAmount,
    });

    useEffect(() => {
        window.addEventListener("scroll", fixedPerformanceHeader);
        return () => {
            window.removeEventListener("scroll", fixedPerformanceHeader);
        };
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        setOrder(recalculateSortDirection(orderBy, order, property));
        setOrderBy(property);
    };

    return (
        <div style={{ position: "relative" }}>
            <div className={classes.tableBorderClass}></div>
            <CustomTableStructure
                paginationProps={{
                    count: Object.values(finalData).length,
                    page,
                    handleChangePage,
                    handleChangeRowsPerPage,
                }}
                tableProps={{ id: "performance-table", size: "small" }}
                className={classes.noBorderClass}
            >
                <EmailCampaignTableHeader
                    selectedTableColumns={selectedTableColumns}
                    onRequestSort={handleRequestSort}
                    choice={order}
                    choiceBy={orderBy}
                />
                <TableBody>
                    <EmailCampaignPercentageTableRow
                        totalAmount={totalPercentageAmount}
                        row={TOTAL_IN_EMAIL_METRIC_ATTRIBUTION_TABLE}
                        selectedTableColumns={selectedTableColumns}
                        firstColTitle="Total"
                        isPercentRow={true}
                    />
                    <EmailCampaignPercentageTableRow
                        totalAmount={pastTotalAmount}
                        row={TOTAL_IN_EMAIL_METRIC_ATTRIBUTION_TABLE}
                        selectedTableColumns={selectedTableColumns}
                        firstColTitle={getRangeFormattedTitle(pastDateFilter)}
                    />
                    <EmailCampaignPercentageTableRow
                        totalAmount={currentTotalAmount}
                        row={TOTAL_IN_EMAIL_METRIC_ATTRIBUTION_TABLE}
                        selectedTableColumns={selectedTableColumns}
                        firstColTitle={getRangeFormattedTitle(currentDateFilter)}
                    />

                    {stableSort(
                        Object.values(finalData).map((c: any) => c.current),
                        getComparator(order, orderBy),
                    )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((currentRow: any, index: number) => {
                            const pastRow = finalData[currentRow.campaignId].past;

                            const campaignName =
                                (currentRow && currentRow.campaignName) || (pastRow && pastRow.campaignName);
                            const campaignId = (currentRow && currentRow.campaignId) || (pastRow && pastRow.campaignId);

                            return (
                                <React.Fragment key={campaignId}>
                                    <EmailCampaignPercentageTableRow
                                        row={percentageCalculationForEmail({
                                            currentRow,
                                            pastRow,
                                        })}
                                        isBothRowAvail={currentRow && pastRow}
                                        selectedTableColumns={selectedTableColumns}
                                        isPercentRow={true}
                                        firstColTitle={campaignName}
                                        allData={{ pastRow, currentRow }}
                                    />
                                    <EmailCampaignPercentageTableRow
                                        row={pastRow}
                                        selectedTableColumns={selectedTableColumns}
                                        firstColTitle={getRangeFormattedTitle(pastDateFilter)}
                                    />
                                    <EmailCampaignPercentageTableRow
                                        row={currentRow}
                                        selectedTableColumns={selectedTableColumns}
                                        firstColTitle={getRangeFormattedTitle(currentDateFilter)}
                                    />
                                </React.Fragment>
                            );
                        })}
                </TableBody>
            </CustomTableStructure>
        </div>
    );
};
